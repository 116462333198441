import { Col, Row } from 'antd'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'

import {
  Dialog,
  AlertDialog,
  SkeletonList,
  ParcelsItem,
  ParcelsDynamicFilters,
  AlertDialogViewsSwitcher,
  DialogViewsSwitcher,
} from 'src/components/common'
import { Empty, InfiniteScroll, Typography } from 'src/components/ui'

import { useParcelsListFetch } from 'src/bus/parcels'

import { useDialog, usePageUrlContext } from 'src/contexts'

import { GET_ENUMS, getInfiniteListLength, rowGutters } from 'src/utils'

const parcelsListHeader = [
  {
    title: 'parcels:list.number',
    span: 4,
  },
  {
    title: 'parcels:list.status_and_type',
    span: 8,
  },
  {
    title: 'parcels:list.weight',
    span: 3,
  },
  {
    title: 'parcels:list.qty',
    span: 3,
  },
  {
    title: 'parcels:list.amount',
    span: 6,
  },
]

export const ParcelsTabContent = () => {
  const { t } = useTranslation()
  const { initialFilters } = usePageUrlContext()

  const { parcelsList, parcelsListHasMore, onFetchMoreParcelsList, loadingParcelsListFetch } =
    useParcelsListFetch(initialFilters)
  const { onPageDialogs, setOnPageDialog } = useDialog()

  const [selectId, setSelectId] = useState<number>()

  const renderItems = () =>
    parcelsList.pages.map((page) => {
      return page.items.map((item) => <ParcelsItem key={item.id} item={item} onSelectId={setSelectId} />)
    })

  return (
    <div className='flex h-full flex-col py-2' data-test-id='parcels-list'>
      {[
        GET_ENUMS.alertDialog.shipmentParcel,
        GET_ENUMS.alertDialog.sendRequest,
        GET_ENUMS.alertDialog.rejectParcel,
        GET_ENUMS.alertDialog.unpackParcel,
      ].map((key) => {
        return (
          <AlertDialog key={key} open={onPageDialogs[key]?.visible} onOpenChange={(open) => setOnPageDialog(key, open)}>
            <AlertDialogViewsSwitcher name={key} params={{ id: selectId }} />
          </AlertDialog>
        )
      })}
      {[GET_ENUMS.dialog.showQRCode, GET_ENUMS.dialog.trackNumberNL].map((key) => {
        return (
          <Dialog key={key} open={onPageDialogs[key]?.visible} onOpenChange={(open) => setOnPageDialog(key, open)}>
            <DialogViewsSwitcher name={key} params={{ id: selectId }} />
          </Dialog>
        )
      })}
      <ParcelsDynamicFilters />
      <div className='bg-gray-1 px-2 py-3'>
        <Row gutter={rowGutters}>
          {parcelsListHeader.map((item) => {
            return (
              <Col key={item.title} span={item.span}>
                <Typography variant='caption' className='font-semibold'>
                  <span dangerouslySetInnerHTML={{ __html: t(item.title) }} />
                </Typography>
              </Col>
            )
          })}
        </Row>
      </div>
      <InfiniteScroll
        hasMore={parcelsListHasMore}
        loadingFetch={loadingParcelsListFetch}
        onFetchMore={onFetchMoreParcelsList}
        dataLength={getInfiniteListLength(parcelsList.pages)}
        loader={<SkeletonList type='parcels' />}
        scrollableTarget='parcels'
        renderItems={renderItems}
        empty={<Empty title='common:empty.no_activity' description='common:empty.parcels_list' />}
      />
    </div>
  )
}
