import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { Button, Form, FormControl, FormField, FormFields, FormItem, FormMessage } from 'src/components/ui'

import { FormPlanVisitEnum, FormPlanVisitType, FormSubmitFn, VisitAvailableDateType } from 'src/models'

import { formShapes } from 'src/bus/forms'

import { formatDate, messages } from 'src/utils'

type PlanVisitFormProps = {
  onSubmit: FormSubmitFn<FormPlanVisitType>
  loadingFetch?: boolean
  loadingCreate?: boolean
  dates?: VisitAvailableDateType | null
}

export const PlanVisitForm = ({ dates, onSubmit, loadingFetch = false, loadingCreate = false }: PlanVisitFormProps) => {
  const router = useRouter()
  const { t } = useTranslation()

  const formProps = useForm<FormPlanVisitType>({
    defaultValues: { ...formShapes.planVisit.shape },
    resolver: zodResolver(formShapes.planVisit.schema(t)),
  })

  const { control, handleSubmit, setError, reset } = formProps

  const onSubmitForm = handleSubmit(async (values) => {
    await onSubmit({ values, acts: { setError, reset } })
  })

  useDeepCompareEffect(() => {
    if (dates) {
      reset({ date: new Date(dates.date) })
    }
  }, [dates, reset])

  return (
    <Form {...formProps}>
      <FormFields.FormGeneral
        onSubmit={onSubmitForm}
        footer={
          <Button block type='submit' loading={loadingCreate} data-test-id='reschedule-visit-btn'>
            {t('buttons:save')}
          </Button>
        }
      >
        <FormField
          control={control}
          name={FormPlanVisitEnum.date}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormFields.InputCalendar
                  {...field}
                  locale={router.locale}
                  loading={loadingFetch}
                  minDate={dates?.dateRange.start ? new Date(dates.dateRange.start) : undefined}
                  maxDate={dates?.dateRange.end ? new Date(dates.dateRange.end) : undefined}
                  tileDisabled={({ date }) => dates?.excludedDates.includes(formatDate(date, 'dashReversed')) || false}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name={FormPlanVisitEnum.comment}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormFields.InputTextArea
                  {...field}
                  placeholder={t(messages.replanVisit.comment.placeholder)}
                  disabled={loadingCreate}
                  rows={4}
                />
              </FormControl>
              <FormMessage className='absolute' />
            </FormItem>
          )}
        />
      </FormFields.FormGeneral>
    </Form>
  )
}
