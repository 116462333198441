import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'

import { FormSubmitParams, FormVerifyInfoType, HttpErrorType } from 'src/models'

import { customersKeys, useCustomersDetailsFetch, useCustomerVatQuery } from 'src/bus/customers'

import { fillServerErrorsToForm } from 'src/utils'

type CustomersVerifyVatCbType = (data: FormSubmitParams<FormVerifyInfoType>) => void

type UseCustomersVatVerifyType = {
  loadingCustomersVatVerify: boolean
  onVerifyCustomersVat: CustomersVerifyVatCbType
}

export const useCustomersVatVerify = (): UseCustomersVatVerifyType => {
  const queryClient = useQueryClient()
  const { customerId, loadingCustomersDetailsFetch } = useCustomersDetailsFetch()
  const { onUpdateVat } = useCustomerVatQuery(customerId)

  const onVerifyCustomersVat = useCallback<CustomersVerifyVatCbType>(
    (data) => {
      onUpdateVat.mutate(
        { data: { vat: data.values.vat } },
        {
          onSuccess: (data) => {
            queryClient.setQueryData(customersKeys.vatInfo(customerId), data)
          },
          onError: (error) => {
            const errs = (error as HttpErrorType).errors
            errs && fillServerErrorsToForm(errs, data.acts.setError)
          },
        },
      )
    },
    [customerId, onUpdateVat, queryClient],
  )

  return {
    loadingCustomersVatVerify: onUpdateVat.isPending || loadingCustomersDetailsFetch,
    onVerifyCustomersVat,
  }
}
