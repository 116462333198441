import { useMutation, UseMutationResult } from '@tanstack/react-query'

import { ClaimType, HttpErrorType, HttpServerRequestParams } from 'src/models'

import { claimsService } from 'src/bus/claims'

import { queryFetcher } from 'src/utils'

type ClaimsCreateMutationType = {
  onCreateClaimMutation: UseMutationResult<ClaimType, HttpErrorType, HttpServerRequestParams>
}

export const useClaimsCreateMutation = (): ClaimsCreateMutationType => {
  const onCreateClaimMutation = useMutation<ClaimType, HttpErrorType, HttpServerRequestParams>({
    mutationFn: (params) => {
      return queryFetcher({
        name: 'createClaim',
        fetcher: claimsService.createClaim,
        fetcherPayload: params,
      })
    },
  })

  return { onCreateClaimMutation }
}
