import {
  HttpResponse,
  AuthTokenType,
  AuthLoginParamsType,
  AuthProfileType,
  authTokenSchema,
  authProfileSchema,
} from 'src/models'

import { zodValidateResponse } from 'src/utils'

import { httpAxiosProvider } from 'src/api'

export const authService = Object.freeze({
  login: async ({ data }: AuthLoginParamsType) => {
    const response = await httpAxiosProvider.post<HttpResponse<AuthTokenType>>('/auth/login', data)
    zodValidateResponse(authTokenSchema, response.data.data)
    return response
  },
  refresh: async ({ data }: { data: AuthTokenType }) => {
    const response = await httpAxiosProvider.post<HttpResponse<AuthTokenType>>('/auth/refresh', data)
    zodValidateResponse(authTokenSchema, response.data.data)
    return response
  },
  authenticate: async () => {
    const response = await httpAxiosProvider.post<HttpResponse<AuthTokenType>>('/auth/jwt/check')
    zodValidateResponse(authTokenSchema, response.data.data)
    return response
  },
  profile: async () => {
    const response = await httpAxiosProvider.get<HttpResponse<AuthProfileType>>('/profile')
    zodValidateResponse(authProfileSchema, response.data.data)
    return response
  },
  logout: () => {
    return httpAxiosProvider.post<HttpResponse<void>>('/auth/logout')
  },
})
