import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { FormSubmitParams, HttpErrorType, CustomerType } from 'src/models'

import { useCustomerQuery } from 'src/bus/customers'

import { useToast } from 'src/hooks'

import { fillServerErrorsToForm } from 'src/utils'

type UseCustomersDetailsUpdateType = {
  loadingCustomersDetailsUpdate: boolean
  onUpdateCustomersDetails: (data: FormSubmitParams<CustomerType>) => void
}

export const useCustomersDetailsUpdate = (): UseCustomersDetailsUpdateType => {
  const router = useRouter()
  const { t } = useTranslation()
  const showToast = useToast()

  const customerId = Number(router.query.id)

  const { onUpdateCustomer } = useCustomerQuery(customerId)

  const onUpdateCustomersDetails = useCallback(
    (data: FormSubmitParams<CustomerType>) => {
      onUpdateCustomer.mutate(
        { params: { id: customerId }, data: data.values },
        {
          onSuccess: () => {
            showToast.success({ title: t('notifications:customer_update') })
            setTimeout(() => {
              router.back()
            }, 500)
          },
          onError: (error) => {
            const errs = (error as HttpErrorType).errors
            errs && fillServerErrorsToForm(errs, data.acts.setError)
          },
        },
      )
    },
    [onUpdateCustomer, showToast, t, customerId, router],
  )

  return {
    loadingCustomersDetailsUpdate: onUpdateCustomer.isPending,
    onUpdateCustomersDetails,
  }
}
