import type { TFunction } from 'i18next'

import { book } from 'src/init/book'

import type { MenuProfileType } from 'src/models'

import { GET_ENUMS } from 'src/utils'

type GetVisitPlanPanel = {
  t: TFunction
  isPlanedStatus: boolean
  customerId: number
  loadingFetch: boolean
}

export const getVisitHistoryPanel = ({
  t,
  customerId,
  isPlanedStatus,
  loadingFetch,
}: GetVisitPlanPanel): MenuProfileType[] => {
  return [
    {
      title: t('buttons:customer_history'),
      key: `${book.customers}/${customerId}${book.historyAll}`,
      disabled: loadingFetch,
      icon: 'History',
    },
    {
      title: t('buttons:cancel_this_visit'),
      key: GET_ENUMS.dialog.cancelVisit,
      icon: 'MinusCircle',
      type: 'cancel',
      disabled: !isPlanedStatus,
      dialog: true,
    },
    {
      title: t('buttons:reschedule_visit'),
      key: GET_ENUMS.dialog.replanVisit,
      icon: 'Calendar',
      disabled: !isPlanedStatus,
      dialog: true,
    },
  ]
}
