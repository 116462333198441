import dayjs, { Dayjs } from 'dayjs'
import type { TFunction } from 'i18next'
import { z } from 'zod'

import { getNotNil, gtOrEqualOne, messages } from 'src/utils'

export const createVisit = {
  shape: {
    customer: '',
    date: null,
    actions: [],
    comment: '',
  },
  schema: (t: TFunction) => {
    return z
      .object({
        customer: z.string().trim().min(1, t(messages.customer.required)),
        date: z
          .custom<Dayjs>((val) => val instanceof dayjs || typeof val === 'string', t(messages.date.required))
          .nullable()
          .default(null),
        actions: z
          .array(z.string())
          .refine((actions: any) => gtOrEqualOne(getNotNil(actions || []).length), t(messages.actions.requiredOneOf)),
        comment: z
          .string()
          .trim()
          .max(
            messages.createVisitComment.max,
            t(messages.createVisitComment.maxText, {
              label: t(messages.createVisitComment.placeholder).toLowerCase(),
              count: messages.createVisitComment.max,
            }),
          ),
      })
      .nullable()
  },
}
