import toFormData from 'json-form-data'
import { useCallback } from 'react'

import { FormSubmitParams, FormUploadImagesType, HttpErrorType } from 'src/models'

import { useOtrsImagesUploadMutation } from 'src/bus/otrs'

import { fillServerErrorsToForm, getConvertedUploadImgToObj } from 'src/utils'

type OtrsImagesUploadCb = (data: FormSubmitParams<FormUploadImagesType>) => void

type OtrsImagesUpload = {
  loadingOtrsImagesUpload: boolean
  onUploadImages: OtrsImagesUploadCb
  otrsImages: string[]
}

export const useOtrsImagesUpload = (): OtrsImagesUpload => {
  const { onImagesUploadMutation } = useOtrsImagesUploadMutation()

  const onUploadImages = useCallback<OtrsImagesUploadCb>(
    (data) => {
      const values = toFormData({
        ...getConvertedUploadImgToObj(data.values.images || []),
      })

      onImagesUploadMutation.mutate(
        { data: values },
        {
          onError: (error) => {
            data.acts.reset()
            const errs = (error as HttpErrorType).errors
            errs && fillServerErrorsToForm(errs, data.acts.setError)
          },
        },
      )
    },
    [onImagesUploadMutation],
  )

  return {
    loadingOtrsImagesUpload: onImagesUploadMutation.isPending,
    otrsImages: onImagesUploadMutation.data || [],
    onUploadImages,
  }
}
