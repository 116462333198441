import NextImage from 'next/image'
import { memo, useEffect, useMemo, useState } from 'react'
import { decodeBase64Image, isBase64Png } from 'utils/base64Image'

import { ShowQRCode } from 'src/components/common/ShowQRCode'
import { Typography } from 'src/components/ui'

type Base64CheckerProps = {
  base64String?: string
}

export const Base64Checker = memo(({ base64String }: Base64CheckerProps) => {
  const base64StringMemo = useMemo(() => base64String, [base64String])
  const isPngImage = useMemo(() => isBase64Png(base64StringMemo), [base64StringMemo])

  const [imageUrl, setImageUrl] = useState<string | null>(null)

  useEffect(() => {
    if (isPngImage) {
      const url = decodeBase64Image(base64StringMemo)

      setImageUrl(url)

      return () => {
        if (url) URL.revokeObjectURL(url)
      }
    }
  }, [isPngImage, base64StringMemo])

  return (
    <div>
      {isPngImage ? (
        <div className='relative flex h-[382px] w-full justify-center'>
          {imageUrl ? (
            <NextImage src={imageUrl} alt='Base64 Image' fill style={{ objectFit: 'contain' }} />
          ) : (
            <Typography variant='secondary'>Failed to decode</Typography>
          )}
        </div>
      ) : (
        <ShowQRCode value={base64StringMemo} />
      )}
    </div>
  )
})
