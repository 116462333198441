import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import { useCallback, useState } from 'react'

import { HttpErrorType } from 'src/models'

import { parcelsKeys, useParcelsRestoreMutation } from 'src/bus/parcels'

import { useToast } from 'src/hooks'

type RestoreParcelCb = (id?: number) => Promise<void>

type ParcelsRestoreType = {
  loadingParcelsRestore: boolean
  onRestoreParcel: RestoreParcelCb
}

export const useParcelsRestore = (): ParcelsRestoreType => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { onRestoreParcelsMutation } = useParcelsRestoreMutation()
  const showToast = useToast()

  const [isLoading, setIsLoading] = useState(false)

  const onRestoreParcel = useCallback<RestoreParcelCb>(
    async (id) => {
      try {
        setIsLoading(true)

        await onRestoreParcelsMutation.mutateAsync({ params: { id: String(id) } })
        showToast.success({ title: t('notifications:parcel_restore') })
        await queryClient.invalidateQueries({ queryKey: parcelsKeys.all })
      } catch (error) {
        const errs = (error as HttpErrorType).errors
        if (errs) {
          const [[title, body]] = Object.entries(errs)
          showToast.error({ title, body: body.desc })
        }
      } finally {
        setIsLoading(false)
      }
    },
    [t, onRestoreParcelsMutation, showToast, queryClient],
  )

  return {
    loadingParcelsRestore: isLoading,
    onRestoreParcel,
  }
}
