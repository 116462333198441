import { useTranslation } from 'next-i18next'

import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  Typography,
} from 'src/components/ui'

import { useDialog } from 'src/contexts'

import { GET_ENUMS } from 'src/utils'

export const AlertDialogSendRequest = () => {
  const { t } = useTranslation()
  const { setOnPageDialog } = useDialog()

  const onConfirm = async () => {
    setOnPageDialog(GET_ENUMS.dialog.showQRCode, true)
  }

  return (
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>
          <Typography variant='title' color='gray-10'>
            {t('parcels:modal_show_qr.title')}
          </Typography>
        </AlertDialogTitle>
        <AlertDialogDescription>
          <Typography variant='secondary' color='gray-7'>
            {t('parcels:modal_show_qr.description')}
          </Typography>
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel variant='outline' className='w-full'>
          {t('buttons:no')}
        </AlertDialogCancel>
        <Button onClick={onConfirm} block asChild>
          <AlertDialogAction>{t('buttons:yes')}</AlertDialogAction>
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  )
}
