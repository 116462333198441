import type { TFunction } from 'i18next'

import { CellSwipe } from 'src/components/common'

import type { SwipeActionType } from 'src/models'

import { getActionsWithoutHidden } from 'src/utils'

type GetVisitItemActionsProps = {
  onSelectCell: (type: string) => () => void
  isHidden?: boolean
  t: TFunction
}

export const getVisitItemRightActions = ({
  isHidden = false,
  onSelectCell,
  t,
}: GetVisitItemActionsProps): SwipeActionType[] => {
  const actions = [
    {
      key: 'replanVisit',
      text: CellSwipe({
        text: t('visits:visits_list.reschedule_visit'),
        icon: 'Calendar',
        type: 'warning',
      }),
      isHidden,
      onClick: onSelectCell('replan-visit'),
    },
    {
      key: 'cancelVisit',
      text: CellSwipe({
        text: t('visits:visits_list.cancel_visit'),
        icon: 'Cross',
        type: 'tomato',
      }),
      isHidden,
      onClick: onSelectCell('cancel-visit'),
    },
  ]

  return getActionsWithoutHidden(actions)
}
