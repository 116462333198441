import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { Drawer, LanguageFilters, NavigationFilters, UsefulLinksPanel } from 'src/components/common'
import { Icon, ListItem, ScrollArea, Typography } from 'src/components/ui'

import { useDrawer } from 'src/contexts'

import { GET_ENUMS, getProfileMenu } from 'src/utils'

export const ProfileMenu = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { onPageDrawers, setOnPageDrawer } = useDrawer()

  const items = getProfileMenu(t)

  const handleClick = (key: string) => async () => {
    switch (key) {
      case 'languages':
        return setOnPageDrawer(GET_ENUMS.drawer.language, true)
      case 'navigation':
        return setOnPageDrawer(GET_ENUMS.drawer.navigation, true)
      case 'links':
        return setOnPageDrawer(GET_ENUMS.drawer.usefulLinks, true)
      default:
        await router.push(key)
    }
  }

  return (
    <ScrollArea>
      <Drawer
        open={onPageDrawers[GET_ENUMS.drawer.language]}
        onOpenChange={(open) => setOnPageDrawer(GET_ENUMS.drawer.language, open)}
      >
        <LanguageFilters />
      </Drawer>
      <Drawer
        open={onPageDrawers[GET_ENUMS.drawer.navigation]}
        onOpenChange={(open) => setOnPageDrawer(GET_ENUMS.drawer.navigation, open)}
      >
        <NavigationFilters />
      </Drawer>
      <Drawer
        open={onPageDrawers[GET_ENUMS.drawer.usefulLinks]}
        onOpenChange={(open) => setOnPageDrawer(GET_ENUMS.drawer.usefulLinks, open)}
      >
        <UsefulLinksPanel />
      </Drawer>
      {items.map((item) => (
        <ListItem variant='profile' size='md' key={item.key} onClick={handleClick(item.key)}>
          <div className='flex items-center gap-3'>
            <Icon name={item.icon} color='blue-gray-7' />
            <Typography>{item.title}</Typography>
          </div>
          {item.arrow && <Icon name='ChevronRight' color='blue-gray-85' />}
        </ListItem>
      ))}
    </ScrollArea>
  )
}
