import { CustomersDetails } from 'src/components/common'
import { ScrollArea } from 'src/components/ui'

export const ViewCustomersDetails = () => {
  return (
    <ScrollArea className='py-2' data-test-id='customers-details'>
      <CustomersDetails />
    </ScrollArea>
  )
}
