import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'next-i18next'
import { ReactNode } from 'react'
import { useForm } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormFields,
  FormItem,
  FormMessage,
  Separator,
  Typography,
} from 'src/components/ui'

import { FormSubmitFn, FormVerifyInfoEnum, FormVerifyInfoType } from 'src/models'

import { formShapes } from 'src/bus/forms'

import { messages } from 'src/utils'

type VerifyVatInfoProps = {
  onSubmit: FormSubmitFn<FormVerifyInfoType>
  loadingFetch: boolean
  loadingUpdate: boolean
  initialValues?: FormVerifyInfoType
  vatInfoStatus: ReactNode
}

export const VerifyVatInfoForm = ({ onSubmit, initialValues, loadingUpdate, vatInfoStatus }: VerifyVatInfoProps) => {
  const { t } = useTranslation()

  const formProps = useForm<FormVerifyInfoType>({
    defaultValues: { ...formShapes.verifyVatInfo.shape },
    resolver: zodResolver(formShapes.verifyVatInfo.schema(t)),
    mode: 'onSubmit',
  })

  const { control, handleSubmit, setError, reset } = formProps

  const onSubmitForm = handleSubmit(async (values) => {
    await onSubmit({ values, acts: { setError, reset } })
  })

  useDeepCompareEffect(() => {
    if (initialValues) {
      reset(initialValues)
    }
  }, [initialValues, reset])

  return (
    <Form {...formProps}>
      <FormFields.FormGeneral onSubmit={onSubmitForm}>
        <Separator className='mb-4' />
        <div className='flex items-center justify-between'>
          <div className='flex w-5/12'>
            <Typography>{t('customers:vat_info_modal.vat')}</Typography>
          </div>
          <div className='flex w-7/12'>
            <FormField
              control={control}
              name={FormVerifyInfoEnum.vat}
              render={({ field }) => (
                <FormItem gutter='none'>
                  <FormControl>
                    <FormFields.InputBase
                      {...field}
                      placeholder={t(messages.vat.placeholder)}
                      disabled={loadingUpdate}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        {vatInfoStatus}
        <div className='mt-2'>
          <Button block type='submit' loading={loadingUpdate}>
            {t('buttons:verify')}
          </Button>
        </div>
      </FormFields.FormGeneral>
    </Form>
  )
}
