import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'next-i18next'
import { useForm } from 'react-hook-form'

import { Button, Form, FormControl, FormField, FormFields, FormItem, FormMessage } from 'src/components/ui'

import { FormReturnSignature, FormReturnSignatureEnum, FormSubmitFn } from 'src/models'

import { formShapes } from 'src/bus/forms'

type ReturnSignatureFormProps = {
  onSubmit?: FormSubmitFn<FormReturnSignature>
  loadingCreate: boolean
}

export const ReturnSignatureForm = ({ onSubmit, loadingCreate }: ReturnSignatureFormProps) => {
  const { t } = useTranslation()

  const formProps = useForm<FormReturnSignature>({
    defaultValues: { ...formShapes.returnSignature.shape },
    resolver: zodResolver(formShapes.returnSignature.schema(t)),
  })

  const { control, handleSubmit, setError, reset } = formProps

  const onSubmitForm = handleSubmit(async (values) => {
    await onSubmit?.({ values, acts: { setError, reset } })
  })

  return (
    <Form {...formProps}>
      <FormFields.FormGeneral
        onSubmit={onSubmitForm}
        footer={
          <Button block type='submit' loading={loadingCreate}>
            {t('buttons:approve_return')}
          </Button>
        }
      >
        <FormField
          control={control}
          name={FormReturnSignatureEnum.signature}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormFields.InputSignature onChange={field.onChange} disabled={loadingCreate} />
              </FormControl>
              <FormMessage className='absolute' />
            </FormItem>
          )}
        />
      </FormFields.FormGeneral>
    </Form>
  )
}
