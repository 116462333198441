import { useRouter } from 'next/router'

import { ClaimsType } from 'src/models'

import { claimsKeys, claimsService } from 'src/bus/claims'
import { useCustomersDetailsFetch } from 'src/bus/customers'

import { useQueryAsync } from 'src/hooks'

type UseClaimsListFetchType = {
  loadingClaimsListFetch: boolean
  claimsList: ClaimsType
}

export const useClaimsListFetch = (): UseClaimsListFetchType => {
  const router = useRouter()
  const { customerId, loadingCustomersDetailsFetch } = useCustomersDetailsFetch()

  const returnId = Number(router.query.id)
  const status = router.query.status
  const params = {
    ...(returnId && { returnId }),
    ...(customerId && { customerId }),
    ...(status && { filter: { status } }),
  }

  const fetchClaimsListProps = useQueryAsync<ClaimsType>({
    key: claimsKeys.list(params),
    name: 'fetchClaimsList',
    fetcher: claimsService.fetchClaims,
    fetcherPayload: params,
    options: {
      enabled: !!returnId && !!customerId,
    },
  })

  return {
    loadingClaimsListFetch: fetchClaimsListProps.isLoading || loadingCustomersDetailsFetch,
    claimsList: fetchClaimsListProps.data || { items: [], meta: null },
  }
}
