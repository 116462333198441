import { useState } from 'react'

import { FiltersDynamicForm } from 'src/components/common'

import {
  ConvertCodeType,
  FiltersDynamicType,
  FilterStaticDataType,
  FilterStaticEnum,
  FormSubmitParams,
} from 'src/models'

import { useClaimsListFetch, useClaimsSearchListFetch } from 'src/bus/claims'

import { useUrlQuery } from 'src/hooks'

export const ReturnFilter = () => {
  const { onUpdateQuery } = useUrlQuery()

  const [orderId, setOrderId] = useState<number | null>(null)

  const { claimsSearchList, onRefetchClaimsSearchList, loadingClaimsSearchListFetch } =
    useClaimsSearchListFetch(orderId)
  const { claimsList, loadingClaimsListFetch } = useClaimsListFetch()

  const filterSearchItem: FilterStaticDataType = {
    search: {
      name: FilterStaticEnum.search,
      placeholder: 'suggestion.placeholder',
      variant: 'suggestion',
    },
  }

  const onSubmit = async (data: FormSubmitParams<FiltersDynamicType>) => {
    const { filterData } = onUpdateQuery({
      filtersValues: data.values,
      filterItems: filterSearchItem,
    })

    await onRefetchClaimsSearchList(filterData)
  }

  const onUpdateOrderId = ({ orderId }: ConvertCodeType) => setOrderId(orderId)

  return (
    <div className='bg-gray-1 px-2'>
      <FiltersDynamicForm
        onSubmit={onSubmit}
        filterItemsSet={filterSearchItem}
        autocompleteProps={{
          data: { products: claimsSearchList.items, claims: claimsList.items },
          loadingClaimsSearchFetch: loadingClaimsSearchListFetch,
          loadingClaimsFetch: loadingClaimsListFetch,
        }}
        advancedIcon='Camera'
        advancedFn={onUpdateOrderId}
      />
    </div>
  )
}
