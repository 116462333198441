import { useRouter } from 'next/router'
import { useMemo } from 'react'

import { book } from 'src/init/book'

import { CustomerType } from 'src/models'

import { useCustomerQuery } from 'src/bus/customers'
import { useVisitsFetch } from 'src/bus/visits'

type UseCustomersDetailsFetchType = {
  loadingCustomersDetailsFetch: boolean
  customersDetails: CustomerType | null
  customerId: number
}

export const useCustomersDetailsFetch = (customerId?: number): UseCustomersDetailsFetchType => {
  const router = useRouter()
  const { visitFirst, loadingVisitsFetch } = useVisitsFetch()

  const id = useMemo(() => {
    let id = 0

    const customerPage = router.pathname.includes(book.customers)

    if (customerPage) {
      id = Number(router.query.id)
    } else if (router.query?.customerId) {
      id = Number(router.query.customerId)
    } else if (visitFirst?.customerId) {
      id = Number(visitFirst?.customerId)
    }

    return customerId || id
  }, [customerId, router, visitFirst])

  const { customerQuery } = useCustomerQuery(id)

  return {
    loadingCustomersDetailsFetch: customerQuery.isLoading || loadingVisitsFetch,
    customersDetails: customerQuery.data || null,
    customerId: id,
  }
}
