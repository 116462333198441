import { keepPreviousData, useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query'

import {
  CustomerParams,
  CustomersType,
  FilterStaticFilterQueryParamsType,
  FormCreateCustomerType,
  HttpErrorType,
} from 'src/models'

import { customersKeys, customersService } from 'src/bus/customers'

import { getNextPageParam, queryFetcher } from 'src/utils'

export const useCustomersQuery = (initialQuery?: FilterStaticFilterQueryParamsType) => {
  const queryClient = useQueryClient()

  const customersQuery = useInfiniteQuery<CustomersType>({
    queryKey: customersKeys.list(initialQuery),
    queryFn: ({ pageParam }) =>
      queryFetcher({
        name: 'fetchCustomersList',
        fetcher: customersService.fetchCustomersList,
        fetcherPayload: { params: { ...initialQuery, page: pageParam } },
      }),
    initialPageParam: 1,
    getNextPageParam,
    placeholderData: keepPreviousData,
    refetchOnMount: true,
  })

  const onCreateCustomer = useMutation<FormCreateCustomerType, HttpErrorType, CustomerParams>({
    mutationFn: (params) => {
      return queryFetcher({
        name: 'createCustomer',
        fetcher: customersService.createCustomer,
        fetcherPayload: params,
      })
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: customersKeys.list(initialQuery) })
    },
  })

  return {
    customersQuery,
    onCreateCustomer,
  }
}
