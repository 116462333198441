export const initialState = {
  statuses: {
    isInitialized: false,
    isAuthenticated: false,
  },
  token: '',
}

export const authKeys = {
  all: ['auth'],
  login: () => [...authKeys.all, 'login'],
  profile: (locale?: string) => [...authKeys.all, 'profile', locale],
}
