import { AlertDialog, AlertDialogViewsSwitcher, ParcelsDetails } from 'src/components/common'

import { useParcelsDetailsFetch } from 'src/bus/parcels'

import { useDialog } from 'src/contexts'

import { GET_ENUMS } from 'src/utils'

import st from './Styles.module.css'

type ViewParcelsDetailsProps = {
  onBack: () => void
}

export const ViewParcelsDetails = ({ onBack }: ViewParcelsDetailsProps) => {
  const { parcelsDetails, loadingParcelsDetailsFetch } = useParcelsDetailsFetch()
  const { onPageDialogs, setOnPageDialog } = useDialog()

  return (
    <div className={st['parcels-details']} data-test-id='parcels-details'>
      <AlertDialog
        open={onPageDialogs[GET_ENUMS.alertDialog.leavePage]?.visible}
        onOpenChange={(open) => setOnPageDialog(GET_ENUMS.alertDialog.leavePage, open)}
      >
        <AlertDialogViewsSwitcher name={GET_ENUMS.alertDialog.leavePage} params={{ onConfirm: onBack }} />
      </AlertDialog>
      <ParcelsDetails data={parcelsDetails} loadingFetch={loadingParcelsDetailsFetch} />
    </div>
  )
}
