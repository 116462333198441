import { cva, VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { cn } from 'src/utils'

const visitHistoryStatusVariants = cva('rounded-full', {
  variants: {
    variant: {
      plan: 'bg-status-plan',
      done: 'bg-status-done',
      canceled: 'bg-status-canceled',
    },
    size: {
      default: 'w-2 h-2',
    },
  },
  defaultVariants: {
    variant: 'plan',
    size: 'default',
  },
})

export type VisitHistoryStatusProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof visitHistoryStatusVariants>

const VisitHistoryStatus = React.forwardRef<HTMLDivElement, VisitHistoryStatusProps>(
  ({ variant, size, className, ...props }, ref) => {
    return <div className={cn(visitHistoryStatusVariants({ variant, size, className }))} ref={ref} {...props} />
  },
)

VisitHistoryStatus.displayName = 'VisitHistoryStatus'

export { VisitHistoryStatus }
