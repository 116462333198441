import { Logo, SigninForm } from 'src/components/common'

import { useAuthLogin } from 'src/bus/auth'

export const IntroSignin = () => {
  const { onLoginAuth, loadingAuthLogin } = useAuthLogin()

  return (
    <div className='flex h-full items-center justify-center'>
      <div className='basis-8/12'>
        <div className='mb-10'>
          <Logo />
        </div>
        <SigninForm loadingCreate={loadingAuthLogin} onSubmit={onLoginAuth} />
      </div>
    </div>
  )
}
