import { useRouter } from 'next/router'

import { ManageToursDaysItemsType, ManageToursDaysListType } from 'src/models'

import { manageToursKeys, manageToursService } from 'src/bus/manageTours'

import { useQueryAsync } from 'src/hooks'

type ManageToursDaysFetchType = {
  loadingManageToursDaysFetch: boolean
  manageToursDays: ManageToursDaysListType
}

export const useManageToursDaysFetch = (): ManageToursDaysFetchType => {
  const router = useRouter()

  const fetchManagePointsDaysProps = useQueryAsync<ManageToursDaysItemsType>({
    key: manageToursKeys.days(router.locale),
    name: 'fetchUserOptionsSectors',
    fetcher: manageToursService.fetchDays,
  })

  return {
    loadingManageToursDaysFetch: fetchManagePointsDaysProps.isLoading,
    manageToursDays: fetchManagePointsDaysProps.data?.items || [],
  }
}
