import { StatusItem } from 'src/components/common'

import { SelectItem } from 'src/models'

type CellStatusProps = {
  value?: SelectItem
  loadingFetch?: boolean
  centered?: boolean
}

export const CellStatus = ({ value = null, loadingFetch = false, centered = false }: CellStatusProps) => {
  return <StatusItem status={value} loadingFetch={loadingFetch} className={centered ? 'mx-auto my-0' : ''} />
}
