import type { TFunction } from 'i18next'

import { book } from 'src/init/book'

import type { MenuProfileType, VisitType } from 'src/models'

import { GET_ENUMS } from 'src/utils'

export type VisitsMenuProps = {
  visitFirst: VisitType | null
  t: TFunction
}

export const getVisitsMenu = ({ visitFirst, t }: VisitsMenuProps): MenuProfileType[] => {
  return [
    {
      title: t('buttons:cancel_all_visits'),
      key: GET_ENUMS.dialog.stopTour,
      disabled: !visitFirst,
      icon: 'Stop',
      type: 'cancel',
      dialog: true,
    },
    {
      title: t('buttons:create_new_visit'),
      key: book.visitsCreate,
      icon: 'Plus',
    },
  ]
}
