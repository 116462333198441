import { LoyaltyProgramProgressBar } from 'src/components/common'

import { CustomerLoyaltyType } from 'src/models'

type CellProgressBarProps = {
  value: CustomerLoyaltyType
  loadingFetch?: boolean
}

export const CellProgressBar = ({ value = null, loadingFetch = false }: CellProgressBarProps) => {
  return <LoyaltyProgramProgressBar value={value} loadingFetch={loadingFetch} />
}
