import { useTranslation } from 'next-i18next'

import { DeleteParcelsArticleForm } from 'src/components/common'
import { DialogContent, DialogHeader, Typography } from 'src/components/ui'

import { ParcelsArticleType } from 'src/models'

import { useParcelsDelete } from 'src/bus/parcels'

import { GET_ENUMS } from 'src/utils'

type DialogDeleteArticleProps = {
  loadingFetch?: boolean
  parcelsArticle?: ParcelsArticleType | null
}

export const DialogDeleteArticle = ({ parcelsArticle = null }: DialogDeleteArticleProps) => {
  const { t } = useTranslation()
  const { onDeleteParcels, loadingParcelsDelete } = useParcelsDelete()

  return (
    <DialogContent name={GET_ENUMS.dialog.deleteArticle}>
      <DialogHeader>
        <Typography variant='title' color='gray-10'>
          {t('parcels:modal_article_delete.title')}
        </Typography>
      </DialogHeader>
      <div className='flex flex-col gap-8'>
        <Typography variant='secondary' color='gray-7' className='text-center'>
          {t('parcels:modal_article_delete.description')}
        </Typography>
        <DeleteParcelsArticleForm
          onSubmit={(data) => onDeleteParcels(data, parcelsArticle?.id)}
          loadingFetch={loadingParcelsDelete}
          initialValues={{ quantity: parcelsArticle?.quantity }}
          parcelsArticlesDetails={parcelsArticle}
        />
      </div>
    </DialogContent>
  )
}
