import { OrdersDeliveriesType } from 'src/models'

import { ordersKeys, ordersService } from 'src/bus/orders'

import { useQueryAsync } from 'src/hooks'

type UseOrdersDeliveriesFetchType = {
  loadingOrdersDeliveriesListFetch: boolean
  ordersDeliveriesList: OrdersDeliveriesType
}

type OrdersDeliveriesProps = {
  orderId: number
  customerId: number
}

export const useOrdersDeliveriesFetch = (params: OrdersDeliveriesProps): UseOrdersDeliveriesFetchType => {
  const fetchOrdersDeliveriesProps = useQueryAsync<OrdersDeliveriesType>({
    key: ordersKeys.deliveriesList(params),
    name: 'fetchOrdersDeliveries',
    fetcher: ordersService.fetchOrdersDeliveries,
    fetcherPayload: params,
    options: { enabled: !!params },
  })

  return {
    loadingOrdersDeliveriesListFetch: fetchOrdersDeliveriesProps.isLoading,
    ordersDeliveriesList: fetchOrdersDeliveriesProps.data || { items: [] },
  }
}
