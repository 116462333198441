import { PropsWithChildren, useState } from 'react'

import { createStrictContext, useStrictContext } from 'src/utils'

type DialogState = {
  visible: boolean
  zIndex: number
}

type ContentType = {
  onPageDialogs: Record<string, DialogState>
  setOnPageDialog: (type: string, visible: boolean) => void
}

const DialogContext = createStrictContext<ContentType>()

export const DialogContextProvider = ({ children }: PropsWithChildren) => {
  const [onPageDialogs, setOnPageDialog] = useState<ContentType['onPageDialogs']>({})
  const [baseZIndex, setBaseZIndex] = useState(110)

  return (
    <DialogContext.Provider
      value={{
        onPageDialogs,
        setOnPageDialog: (dialogType, visible) => {
          setOnPageDialog((prev) => {
            const currentZIndex = prev[dialogType]?.zIndex || baseZIndex

            return {
              ...prev,
              [dialogType]: {
                visible,
                zIndex: visible ? baseZIndex + 1 : currentZIndex,
              },
            }
          })
          if (visible) setBaseZIndex((prev) => prev + 1)
        },
      }}
    >
      {children}
    </DialogContext.Provider>
  )
}

export const useDialog = () => useStrictContext(DialogContext)
