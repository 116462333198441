import type { TFunction } from 'i18next'

import { CellSwipe } from 'src/components/common'

import type { ParcelsArticleType, SwipeActionType } from 'src/models'

import { getActionsWithoutHidden } from 'src/utils'

type GetParcelsArticlesActionsProps = {
  onSelectCell: (type: string) => void
  isDeleted: boolean
  article: ParcelsArticleType
  t: TFunction
}

export const getParcelsArticlesLeftActions = ({
  onSelectCell,
  article,
  isDeleted,
  t,
}: GetParcelsArticlesActionsProps): SwipeActionType[] => {
  const actions = [
    {
      key: 'delete',
      text: CellSwipe({
        text: t('buttons:delete'),
        icon: 'Delete',
        type: 'danger',
      }),
      isHidden: isDeleted || !!article.parcelId,
      onClick: () => onSelectCell('delete'),
    },
    {
      key: 'recover',
      text: CellSwipe({
        text: t('buttons:recover'),
        icon: 'RotateLeft',
        type: 'green',
      }),
      isHidden: !isDeleted,
      onClick: () => onSelectCell('recover'),
    },
    {
      key: 'manual',
      text: CellSwipe({
        text: t('buttons:manual'),
        icon: 'Manual',
        type: 'yellow',
      }),
      isHidden: true,
      onClick: () => onSelectCell('manual'),
    },
  ]

  return getActionsWithoutHidden(actions)
}
