import { KamCustomerType } from 'src/models'

import { kamCustomersService } from 'src/bus/kam-customers/service'
import { kamCustomersKeys } from 'src/bus/kam-customers/store'

import { useQueryAsync } from 'src/hooks'

type KamCustomersFetchType = {
  loadingKamCustomersFetch: boolean
  kamCustomers: KamCustomerType | null
}

export const useKamCustomersFetch = (id: number, isKamMarketType: boolean): KamCustomersFetchType => {
  const params = { id }

  const fetchKamCustomersProps = useQueryAsync<KamCustomerType>({
    key: kamCustomersKeys.list(params),
    name: 'fetchCustomersCredentials',
    fetcher: kamCustomersService.fetchKamCustomers,
    fetcherPayload: params,
    options: { enabled: !!id && isKamMarketType },
  })

  return {
    loadingKamCustomersFetch: fetchKamCustomersProps.isLoading,
    kamCustomers: fetchKamCustomersProps.data || null,
  }
}
