import {
  HttpResponse,
  VisitParamsType,
  VisitsHistoryType,
  VisitCreateReportParamsType,
  VisitHistoryType,
  VisitCancelParamsType,
  visitHistorySchema,
  visitHistoryListSchema,
  baseSelectListSchema,
  SelectListType,
} from 'src/models'

import { zodValidateResponse } from 'src/utils'

import { httpAxiosProvider } from 'src/api'

export const visitsHistoryService = Object.freeze({
  fetchVisitsHistory: async ({ params }: VisitParamsType) => {
    const response = await httpAxiosProvider.get<HttpResponse<VisitsHistoryType>>('/visit-history', { params })
    zodValidateResponse(visitHistoryListSchema, response.data.data)
    return response
  },
  fetchVisitsHistoryDetails: async ({ params }: VisitParamsType) => {
    const response = await httpAxiosProvider.get<HttpResponse<VisitHistoryType>>(`/visit-history/${params?.id}`)
    zodValidateResponse(visitHistorySchema, response.data.data)
    return response
  },
  fetchVisitsHistoryReport: ({ params }: VisitParamsType) => {
    return httpAxiosProvider.get<HttpResponse<VisitCreateReportParamsType>>(`/visit/${params?.id}/report`)
  },
  fetchVisitsHistorySectors: async () => {
    const response = await httpAxiosProvider.get<HttpResponse<SelectListType>>('/visit-history/options/sectors')
    zodValidateResponse(baseSelectListSchema, response.data.data)
    return response
  },
  fetchVisitsHistoryCustomers: async () => {
    const response = await httpAxiosProvider.get<HttpResponse<SelectListType>>('/visit-history/options/customers')
    zodValidateResponse(baseSelectListSchema, response.data.data)
    return response
  },
  fetchVisitsHistoryReasonCancel: ({ params }: VisitParamsType) => {
    return httpAxiosProvider.get<HttpResponse<VisitCancelParamsType>>(`/visit/${params?.id}/cancel`)
  },
})
