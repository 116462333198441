import { PropsWithChildren, ReactNode, useState } from 'react'

import { createStrictContext, useStrictContext } from 'src/utils'

type ContentType = {
  isDrawer: boolean
  onPageDrawers: Record<string, boolean>
  setOnPageDrawer: (type: string, visible: boolean) => void
  setIsDrawer: (value: boolean) => void
}

type DrawerContextProviderProps = {
  children?: ReactNode
}

const DrawerContext = createStrictContext<ContentType>()

export const DrawerContextProvider = ({ children }: PropsWithChildren<DrawerContextProviderProps>) => {
  const [isDrawer, setIsDrawer] = useState<boolean>(false)
  const [onPageDrawers, setOnPageDrawer] = useState<ContentType['onPageDrawers']>({})

  return (
    <DrawerContext.Provider
      value={{
        isDrawer,
        onPageDrawers,
        setIsDrawer: (newIsDrawer) => {
          setIsDrawer(newIsDrawer)
        },
        setOnPageDrawer: (dialogType, visible) => {
          setOnPageDrawer((prev) => ({
            ...prev,
            [dialogType]: visible,
          }))
        },
      }}
    >
      {children}
    </DrawerContext.Provider>
  )
}
export const DrawerContextConsumer = DrawerContext.Consumer

export const useDrawer = () => useStrictContext(DrawerContext)
