import { FiltersDynamicForm } from 'src/components/common'

import { FilterStaticDataType, FilterStaticEnum, FilterStaticType, FormSubmitParams } from 'src/models'

import { useCustomersFilters, useCustomersListFetch } from 'src/bus/customers'

import { useUrlQuery } from 'src/hooks'

export const CustomersFilters = () => {
  const { onRefetchCustomersList, customersFiltersStaticInitialValues } = useCustomersListFetch()
  const { onUpdateQuery } = useUrlQuery()
  const { customersFilters } = useCustomersFilters()

  const filterStaticItems: FilterStaticDataType = {
    businessType: {
      name: FilterStaticEnum.businessType,
      options: customersFilters.businessType,
      isMulti: true,
    },
    customerStatus: {
      name: FilterStaticEnum.customerStatus,
      options: customersFilters.customerStatus,
      isMulti: true,
    },
    planVisits: {
      name: FilterStaticEnum.planVisits,
      options: customersFilters.planVisits,
    },
    potential: {
      name: FilterStaticEnum.potential,
      options: customersFilters.potential,
      isMulti: true,
    },
    quantityEmployees: {
      name: FilterStaticEnum.quantityEmployees,
      options: customersFilters.quantityEmployees,
      isMulti: true,
    },
    sectors: {
      name: FilterStaticEnum.sectors,
      options: customersFilters.sectors,
      isMulti: true,
    },
    zones: {
      name: FilterStaticEnum.zones,
      options: customersFilters.zones,
      isMulti: true,
    },
  }

  const filterSearchItem: FilterStaticDataType = {
    search: {
      name: FilterStaticEnum.search,
      placeholder: 'search.placeholder',
    },
  }

  const onSubmit = async (data: FormSubmitParams<FilterStaticType>) => {
    const { filterData } = onUpdateQuery({
      filtersValues: data.values,
      filterItems: filterStaticItems,
    })

    await onRefetchCustomersList(filterData)
  }

  return (
    <div className='px-2'>
      <FiltersDynamicForm
        filterItemsSet={filterSearchItem}
        onSubmit={onSubmit}
        initialValues={customersFiltersStaticInitialValues.filter}
      />
    </div>
  )
}
