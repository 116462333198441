import { Col, Row } from 'antd'

import { WrappedNodeDefault } from 'src/components/common'
import { ListItem, Typography } from 'src/components/ui'
import { InputRadio } from 'src/components/ui/FormFields'

import { rowGutters } from 'src/utils'

type ParcelsReplaceNewItemProps = {
  item: { id: number; title: string }
  onSelectId: (id: number) => void
  isSelected: boolean
}

export const ParcelsReplaceNewItem = ({ item, isSelected, onSelectId }: ParcelsReplaceNewItemProps) => {
  return (
    <ListItem size='sm' onClick={() => onSelectId(item.id)}>
      <div className='grid h-[44px] w-full'>
        <Row align='middle' gutter={rowGutters}>
          <Col span={3}>
            <InputRadio checked={isSelected} />
          </Col>
          <Col span={21}>
            <Typography variant='secondary'>{WrappedNodeDefault(item.title)}</Typography>
          </Col>
        </Row>
      </div>
    </ListItem>
  )
}
