import { StaticDataZonesType } from 'src/models'

import { staticDataKeys, staticDataService } from 'src/bus/staticData'

import { useQueryAsync } from 'src/hooks'

type UseStaticDataZonesFetchType = {
  loadingStaticDataZonesFetch: boolean
  staticDataZones: StaticDataZonesType
}

export const useStaticDataZonesFetch = (): UseStaticDataZonesFetchType => {
  const fetchStaticDataZonesProps = useQueryAsync<StaticDataZonesType>({
    key: staticDataKeys.zones(),
    name: 'fetchStaticDataZones',
    fetcher: staticDataService.fetchStaticDataZones,
  })

  return {
    loadingStaticDataZonesFetch: fetchStaticDataZonesProps.isLoading,
    staticDataZones: fetchStaticDataZonesProps.data || { zones: [] },
  }
}
