import { useRouter } from 'next/router'
import QS from 'qs'
import { useCallback } from 'react'

import { FilterStaticFilterQueryParamsType, CustomersInfiniteType, TRefetchFilterStaticCb } from 'src/models'

import { customersState, useCustomersQuery } from 'src/bus/customers'

import { getParamsFromQueryStr } from 'src/utils'

type UseCustomersListFetchType = {
  loadingCustomersListFetch: boolean
  customersListHasMore: boolean
  customersList: CustomersInfiniteType
  onFetchMoreCustomersList: () => void
  onRefetchCustomersList: TRefetchFilterStaticCb
  customersFiltersStaticInitialValues: FilterStaticFilterQueryParamsType
}

export const useCustomersListFetch = (): UseCustomersListFetchType => {
  const router = useRouter()

  const initialQuery = getParamsFromQueryStr(router.query)

  const { customersQuery } = useCustomersQuery(initialQuery)

  const onRefetchCustomersList = useCallback<TRefetchFilterStaticCb>(
    async (values) => {
      const newParams = { ...initialQuery, ...values?.filterData }
      await router.replace({ pathname: router.pathname, query: QS.stringify(newParams) })
    },
    [router, initialQuery],
  )

  return {
    loadingCustomersListFetch: customersQuery.isLoading,
    customersFiltersStaticInitialValues: initialQuery,
    onFetchMoreCustomersList: customersQuery.fetchNextPage,
    customersListHasMore: customersQuery.hasNextPage,
    customersList: customersQuery.data || customersState.customers,
    onRefetchCustomersList,
  }
}
