import { useTranslation } from 'next-i18next'
import Link from 'next/link'

import { book } from 'src/init/book'

import { BottomNavigationBar } from 'src/components/common'
import { Button } from 'src/components/ui'

export const CustomersFooter = () => {
  const { t } = useTranslation()

  return (
    <div className='shadow-level-2'>
      <div className='p-2'>
        <Button block>
          <Link href={book.customersCreate}>{t('buttons:add_new_company')}</Link>
        </Button>
      </div>
      <BottomNavigationBar />
    </div>
  )
}
