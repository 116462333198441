import { zodResolver } from '@hookform/resolvers/zod'
import { ImageUploadItem } from 'antd-mobile/es/components/image-uploader'
import { useTranslation } from 'next-i18next'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import {
  FormControl,
  FormField,
  FormFields,
  FormItem,
  FormLabel,
  FormMessage,
  Loader,
  Typography,
} from 'src/components/ui'

import { FormCustomerIssueEnum, FormSubmitFn, FormUploadImagesType } from 'src/models'

import { formShapes } from 'src/bus/forms'

import { messages } from 'src/utils'

type IssueImagesFormType = {
  onSubmit: FormSubmitFn<FormUploadImagesType>
  loadingUpload: boolean
  onImageDelete: (item: ImageUploadItem) => Promise<boolean> | void
}

export const IssueImagesForm = ({ onSubmit, loadingUpload, onImageDelete }: IssueImagesFormType) => {
  const { t } = useTranslation()

  const formProps = useForm<FormUploadImagesType>({
    defaultValues: { ...formShapes.customerIssueImages.shape },
    resolver: zodResolver(formShapes.customerIssueImages.schema(t)),
    mode: 'onChange',
  })

  const { control, watch, setValue, handleSubmit, setError, reset } = formProps

  const onSubmitForm = handleSubmit((values) => {
    onSubmit({ values, acts: { setError, reset } })
  })

  const onDelete = (item: ImageUploadItem) => {
    setValue('images', [])
    onImageDelete(item)
  }

  useEffect(() => {
    const subscription = watch(async () => {
      await onSubmitForm()
    })

    return () => subscription.unsubscribe()
  }, [watch, onSubmitForm])

  return (
    <FormField
      control={control}
      name={FormCustomerIssueEnum.images}
      render={({ field }) => (
        <FormItem>
          <FormLabel>
            <Typography variant='secondary' className='font-semibold'>
              {t(messages.customerIssue.images.label)}
            </Typography>
          </FormLabel>
          <FormControl>
            <FormFields.InputImageUploader
              value={field.value}
              onChange={field.onChange}
              onDelete={onDelete}
              hasDisabled={loadingUpload}
              multiple={false}
              placeholder={t(messages.customerIssue.images.placeholder)}
            />
          </FormControl>
          <FormMessage />
          {loadingUpload && <Loader type='absolute' />}
        </FormItem>
      )}
    />
  )
}
