import type { TFunction } from 'i18next'
import { z } from 'zod'

import { messages, OPTION_OBJECT } from 'src/utils'

export const updateTourPoints = {
  shape: {
    end: null,
    intermediate: null,
  },
  schema: (t: TFunction) => {
    return z.object({
      end: z.object(OPTION_OBJECT, { invalid_type_error: t(messages.endPoint.required) }),
      intermediate: z.object(OPTION_OBJECT).nullable().default(null),
    })
  },
}
