import { useRouter } from 'next/router'

import { SelectListType, StaticDataItemType } from 'src/models'

import { claimsKeys, claimsState, claimsService, useClaimsDetailsFetch } from 'src/bus/claims'
import { useCustomersDetailsFetch } from 'src/bus/customers'

import { useQueryAsync } from 'src/hooks'

import { getParamsFromQueryStr } from 'src/utils'

type UseClaimsQuantityFetchType = {
  loadingClaimsQuantityFetch: boolean
  claimsQuantity: StaticDataItemType[]
}

export const useClaimsQuantityFetch = (causeId?: number): UseClaimsQuantityFetchType => {
  const router = useRouter()
  const { claimsDetails } = useClaimsDetailsFetch()
  const { customerId } = useCustomersDetailsFetch()

  const initialQuery = getParamsFromQueryStr(router.query)

  const orderProductId = Number(claimsDetails?.orderProductId || initialQuery?.orderProductId)
  const claimId = Number(claimsDetails?.id)
  const params = { ...(claimId ? { claimId } : { orderProductId }), customerId, causeId }

  const fetchClaimsOptionsProps = useQueryAsync<SelectListType>({
    key: claimsKeys.quantities(params),
    name: 'fetchClaimsQuantity',
    fetcher: claimsService.fetchQuantities,
    fetcherPayload: params,
    options: { enabled: (!!orderProductId || !!claimId) && !!customerId && !!causeId },
  })

  return {
    loadingClaimsQuantityFetch: fetchClaimsOptionsProps.isLoading,
    claimsQuantity: fetchClaimsOptionsProps.data?.items || claimsState.claimQuantity,
  }
}
