import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useState } from 'react'

import { HistoryCard, HistoryItemRow, WrappedNodeDefault, HistoryItem, HistoryEllipsis } from 'src/components/common'
import { Button, Icon, Typography } from 'src/components/ui'

import { HistoryIssueType } from 'src/models'

import { formatDate, getTranslate, orNull, ternary } from 'src/utils'

type HistoryIssueItemProps = {
  item: HistoryIssueType
  showDate: boolean
  index: number
}

export const HistoryIssueItem = ({ item, index, showDate }: HistoryIssueItemProps) => {
  const { t } = useTranslation()

  const [showDetails, setShowDetails] = useState(false)

  const getHistoryT = getTranslate('history', t)

  const onToggleDetails = () => setShowDetails((prev) => !prev)

  return (
    <HistoryItem date={item.date} index={index} showDate={showDate}>
      <HistoryCard variant='issue'>
        <Typography className='mb-2 font-semibold'>{getHistoryT('issue_number', { number: item.ticketId })}</Typography>
        <HistoryItemRow
          label={getHistoryT('date')}
          description={WrappedNodeDefault(formatDate(item?.closedAt || item.date, 'slash'))}
        />
        <HistoryItemRow label={getHistoryT('status')} description={WrappedNodeDefault(item.status?.label)} />
        <HistoryItemRow label={getHistoryT('reason')} description={WrappedNodeDefault(item.reason?.label)} />
        <HistoryItemRow
          label={getHistoryT('decision')}
          description={ternary(
            item.solution,
            <HistoryEllipsis content={item.solution || ''} rows={2} direction='end' />,
            WrappedNodeDefault(''),
          )}
        />
        <Button block variant='ghost' onClick={onToggleDetails} className='my-1'>
          <div className='flex items-center gap-1'>
            {getHistoryT('view_full_issue')}
            <Icon name={showDetails ? 'ChevronUp' : 'ChevronDown'} color='blue-8' />
          </div>
        </Button>

        {orNull(
          showDetails,
          <>
            <div className='my-2'>
              <Typography className='font-semibold'>{getHistoryT('issue_details')}</Typography>
            </div>
            <HistoryItemRow label={getHistoryT('order')} description={`#${item.order?.label}`} show={!!item.order} />
            <HistoryItemRow
              label={getHistoryT('correct_address')}
              description={item.correctAddress}
              show={!!item.correctAddress}
            />
            <HistoryItemRow label={getHistoryT('vin_number')} description={item.vin} show={!!item.vin} />
            <HistoryItemRow
              label={getHistoryT('vin_reg_number')}
              description={item.vinOrPlateNumber}
              show={!!item.vinOrPlateNumber}
            />
            {item?.products?.map((product, index) => {
              return (
                <div key={`${product.brand}-${product.article}-${index}`}>
                  <HistoryItemRow label={getHistoryT('brand_ref')} description={product.brand} />
                  <HistoryItemRow label={getHistoryT('article_ref')} description={product.article} />
                </div>
              )
            })}
            <HistoryItemRow
              label={getHistoryT('photo')}
              description={
                <div className='flex flex-wrap gap-2'>
                  {item.images?.map((image) => {
                    return (
                      <Image
                        key={image}
                        src={image}
                        alt={getHistoryT('photo')}
                        width={84}
                        height={120}
                        loading='lazy'
                      />
                    )
                  })}
                </div>
              }
              show={!!item.images?.length}
            />
            <HistoryItemRow
              label={getHistoryT('comment')}
              description={<div className='whitespace-pre-wrap'>{item.comment}</div>}
              show={!!item.comment}
            />
          </>,
        )}
      </HistoryCard>
    </HistoryItem>
  )
}
