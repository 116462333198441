export const historyState = {
  orders: {
    pages: [
      {
        items: [],
        meta: null,
      },
    ],
  },
  all: {
    pages: [
      {
        items: [],
        meta: null,
      },
    ],
  },
}

export const historyKeys = {
  all: ['history'],
  lists: () => [...historyKeys.all, 'lists'],
  orders: (params: any) => [...historyKeys.lists(), 'orders', params],
  historiesAll: () => [...historyKeys.all, 'histories-all'],
  historyAll: (params: any) => [...historyKeys.historiesAll(), 'history-all', params],
}
