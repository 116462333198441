import { useMutation, useQueryClient } from '@tanstack/react-query'
import { reject, equals } from 'ramda'

import { HttpErrorType, VisitParamsType, VisitsType, VisitType } from 'src/models'

import { historyKeys } from 'src/bus/history'
import { tourKeys } from 'src/bus/tour'
import { visitsKeys, visitsService } from 'src/bus/visits'
import { visitsHistoryKeys } from 'src/bus/visitsHistory'

import { useQueryAsync } from 'src/hooks'

import { queryFetcher } from 'src/utils'

export const useVisitsQuery = () => {
  const queryClient = useQueryClient()

  const visitsQuery = useQueryAsync<VisitsType>({
    key: visitsKeys.list(),
    name: 'fetchVisits',
    fetcher: visitsService.fetchVisits,
  })

  const onCreateVisit = useMutation<void, HttpErrorType, VisitParamsType>({
    mutationKey: visitsKeys.list(),
    mutationFn: (params) => {
      return queryFetcher({
        name: 'createVisit',
        fetcher: visitsService.createVisit,
        fetcherPayload: params,
      })
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: visitsKeys.list() })
      await queryClient.refetchQueries({ queryKey: historyKeys.lists() })
      await queryClient.refetchQueries({ queryKey: visitsHistoryKeys.historyAll() })
      await queryClient.refetchQueries({ queryKey: tourKeys.planForToday() })
      await queryClient.refetchQueries({ queryKey: tourKeys.tourPoints() })
    },
  })

  const onCancelVisit = useMutation<void, HttpErrorType, VisitParamsType>({
    mutationKey: visitsKeys.list(),
    mutationFn: (params) => {
      return queryFetcher({
        name: 'cancelVisit',
        fetcher: visitsService.cancelVisit,
        fetcherPayload: params,
      })
    },
    onMutate: async (params) => {
      await queryClient.cancelQueries({ queryKey: visitsKeys.list() })
      const previousData = queryClient.getQueryData(visitsKeys.list())

      if (previousData) {
        queryClient.setQueryData(visitsKeys.list(), (old: VisitsType | undefined) => {
          return old
            ? {
                ...old,
                items: reject((item: VisitType) => equals(item.id, params?.params?.id), old.items),
              }
            : { items: [] }
        })
      }

      return previousData
    },
    onError: (_, __, context) => {
      context && queryClient.setQueryData(visitsKeys.list(), context)
    },
    onSettled: async () => {
      await queryClient.refetchQueries({ queryKey: historyKeys.lists() })
      await queryClient.refetchQueries({ queryKey: visitsHistoryKeys.historyAll() })
      await queryClient.invalidateQueries({ queryKey: visitsKeys.list() })
      await queryClient.refetchQueries({ queryKey: tourKeys.planForToday() })
      await queryClient.refetchQueries({ queryKey: tourKeys.tourPoints() })
    },
  })

  const onReplanVisit = useMutation<void, HttpErrorType, VisitParamsType>({
    mutationKey: visitsKeys.list(),
    mutationFn: (params) => {
      return queryFetcher({
        name: 'replanVisit',
        fetcher: visitsService.replanVisit,
        fetcherPayload: params,
      })
    },
    onMutate: async (params) => {
      await queryClient.cancelQueries({ queryKey: visitsKeys.list() })
      const previousData = queryClient.getQueryData(visitsKeys.list())

      if (previousData) {
        queryClient.setQueryData(visitsKeys.list(), (old: VisitsType | undefined) => {
          return old
            ? {
                ...old,
                items: reject((item: VisitType) => equals(item.id, params?.params?.id), old.items),
              }
            : { items: [] }
        })
      }

      return previousData
    },
    onError: (_, __, context) => {
      context && queryClient.setQueryData(visitsKeys.list(), context)
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: visitsKeys.list() })
      await queryClient.refetchQueries({ queryKey: historyKeys.lists() })
      await queryClient.refetchQueries({ queryKey: visitsHistoryKeys.historyAll() })
      await queryClient.refetchQueries({ queryKey: tourKeys.planForToday() })
      await queryClient.refetchQueries({ queryKey: tourKeys.tourPoints() })
    },
  })

  return {
    visitsQuery,
    onCreateVisit,
    onCancelVisit,
    onReplanVisit,
  }
}
