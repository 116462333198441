export const visitsHistoryState = {
  visitsHistory: {
    pages: [
      {
        items: [],
        meta: null,
      },
    ],
  },
  historyDetails: null,
  historyReport: null,
  staticData: {
    sectors: {
      items: [],
      meta: null,
    },
    customers: {
      items: [],
      meta: null,
    },
  },
}

export const visitsHistoryKeys = {
  all: ['visits-history'],
  historyAll: () => [...visitsHistoryKeys.all, 'history-all'],
  history: (params: any) => [...visitsHistoryKeys.historyAll(), params],
  historyDetails: (id: number) => [...visitsHistoryKeys.all, 'history-details', id],
  historyReport: (id: number) => [...visitsHistoryKeys.all, 'history-report', id],
  historySectors: () => [...visitsHistoryKeys.all, 'history-sectors'],
  historyCustomers: () => [...visitsHistoryKeys.all, 'history-customers'],
}
