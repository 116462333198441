import { UploadPhotoClaimsForm } from 'src/components/common'
import { Loader } from 'src/components/ui'

import { ClaimType } from 'src/models'

import { useClaimsImagesUpload } from 'src/bus/claims'

import { orNull } from 'src/utils'

type ClaimsUploadPhotoProps = {
  data: ClaimType | null
  loadingFetch: boolean
  onPrevStep: () => void
}

export const ClaimsUploadPhoto = ({ data = null, loadingFetch = false, onPrevStep }: ClaimsUploadPhotoProps) => {
  const { onUploadImagesClaims, loadingClaimsUploadImages } = useClaimsImagesUpload()

  return (
    <>
      {orNull(loadingFetch, <Loader type='fixed' />)}
      <UploadPhotoClaimsForm
        isNotEditable={data ? !data?.isImagesEditable : false}
        initialValues={{ images: data?.images || [] }}
        loadingFetch={loadingFetch}
        loadingUpdate={loadingClaimsUploadImages}
        onSubmit={onUploadImagesClaims}
        onPrevStep={onPrevStep}
      />
    </>
  )
}
