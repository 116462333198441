import { VisitsType, VisitType } from 'src/models'

import { visitsState, useVisitsQuery } from 'src/bus/visits'

type UseVisitsFetchType = {
  loadingVisitsFetch: boolean
  visits: VisitsType
  visitFirst: VisitType | null
}

export const useVisitsFetch = (): UseVisitsFetchType => {
  const { visitsQuery } = useVisitsQuery()

  const visits = visitsQuery.data || visitsState.visits

  return {
    loadingVisitsFetch: visitsQuery.isLoading,
    visits,
    visitFirst: visits.items[0] || null,
  }
}
