import { useRouter } from 'next/router'

import { SelectListType, StaticDataItemType } from 'src/models'

import { claimsKeys, claimsState, claimsService, useClaimsDetailsFetch } from 'src/bus/claims'
import { useCustomersDetailsFetch } from 'src/bus/customers'

import { useQueryAsync } from 'src/hooks'

import { getParamsFromQueryStr } from 'src/utils'

type UseClaimsCausesFetchType = {
  loadingClaimsCausesFetch: boolean
  claimsCauses: StaticDataItemType[]
}

export const useClaimsCausesFetch = (): UseClaimsCausesFetchType => {
  const router = useRouter()
  const { claimsDetails } = useClaimsDetailsFetch()
  const { customerId, loadingCustomersDetailsFetch } = useCustomersDetailsFetch()

  const initialQuery = getParamsFromQueryStr(router.query)

  const orderProductId = Number(claimsDetails?.orderProductId || initialQuery?.orderProductId)
  const claimId = Number(claimsDetails?.id)
  const params = { ...(claimId ? { claimId } : { orderProductId }), customerId }

  const fetchClaimsOptionsProps = useQueryAsync<SelectListType>({
    key: claimsKeys.causes(params),
    name: 'fetchClaimsCauses',
    fetcher: claimsService.fetchCauses,
    fetcherPayload: params,
    options: { enabled: (!!orderProductId || !!claimId) && !!customerId },
  })

  return {
    loadingClaimsCausesFetch: fetchClaimsOptionsProps.isLoading || loadingCustomersDetailsFetch,
    claimsCauses: fetchClaimsOptionsProps.data?.items || claimsState.claimCauses,
  }
}
