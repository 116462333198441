import { TextArea as AntMobileTextarea } from 'antd-mobile'
import { TextAreaProps as AntMobileTextAreaProps, TextAreaRef } from 'antd-mobile/es/components/text-area'
import { forwardRef } from 'react'
import { cn } from 'utils/helpers'

import st from './Styles.module.css'

export type TextareaProps = AntMobileTextAreaProps & {
  className?: string
}

export const InputTextArea = forwardRef<TextAreaRef, TextareaProps>(
  (
    { className = '', placeholder = 'Textarea', disabled = false, showCount = true, maxLength = 512, ...restProps },
    ref,
  ) => {
    const textareaStyle = cn(st['textarea'], { [st['disabled']]: disabled }, className)

    return (
      <AntMobileTextarea
        {...restProps}
        className={textareaStyle}
        showCount={showCount}
        maxLength={maxLength}
        ref={ref}
        disabled={disabled}
        placeholder={placeholder}
      />
    )
  },
)

InputTextArea.displayName = 'InputTextArea'
