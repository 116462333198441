import { useTranslation } from 'next-i18next'

import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  Typography,
} from 'src/components/ui'

import { useAuthProfile } from 'src/bus/auth'
import { useParcelsShipmentRequest } from 'src/bus/parcels'

import { useDialog } from 'src/contexts'

import { GET_ENUMS } from 'src/utils'

export const AlertDialogShipmentParcel = ({ id }: { id?: number }) => {
  const { t } = useTranslation()
  const { isCountryNotNL } = useAuthProfile()
  const { setOnPageDialog } = useDialog()
  const { onShipmentParcels, loadingParcelsShipmentRequest } = useParcelsShipmentRequest()

  const onConfirm = async () => {
    isCountryNotNL ? await onShipmentParcels(id) : setOnPageDialog(GET_ENUMS.dialog.trackNumberNL, true)
  }

  return (
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>
          <Typography variant='title' color='gray-10'>
            {t('parcels:modal_send_request.title')}
          </Typography>
        </AlertDialogTitle>
        <AlertDialogDescription>
          <Typography variant='secondary' color='gray-7'>
            {t('parcels:modal_send_request.description')}
          </Typography>
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel variant='outline' className='w-full'>
          {t('buttons:no')}
        </AlertDialogCancel>
        <Button onClick={onConfirm} block loading={loadingParcelsShipmentRequest} asChild>
          <AlertDialogAction>{t('buttons:yes')}</AlertDialogAction>
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  )
}
