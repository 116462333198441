import { HttpResponse, CouponsParams, CouponType, couponSchema } from 'src/models'

import { zodValidateResponse } from 'src/utils'

import { httpAxiosProvider } from 'src/api'

export const couponsService = Object.freeze({
  createCoupon: ({ data }: CouponsParams) => {
    return httpAxiosProvider.post<HttpResponse<CouponType>>('/coupon', data)
  },
  fetchCoupons: async ({ params }: CouponsParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<CouponType>>('/coupon', { params })
    zodValidateResponse(couponSchema, response.data.data)
    return response
  },
})
