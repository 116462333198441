import { StaticDataItemsType } from 'src/models'

import { useParcelsStaticDataFetch } from 'src/bus/parcels'

type UseParcelsFiltersType = {
  loadingParcelsFiltersFetch: boolean
  parcelsFilters: StaticDataItemsType
}

export const useParcelsFilters = (): UseParcelsFiltersType => {
  const { parcelsStatus, parcelsType, loadingParcelsStaticDataFetch } = useParcelsStaticDataFetch()

  const filters = { parcelsStatus, parcelsType }

  return {
    parcelsFilters: filters,
    loadingParcelsFiltersFetch: loadingParcelsStaticDataFetch,
  }
}
