import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'

import { HttpErrorType, VisitParamsType, VisitsType, VisitUpdateOrderParamsType } from 'src/models'

import { tourKeys } from 'src/bus/tour'
import { visitsKeys, visitsService } from 'src/bus/visits'

import { useCurrentLocation, useMutationAsync, useToast } from 'src/hooks'

type UpdateVisitsOrderCb = (visitIds: number[]) => Promise<void>

type UseVisitsOrderUpdateType = {
  onUpdateVisitsOrder: UpdateVisitsOrderCb
}

export const useVisitsOrderUpdate = (): UseVisitsOrderUpdateType => {
  const queryClient = useQueryClient()
  const showToast = useToast()
  const { geoLocation } = useCurrentLocation()

  const onUpdateVisitsOrderMutation = useMutationAsync<
    void,
    VisitUpdateOrderParamsType,
    any,
    string[],
    VisitParamsType
  >({
    key: visitsKeys.list(),
    name: 'updateVisitsOrder',
    fetcher: visitsService.updateVisitsOrder,
    options: {
      onMutate: async (newData) => {
        await queryClient.cancelQueries({ queryKey: visitsKeys.list() })

        const previousData = queryClient.getQueryData(visitsKeys.list()) as VisitsType

        queryClient.setQueryData<VisitsType>(visitsKeys.list(), (old) => {
          if (!old) {
            return { items: [], meta: undefined }
          }

          const sorted = newData.visitIds.reduce(
            (acc, id) => {
              const found = old.items.find((item) => item.id === id)

              if (found) {
                acc.push(found)
              }

              return acc
            },
            [] as VisitsType['items'],
          )

          return { items: sorted }
        })

        return { previousData }
      },
      onError: (_, __, context) => {
        queryClient.setQueryData(visitsKeys.list(), context.previousData)
      },
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey: visitsKeys.list() })
        geoLocation && (await queryClient.refetchQueries({ queryKey: tourKeys.tourRoute(geoLocation) }))
      },
    },
  })

  const onUpdateVisitsOrder = useCallback<UpdateVisitsOrderCb>(
    async (visitIds: number[]) => {
      try {
        await onUpdateVisitsOrderMutation.mutateAsync({ visitIds })
      } catch (error) {
        const errs = (error as HttpErrorType).errors
        if (errs) {
          const [[title, body]] = Object.entries(errs)
          showToast.error({ title, body: body.desc })
        }
      }
    },
    [onUpdateVisitsOrderMutation, showToast],
  )

  return {
    onUpdateVisitsOrder,
  }
}
