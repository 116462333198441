import { useTranslation } from 'next-i18next'

import { BottomNavigationBar } from 'src/components/common'
import { Button } from 'src/components/ui'

import { useReturnsCreate, useReturnsInProgress } from 'src/bus/returns'

import { orNull } from 'src/utils'

export const ReturnsFooter = () => {
  const { t } = useTranslation()
  const { hasActiveReturn } = useReturnsInProgress()
  const { onCreateReturn, loadingReturnsCreate } = useReturnsCreate()

  return (
    <div className='shadow-level-2'>
      <div className='p-2'>
        {orNull(
          !hasActiveReturn,
          <Button block onClick={onCreateReturn} loading={loadingReturnsCreate}>
            {t('buttons:create_new_return')}
          </Button>,
        )}
      </div>
      <BottomNavigationBar />
    </div>
  )
}
