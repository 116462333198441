import {
  HttpResponse,
  ClaimsParams,
  ClaimsType,
  ClaimsSearchType,
  ClaimType,
  ClaimOptionsType,
  ClaimTotalPriceType,
  SelectListType,
  claimListSchema,
  claimSchema,
  claimsSearchSchema,
  claimOptionsSchema,
  baseSelectListSchema,
  claimTotalPriceSchema,
} from 'src/models'

import { zodValidateResponse } from 'src/utils'

import { httpAxiosProvider } from 'src/api'

export const claimsService = Object.freeze({
  fetchClaims: async ({ params }: ClaimsParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<ClaimsType>>('/return-claim', {
      params,
    })
    zodValidateResponse(claimListSchema, response.data.data)
    return response
  },

  searchClaims: async ({ params }: ClaimsParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<ClaimsSearchType>>('/return-claim-search', { params })
    zodValidateResponse(claimsSearchSchema, response.data.data)
    return response
  },
  fetchClaimsDetails: async ({ params }: ClaimsParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<ClaimType>>(`/return-claim/${params?.id}`, { params })
    zodValidateResponse(claimSchema, response.data.data)
    return response
  },
  updateClaim: ({ params, data }: ClaimsParams) => {
    return httpAxiosProvider.post<HttpResponse<void>>(`/return-claim/${params?.id}/update`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },
  uploadImages: ({ params, data }: ClaimsParams) => {
    return httpAxiosProvider.post<HttpResponse<ClaimType>>(`/return-claim/${params?.id}/images`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },
  deleteClaim: ({ params }: ClaimsParams) => {
    return httpAxiosProvider.delete<HttpResponse<void>>(`/return-claim/${params?.id}`, { params })
  },
  fetchClaimsOptions: async ({ params }: ClaimsParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<ClaimOptionsType>>('/claim-options', {
      params,
    })
    zodValidateResponse(claimOptionsSchema, response.data.data)
    return response
  },
  fetchCauses: async ({ params }: ClaimsParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<SelectListType>>('/claim-options/causes', { params })
    zodValidateResponse(baseSelectListSchema, response.data.data)
    return response
  },
  fetchQuantities: async ({ params }: ClaimsParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<SelectListType>>('/claim-options/quantities', { params })
    zodValidateResponse(baseSelectListSchema, response.data.data)
    return response
  },
  fetchPrice: async ({ params }: ClaimsParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<ClaimTotalPriceType>>('/claim-options/total-price', {
      params,
    })
    zodValidateResponse(claimTotalPriceSchema, response.data.data)
    return response
  },
  createClaim: ({ data }: ClaimsParams) => {
    return httpAxiosProvider.post<HttpResponse<ClaimType>>('/return-claim', data)
  },
})
