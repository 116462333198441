import type { TFunction } from 'i18next'
import { z } from 'zod'

import { messages } from 'src/utils'

export const customerIssueImages = {
  shape: {
    images: [],
  },
  schema: (t: TFunction) => {
    return z.object({
      images: z
        .array(
          z.object({
            key: z.union([z.string(), z.number()]).optional(),
            url: z.string(),
            thumbnailUrl: z.string().optional(),
            extra: z.custom().optional(),
          }),
        )
        .min(
          messages.customerIssue.images.min,
          t(messages.customerIssue.images.minText, { count: messages.customerIssue.images.min }),
        ),
    })
  },
}
