import { useTranslation } from 'next-i18next'

import { EditNoteForm } from 'src/components/common'
import { DialogContent, DialogHeader, Typography } from 'src/components/ui'

import { useCustomersDetailsFetch, useCustomersNoteEdit } from 'src/bus/customers'

import { GET_ENUMS } from 'src/utils'

export const DialogEditNote = () => {
  const { t } = useTranslation()
  const { onEditCustomersNote, loadingCustomersNoteEdit } = useCustomersNoteEdit()
  const { customersDetails, loadingCustomersDetailsFetch } = useCustomersDetailsFetch()

  return (
    <DialogContent name={GET_ENUMS.dialog.editNote}>
      <DialogHeader>
        <Typography variant='title' color='gray-10'>
          {t('buttons:edit_note')}
        </Typography>
      </DialogHeader>
      <EditNoteForm
        loadingCreate={loadingCustomersNoteEdit}
        onSubmit={onEditCustomersNote}
        initialValues={{ notes: customersDetails?.notes || '' }}
        loadingFetch={loadingCustomersDetailsFetch}
      />
    </DialogContent>
  )
}
