import Link from 'next/link'

import { WrappedNodeDefault } from 'src/components/common'
import { Skeleton, Typography } from 'src/components/ui'

import st from './Styles.module.css'

type CellLinkProps = {
  value?: string
  type?: 'tel' | 'mailto'
  loadingFetch?: boolean
}

export const CellLink = ({ value = '', type = 'tel', loadingFetch = false }: CellLinkProps) => {
  if (loadingFetch) {
    return <Skeleton className='h-4' />
  }

  return (
    <div className={st['cell-link']}>
      {value ? (
        <Link href={`${type}:${value}`}>
          <Typography variant='secondary' color='blue-8'>
            {value}
          </Typography>
        </Link>
      ) : (
        WrappedNodeDefault(value)
      )}
    </div>
  )
}
