import { CellTextRich } from 'src/components/common'

import { ActionType } from 'src/models'

type ActionsDetailsModelType = {
  data: ActionType | null
  loadingFetch?: boolean
}

export const actionsDetailsModel = ({ data = null, loadingFetch = false }: ActionsDetailsModelType) => {
  const Code = CellTextRich({ value: data?.code, loadingFetch })
  const Type = CellTextRich({ value: data?.type, loadingFetch })
  const Reason = CellTextRich({ value: data?.reason, loadingFetch })
  const Context = CellTextRich({ value: data?.context, loadingFetch })
  const Goal = CellTextRich({ value: data?.goals, loadingFetch })
  const Materials = CellTextRich({ value: data?.materials, loadingFetch })
  const ExecutionSteps = CellTextRich({ value: data?.executionSteps, loadingFetch })

  return [
    { id: 1, title: 'code', component: Code },
    { id: 2, title: 'type', component: Type },
    { id: 3, title: 'reason', component: Reason },
    { id: 4, title: 'context', component: Context },
    { id: 5, title: 'goal', component: Goal },
    { id: 6, title: 'materials', component: Materials },
    { id: 7, title: 'execution_steps', component: ExecutionSteps },
  ]
}
