import { useTranslation } from 'next-i18next'

import { useCustomersClose } from 'src/bus/customers'

import { AlertDialogConfirm } from './AlertDialogConfirm'

export const AlertDialogCloseCustomer = () => {
  const { t } = useTranslation()
  const { onCloseCustomer, loadingCustomersClose } = useCustomersClose()

  const onConfirm = () => onCloseCustomer(true)

  return (
    <AlertDialogConfirm
      title={t('modal_close_garage.title')}
      description={t('modal_close_garage.description')}
      loadingFetch={loadingCustomersClose}
      onConfirm={onConfirm}
      buttonVariant='cta'
    />
  )
}
