import { Col, Row } from 'antd'
import copy from 'copy-to-clipboard'
import { useTranslation } from 'next-i18next'

import { WrappedNodeDefault } from 'src/components/common'
import { ListItem, SwipeAction, Typography } from 'src/components/ui'

import { OrdersArticleType } from 'src/models'

import { useToast } from 'src/hooks'

import { getOrderItemActions, rowGutters } from 'src/utils'

type OrdersArticlesItemProps = {
  item: OrdersArticleType
}

export const OrdersArticlesItem = ({ item }: OrdersArticlesItemProps) => {
  const { t } = useTranslation()
  const showToast = useToast()

  const renderContent = (value: string | number | null, span: number) => {
    return (
      <Col span={span}>
        <Typography variant='secondary'>{WrappedNodeDefault(value)}</Typography>
      </Col>
    )
  }

  const onSelectCell = () => {
    const result = copy(item.article, { debug: true })

    if (result) {
      showToast.success({ title: t('notifications:order_article_copied') })
    }
  }

  const articlesLeftActions = getOrderItemActions({
    onSelectCell: () => onSelectCell,
    t,
  })

  return (
    <SwipeAction leftActions={articlesLeftActions}>
      <ListItem variant='order' size='sm'>
        <Row align='middle' gutter={rowGutters}>
          {renderContent(item.article, 6)}
          {renderContent(item.articleType, 6)}
          {renderContent(item.brand, 5)}
          {renderContent(item.quantity, 3)}
          {renderContent(item.sum, 4)}
        </Row>
      </ListItem>
    </SwipeAction>
  )
}
