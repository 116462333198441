import { z } from 'zod'

import { hasID, locationSchema } from './Base'
import { BaseDataListType, HttpServerRequestParams, StaticDataItemType } from './index'

export const visitBaseSchema = z
  .object({
    id: z.number(),
    companyName: z.string(),
    companyTitle: z.string(),
    sector: z.string(),
    customerId: z.number(),
    comment: z.string(),
    status: z.custom(),
    location: locationSchema,
  })
  .strict()

export const visitSchema = visitBaseSchema.merge(hasID)

export const visitSchemaList = z.object({
  items: visitSchema.array(),
})

export type VisitType = z.infer<typeof visitSchema>
export type VisitsType = z.infer<typeof visitSchemaList>

export type VisitCancelParamsType = {
  reason: StaticDataItemType
  comment: string
}

export type VisitReasonCancelType = {
  reason: string
  comment: string
}

export type VisitCreateReportParamsType = {
  businessActions: StaticDataItemType[]
  actions: StaticDataItemType[]
  comment: string
}

export type VisitCreateReportSubmitParamsType = {
  businessActions: string[]
  actions: string[]
  comment: string
}

export type VisitUpdateOrderParamsType = {
  visitIds: number[]
}

export type VisitParamsType = HttpServerRequestParams

export type VisitAvailableDateType = {
  date: string
  dateRange: {
    start: string
    end: string
  }
  excludedDates: string[]
}

export type VisitActionsType = BaseDataListType
