import { GoogleMap as GoogleMapReact, GoogleMapProps as GoogleMapReactProps } from '@react-google-maps/api'

import { Skeleton } from 'src/components/ui'

import { cn, googleMapDefaultOptions } from 'src/utils'

import st from './Styles.module.css'

type GoogleMapProps = GoogleMapReactProps & {
  className?: string
  onLoad?: (map: google.maps.Map) => void
  isLoaded?: boolean
  loadError?: any
}

export const GoogleMap = ({
  options,
  className = '',
  isLoaded = false,
  onLoad,
  children,
  onClick,
  loadError,
  ...rest
}: GoogleMapProps) => {
  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? (
    <GoogleMapReact
      {...rest}
      onClick={onClick}
      mapContainerClassName={cn('w-full h-full rounded-sm', className, st['google-map'])}
      options={{ ...googleMapDefaultOptions.options, ...(options && options) }}
      onLoad={(map) => onLoad?.(map)}
    >
      {children}
    </GoogleMapReact>
  ) : (
    <Skeleton className='flex h-full flex-col' />
  )
}
