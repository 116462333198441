import { z } from 'zod'

import { metaSchema } from './Base'
import { HttpServerRequestParams, selectSchemaNullable } from './index'
import { selectListSchema } from './StaticData'

export const tourPointSchema = z.object({
  id: z.number(),
  label: z.string(),
  type: selectSchemaNullable,
  lat: z.number(),
  lng: z.number(),
})

export const tourPointListSchema = z.object({
  items: tourPointSchema.array(),
  meta: metaSchema.optional(),
})

export const tourPointStaticSchema = z.object({
  end: selectListSchema,
  intermediate: selectListSchema,
  activeEnd: selectSchemaNullable,
  activeIntermediate: selectSchemaNullable,
})

export type TourPointType = z.infer<typeof tourPointSchema>
export type TourPointsType = z.infer<typeof tourPointListSchema>
export type TourPointsStaticsType = z.infer<typeof tourPointStaticSchema>

export type TourPointsParams = HttpServerRequestParams
