import { CellText } from 'src/components/common'

type CellRangeProps = {
  value?: string
  loadingFetch?: boolean
}

export const CellRange = (props: CellRangeProps) => {
  const { value = '-', loadingFetch = false } = props

  return <CellText value={value} loadingFetch={loadingFetch} />
}
