import currency from 'currency.js'

import { TCurrencyConfig } from 'src/models'

import { initialCountry } from 'src/utils/constants'

import { currencyConfig } from './config'

export const currencyBase = (
  value: number | null = 0,
  country = initialCountry,
  options?: Partial<TCurrencyConfig>,
) => {
  return currency(Number(value), { ...currencyConfig[country], errorOnInvalid: true, ...options })
}

export const currencyFormat = (
  value: number | null = 0,
  country?: string,
  options?: Partial<TCurrencyConfig>,
): string => currencyBase(value, country, options).format()
