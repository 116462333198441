import { OverlayView } from '@react-google-maps/api'
import * as GeoJSON from 'geojson'
import debounce from 'lodash.debounce'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'
import supercluster, { ClusterFeature, PointFeature } from 'supercluster'
import { GET_ENUMS } from 'utils/constants'

import { CustomMarker, Dialog, DialogViewsSwitcher, ManageToursDynamicFilters } from 'src/components/common'
import { GoogleMap, Loader } from 'src/components/ui'

import { ManagePointType } from 'src/models'

import { useManagePointsFetch } from 'src/bus/manageTours'

import { useCurrentLocation } from 'src/hooks'

import { useDialog, useGoogle } from 'src/contexts'

const center = {
  lat: 48.85963804580478,
  lng: 2.353214417243549,
}

const mapOptions = {
  minZoom: 11,
  maxZoom: 21,
  disableDefaultUI: true,
  clickableIcons: false,
}

type TClusters = Array<ClusterFeature<{ marker: ManagePointType }>>

export const ViewManageTours = () => {
  const router = useRouter()
  const { isLoaded, loadError, onUnmount } = useGoogle()
  const { onPageDialogs, setOnPageDialog } = useDialog()
  const { managePoints, loadingManagePointsFetch } = useManagePointsFetch()
  const { geoLocation, loadingLocation } = useCurrentLocation()

  const [clusters, setClusters] = useState<TClusters>([])
  const [activeMarker, setActiveMarker] = useState<ManagePointType | null>(null)

  const mapRef = useRef<google.maps.Map | null>(null)
  const centerBounds = useRef<google.maps.LatLngLiteral>(center)

  const createClusters = useCallback(() => {
    if (!mapRef.current) {
      return
    }

    const zoom = mapRef.current.getZoom() || 10
    const bounds = mapRef.current.getBounds()

    if (!bounds) {
      return
    }

    const clusterer = new supercluster({
      radius: 80,
      minZoom: 11,
      maxZoom: 19,
    })

    const points: PointFeature<{ marker: ManagePointType }>[] = managePoints.map((marker) => ({
      type: 'Feature',
      properties: { cluster: false, marker },
      geometry: {
        type: 'Point',
        coordinates: [marker.location.lng, marker.location.lat],
      },
    }))

    clusterer.load(points)

    const clusterBounds: GeoJSON.BBox = [
      bounds.getSouthWest().lng(),
      bounds.getSouthWest().lat(),
      bounds.getNorthEast().lng(),
      bounds.getNorthEast().lat(),
    ]

    const clusters = clusterer.getClusters(clusterBounds, zoom) as TClusters

    setClusters(clusters)
  }, [managePoints])

  const updateMarkers = async () => {
    if (!mapRef.current) {
      return
    }

    const bounds = mapRef.current.getBounds()

    if (!bounds) {
      return
    }

    const boundsParams = {
      southWestLat: bounds.getSouthWest().lat(),
      southWestLng: bounds.getSouthWest().lng(),
      northEastLat: bounds.getNorthEast().lat(),
      northEastLng: bounds.getNorthEast().lng(),
    }

    await router.replace({ pathname: router.pathname, query: { ...router.query, ...boundsParams } })
  }

  const onMapChanged = debounce(async () => {
    updateMarkers().then(() => {
      createClusters()
    })
  }, 500)

  const onLoadHandler = async (map: google.maps.Map) => {
    mapRef.current = map
  }

  useEffect(() => {
    if (
      'northEastLat' in router.query &&
      'northEastLng' in router.query &&
      'southWestLat' in router.query &&
      'southWestLng' in router.query
    ) {
      const centerLat = (Number(router.query.southWestLat) + Number(router.query.northEastLat)) / 2
      const centerLng = (Number(router.query.southWestLng) + Number(router.query.northEastLng)) / 2

      if (centerBounds.current && centerLat && centerLng) {
        centerBounds.current = { lat: centerLat, lng: centerLng }
      }
      return
    }

    if (geoLocation) {
      centerBounds.current = geoLocation
    }
  }, [loadingLocation, geoLocation])

  useEffect(() => {
    if (mapRef.current) {
      updateMarkers().then(() => {
        createClusters()
      })
    }
  }, [managePoints.length])

  const onOpenPopup = (marker: ManagePointType) => {
    setActiveMarker(marker)
    setOnPageDialog(GET_ENUMS.dialog.customerCard, true)
  }

  const onClosePopup = (e: google.maps.MapMouseEvent) => {
    e.stop()
    setActiveMarker(null)
    setOnPageDialog(GET_ENUMS.dialog.customerCard, false)
  }

  const onOpenPopupVisit = (key: string) => {
    setOnPageDialog(GET_ENUMS.dialog.customerCard, false)
    setOnPageDialog(key, true)
  }

  return (
    <div className='relative flex h-full flex-col' data-test-id='manage-tours'>
      <Dialog
        open={onPageDialogs[GET_ENUMS.dialog.customerCard]?.visible}
        onOpenChange={(open) => setOnPageDialog(GET_ENUMS.dialog.customerCard, open)}
      >
        <DialogViewsSwitcher
          name={GET_ENUMS.dialog.customerCard}
          params={{ id: activeMarker?.customerId, visitId: activeMarker?.visitId, onOpenOtherPopup: onOpenPopupVisit }}
        />
      </Dialog>
      {[GET_ENUMS.dialog.cancelVisit, GET_ENUMS.dialog.replanVisit].map((key) => {
        return (
          <Dialog key={key} open={onPageDialogs[key]?.visible} onOpenChange={(open) => setOnPageDialog(key, open)}>
            <DialogViewsSwitcher name={key} params={{ id: activeMarker?.visitId, title: activeMarker?.title }} />
          </Dialog>
        )
      })}
      {loadingManagePointsFetch && <Loader type='absolute' />}
      <ManageToursDynamicFilters />
      <GoogleMap
        isLoaded={isLoaded}
        loadError={loadError}
        zoom={10}
        center={centerBounds.current}
        options={mapOptions}
        onLoad={onLoadHandler}
        onDragEnd={onMapChanged}
        onZoomChanged={onMapChanged}
        onUnmount={onUnmount}
        onClick={(e) => onClosePopup(e)}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates
          const { cluster: isCluster, point_count: pointCount, marker } = cluster.properties

          if (isCluster) {
            const size = `${20 + (pointCount / managePoints.length || 0) * 40}px`

            return (
              <OverlayView
                key={cluster.id}
                position={{ lat: latitude, lng: longitude }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                getPixelPositionOffset={(width, height) => ({
                  x: -(width / 2),
                  y: -(height / 2),
                })}
              >
                <span
                  className='flex items-center justify-center rounded-full bg-primary text-white'
                  style={{ width: size, height: size }}
                >
                  {pointCount}
                </span>
              </OverlayView>
            )
          }

          return <CustomMarker key={marker.id} marker={marker} onClick={() => onOpenPopup(marker)} />
        })}
      </GoogleMap>
    </div>
  )
}
