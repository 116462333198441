import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import { useCallback, useState } from 'react'

import { HttpErrorType, ParcelsPackType } from 'src/models'

import { parcelsKeys, useParcelsPackMutation } from 'src/bus/parcels'

import { useToast } from 'src/hooks'

type PackParcelsCb = (id: number, type: ParcelsPackType) => Promise<void>

type UseParcelsPackType = {
  loadingParcelsPack: boolean
  onPackParcels: PackParcelsCb
}

export const useParcelsPack = (): UseParcelsPackType => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { onPackParcelsMutation } = useParcelsPackMutation()
  const showToast = useToast()

  const [isLoading, setIsLoading] = useState(false)

  const onPackParcels: PackParcelsCb = useCallback(
    async (id, type) => {
      try {
        setIsLoading(true)

        await onPackParcelsMutation.mutateAsync({ params: { id: String(id) }, data: { type } })
        showToast.success({ title: t(`notifications:parcel_${type}`) })
        await queryClient.invalidateQueries({ queryKey: parcelsKeys.all })
        await queryClient.refetchQueries({ queryKey: parcelsKeys.lists() })
        await queryClient.refetchQueries({ queryKey: parcelsKeys.articles() })
        await queryClient.refetchQueries({ queryKey: parcelsKeys.totals() })
        await queryClient.refetchQueries({ queryKey: parcelsKeys.detail(id) })
      } catch (error) {
        const errs = (error as HttpErrorType).errors
        if (errs) {
          const [[title, body]] = Object.entries(errs)
          showToast.error({ title, body: body.desc })
        }
      } finally {
        setIsLoading(false)
      }
    },
    [onPackParcelsMutation, showToast, t, queryClient],
  )

  return {
    loadingParcelsPack: isLoading,
    onPackParcels,
  }
}
