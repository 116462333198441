import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { book } from 'src/init/book'

import { AlertDialog, AlertDialogViewsSwitcher, BottomNavigationBar } from 'src/components/common'
import { Button, FloatingPanel, Icon, ListItem, Typography } from 'src/components/ui'

import { useCustomersDetailsFetch } from 'src/bus/customers'

import { useDialog } from 'src/contexts'

import { FLOATING_PANEL_ANCHORS, GET_ENUMS, getCustomersMenu } from 'src/utils'

export const CustomersDetailsFooter = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { onPageDialogs, setOnPageDialog } = useDialog()

  const { customersDetails, loadingCustomersDetailsFetch } = useCustomersDetailsFetch()

  const isClosed = !!customersDetails?.isClosed

  const menu = getCustomersMenu({
    t,
    loading: loadingCustomersDetailsFetch,
    isClosed: isClosed,
  })

  const onClickHandler = (key: string) => setOnPageDialog(key, true)

  return (
    <>
      {[GET_ENUMS.alertDialog.openCustomer, GET_ENUMS.alertDialog.closeCustomer].map((key) => {
        return (
          <AlertDialog key={key} open={onPageDialogs[key]?.visible} onOpenChange={(open) => setOnPageDialog(key, open)}>
            <AlertDialogViewsSwitcher name={key} />
          </AlertDialog>
        )
      })}
      <FloatingPanel anchors={FLOATING_PANEL_ANCHORS.ONE_STEP}>
        <div className='mb-4 px-4'>
          <Button block variant='outline'>
            <Link href={`${book.customers}/${router.query.id}${book.historyAll}`}>{t('buttons:customer_history')}</Link>
          </Button>
        </div>
        {menu.map((item) => {
          const isCancel = item.type === 'cancel'

          return (
            <ListItem key={item.key} disabled={item.disabled} onClick={() => onClickHandler(item.key)} size='panel'>
              <div className='flex items-center gap-4'>
                <Icon name={item.icon} color={isCancel ? 'error' : 'blue-gray-7'} />
                <Typography color={isCancel ? 'error' : 'default'}>{item.title}</Typography>
              </div>
            </ListItem>
          )
        })}
      </FloatingPanel>
      <BottomNavigationBar />
    </>
  )
}
