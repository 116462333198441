import { z } from 'zod'

import { SelectItem, StaticDataItemType } from './index'
import { selectListSchema } from './StaticData'

export const hasID = z.object({ id: z.number() })
export const emailSchema = z.union([z.literal(''), z.string().email()])
export const metaSchema = z
  .object({
    currentPage: z.number(),
    from: z.number().nullable(),
    lastPage: z.number(),
    path: z.string(),
    perPage: z.number(),
    to: z.number().nullable(),
    total: z.number(),
  })
  .nullable()

export const locationSchema = z.object({
  lat: z.number(),
  lng: z.number(),
})

export const baseSelectListSchema = z.object({
  items: selectListSchema,
})
export type SelectListType = z.infer<typeof baseSelectListSchema>

export type LocationType = z.infer<typeof locationSchema>

export type MetaType = z.infer<typeof metaSchema>

export type BaseUnknownRecordType = Record<string, unknown>

export type BaseEmptyFn = () => void
export type BaseEmptyAsyncFn = () => Promise<void>

export type BaseDataListType<T = StaticDataItemType> = {
  items: T[]
  meta?: MetaType
}

export type BaseDataListStringType = {
  items: string[]
}

export type BaseDataInfiniteType<T = []> = {
  pages: BaseDataListType<T>[]
  pageParams?: unknown
}

export type BaseMutationPayloadType<P = BaseUnknownRecordType, D = BaseUnknownRecordType, H = BaseUnknownRecordType> = {
  params?: P
  data?: D
  headers?: H
}

export type BaseShapeFormType = {
  [key: string]: string | null | SelectItem | BaseShapeFormType | BaseShapeFormType[] | any
}

export type BaseSizeType = 'sm' | 'md' | 'lg'
export type GutterType = 'none' | BaseSizeType
export type IconSize = 'mini' | BaseSizeType

export type SkeletonType =
  | 'default'
  | 'history'
  | 'returns'
  | 'claims'
  | 'customers'
  | 'metrics'
  | 'parcels'
  | 'actions'
  | 'checklist'
  | 'parcels-articles'
  | 'orders-articles'
  | 'tour-points'
  | 'visits-history'
  | 'orders-delivery'
