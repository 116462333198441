import { useTranslation } from 'next-i18next'
import NextImage from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { book } from 'src/init/book'

import { Button, DialogContent, DialogHeader, Typography } from 'src/components/ui'

import { useDialog } from 'src/contexts'

import { GET_ENUMS } from 'src/utils'

export const DialogNoPoints = () => {
  const { t } = useTranslation()
  const { setOnPageDialog } = useDialog()
  const router = useRouter()

  useEffect(() => {
    if (router.pathname !== book.dashboard) {
      setOnPageDialog(GET_ENUMS.dialog.noPoints, false)
    }
  }, [setOnPageDialog, router])

  return (
    <DialogContent name={GET_ENUMS.dialog.noPoints} className='flex flex-col items-center justify-center'>
      <DialogHeader>
        <Typography variant='title' color='gray-10'>
          {t('hey')}
        </Typography>
      </DialogHeader>
      <Typography variant='secondary' color='gray-7' className='max-w-[70%]'>
        {t('view_full_tour_info')}
      </Typography>
      <NextImage src='/assets/images/flag.png' width={64} height={64} alt='flag' priority />
      <Button variant='ghost'>
        <Link href={book.tourPoints}>
          <Typography variant='secondary' color='blue-7'>
            {t('buttons:create_new_point')}
          </Typography>
        </Link>
      </Button>
    </DialogContent>
  )
}
