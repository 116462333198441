import { FiltersDynamicForm } from 'src/components/common'

import { FiltersDynamicType, FilterStaticDataType, FilterStaticEnum, FormSubmitParams } from 'src/models'

import { useParcelsFilters, useParcelsListFetch } from 'src/bus/parcels'

import { useUrlQuery } from 'src/hooks'

import { usePageUrlContext } from 'src/contexts'

export const ParcelsDynamicFilters = () => {
  const { initialFilters, setStorageFilters } = usePageUrlContext()

  const { onRefetchParcelsList } = useParcelsListFetch(initialFilters)
  const { onUpdateQuery } = useUrlQuery()
  const { parcelsFilters } = useParcelsFilters()

  const filterStaticItems: FilterStaticDataType = {
    parcelsStatus: {
      name: FilterStaticEnum.parcelsStatus,
      options: parcelsFilters.parcelsStatus,
      isMulti: true,
    },
    parcelsType: {
      name: FilterStaticEnum.parcelsType,
      options: parcelsFilters.parcelsType,
    },
    date: {
      name: FilterStaticEnum.date,
    },
  }

  const filterSearchItem = {
    search: {
      name: FilterStaticEnum.search,
      placeholder: 'suggestion.placeholder',
    },
  }

  const onSubmit = async (data: FormSubmitParams<FiltersDynamicType>) => {
    const { filterData, filter } = onUpdateQuery({
      filtersValues: data.values,
      filterItems: filterStaticItems,
    })

    setStorageFilters(filter)
    await onRefetchParcelsList(filterData)
  }

  return (
    <div className='bg-gray-1 px-2'>
      <FiltersDynamicForm
        filterItemsSet={filterSearchItem}
        onSubmit={onSubmit}
        advancedIcon='Camera'
        initialValues={initialFilters?.filter}
      />
    </div>
  )
}
