import { Col, Row } from 'antd'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'

import {
  AlertDialog,
  Dialog,
  AlertDialogViewsSwitcher,
  DialogViewsSwitcher,
  Link,
  ParcelsArticlesFilters,
  ParcelsDetailsItem,
  SkeletonList,
  WrappedNodeDefault,
} from 'src/components/common'
import { Empty, Icon, InfiniteScroll, Loader, Skeleton, Typography } from 'src/components/ui'

import { ConvertCodeType, ParcelsArticleType, ParcelType } from 'src/models'

import { useParcelsArticlesFetch, useParcelsCheck, useParcelsCheckCompliance, useParcelsMoveOut } from 'src/bus/parcels'

import { useDialog } from 'src/contexts'

import { GET_ENUMS, getInfiniteList, getInfiniteListLength, gtOne, orNull, rowGutters, ternary } from 'src/utils'

type ParcelsDetailsProps = {
  data: ParcelType | null
  loadingFetch: boolean
}

export const ParcelsDetails = ({ data, loadingFetch }: ParcelsDetailsProps) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { checkCompliance } = useParcelsCheckCompliance()
  const { onMoveOutParcels } = useParcelsMoveOut()
  const { onPageDialogs, setOnPageDialog } = useDialog()
  const { onCheckParcels, loadingParcelsCheck } = useParcelsCheck()

  const [selectItem, setSelectItem] = useState<ParcelsArticleType>()

  const parcelId = Number(router.query.id)

  const isCheckCompliance = checkCompliance || !!data?.isChecked

  const { articlesList, loadingArticlesListFetch, articlesListHasMore, onFetchMoreArticlesList } =
    useParcelsArticlesFetch({ parcelId })

  const articlesHeader = useMemo(() => {
    const defaultHeader = [
      {
        title: 'parcels:list.article_and_name',
        span: isCheckCompliance ? 9 : 12,
      },
      {
        title: 'parcels:list.weight',
        span: 3,
      },
      {
        title: 'parcels:list.qty',
        span: 3,
      },
      {
        title: 'parcels:list.amount',
        span: 6,
      },
    ]

    if (isCheckCompliance) {
      defaultHeader.splice(0, 0, {
        title: '',
        span: 3,
      })
    }

    return [...defaultHeader]
  }, [isCheckCompliance])

  const onHandleCheck = () => {
    if (!selectItem) {
      return
    }

    onCheckParcels(selectItem.id)
    setOnPageDialog(GET_ENUMS.alertDialog.confirm, false)
  }

  const onCheckCompliance = (item: ParcelsArticleType) => {
    setSelectItem(item)

    if (!data?.isAvailableCheck) {
      return
    }

    if (!item.isChecked && gtOne(Number(item.quantity))) {
      setOnPageDialog(GET_ENUMS.alertDialog.confirm, true)
    } else {
      onCheckParcels(item.id)
    }
  }

  const onMoveOut = async (item: ParcelsArticleType) => {
    setSelectItem(item)
    if (gtOne(Number(item.quantity))) {
      setOnPageDialog(GET_ENUMS.dialog.moveOutArticle, true)
    } else {
      await onMoveOutParcels(item.id)
    }
  }

  const renderItems = () =>
    articlesList.pages.map((page) => {
      return page.items.map((item) => {
        return (
          <ParcelsDetailsItem
            key={item.id}
            item={item}
            checkCompliance={isCheckCompliance}
            onCheckCompliance={onCheckCompliance}
            onMoveOut={onMoveOut}
          />
        )
      })
    })

  const infiniteList = getInfiniteList(articlesList.pages) as unknown as ParcelsArticleType[]

  const onHandleCheckParcels = ({ articleNo }: ConvertCodeType) => {
    const foundArticle = infiniteList.find((item) => item.article === articleNo)

    if (foundArticle?.id) {
      onCheckParcels(foundArticle.id)
    }
  }

  return (
    <>
      <AlertDialog
        open={onPageDialogs[GET_ENUMS.alertDialog.confirm]?.visible}
        onOpenChange={(open) => setOnPageDialog(GET_ENUMS.alertDialog.confirm, open)}
      >
        <AlertDialogViewsSwitcher
          name={GET_ENUMS.alertDialog.confirm}
          params={{
            title: t('parcels:modal_check.title', { qty: selectItem?.quantity }) as string,
            description: t('parcels:modal_check.description'),
            onConfirm: onHandleCheck,
          }}
        />
      </AlertDialog>
      <AlertDialog
        open={onPageDialogs[GET_ENUMS.alertDialog.packParcel]?.visible}
        onOpenChange={(open) => setOnPageDialog(GET_ENUMS.alertDialog.packParcel, open)}
      >
        <AlertDialogViewsSwitcher name={GET_ENUMS.alertDialog.packParcel} />
      </AlertDialog>
      <Dialog
        open={onPageDialogs[GET_ENUMS.dialog.moveOutArticle]?.visible}
        onOpenChange={(open) => setOnPageDialog(GET_ENUMS.dialog.moveOutArticle, open)}
      >
        <DialogViewsSwitcher name={GET_ENUMS.dialog.moveOutArticle} params={{ parcelsArticle: selectItem }} />
      </Dialog>
      <div className='mb-2'>
        {ternary(
          loadingFetch,
          <Skeleton className='mx-2 h-6' />,
          <div className='flex w-full items-center justify-center gap-2'>
            <Typography variant='secondary' color='gray-7'>
              {t('parcels:track_number')}:
            </Typography>
            <Typography variant='secondary'>{WrappedNodeDefault(data?.shipmentNumber)}</Typography>
            {data?.trackingLink && (
              <div>
                <Link href={data.trackingLink} blank>
                  <Icon name='NewWindow' color='gray-7' />
                </Link>
              </div>
            )}
          </div>,
        )}
      </div>
      {ternary(
        loadingFetch,
        <Skeleton className='mx-2 h-[22px]' />,
        <div className='flex w-full items-center justify-around gap-6'>
          <div className='flex items-center gap-1'>
            <Typography variant='secondary' color='gray-7'>
              {t('type')}:
            </Typography>
            <Typography variant='secondary'>{data?.type?.label}</Typography>
          </div>
          <div className='flex items-center gap-1 '>
            <Typography variant='secondary' color='gray-7'>
              {t('status')}:
            </Typography>
            <Typography variant='secondary'>{data?.status?.label}</Typography>
          </div>
        </div>,
      )}
      {orNull(
        checkCompliance,
        <div className='mt-2'>
          <ParcelsArticlesFilters parcelId={parcelId} onCheckParcels={onHandleCheckParcels} />
        </div>,
      )}
      <div className='bg-gray-1 px-2 py-3'>
        <Row gutter={rowGutters}>
          {articlesHeader.map((item) => {
            return (
              <Col key={item.title} span={item.span}>
                {orNull(
                  item.title,
                  <Typography variant='caption' className='font-semibold'>
                    <span dangerouslySetInnerHTML={{ __html: item.title && t(item.title) }} />
                  </Typography>,
                )}
              </Col>
            )
          })}
        </Row>
      </div>
      {orNull(loadingParcelsCheck, <Loader />)}
      <InfiniteScroll
        hasMore={articlesListHasMore}
        loadingFetch={loadingArticlesListFetch}
        onFetchMore={onFetchMoreArticlesList}
        dataLength={getInfiniteListLength(articlesList.pages)}
        loader={<SkeletonList type='parcels-articles' />}
        scrollableTarget='parcels'
        renderItems={renderItems}
        empty={<Empty title='common:empty.no_activity' description='common:empty.parcels_details' />}
      />
    </>
  )
}
