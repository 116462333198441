export const actionsState = {
  actionsList: {
    items: [],
    meta: null,
  },
  actionsReports: {
    items: [],
    meta: null,
  },
}

export const actionsKeys = {
  all: ['actions'],
  list: (visitId: number) => [...actionsKeys.all, 'list', visitId],
  reports: (visitId: number) => [...actionsKeys.all, 'report', visitId],
  details: (id: number) => [...actionsKeys.all, 'details', id],
}
