import { Stepper } from 'antd-mobile'

import st from './Styles.module.css'

type Props = {
  defaultValue?: number
  min?: number
  max?: number
  value?: number
  onChange: (value: number) => void
}

export const InputStepper = ({ defaultValue = 1, min = 1, max = 1, onChange, value }: Props) => {
  return (
    <div className={st.stepper}>
      <Stepper defaultValue={defaultValue} min={min} max={max} value={value} onChange={onChange} />
    </div>
  )
}
