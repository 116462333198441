import {
  Children,
  Context,
  createContext,
  createElement,
  isValidElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'

export function useStrictContext<T>(context: Context<T | null>) {
  const value = useContext(context)

  if (!value) {
    throw new Error('Strict context not passed')
  }

  return value as T
}

export function createStrictContext<T>() {
  return createContext<T | null>(null)
}

export function ComposeChildren({ children }: { children: ReactNode }) {
  const array = Children.toArray(children)
  const last = array.pop()

  return (
    <>
      {array.reduceRight(
        (child, element) => (isValidElement(element) ? createElement(element.type, element.props, child) : child),
        last,
      )}
    </>
  )
}

type Fn<ARGS extends any[], R> = (...args: ARGS) => R

export function useEventCallback<A extends any[], R>(fn: Fn<A, R>): Fn<A, R> {
  const ref = useRef<Fn<A, R>>(fn)

  useEffect(() => {
    ref.current = fn
  })

  return useMemo(
    () =>
      (...args: A): R => {
        const { current } = ref
        return current(...args)
      },
    [],
  )
}
