import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { book } from 'src/init/book'

import { FormSubmitParams, HttpErrorType, VisitCreateReportSubmitParamsType } from 'src/models'

import { tourKeys } from 'src/bus/tour'
import { visitsKeys, useVisitsCreateReportMutation } from 'src/bus/visits'

import { useToast } from 'src/hooks'

import { fillServerErrorsToForm } from 'src/utils'

type VisitsReportCreateCb = (data: FormSubmitParams<VisitCreateReportSubmitParamsType>) => Promise<void>

type VisitsReportCreateType = {
  loadingVisitsReportCreate: boolean
  onCreateVisitsReport: VisitsReportCreateCb
}

export const useVisitsReportCreate = (): VisitsReportCreateType => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const { t } = useTranslation()
  const showToast = useToast()
  const { onCreateVisitReportMutation } = useVisitsCreateReportMutation()

  const onCreateVisitsReport = useCallback<VisitsReportCreateCb>(
    async (data) => {
      const id = Number(router.query.id)

      onCreateVisitReportMutation.mutate(
        { data: data.values, params: { id } },
        {
          onError: (error) => {
            const errs = (error as HttpErrorType).errors
            if (error instanceof Object && errs) {
              fillServerErrorsToForm(errs, data.acts.setError)
            }
          },
          onSuccess: async () => {
            await router.replace(book.visits)
            showToast.success({ title: t('notifications:report_create') })
            await queryClient.invalidateQueries({ queryKey: visitsKeys.visit(id) })
            await queryClient.invalidateQueries({ queryKey: visitsKeys.list() })
            await queryClient.invalidateQueries({ queryKey: tourKeys.planForToday() })
          },
        },
      )
    },
    [onCreateVisitReportMutation, router, showToast, t, queryClient],
  )

  return {
    loadingVisitsReportCreate: onCreateVisitReportMutation.isPending,
    onCreateVisitsReport,
  }
}
