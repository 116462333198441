import { StaticDataItemType, StaticDataKeys } from 'src/models'

import { useStaticDataFetch } from 'src/bus/staticData'

type UseClaimsAdditionalCausesFetchType = {
  loadingAdditionalCausesFetch: boolean
  additionalCauses: StaticDataItemType[]
}

export const useClaimsAdditionalCausesFetch = (): UseClaimsAdditionalCausesFetchType => {
  const staticDataKeys = [StaticDataKeys.additionalCauses]

  const { staticData, loadingStaticDataFetch } = useStaticDataFetch(staticDataKeys)

  return {
    loadingAdditionalCausesFetch: loadingStaticDataFetch,
    additionalCauses: staticData?.[StaticDataKeys.additionalCauses] || [],
  }
}
