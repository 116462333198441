import { TourPlanForTodayType } from 'src/models'

import { tourKeys, tourService } from 'src/bus/tour'

import { useQueryAsync } from 'src/hooks'

type UseTourPlanForTodayFetchType = {
  loadingPlanForTodayFetch: boolean
  planForToday: TourPlanForTodayType | null
}

export const useTourPlanForTodayFetch = (): UseTourPlanForTodayFetchType => {
  const fetchTourPlanForTodayProps = useQueryAsync<TourPlanForTodayType>({
    key: tourKeys.planForToday(),
    name: 'fetchTourPlanForToday',
    fetcher: tourService.fetchPlanForToday,
  })

  return {
    loadingPlanForTodayFetch: fetchTourPlanForTodayProps.isLoading,
    planForToday: fetchTourPlanForTodayProps.data || null,
  }
}
