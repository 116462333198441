import { CustomersList, CustomersFilters } from 'src/components/common'

export const ViewCustomers = () => {
  return (
    <div className='flex h-full flex-col gap-4 py-2' data-test-id='customers'>
      <CustomersFilters />
      <CustomersList />
    </div>
  )
}
