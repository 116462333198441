import { useQueryClient } from '@tanstack/react-query'
import toFormData from 'json-form-data'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { FormSubmitParams, FormUploadImagesType, HttpErrorType } from 'src/models'

import { claimsKeys, useClaimsUploadImagesMutation } from 'src/bus/claims'
import { useCustomersDetailsFetch } from 'src/bus/customers'
import { returnsKeys } from 'src/bus/returns'

import { useToast } from 'src/hooks'

import { getConvertedUploadImgToObj } from 'src/utils'

type ClaimsUpdateCbType = (data: FormSubmitParams<FormUploadImagesType>) => Promise<void>

type UseClaimsUpdateType = {
  loadingClaimsUploadImages: boolean
  onUploadImagesClaims: ClaimsUpdateCbType
}

export const useClaimsImagesUpload = (): UseClaimsUpdateType => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const showToast = useToast()
  const { customerId } = useCustomersDetailsFetch()
  const { onUploadImagesClaimsMutation } = useClaimsUploadImagesMutation()

  const id = Number(router.query.id)
  const params = { ...(id && { id }), ...(customerId && { customerId }) }

  const queryKey = claimsKeys.detail(params)

  const onUploadImagesClaims = useCallback<ClaimsUpdateCbType>(
    async (data) => {
      try {
        const values = toFormData({
          customerId,
          ...getConvertedUploadImgToObj(data.values.images || []),
        })

        router.back()

        await onUploadImagesClaimsMutation.mutateAsync({
          data: values,
          params: { id: String(id) },
        })

        await queryClient.refetchQueries({ queryKey })
        await queryClient.invalidateQueries({ queryKey: returnsKeys.all })
      } catch (error) {
        const errs = (error as HttpErrorType).errors
        if (errs) {
          const [[title, body]] = Object.entries(errs)
          showToast.error({ title, body: body.desc })
        }
      }
    },
    [customerId, queryKey, showToast, onUploadImagesClaimsMutation, router, id, queryClient],
  )

  return {
    loadingClaimsUploadImages: onUploadImagesClaimsMutation.isPending,
    onUploadImagesClaims,
  }
}
