import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'next-i18next'
import { BaseSyntheticEvent, ReactNode } from 'react'
import { useForm } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { GroupVisitPlanActionsFields } from 'src/components/common'
import { Form, FormControl, FormField, FormFields, FormItem, FormMessage, Separator } from 'src/components/ui'

import {
  FormVisitReportEnum,
  FormReportVisitType,
  ActionsReportsType,
  VisitCreateReportParamsType,
  StaticDataItemType,
  FormSubmitParams,
  VisitCreateReportSubmitParamsType,
} from 'src/models'

import { useAuthProfile } from 'src/bus/auth'
import { formShapes } from 'src/bus/forms'

import { messages, orNull } from 'src/utils'

type VisitReportFormProps = {
  loadingActionsFetch?: boolean
  loadingBusinessActionsFetch?: boolean
  actionsReports: ActionsReportsType
  businessActions: StaticDataItemType[]
  loadingCreate?: boolean
  formDisabled?: boolean
  initialValues?: VisitCreateReportParamsType | null
  onSubmit?: (data: FormSubmitParams<VisitCreateReportSubmitParamsType>) => Promise<void>
  renderFloatingPanel?: (
    onSubmit: (e?: BaseSyntheticEvent | undefined) => Promise<void>,
    loadingSubmit: boolean,
    disabledBtn: boolean,
  ) => ReactNode
}

export const VisitReportForm = ({
  loadingActionsFetch = false,
  loadingBusinessActionsFetch = false,
  initialValues = null,
  loadingCreate = false,
  actionsReports,
  businessActions,
  formDisabled = false,
  onSubmit,
  renderFloatingPanel,
}: VisitReportFormProps) => {
  const { t } = useTranslation()
  const { isRoleProspector } = useAuthProfile()

  const formProps = useForm<FormReportVisitType>({
    defaultValues: { ...formShapes.visitReport.shape },
    resolver: zodResolver(formShapes.visitReport.schema(t, isRoleProspector)),
    mode: 'onChange',
  })

  const { handleSubmit, control, setError, reset, formState } = formProps
  const { isDirty, isValid } = formState

  const disabledBtn = !isValid || !isDirty || formDisabled

  useDeepCompareEffect(() => {
    if (initialValues) {
      // form can't update { id, value, label } format, only string should be using for checkbox value
      const actions = initialValues.actions.map((item) => String(item?.id))
      const businessActions = initialValues.businessActions.map((item) => String(item?.id))
      reset({ ...initialValues, businessActions, actions })
    }
  }, [initialValues, reset])

  const onSubmitForm = handleSubmit(async (values) => {
    const enhancedValues = {
      ...values,
      actions: values.actions.map((item) => actionsReports.items.find((action) => action?.value === item)),
      businessActions: values.businessActions.map((item) => businessActions.find((action) => action?.value === item)),
    } as unknown as VisitCreateReportSubmitParamsType

    await onSubmit?.({ values: enhancedValues, acts: { setError, reset } })
  })

  return (
    <Form {...formProps}>
      <FormFields.FormGeneral>
        {orNull(
          isRoleProspector,
          <>
            <GroupVisitPlanActionsFields
              name={FormVisitReportEnum.businessActions}
              options={businessActions}
              control={control}
              loading={loadingBusinessActionsFetch}
              hasDisabled={formDisabled}
              className='last:mb-0'
            />
            <Separator className='my-2' />
          </>,
        )}
        <GroupVisitPlanActionsFields
          name={FormVisitReportEnum.actions}
          options={actionsReports.items}
          control={control}
          loading={loadingActionsFetch}
          hasDisabled={formDisabled}
        />
        <FormField
          control={control}
          name={FormVisitReportEnum.comment}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormFields.InputTextArea
                  {...field}
                  placeholder={t(messages.createReport.comment.placeholder)}
                  disabled={formDisabled}
                  rows={4}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div>{renderFloatingPanel?.(onSubmitForm, loadingCreate, disabledBtn)}</div>
      </FormFields.FormGeneral>
    </Form>
  )
}
