import { CellCopy } from 'src/components/common'

import { useCouponsFetch } from 'src/bus/coupons'

export const CellActiveCoupon = (visitId?: number) => {
  const { coupons, loadingCouponsFetch } = useCouponsFetch(visitId)

  const couponValue = coupons?.current.code || ''

  return (
    <CellCopy
      value={couponValue}
      disabled={loadingCouponsFetch || !couponValue}
      copiedText='customer_coupon_copied'
      copyValue={couponValue}
      loadingFetch={loadingCouponsFetch}
    />
  )
}
