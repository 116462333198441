import type { TFunction } from 'i18next'
import { z } from 'zod'

import { messages } from 'src/utils'

export const uploadImagesClaims = {
  shape: {
    images: [],
  },
  schema: (t: TFunction) => {
    return z
      .object({
        images: z
          .array(
            z.object({
              key: z.union([z.string(), z.number()]).optional(),
              url: z.string(),
              thumbnailUrl: z.string().optional(),
              extra: z.custom().optional(),
            }),
          )
          .min(messages.claimImages.min, t(messages.claimImages.minText, { count: messages.claimImages.min })),
      })
      .nullable()
  },
}
