import { VisitActionsType } from 'src/models'

import { visitsState, visitsKeys, visitsService } from 'src/bus/visits'

import { useQueryAsync } from 'src/hooks'

type UseVisitsActionsFetchType = {
  loadingVisitsActionsFetch: boolean
  visitsActions: VisitActionsType
}

export const useVisitsActionsFetch = (customerId?: number): UseVisitsActionsFetchType => {
  const params = { customerId }

  const fetchVisitsActionsProps = useQueryAsync<VisitActionsType>({
    key: visitsKeys.actions(customerId),
    name: 'fetchVisitsActions',
    fetcher: visitsService.fetchVisitsActions,
    fetcherPayload: params,
    options: { enabled: !!customerId },
  })

  return {
    loadingVisitsActionsFetch: fetchVisitsActionsProps.isLoading,
    visitsActions: fetchVisitsActionsProps.data || visitsState.visitActions,
  }
}
