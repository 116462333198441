import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import {
  BaseMutationPayloadType,
  BaseUnknownRecordType,
  FetchDataType,
  HttpErrorType,
  HttpStatusCode,
} from 'src/models'

import { queryFetcher, isNotNilOrNotEmpty } from 'src/utils'

type UseQueryAsyncOptionsType<TQueryFnData, TData, TQueryKey extends QueryKey, TFetcherPayload> = {
  key: TQueryKey
  name: string
  fetcher: FetchDataType<TQueryFnData>
  fetcherPayload?: TFetcherPayload
  fetcherType?: 'query' | 'mutation'
  options?: Omit<UseQueryOptions<TQueryFnData, HttpErrorType, TData, TQueryKey>, 'queryKey' | 'queryFn'>
}

/* core */
export const useQueryAsync = <
  TQueryFnData = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
  TFetcherPayload = BaseUnknownRecordType,
>({
  key,
  name,
  fetcher,
  fetcherPayload: params,
  fetcherType = 'query',
  options,
}: UseQueryAsyncOptionsType<TQueryFnData, TData, TQueryKey, TFetcherPayload & { id?: number }>) => {
  const fetcherPayload = {
    ...(params?.id ? { id: params.id } : {}),
    ...(isNotNilOrNotEmpty(params) ? { params } : {}),
  }

  return useQuery({
    queryKey: key,
    queryFn: () =>
      queryFetcher<TQueryFnData, BaseMutationPayloadType<TFetcherPayload>>({
        name,
        fetcher,
        fetcherType,
        fetcherPayload,
      }),
    throwOnError: (error) => error?.status >= HttpStatusCode.server,
    ...options,
  })
}
