import { StaticDataInnerShape, StaticDataType } from 'src/models'

import { staticDataKeys, staticDataService } from 'src/bus/staticData'

import { useQueryAsync } from 'src/hooks'

type UseStaticDataFetchType = {
  loadingStaticDataFetch: boolean
  staticData: StaticDataInnerShape | null
}

export const useStaticDataFetch = (values: StaticDataType[]): UseStaticDataFetchType => {
  const params = { types: values }

  const fetchStaticDataProps = useQueryAsync<StaticDataInnerShape>({
    key: staticDataKeys.list(params),
    name: 'fetchStaticData',
    fetcher: staticDataService.fetchStaticData,
    fetcherPayload: params,
    options: { enabled: !!params?.types.length },
  })

  return {
    loadingStaticDataFetch: fetchStaticDataProps.isLoading,
    staticData: fetchStaticDataProps.data || null,
  }
}
