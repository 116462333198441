import { Icon } from 'src/components/ui'
import { IconProps } from 'src/components/ui/Icon'

type WrapperIconProps = IconProps & {
  activeName?: string
  inactiveName?: string
  active?: boolean
}

export const WrapperIcon = ({ activeName = '', inactiveName = '', active = false, ...rest }: WrapperIconProps) => {
  return <Icon {...rest} name={active ? activeName : inactiveName} />
}
