import { StaticDataItemType, StaticDataKeys } from 'src/models'

import { useStaticDataFetch } from 'src/bus/staticData'

type ManagePointsStaticDataFetchType = {
  customerStatus: StaticDataItemType[]
  profileExists: StaticDataItemType[]
  potential: StaticDataItemType[]
  businessType: StaticDataItemType[]
  quantityEmployees: StaticDataItemType[]
  plannedVisitsExist: StaticDataItemType[]
  loadingCustomerStaticDataFetch: boolean
}

export const useManagePointsStaticDataFetch = (): ManagePointsStaticDataFetchType => {
  const staticDataKeys = [
    StaticDataKeys.acceptance,
    StaticDataKeys.customerStatus,
    StaticDataKeys.potential,
    StaticDataKeys.businessType,
    StaticDataKeys.quantityEmployees,
    StaticDataKeys.plannedVisitsExistence,
  ]

  const { staticData, loadingStaticDataFetch } = useStaticDataFetch(staticDataKeys)

  const data = {
    customerStatus: staticData?.['customer-status'] || [],
    profileExists: staticData?.['acceptance'] || [],
    potential: staticData?.['potential'] || [],
    businessType: staticData?.['business-type'] || [],
    quantityEmployees: staticData?.['quantity-employees'] || [],
    plannedVisitsExist: staticData?.['planned-visits-existence'] || [],
  }

  return {
    loadingCustomerStaticDataFetch: loadingStaticDataFetch,
    ...data,
  }
}
