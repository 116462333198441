import { useTranslation } from 'next-i18next'

import { FiltersStaticForm } from 'src/components/common'
import { SheetContent, Typography } from 'src/components/ui'

import { FilterStaticDataType, FilterStaticEnum } from 'src/models'

import { useSettingsFetch, useNavigationFetch, useNavigationUpdate } from 'src/bus/settings'

import { initialNavigation } from 'src/utils'

import st from './Styles.module.css'

export const NavigationFilters = () => {
  const { t } = useTranslation()
  const { navigator, loadingNavigationFetch } = useNavigationFetch()
  const { settings, loadingSettingsFetch } = useSettingsFetch()
  const { onUpdateNavigation, loadingNavigationUpdate } = useNavigationUpdate()

  const filterStaticItems: FilterStaticDataType = {
    navigator: {
      name: FilterStaticEnum.navigator,
      options: navigator,
    },
  }

  return (
    <SheetContent>
      <div className={st['content']}>
        <div className='p-2'>
          <Typography className='font-semibold'>{t('profile:choose_default_navigator')}</Typography>
        </div>
        <FiltersStaticForm
          onSubmit={onUpdateNavigation}
          initialValues={{ navigator: settings?.navigator?.value || initialNavigation }}
          filterItemsSet={filterStaticItems}
          buttons={['apply']}
          loadingSubmit={loadingNavigationUpdate}
          loadingStaticDataFetch={loadingNavigationFetch || loadingSettingsFetch}
        />
      </div>
    </SheetContent>
  )
}
