import { useTranslation } from 'next-i18next'

import { CustomersListItem, SkeletonList } from 'src/components/common'
import { Empty, InfiniteScroll, Typography } from 'src/components/ui'

import { useCustomersListFetch } from 'src/bus/customers'

import { getInfiniteListLength } from 'src/utils'

export const CustomersList = () => {
  const { t } = useTranslation()
  const { customersList, loadingCustomersListFetch, onFetchMoreCustomersList, customersListHasMore } =
    useCustomersListFetch()

  const renderItems = (onClick: () => void) => {
    return customersList.pages.map((page) => {
      return page.items.map((item) => {
        return <CustomersListItem key={item.id} item={item} onClick={onClick} />
      })
    })
  }

  return (
    <>
      <div className='px-4'>
        <div className='flex items-center gap-2'>
          <div className='grid basis-5/12'>
            <Typography variant='secondary' color='gray-7'>
              {t('customers:customer_name')}
            </Typography>
          </div>
          <div className='grid basis-2/12 justify-center'>
            <Typography variant='secondary' color='gray-7'>
              {t('customers:days')}
            </Typography>
          </div>
          <div className='grid basis-2.5/12 justify-center'>
            <Typography variant='secondary' color='gray-7'>
              {t('status')}
            </Typography>
          </div>
          <div className='grid basis-2.5/12 justify-center'>
            <Typography variant='secondary' color='gray-7'>
              {t('customers:sector')}
            </Typography>
          </div>
        </div>
      </div>
      <InfiniteScroll
        hasMore={customersListHasMore}
        onFetchMore={onFetchMoreCustomersList}
        dataLength={getInfiniteListLength(customersList.pages)}
        loadingFetch={loadingCustomersListFetch}
        loader={<SkeletonList type='customers' />}
        renderItems={renderItems}
        scrollableTarget='customers'
        empty={<Empty title='common:hey' description='common:empty.customers_list' />}
      />
    </>
  )
}
