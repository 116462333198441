import { WrappedNodeDefault } from 'src/components/common'
import { Typography, Skeleton } from 'src/components/ui'

type CellTextProps = {
  value?: string | number | null
  loadingFetch?: boolean
}

export const CellText = ({ value = '', loadingFetch = false }: CellTextProps) => {
  if (loadingFetch) {
    return <Skeleton className='h-[22px]' />
  }

  return <Typography variant='secondary'>{WrappedNodeDefault(value)}</Typography>
}
