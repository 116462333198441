import { useMutation } from '@tanstack/react-query'
import { UseMutationResult } from '@tanstack/react-query'

import { HttpErrorType, VisitParamsType } from 'src/models'

import { visitsService } from 'src/bus/visits'

import { queryFetcher } from 'src/utils'

type VisitsCreateFarAwayReportMutationType = {
  onCreateVisitFarAwayReportMutation: UseMutationResult<void, HttpErrorType, VisitParamsType>
}

export const useVisitsCreateFarAwayReportMutation = (): VisitsCreateFarAwayReportMutationType => {
  const onCreateVisitFarAwayReportMutation = useMutation<void, HttpErrorType, VisitParamsType>({
    mutationFn: (params) => {
      return queryFetcher({
        name: 'createVisitFarAwayReport',
        fetcher: visitsService.createVisitFarAwayReport,
        fetcherPayload: params,
      })
    },
  })

  return { onCreateVisitFarAwayReportMutation }
}
