import { useTranslation } from 'next-i18next'
import Link from 'next/link'

import { book } from 'src/init/book'

import { CornerEuro, HistoryCard, HistoryItem, HistoryItemRow, WrappedNodeDefault } from 'src/components/common'
import { Typography } from 'src/components/ui'

import { HistoryOrderType } from 'src/models'

import { useAuthProfile } from 'src/bus/auth'

import { currencyFormat, formatDate, getTranslate, orNull } from 'src/utils'

type HistoryOrderItemType = {
  item: HistoryOrderType
  showDate: boolean
  customerId: number
  index: number
}

export const HistoryOrdersItem = ({ item, index, customerId, showDate }: HistoryOrderItemType) => {
  const { t } = useTranslation()
  const { profile } = useAuthProfile()

  const getHistoryT = getTranslate('history', t)

  const pathToDetails = { pathname: `${book.orders}/${item.id}`, query: { customerId } }

  const dateTitle = t(item?.deliveryAt ? 'delivered' : 'expected')
  const dateValue = formatDate(item.deliveryAt || item.expectedDeliveryAt, 'slashShort')

  return (
    <HistoryItem date={item.createdAt} index={index} showDate={showDate}>
      <HistoryCard variant='order'>
        {orNull(item?.isOrderPaid ? !item.isOrderPaid : false, <CornerEuro />)}
        <Link href={pathToDetails}>
          <div className='mb-2'>
            <Typography className='font-semibold'>{getHistoryT('order_number', { number: item.id })}</Typography>
          </div>
          <HistoryItemRow label={getHistoryT('status')} description={WrappedNodeDefault(item?.status?.label)} />
          <HistoryItemRow label={dateTitle} description={WrappedNodeDefault(dateValue)} />
          <HistoryItemRow
            label={getHistoryT('amount')}
            description={currencyFormat(item?.price, profile?.country?.value)}
          />
          <HistoryItemRow
            label={getHistoryT('total_qty')}
            description={
              <>
                {WrappedNodeDefault(getHistoryT('quantity_value', { value: item?.totalQuantity }))}
                &nbsp;
                {WrappedNodeDefault(getHistoryT('articles_value', { value: item?.totalArticles }))}
              </>
            }
          />
        </Link>
      </HistoryCard>
    </HistoryItem>
  )
}
