import { forwardRef } from 'react'
import { ClearSuggestions, getGeocode, getLatLng, SetValue, Suggestions } from 'use-places-autocomplete'

import { ListItem, Skeleton, Typography } from 'src/components/ui'
import { InputBase } from 'src/components/ui/FormFields'

import { LocationType } from 'src/models'

import { ternary } from 'src/utils'

import st from './Styles.module.css'

type InputGoogleSearchProps = {
  panTo?: (latLng: LocationType | null) => void
  onChange?: (latLng: LocationType | null) => void
  placeholder?: string
  loading?: boolean
  value: string
  ready: boolean
  suggestions: Suggestions
  setValue: SetValue
  clearSuggestions: ClearSuggestions
}

export const InputGoogleSearch = forwardRef<any, InputGoogleSearchProps>(
  ({ panTo, onChange, placeholder = '', ready, setValue, value, clearSuggestions, ...rest }, ref) => {
    const handleInput = (value: string) => {
      if (!value) {
        onChange?.(null)
        panTo?.(null)
      }

      setValue(value)
    }

    const handleSelect = async (address: string) => {
      setValue(address, false)
      clearSuggestions()

      try {
        const results = await getGeocode({ address })
        const { lat, lng } = getLatLng(results[0])
        panTo?.({ lat, lng })
        onChange?.({ lat, lng })
      } catch (error) {
        console.log('😱 Error: ', error)
      }
    }

    const suggestions = rest.suggestions.data.map((suggestion) => {
      const {
        place_id,
        description,
        structured_formatting: { main_text, secondary_text },
      } = suggestion

      return (
        <ListItem size='md' key={place_id} onClick={() => handleSelect(description)}>
          <Typography variant='secondary'>
            <strong>{main_text}</strong> <small>{secondary_text}</small>
          </Typography>
        </ListItem>
      )
    })

    return (
      <>
        {ternary(
          !ready,
          <Skeleton className='h-10' />,
          <>
            <InputBase
              {...rest}
              ref={ref}
              value={value}
              onChange={handleInput}
              disabled={!ready}
              placeholder={placeholder}
            />
            {rest.suggestions.status === 'OK' && <div className={st['suggestions-list']}>{suggestions}</div>}
          </>,
        )}
      </>
    )
  },
)

InputGoogleSearch.displayName = 'InputGoogleSearch'
