import { useRouter } from 'next/router'

import { ActionsReportsType } from 'src/models'

import { actionsKeys, actionsState, actionsService } from 'src/bus/actions'

import { useQueryAsync } from 'src/hooks'

type UseActionsReportsFetchType = {
  loadingActionsReportsFetch: boolean
  actionsReports: ActionsReportsType
}

export const useActionsReportsFetch = (): UseActionsReportsFetchType => {
  const router = useRouter()
  const id = Number(router.query.id)
  const params = { visitId: id }

  const fetchActionsReportProps = useQueryAsync<ActionsReportsType>({
    key: actionsKeys.reports(id),
    name: 'fetchActionsReport',
    fetcher: actionsService.fetchActionsReports,
    fetcherPayload: params,
    options: { enabled: !!id },
  })

  return {
    loadingActionsReportsFetch: fetchActionsReportProps.isLoading,
    actionsReports: fetchActionsReportProps.data || actionsState.actionsReports,
  }
}
