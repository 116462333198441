import { cva, VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { WrappedNodeDefault } from 'src/components/common'
import { Skeleton } from 'src/components/ui'

import { SelectItem } from 'src/models'

import { cn, getStatusFromValue } from 'src/utils'

const statusItemVariants = cva(
  "py-0.5 px-2 flex w-fit items-center justify-center rounded-full font-['Inter'] text-xs/[20px] text-white",
  {
    variants: {
      variant: {
        stnd: 'bg-gray-7 font-semibold',
        lead: 'bg-gray-7 font-semibold',
        prsp: 'bg-gray-7 font-semibold',
        nact: 'bg-gray-7 font-semibold',
        ncst: 'bg-gray-7 font-semibold',
        ncst_0: 'bg-gray-7 font-semibold',
        ncst_1: 'bg-gray-7 font-semibold',
        ncst_2: 'bg-gray-7 font-semibold',
        ncst_3: 'bg-gray-7 font-semibold',
        ncst_4: 'bg-gray-7 font-semibold',
        clos: 'bg-red-dark font-semibold',
        clsd: 'bg-red-dark font-semibold',
        silv: 'bg-gray-6',
        gold: 'bg-gold',
        plat: 'bg-blue-gray-8',
        completed: 'bg-lite-green-opacity-1 text-yellow-green',
        in_progress: 'bg-blue-gray-2 text-blue-gray-9',
        'in-progress': 'bg-blue-gray-2 text-blue-gray-9',
      },
    },
    defaultVariants: {
      variant: 'stnd',
    },
  },
)

export type TStatusItemVariants = VariantProps<typeof statusItemVariants>['variant']

export type StatusItemProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof statusItemVariants> & {
    status: SelectItem
    loadingFetch?: boolean
  }

const StatusItem = React.forwardRef<HTMLDivElement, StatusItemProps>(
  ({ status, loadingFetch = false, className, ...props }, ref) => {
    if (loadingFetch) {
      return <Skeleton className='h-6 w-full' />
    }

    if (!status) {
      return <>{WrappedNodeDefault('')}</>
    }

    const { statusValue: variant, label } = getStatusFromValue(status) || {}

    return (
      <div className={cn(statusItemVariants({ variant, className }))} ref={ref} {...props}>
        {WrappedNodeDefault(label)}
      </div>
    )
  },
)

StatusItem.displayName = 'StatusItem'

export { StatusItem, statusItemVariants }
