import { StaticDataItemType, ClaimType } from 'src/models'

type ClaimsState = {
  claim: ClaimType | null
  claimQuantity: StaticDataItemType[]
  claimCauses: StaticDataItemType[]
  claimTotalPrice: number
}

export const claimsState: ClaimsState = {
  claim: null,
  claimQuantity: [],
  claimCauses: [],
  claimTotalPrice: 0,
}

export const claimsKeys = {
  all: ['claims'],
  lists: () => [...claimsKeys.all, 'list'],
  list: (params: any) => [...claimsKeys.lists(), params], // TODO fix any
  details: () => [...claimsKeys.all, 'detail'],
  detail: (params: any) => [...claimsKeys.details(), params], // TODO fix any
  searchLists: () => [...claimsKeys.all, 'search-list'],
  searchList: (filters: any) => [...claimsKeys.searchLists(), { filters }], // TODO fix any
  causes: (params: any) => [...claimsKeys.all, 'causes', params],
  quantities: (params: any) => [...claimsKeys.all, 'quantities', params],
  totalPrice: (params: any) => [...claimsKeys.all, 'totalPrice', params],
}
