import { SearchBarProps, SearchBarRef } from 'antd-mobile/es/components/search-bar'
import { forwardRef } from 'react'

import { FormFields } from 'src/components/ui'

import { ConvertCodeType } from 'src/models'

import { orNull } from 'src/utils'

import st from './Styles.module.css'

type InputSuggestionProps = SearchBarProps & {
  loadingFetch?: boolean
  renderItem?: JSX.Element
  advancedIcon?: string
  advancedFn?: (data: ConvertCodeType) => void
}

const SuggestionItem = ({ value }: { value?: string }) => <div>{value}</div>

export const InputSuggestion = forwardRef<SearchBarRef, InputSuggestionProps>(
  ({ renderItem = null, advancedIcon, ...rest }, ref) => {
    const renderItemJSX = renderItem ? renderItem : <SuggestionItem />

    return (
      <div className='relative font-[Inter]'>
        <FormFields.SearchBar {...rest} ref={ref} icon={null} advancedIcon={advancedIcon} />
        {orNull(rest.value, <div className={st['autocomplete-list']}>{renderItemJSX}</div>)}
      </div>
    )
  },
)

InputSuggestion.displayName = 'InputSuggestion'
