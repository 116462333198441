import { useEffect, useMemo } from 'react'

import { Dialog, PlanForToday, PlanForTodayMap, DialogViewsSwitcher } from 'src/components/common'

import { useTourPointsFetch, useTourRouteOptimize } from 'src/bus/tour'

import { useDialog } from 'src/contexts'

import { getEndType, GET_ENUMS, isNilOrEmpty } from 'src/utils'

export const ViewNewsPlans = () => {
  const { onPageDialogs, setOnPageDialog } = useDialog()
  const { tourPoints, loadingTourPointsFetch, errorTourPoints } = useTourPointsFetch()
  useTourRouteOptimize()

  const isInitialOpen = useMemo(
    () => !loadingTourPointsFetch && isNilOrEmpty(errorTourPoints) && isNilOrEmpty(getEndType(tourPoints.items)),
    [errorTourPoints, tourPoints.items, loadingTourPointsFetch],
  )

  useEffect(() => {
    if (!isInitialOpen || onPageDialogs[GET_ENUMS.dialog.noPoints]?.visible) {
      return
    }

    setOnPageDialog(GET_ENUMS.dialog.noPoints, true)
  }, [isInitialOpen])

  return (
    <div className='flex h-full flex-col gap-y-2 py-2' data-test-id='dashboard'>
      <Dialog
        open={onPageDialogs[GET_ENUMS.dialog.noPoints]?.visible}
        onOpenChange={(open) => setOnPageDialog(GET_ENUMS.dialog.noPoints, open)}
      >
        <DialogViewsSwitcher name={GET_ENUMS.dialog.noPoints} />
      </Dialog>
      <PlanForToday hasEstimate />
      <PlanForTodayMap />
    </div>
  )
}
