import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { book } from 'src/init/book'

import { Dialog, DialogViewsSwitcher } from 'src/components/common'
import { ListItem, Button, Typography, Icon, FloatingPanel } from 'src/components/ui'

import { MenuProfileType } from 'src/models'

import { useCustomersDetailsFetch } from 'src/bus/customers'

import { useDialog } from 'src/contexts'

import { FLOATING_PANEL_ANCHORS, GET_ENUMS, getVisitPlanPanel } from 'src/utils'

export const VisitPlanPanel = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const { onPageDialogs, setOnPageDialog } = useDialog()
  const { customersDetails, loadingCustomersDetailsFetch } = useCustomersDetailsFetch()

  const visitId = router.query.id

  const items = getVisitPlanPanel({
    t,
    hasDisabled: !customersDetails?.isHasLeadInvitedCredential,
    customerId: Number(customersDetails?.id),
    loadingFetch: loadingCustomersDetailsFetch,
  })

  const onNavigationHandler = () => {
    visitId && router.push(`${book.visits}/${visitId}${book.visitReport}`)
  }

  const onClickHandler = async (item: MenuProfileType) => {
    if (item.disabled) {
      return
    }

    if (item.dialog) {
      setOnPageDialog(item.key, true)
      return
    }
    await router.push(item.key)
  }

  return (
    <>
      {[
        GET_ENUMS.dialog.createCoupon,
        GET_ENUMS.dialog.cancelVisit,
        GET_ENUMS.dialog.replanVisit,
        GET_ENUMS.dialog.customerCredentials,
      ].map((key) => {
        return (
          <Dialog key={key} open={onPageDialogs[key]?.visible} onOpenChange={(open) => setOnPageDialog(key, open)}>
            <DialogViewsSwitcher name={key} />
          </Dialog>
        )
      })}
      <FloatingPanel anchors={FLOATING_PANEL_ANCHORS.FIVE_STEPS}>
        <div className='mb-4 px-4'>
          <Button block onClick={onNavigationHandler}>
            {t('buttons:create_report')}
          </Button>
        </div>
        {items.map((item) => {
          const isCancel = item.type === 'cancel'
          return (
            <ListItem key={item.key} disabled={item.disabled} onClick={() => onClickHandler(item)} size='panel'>
              <div className='flex items-center gap-4'>
                <Icon name={item.icon} color={isCancel ? 'error' : 'blue-gray-7'} />
                <Typography color={isCancel ? 'error' : 'default'}>{item.title}</Typography>
              </div>
            </ListItem>
          )
        })}
      </FloatingPanel>
    </>
  )
}
