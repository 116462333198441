import type { TFunction } from 'i18next'

import { CellSwipe } from 'src/components/common'

import type { ParcelType, SwipeActionType } from 'src/models'

import { getActionsWithoutHidden, getRejectStatus } from 'src/utils'

type GetParcelsArticlesActionsProps = {
  onSelectCell: (type: string) => () => void
  t: TFunction
  parcel: ParcelType
}

export const getParcelsActions = ({ onSelectCell, t, parcel }: GetParcelsArticlesActionsProps): SwipeActionType[] => {
  const actions = [
    {
      key: 'request',
      text: CellSwipe({
        text: t('buttons:request'),
        icon: 'ScrollUp',
        type: 'warning',
      }),
      isHidden: !parcel.isAvailableShipmentRequest,
      onClick: onSelectCell('request'),
    },
    {
      key: 'show-qr',
      text: CellSwipe({
        text: t('buttons:show_qr'),
        icon: 'QRCode',
        type: 'warning',
      }),
      isHidden: !parcel.isAvailableQrCode,
      onClick: onSelectCell('show-qr'),
    },
    {
      key: 'pack',
      text: CellSwipe({
        text: t('buttons:pack'),
        icon: 'Box',
        type: 'info',
      }),
      isHidden: !parcel.isAvailablePack,
      onClick: onSelectCell('pack'),
    },
    {
      key: 'unpack',
      text: CellSwipe({
        text: t('buttons:unpack'),
        icon: 'Box',
        type: 'danger',
      }),
      isHidden: !parcel.isAvailableUnpack,
      onClick: onSelectCell('unpack'),
    },
    {
      key: 'reject',
      text: CellSwipe({
        text: t('buttons:reject'),
        icon: 'Cross',
        type: 'danger',
      }),
      isHidden: getRejectStatus(parcel),
      onClick: onSelectCell('reject'),
    },
    {
      key: 'restore',
      text: CellSwipe({
        text: t('buttons:restore'),
        icon: 'RotateLeft',
        type: 'green',
      }),
      isHidden: !getRejectStatus(parcel),
      onClick: onSelectCell('restore'),
    },
  ]

  return getActionsWithoutHidden(actions)
}
