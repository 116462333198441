import { Col, Row } from 'antd'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'

import { ParcelsReplaceItem, ParcelsReplaceNewItem, SkeletonList } from 'src/components/common'
import { Button, DialogContent, DialogHeader, Empty, InfiniteScroll, Typography } from 'src/components/ui'

import { useParcelsReplace, useParcelsReplaceFetch } from 'src/bus/parcels'

import { GET_ENUMS, NEW_PARCEL_ID, rowGutters } from 'src/utils'

const parcelsReplaceHeader = [
  {
    title: '',
    span: 3,
  },
  {
    title: 'parcels:replace_list.number',
    span: 4,
  },
  {
    title: 'parcels:replace_list.status_and_type',
    span: 9,
  },
  {
    title: 'parcels:replace_list.weight',
    span: 4,
  },
  {
    title: 'parcels:replace_list.qty',
    span: 4,
  },
]

type DialogReplaceArticleProps = {
  id?: number
}

export const DialogReplaceArticle = ({ id }: DialogReplaceArticleProps) => {
  const { t } = useTranslation()
  const { parcelsReplace, loadingParcelsReplaceFetch } = useParcelsReplaceFetch(id)
  const { onReplaceParcels, loadingParcelsReplace } = useParcelsReplace()

  const [selectId, setSelectId] = useState<number>()

  const renderItems = () => {
    return parcelsReplace.items.map((item) => {
      return <ParcelsReplaceItem key={item.id} item={item} isSelected={selectId === item.id} onSelectId={setSelectId} />
    })
  }

  return (
    <DialogContent name={GET_ENUMS.dialog.replaceArticle}>
      <DialogHeader>
        <Typography variant='title' color='gray-10'>
          {t('parcels:modal_replace.title')}
        </Typography>
      </DialogHeader>
      <Typography variant='secondary' color='gray-7' className='text-center'>
        {t('parcels:modal_replace.description')}
      </Typography>
      <div className='flex h-full max-h-[50vh] flex-col overflow-auto'>
        <div className='bg-gray-1 px-2 py-3'>
          <Row gutter={rowGutters}>
            {parcelsReplaceHeader.map((item) => {
              return (
                <Col key={item.title} span={item.span}>
                  <Typography variant='caption' className='font-semibold'>
                    <span dangerouslySetInnerHTML={{ __html: item.title && t(item.title) }} />
                  </Typography>
                </Col>
              )
            })}
          </Row>
        </div>
        <InfiniteScroll
          hasMore={false}
          loadingFetch={loadingParcelsReplaceFetch}
          onFetchMore={() => undefined}
          dataLength={parcelsReplace.items.length}
          loader={<SkeletonList type='parcels' lineCount={2} />}
          scrollableTarget='replace-parcels'
          renderItems={renderItems}
          empty={<Empty title='common:empty.no_activity' description='common:empty.parcels_replace_list' />}
        />
        <div className='mx-2'>
          <ParcelsReplaceNewItem
            item={{ id: NEW_PARCEL_ID, title: t('parcels:modal_replace.new_parcel') }}
            isSelected={selectId === NEW_PARCEL_ID}
            onSelectId={setSelectId}
          />
        </div>
      </div>
      <Button
        disabled={!selectId}
        loading={loadingParcelsReplace}
        onClick={() => onReplaceParcels(id, selectId === NEW_PARCEL_ID ? undefined : selectId)}
      >
        {t('buttons:change_parcel')}
      </Button>
    </DialogContent>
  )
}
