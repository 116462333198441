import { Row, Col } from 'antd'

import { book } from 'src/init/book'

import { Link, WrappedNodeDefault, CornerEuro } from 'src/components/common'
import { Icon, ListItem, Typography } from 'src/components/ui'

import { AutocompleteOrderType } from 'src/models'

import { orNull, rowGutters, ternary } from 'src/utils'

type OrderAutocompleteItemProps = {
  item: AutocompleteOrderType
}

export const OrderAutocompleteItem = ({ item }: OrderAutocompleteItemProps) => {
  const pathToDetails = {
    pathname: book.claims,
    query: {
      orderProductId: item.orderProductId,
      orderId: item.orderId,
      amount: item.price,
    },
  }

  const renderContent = (value?: string | number | null, span?: number) => {
    return (
      <Col span={span}>
        <Typography variant='secondary'>{WrappedNodeDefault(value)}</Typography>
      </Col>
    )
  }

  const content = (
    <ListItem size='sm' variant='order'>
      {orNull(!item.isOrderPaid, <CornerEuro />)}
      <Row align='middle' gutter={rowGutters}>
        <Col span={7}>
          <div className='flex items-center'>
            <div className='mr-1.5 flex flex-col items-center justify-between'>
              {item.causes.map((cause) => {
                return <Icon key={cause?.id} name={cause?.value} width={20} height={20} />
              })}
              {item.causesNonReturn.map((cause) => {
                return <Icon key={cause?.id} name={cause?.value} width={20} height={20} />
              })}
            </div>
            <Typography variant='secondary'>{item.article}</Typography>
          </div>
        </Col>
        {renderContent(item.orderId, 5)}
        {renderContent(item.articleType, 6)}
        {renderContent(item.brand, 6)}
      </Row>
    </ListItem>
  )

  return <>{ternary(item.isAvailableToReturn, <Link href={pathToDetails}>{content}</Link>, content)}</>
}
