const { isProduction, BUILD_CI } = require('./scripts/build.config')

const I18N_LOCALES_PATH = './public/locales'
const I18N_DEFAULT_LOCALE = 'fr'
const I18N_LOCALES = ['en', 'fr', 'de', 'nl']

module.exports = {
  i18n: {
    defaultLocale: I18N_DEFAULT_LOCALE,
    locales: I18N_LOCALES,
    localeDetection: false,
  },
  localePath: I18N_LOCALES_PATH,
  reloadOnPrerender: !isProduction,
  debug: !isProduction && typeof window !== 'undefined',
  missingInterpolationHandler: true,
  missingKeyHandler: true,
  preload: true,
}
