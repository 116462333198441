import { Col, Row } from 'antd'
import { useTranslation } from 'next-i18next'
import { ReactNode } from 'react'

import { CellText, CellCopy } from 'src/components/common'
import { Separator, Typography } from 'src/components/ui'

import { useCustomersDetailsFetch } from 'src/bus/customers'

import { isNilOrEmpty, rowGutters } from 'src/utils'

export const ReturnCustomerInfo = () => {
  const { t } = useTranslation()
  const { customersDetails, loadingCustomersDetailsFetch } = useCustomersDetailsFetch()

  const renderRow = (title: string, value: ReactNode) => {
    return (
      <div className='bg-gray-1 px-2 py-3'>
        <Row align='middle' gutter={rowGutters}>
          <Col span={9}>
            <Typography variant='secondary' color='gray-7'>
              {t(`customers:${title}`)}
            </Typography>
          </Col>
          <Col span={15}>{value}</Col>
        </Row>
      </div>
    )
  }

  const copyValue = `${t('customers:customer_name')}: ${customersDetails?.title}\n${t(
    'customers:customer_id',
  )}: ${customersDetails?.awsId}`

  return (
    <>
      {renderRow(
        'customer_name',
        <CellText value={customersDetails?.title} loadingFetch={loadingCustomersDetailsFetch} />,
      )}
      <Separator />
      {renderRow(
        'customer_id',
        <CellCopy
          value={customersDetails?.awsId}
          disabled={isNilOrEmpty(customersDetails) || loadingCustomersDetailsFetch}
          copyValue={copyValue}
          loadingFetch={loadingCustomersDetailsFetch}
          copiedText='customer_id_copied'
        />,
      )}
    </>
  )
}
