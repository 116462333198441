import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import { useCallback, useState } from 'react'

import { HttpErrorType } from 'src/models'

import { useClaimsDeleteMutation, claimsKeys } from 'src/bus/claims'
import { useCustomersDetailsFetch } from 'src/bus/customers'
import { useReturnsInProgress, returnsKeys } from 'src/bus/returns'

import { useToast } from 'src/hooks'

import { useDialog } from 'src/contexts'

import { GET_ENUMS } from 'src/utils'

type UseClaimsDeleteType = {
  loadingClaimsDelete: boolean
  onDeleteClaim: (id: number) => Promise<void>
}

export const useClaimsDelete = (): UseClaimsDeleteType => {
  const queryClient = useQueryClient()
  const showToast = useToast()
  const { t } = useTranslation()
  const { setOnPageDialog } = useDialog()
  const { returnId } = useReturnsInProgress()
  const { onDeleteClaimMutation } = useClaimsDeleteMutation()
  const { customerId } = useCustomersDetailsFetch()

  const [isLoading, setIsLoading] = useState(false)

  const onDeleteClaim = useCallback(
    async (id: number) => {
      try {
        setIsLoading(true)

        await onDeleteClaimMutation.mutateAsync({
          params: { id, ...(customerId && { customerId }) },
        })

        setOnPageDialog(GET_ENUMS.alertDialog.confirm, false)
        showToast.success({ title: t('notifications:claim_delete') })
        await queryClient.invalidateQueries({ queryKey: claimsKeys.all })
        await queryClient.refetchQueries({
          queryKey: returnsKeys.total({ id: returnId, customerId }),
        })
      } catch (error) {
        const errs = (error as HttpErrorType).errors
        if (errs) {
          const [[title, body]] = Object.entries(errs)
          showToast.error({ title, body: body.desc })
        }
      } finally {
        setIsLoading(false)
      }
    },
    [customerId, onDeleteClaimMutation, returnId, setOnPageDialog, showToast, t],
  )

  return {
    onDeleteClaim,
    loadingClaimsDelete: isLoading,
  }
}
