export const Fonts = () => {
  const baseFontFamily = 'Inter'
  const secondFontFamily = 'Montserrat'
  const baseFontPath = '/assets/fonts/inter'
  const secondFontPath = '/assets/fonts/montserrat'

  const baseFonts = ['inter_regular.woff2', 'inter_bold.woff2', 'inter_semibold.woff2', 'inter_light.woff2']
  const secondFonts = ['montserrat_bold.woff2', 'montserrat_semibold.woff2']

  return (
    <>
      {baseFonts.map((font) => {
        return (
          <link
            key={font}
            rel='preload'
            href={`${baseFontPath}/${font}`}
            as='font'
            crossOrigin='anonymous'
            type='font/woff2'
          />
        )
      })}
      {secondFonts.map((font) => {
        return (
          <link
            key={font}
            rel='preload'
            href={`${secondFontPath}/${font}`}
            as='font'
            crossOrigin='anonymous'
            type='font/woff2'
          />
        )
      })}
      <style
        dangerouslySetInnerHTML={{
          __html: `
            @font-face {
              font-family: ${baseFontFamily};
              font-style: normal;
              font-weight: 400;
              font-display: swap;
              src: local("Inter"), local("Inter-Regular"), url("${baseFontPath}/inter_regular.woff2") format("woff2"),
                url("${baseFontPath}/inter_regular.woff") format("woff");
            }
             @font-face {
              font-family: ${baseFontFamily};
              font-style: normal;
              font-weight: 900;
              font-display: swap;
              src: local("Inter"), local("Inter-Black"), url("${baseFontPath}/inter_black.woff2") format("woff2"),
                url("${baseFontPath}/inter_black.woff") format("woff");
            }
            @font-face {
              font-family: ${baseFontFamily};
              font-style: normal;
              font-weight: 700;
              font-display: swap;
              src: local("Inter"), local("Inter-Bold"), url("${baseFontPath}/inter_bold.woff2") format("woff2"),
                url("${baseFontPath}/inter_bold.woff") format("woff");
            }
            @font-face {
              font-family: ${baseFontFamily};
              font-style: normal;
              font-weight: 600;
              font-display: swap;
              src: local("Inter"), local("Inter-Semibold"), url("${baseFontPath}/inter_semibold.woff2") format("woff2"),
                url("${baseFontPath}/inter_semibold.woff") format("woff");
            }
            @font-face {
              font-family: ${baseFontFamily};
              font-style: normal;
              font-weight: 300;
              font-display: swap;
              src: local("Inter"), local("Inter-Light"), url("${baseFontPath}/inter_light.woff2") format("woff2"),
                url("${baseFontPath}/inter_light.woff") format("woff");
            }
            @font-face {
              font-family: ${secondFontFamily};
              font-style: normal;
              font-weight: 700;
              font-display: swap;
              src: local("Montserrat"), local("Montserrat-Bold"), url("${secondFontPath}/montserrat_bold.woff2") format("woff2"),
                url("${secondFontPath}/montserrat_bold.woff") format("woff");
            }
            @font-face {
              font-family: ${secondFontFamily};
              font-style: normal;
              font-weight: 600;
              font-display: swap;
              src: local("Montserrat"), local("Montserrat-Semibold"), url("${secondFontPath}/montserrat_semibold.woff2") format("woff2"),
                url("${secondFontPath}/montserrat_semibold.woff") format("woff");
            }
          `,
        }}
      />
    </>
  )
}
