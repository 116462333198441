import { StaticDataItemsType } from 'src/models'

import { useHistoryStaticDataFetch } from 'src/bus/history'

type UseHistoryFiltersType = {
  loadingHistoryFiltersFetch: boolean
  historyFilters: StaticDataItemsType
}

export const useHistoryFilters = (): UseHistoryFiltersType => {
  const { historyType, customerHistoryType, loadingHistoryStaticDataFetch } = useHistoryStaticDataFetch()

  return {
    historyFilters: { historyType, customerHistoryType },
    loadingHistoryFiltersFetch: loadingHistoryStaticDataFetch,
  }
}
