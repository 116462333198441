export const countryIcons = [
  {
    value: 'en',
    icon: 'CountryEn',
  },
  {
    value: 'fr',
    icon: 'CountryFr',
  },
  {
    value: 'de',
    icon: 'CountryDe',
  },
  {
    value: 'gb',
    icon: 'CountryEn',
  },
  {
    value: 'nl',
    icon: 'CountryNl',
  },
]
