import { useTranslation } from 'next-i18next'

import { ClaimsAutocomplete, OrdersAutocomplete } from 'src/components/common'
import { Typography } from 'src/components/ui'

import { AutocompletePropsType } from 'src/models'

import { orNull } from 'src/utils'

type FilterAutocompleteItemProps = {
  data?: AutocompletePropsType['data']
  loadingClaimsSearchFetch?: boolean
  loadingClaimsFetch?: boolean
}

export const FilterAutocompleteItem = ({
  data = null,
  loadingClaimsSearchFetch = false,
  loadingClaimsFetch = false,
}: FilterAutocompleteItemProps) => {
  const { t } = useTranslation()

  return orNull(
    data,
    <>
      <div className='p-2'>
        <Typography variant='secondary' className='font-semibold'>
          {t('returns:returns_details.matches_returns')}:
        </Typography>
      </div>
      <ClaimsAutocomplete claims={data?.claims} loadingFetch={loadingClaimsFetch} />
      <div className='p-2'>
        <Typography variant='secondary' className='font-semibold'>
          {t('returns:returns_details.matches_orders')}:
        </Typography>
      </div>
      <OrdersAutocomplete orders={data?.products} loadingFetch={loadingClaimsSearchFetch} />
    </>,
  )
}
