import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useState } from 'react'

import { CustomerIssueForm } from 'src/components/common'
import { DialogContent, DialogHeader, Typography } from 'src/components/ui'

import { useOrderOptionsFetch, useOrderProductOptionsFetch } from 'src/bus/orders'
import { useIssueCreate, useOtrsTicketReasonsFetch } from 'src/bus/otrs'
import { useClarificationsFetch, useFieldsFetch } from 'src/bus/otrsOptions'

import { GET_ENUMS } from 'src/utils'

export const DialogCustomerIssue = () => {
  const router = useRouter()

  const visitId = Number(router.query.id)
  const customerId = Number(router.query.customerId)

  const { t } = useTranslation()
  const { onCreateIssue, loadingIssueCreate } = useIssueCreate(visitId)
  const { otrsTicketReasons, loadingOtrsTicketReasonsFetch } = useOtrsTicketReasonsFetch()

  const [selectedReasonId, setSelectedReasonId] = useState<number>()
  const [selectedClarificationId, setSelectedClarificationId] = useState<number>()
  const [selectedOrderId, setSelectedOrderId] = useState<number>()

  const { otrsOptionsFields, loadingOtrsOptionsFieldsFetch } = useFieldsFetch(selectedReasonId, selectedClarificationId)
  const { clarifications, loadingClarificationsFetch } = useClarificationsFetch(selectedReasonId)
  const { orderOptions, loadingOrderOptionsFetch } = useOrderOptionsFetch(customerId)
  const { orderProductOptions, loadingOrderProductOptionsFetch } = useOrderProductOptionsFetch({
    orderId: selectedOrderId,
    customerId,
  })

  return (
    <DialogContent name={GET_ENUMS.dialog.customerIssue} className='px-2'>
      <DialogHeader>
        <Typography variant='title' color='gray-10'>
          {t('customers:issue_dialog.title')}
        </Typography>
        <Typography variant='secondary' color='gray-7'>
          {t('customers:issue_dialog.description')}
        </Typography>
      </DialogHeader>
      <CustomerIssueForm
        reasons={otrsTicketReasons}
        loadingReasons={loadingOtrsTicketReasonsFetch}
        onSelectReasonId={setSelectedReasonId}
        clarifications={clarifications}
        loadingClarifications={loadingClarificationsFetch}
        fields={otrsOptionsFields}
        loadingFields={loadingOtrsOptionsFieldsFetch}
        onSelectClarificationId={setSelectedClarificationId}
        orderOptions={orderOptions}
        loadingOrderOptions={loadingOrderOptionsFetch}
        orderProductOptions={orderProductOptions}
        loadingOrderProductOptions={loadingOrderProductOptionsFetch}
        onSelectOrderId={setSelectedOrderId}
        initialValues={null}
        loadingCreate={loadingIssueCreate}
        onSubmit={onCreateIssue}
      />
    </DialogContent>
  )
}
