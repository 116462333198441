import { z } from 'zod'

import { hasID } from './Base'
import { BaseDataInfiniteType, metaSchema, selectSchema } from './index'

// export type VisitHistoryType = {
//   id: number;
//   companyName: string;
//   companyTitle: string;
//   customerId: number;
//   status: StaticDataItemType;
//   date: string;
//   sector: string;
//   comment: string;
//   customerStatus: StaticDataItemType;
// };

export const visitHistoryBaseSchema = z.object({
  companyName: z.string(),
  companyTitle: z.string(),
  customerId: z.number(),
  status: selectSchema,
  date: z.string(),
  sector: z.string(),
  comment: z.string(),
  customerStatus: selectSchema,
})

export const visitHistorySchema = visitHistoryBaseSchema.merge(hasID)

export type TVisitHistoryStatus = 'done' | 'canceled' | 'plan'

export const visitHistoryListSchema = z.object({
  items: visitHistorySchema.array(),
  meta: metaSchema,
})

export type VisitHistoryType = z.infer<typeof visitHistorySchema>
export type VisitsHistoryType = z.infer<typeof visitHistoryListSchema>
export type VisitsHistoryInfiniteType = BaseDataInfiniteType<VisitHistoryType>
