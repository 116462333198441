import { z } from 'zod'

import { HttpRequestParams } from 'src/models'

export const couponSchema = z.object({
  current: z.object({
    type: z.string().nullable(),
    code: z.string().nullable(),
    daysLeft: z.number().nullable(),
  }),
  next: z.object({
    type: z.string(),
    availableDate: z.number(),
  }),
  availableLoyaltyQuantity: z.number(),
  available: z.boolean(),
  campaignAvailable: z.boolean(),
  campaign: z.object({
    name: z.string(),
    code: z.string(),
    period: z.object({
      start: z.number(),
      end: z.number(),
    }),
  }),
})

export type CouponType = z.infer<typeof couponSchema>

export type CouponsParams = HttpRequestParams<{
  visitId: number
  type?: string
}>
