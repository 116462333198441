import { create } from 'zustand'

import { ArticlesType, ParcelsCheckComplianceState } from 'src/models'

export const parcelsState = {
  parcelsArticles: {
    pages: [
      {
        items: [],
        meta: null,
      },
    ],
  },
}

export const parcelsKeys = {
  all: ['parcels'],
  lists: () => [...parcelsKeys.all, 'list'],
  list: (params: any) => [...parcelsKeys.lists(), params],
  articles: () => [...parcelsKeys.all, 'articles'],
  articlesList: (params: any) => [...parcelsKeys.articles(), params],
  replaces: () => [...parcelsKeys.all, 'replaces'],
  replace: (id?: number) => [...parcelsKeys.replaces(), 'replace', id],
  details: () => [...parcelsKeys.all, 'detail'],
  detail: (id: number) => [...parcelsKeys.details(), id],
  articlesDetails: () => [...parcelsKeys.all, 'articles-detail'],
  articlesDetail: (id: number) => [...parcelsKeys.articlesDetails(), id],
  totals: () => [...parcelsKeys.all, 'total'],
  total: (params: any) => [...parcelsKeys.totals(), params],
  articlesTotals: () => [...parcelsKeys.all, 'articles-total'],
  articlesTotal: (params: any) => [...parcelsKeys.articlesTotals(), params],
  checkGenerate: (type: ArticlesType) => [...parcelsKeys.all, 'check-generate', type],
  checkMoveAllTo: (type: ArticlesType) => [...parcelsKeys.all, 'check-move-all-to', type],
  qrCode: (id?: number) => [...parcelsKeys.all, 'qr-code', id],
}

export const useCheckCompliance = create<ParcelsCheckComplianceState>((set) => ({
  checkCompliance: false,
  onCheckCompliance: (checkCompliance: boolean) => set(() => ({ checkCompliance })),
}))
