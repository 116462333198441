import { useRouter } from 'next/router'
import QS from 'qs'
import { useCallback } from 'react'

import {
  FilterStaticFilterQueryParamsType,
  ManagePointsItemsType,
  ManagePointsListType,
  TRefetchFilterStaticCb,
} from 'src/models'

import { manageToursKeys, manageToursService } from 'src/bus/manageTours'

import { useQueryAsync } from 'src/hooks'

import { getParamsFromQueryStr } from 'src/utils'

type ManagePointsFetchType = {
  loadingManagePointsFetch: boolean
  managePoints: ManagePointsListType
  onRefetchManagePoints: TRefetchFilterStaticCb
  managePointsFiltersStaticInitialValues: FilterStaticFilterQueryParamsType
}

export const useManagePointsFetch = (): ManagePointsFetchType => {
  const router = useRouter()

  const initialQuery = getParamsFromQueryStr(router.query)

  const isEnabled =
    'southWestLat' in initialQuery &&
    'southWestLng' in initialQuery &&
    'northEastLat' in initialQuery &&
    'northEastLng' in initialQuery

  const fetchManagePointsProps = useQueryAsync<ManagePointsItemsType>({
    key: manageToursKeys.points(initialQuery),
    name: 'fetchClaimsDetails',
    fetcher: manageToursService.fetchManagePoints,
    fetcherPayload: initialQuery,
    options: { enabled: isEnabled },
  })

  const onRefetchManagePoints = useCallback<TRefetchFilterStaticCb>(
    async (values) => {
      const newParams = { ...initialQuery, ...values?.filterData }
      await router.replace({ pathname: router.pathname, query: QS.stringify(newParams) })
    },
    [router, initialQuery],
  )

  return {
    loadingManagePointsFetch: fetchManagePointsProps.isLoading,
    managePoints: fetchManagePointsProps.data?.items || [],
    managePointsFiltersStaticInitialValues: initialQuery,
    onRefetchManagePoints,
  }
}
