import { useMemo } from 'react'

type useInfiniteScrollType = {
  nextPage: number
  hasMore: boolean
}

type InfiniteScrollProps = {
  currentPage?: number
  lastPage?: number
}

export const useInfiniteScroll = (props: InfiniteScrollProps): useInfiniteScrollType => {
  const { currentPage = 0, lastPage = Infinity } = props

  const hasMore = useMemo(() => {
    return currentPage !== 0 && currentPage < lastPage
  }, [currentPage, lastPage])

  return {
    nextPage: currentPage + 1,
    hasMore,
  }
}
