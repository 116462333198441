import { useInfiniteQuery, keepPreviousData } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import QS from 'qs'
import { useCallback } from 'react'

import {
  FilterStaticFilterQueryParamsType,
  HistoryAllInfiniteType,
  HistoryAllListType,
  TRefetchFilterStaticCb,
} from 'src/models'

import { historyKeys, historyState, historyService } from 'src/bus/history'

import { getNextPageParam, getParamsFromQueryStr, queryFetcher } from 'src/utils'

type HistoryAllFetchType = {
  loadingHistoryAllFetch: boolean
  onFetchMoreHistoryAll: () => void
  historyAllHasMore: boolean
  historyAll: HistoryAllInfiniteType
  onRefetchHistoryAllList: TRefetchFilterStaticCb
  historyAllFiltersStaticInitialValues: FilterStaticFilterQueryParamsType
}

export const useHistoryAllFetch = (): HistoryAllFetchType => {
  const router = useRouter()

  const initialQuery = getParamsFromQueryStr(router.query)

  const customerId = Number(router.query.id)
  const params = { ...initialQuery, customerId }

  const fetchHistoryAllProps = useInfiniteQuery<HistoryAllListType>({
    queryKey: historyKeys.historyAll(initialQuery),
    queryFn: ({ pageParam }) => {
      return queryFetcher({
        name: 'fetchHistoryAll',
        fetcher: historyService.fetchHistoryAll,
        fetcherPayload: { params: { ...params, page: pageParam, size: 20 } },
      })
    },
    initialPageParam: 1,
    getNextPageParam,
    enabled: !!customerId,
    placeholderData: keepPreviousData,
  })

  const onRefetchHistoryAllList = useCallback<TRefetchFilterStaticCb>(
    async (values) => {
      const newParams = { ...initialQuery, ...values?.filterData }
      await router.replace({ pathname: router.pathname, query: QS.stringify(newParams) })
    },
    [router, initialQuery],
  )

  return {
    loadingHistoryAllFetch: fetchHistoryAllProps.isLoading,
    historyAllFiltersStaticInitialValues: initialQuery,
    onFetchMoreHistoryAll: fetchHistoryAllProps.fetchNextPage,
    historyAllHasMore: !!fetchHistoryAllProps.hasNextPage,
    historyAll: fetchHistoryAllProps.data || historyState.all,
    onRefetchHistoryAllList,
  }
}
