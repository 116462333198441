import { Collapse, Swiper } from 'antd-mobile'
import { useTranslation } from 'next-i18next'
import NextImage from 'next/image'

import { Skeleton, Accordion, Empty, Icon } from 'src/components/ui'

import { useTourNewsFetch } from 'src/bus/tour'

import { getHasData, ternary } from 'src/utils'

import st from './Styles.module.css'

export const LatestNews = () => {
  const { t } = useTranslation()
  const { news, loadingNewsFetch } = useTourNewsFetch()

  const swiperItems = news.items.map((n, index) => (
    <Swiper.Item key={n.id || index}>
      <div className={st['latest-news-image']}>
        <NextImage src={n.imageUrl} alt='news' layout='fill' unoptimized priority />
      </div>
    </Swiper.Item>
  ))

  return (
    <div className={st['latest-news']}>
      <Accordion className={st['latest-news-accordion']} activeKey='latestNews' accordion>
        <Collapse.Panel
          key='latestNews'
          title={t('news-and-plans:latest_news')}
          arrow={<Icon name='ArrowTriangleTop' />}
        >
          {ternary(
            loadingNewsFetch,
            <Skeleton className='h-[168px]' />,
            <>
              {ternary(
                getHasData(news.items),
                <Swiper className={st['latest-news-swiper']}>{swiperItems}</Swiper>,
                <Empty title='news-and-plans:no_current_news' hasImage />,
              )}
            </>,
          )}
        </Collapse.Panel>
      </Accordion>
    </div>
  )
}
