import { BaseDataListType, StaticDataItemType } from 'src/models'

import { ordersKeys, ordersService } from 'src/bus/orders'

import { useQueryAsync } from 'src/hooks'

type UseOrderProductOptionsFetchType = {
  loadingOrderProductOptionsFetch: boolean
  orderProductOptions: StaticDataItemType[]
}

export const useOrderProductOptionsFetch = ({
  orderId,
  customerId,
}: {
  orderId?: number
  customerId?: number
}): UseOrderProductOptionsFetchType => {
  const fetchOrderProductOptionsProps = useQueryAsync<BaseDataListType>({
    key: ordersKeys.orderProductOptions({ orderId, customerId }),
    name: 'fetchOrderProductOptions',
    fetcher: ordersService.fetchOrderProductOptions,
    fetcherPayload: { orderId, customerId },
    options: { enabled: !!customerId && !!orderId },
  })

  return {
    loadingOrderProductOptionsFetch: fetchOrderProductOptionsProps.isLoading,
    orderProductOptions: fetchOrderProductOptionsProps.data?.items || [],
  }
}
