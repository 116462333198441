import { StaticDataItemsType } from 'src/models'

import { useParcelsStaticDataFetch } from 'src/bus/parcels'

type ParcelsArticlesFiltersType = {
  loadingParcelsArticlesFiltersFetch: boolean
  parcelsArticlesFilters: StaticDataItemsType
}

export const useParcelsArticlesFilters = (): ParcelsArticlesFiltersType => {
  const { parcelArticleStatus, loadingParcelsStaticDataFetch } = useParcelsStaticDataFetch()

  const filters = { parcelArticleStatus }

  return {
    parcelsArticlesFilters: filters,
    loadingParcelsArticlesFiltersFetch: loadingParcelsStaticDataFetch,
  }
}
