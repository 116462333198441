import { BaseMutationPayloadType, FetchDataType, HttpStatusCode } from 'src/models'

import {
  CLIENT_API_INITIAL_ERR,
  getFormatErrorValidation,
  HttpError,
  isApiError,
  verifyBrowser,
  verifyEnvironment,
  logger,
} from 'src/utils'

type QueryFetcherPropsType<TQueryFnData, TFetcherPayload> = {
  name: string
  fetcher: FetchDataType<TQueryFnData>
  fetcherPayload?: TFetcherPayload
  fetcherType?: 'query' | 'mutation'
  producer?: 'Client' | 'Server'
}

export const queryFetcher = async <TQueryFnData = unknown, TFetcherPayload = BaseMutationPayloadType>({
  name,
  fetcher,
  fetcherType = 'query',
  fetcherPayload,
  producer = 'Client',
}: QueryFetcherPropsType<TQueryFnData, TFetcherPayload>): Promise<TQueryFnData> => {
  const timeStart = performance.now()

  try {
    const { isDevelopment } = verifyEnvironment()

    if (isDevelopment) {
      if (verifyBrowser()) {
        logger({
          isStarted: true,
          operation: fetcherType,
          name,
          producer,
          ...(fetcherPayload && fetcherPayload),
        })
        //     logger.browserReactQueryInformationLogger.info({
        //       isStarted: true,
        //       operation: fetcherType,
        //       document: name,
        //       producer,
        //       ...(fetcherPayload && fetcherPayload),
        //     });
        //   } else {
        //     logger.serverDevelopmentLogger.info(
        //       `ReactQuery {"document": "${name}", operation: "${fetcherType}", producer: "SSR Server" } was started`,
        //     );
      }
    }

    const response = await fetcher(fetcherPayload)
    const { data, status } = response
    const result = data.data

    const statusCodes = [HttpStatusCode.success, HttpStatusCode.created, HttpStatusCode.noContent]
    if (!statusCodes.includes(status)) {
      if (isDevelopment) {
        if (verifyBrowser()) {
          logger({
            isFinished: true,
            name,
            operation: fetcherType,
            producer,
            data,
            status,
            time: (performance.now() - timeStart).toFixed(),
            isError: true,
          })
          //     logger.browserReactQueryInformationLogger.error({
          //       isFinished: true,
          //       operation: fetcherType,
          //       document: name,
          //       producer: "Client",
          //       status,
          //       data,
          //     });
          //   } else {
          //     logger.serverDevelopmentLogger.error(
          //       `ReactQuery {"document": "${name}", operation: "${fetcherType}", producer: "SSR Server" } was finished with status ${status}`,
          //     );
        }
      }
      if (!isApiError(data)) {
        throw { status: CLIENT_API_INITIAL_ERR.status }
      }

      const errLocalized = getFormatErrorValidation(data.error)
      console.log('errLocalized', errLocalized)
      throw new HttpError(status, errLocalized)
    }

    if (isDevelopment) {
      if (verifyBrowser()) {
        logger({
          isFinished: true,
          name,
          operation: fetcherType,
          producer,
          status,
          data,
          time: (performance.now() - timeStart).toFixed(),
        })
        //     logger.browserReactQueryInformationLogger.info({
        //       isFinished: true,
        //       operation: fetcherType,
        //       document: name,
        //       producer: "Client",
        //       status,
        //       data,
        //     });
        //   } else {
        //     logger.serverDevelopmentLogger.info(
        //       `ReactQuery {"document": "${name}", operation: "${fetcherType}", producer: "SSR Server" } was finished with status ${status}`,
        //     );
      }
    }
    return Promise.resolve(result)
  } catch (err) {
    if (err instanceof HttpError) {
      const errShape = {
        type: err.name,
        message: err.message,
        status: err.status,
        errors: err.errors,
      }
      console.log('errShape', errShape)
      return Promise.reject(errShape)
    }
    return Promise.reject(err)
  }
}
