import { SelectDays, SelectQuickFilters } from 'src/components/common'
import { Skeleton } from 'src/components/ui'

import {
  FilterStaticDataType,
  FilterStaticRequestValuesType,
  StaticDataItemType,
  TRefetchFilterStaticCb,
} from 'src/models'

import { useManagePointsFilters, useManageToursDaysFetch } from 'src/bus/manageTours'

import { useUrlQuery } from 'src/hooks'

type ManageToursQuickFiltersProps = {
  filterStaticItems: FilterStaticDataType
  onRefetch: TRefetchFilterStaticCb
  initialValues?: FilterStaticRequestValuesType | null
}

export const ManageToursQuickFilters = ({
  initialValues = null,
  filterStaticItems,
  onRefetch,
}: ManageToursQuickFiltersProps) => {
  const { onUpdateQuery } = useUrlQuery()
  const { managePointsFilters, loadingManagePointsFiltersFetch } = useManagePointsFilters()
  const { manageToursDays, loadingManageToursDaysFetch } = useManageToursDaysFetch()

  const onChangeDays = async (dates: { startDate: Date | null; endDate: Date | null }) => {
    const { filterData } = onUpdateQuery({
      filtersValues: dates,
      filterItems: filterStaticItems,
    })

    await onRefetch(filterData)
  }

  const onChangeFilters = async (plannedVisitsExist: StaticDataItemType[]) => {
    const { filterData } = onUpdateQuery({
      filtersValues: { plannedVisitsExist },
      filterItems: filterStaticItems,
    })

    await onRefetch(filterData)
  }

  return (
    <>
      {loadingManagePointsFiltersFetch || loadingManageToursDaysFetch ? (
        <Skeleton className='h-8 w-full' />
      ) : (
        <div className='flex gap-2 overflow-x-auto pb-2'>
          <SelectQuickFilters
            filters={managePointsFilters}
            initialValues={initialValues}
            filterItemsSet={filterStaticItems}
            onChange={onChangeFilters}
            loading={loadingManagePointsFiltersFetch}
          />
          <SelectDays
            days={manageToursDays}
            initialValues={initialValues}
            filterItemsSet={filterStaticItems}
            onChange={onChangeDays}
            loading={loadingManageToursDaysFetch}
          />
        </div>
      )}
    </>
  )
}
