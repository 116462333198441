import { useTranslation } from 'next-i18next'

import { Drawer, FiltersStaticForm } from 'src/components/common'
import { Button, Icon, SheetContent, Typography } from 'src/components/ui'

import {
  FilterStaticType,
  FormSubmitFn,
  FilterStaticRequestValuesType,
  FiltersButtonType,
  FilterStaticDataType,
} from 'src/models'

import { useDrawer } from 'src/contexts'

import {
  GET_ENUMS,
  getFilterActiveOptionsCount,
  getFilterOptionsIsNotEmpty,
  getFilterTitleIsVisible,
  notEqZero,
  orNull,
} from 'src/utils'

type FiltersDrawerProps = {
  onSubmit: FormSubmitFn<FilterStaticType>
  loadingFetch?: boolean
  loadingStaticDataFetch?: boolean
  loadingSubmit?: boolean
  initialValues?: FilterStaticRequestValuesType | null
  buttons: FiltersButtonType[]
  filterItemsSet?: FilterStaticDataType
}

export const FiltersDrawer = ({ initialValues, filterItemsSet = {}, ...rest }: FiltersDrawerProps) => {
  const { t } = useTranslation()
  const { onPageDrawers, setOnPageDrawer } = useDrawer()

  const filterActiveCount = getFilterActiveOptionsCount(initialValues)
  const filterIsActive = getFilterOptionsIsNotEmpty(filterItemsSet) && notEqZero(filterActiveCount)
  const filterTitleIsVisible = getFilterTitleIsVisible(filterItemsSet)

  const onOpenFilters = () => setOnPageDrawer(GET_ENUMS.drawer.filters, true)

  return (
    <>
      <Drawer
        open={onPageDrawers[GET_ENUMS.drawer.filters]}
        onOpenChange={(open) => setOnPageDrawer(GET_ENUMS.drawer.filters, open)}
      >
        <SheetContent>
          <div className='flex h-full flex-col'>
            {orNull(
              filterTitleIsVisible,
              <div className='p-2'>
                <Typography className='font-semibold'>{t('filters')}</Typography>
              </div>,
            )}
            <FiltersStaticForm initialValues={initialValues} filterItemsSet={filterItemsSet} {...rest} />
          </div>
        </SheetContent>
      </Drawer>
      <Button variant='ghost' size='icon-md' onClick={onOpenFilters} className='relative'>
        {orNull(
          filterIsActive,
          <div className='absolute right-0 top-0 block flex h-3 w-3 items-center justify-center rounded-full bg-orange-8'>
            <Typography variant='caption' color='white' className='text-[11px]'>
              {filterActiveCount}
            </Typography>
          </div>,
        )}
        <Icon name='Filter' color='blue-gray-85' />
      </Button>
    </>
  )
}
