import { Fragment, PropsWithChildren } from 'react'

import { DynamicImport, AlertDialogCloseCustomer, AlertDialogOpenCustomer } from 'src/components/common'
import { TButtonVariants } from 'src/components/ui/Button'

import { GET_ENUMS } from 'src/utils'

type AlertDialogViewsSwitcherProps = PropsWithChildren<{
  name?: string
  params?: {
    id?: number
    title?: string
    description?: string
    onConfirm?: () => void
    loadingFetch?: boolean
    buttonVariant?: TButtonVariants
  }
}>

export const AlertDialogViewsSwitcher = ({ name = '', params }: AlertDialogViewsSwitcherProps) => {
  const dialogs: Record<string, any> = {
    [GET_ENUMS.alertDialog.confirm]: () => {
      const AlertDialogConfirmDynamic = DynamicImport(
        import('../common/AlertDialogViews/AlertDialogConfirm'),
        'AlertDialogConfirm',
      )
      return <AlertDialogConfirmDynamic {...params} />
    },
    [GET_ENUMS.alertDialog.closeCustomer]: () => {
      const AlertDialogCloseCustomerDynamic = DynamicImport(
        import('../common/AlertDialogViews/AlertDialogCloseCustomer'),
        'AlertDialogCloseCustomer',
      )
      return <AlertDialogCloseCustomer />
    },
    [GET_ENUMS.alertDialog.openCustomer]: () => {
      const AlertDialogOpenCustomerDynamic = DynamicImport(
        import('../common/AlertDialogViews/AlertDialogOpenCustomer'),
        'AlertDialogOpenCustomer',
      )
      return <AlertDialogOpenCustomer />
    },
    [GET_ENUMS.alertDialog.leavePage]: () => {
      const AlertDialogLeavePageDynamic = DynamicImport(
        import('../common/AlertDialogViews/AlertDialogLeavePage'),
        'AlertDialogLeavePage',
      )
      return <AlertDialogLeavePageDynamic {...params} />
    },
    [GET_ENUMS.alertDialog.packParcel]: () => {
      const AlertDialogPackParcelDynamic = DynamicImport(
        import('../common/AlertDialogViews/AlertDialogPackParcel'),
        'AlertDialogPackParcel',
      )
      return <AlertDialogPackParcelDynamic />
    },
    [GET_ENUMS.alertDialog.unpackParcel]: () => {
      const AlertDialogUnpackParcelDynamic = DynamicImport(
        import('../common/AlertDialogViews/AlertDialogUnpackParcel'),
        'AlertDialogUnpackParcel',
      )
      return <AlertDialogUnpackParcelDynamic {...params} />
    },
    [GET_ENUMS.alertDialog.shipmentParcel]: () => {
      const AlertDialogShipmentParcelDynamic = DynamicImport(
        import('../common/AlertDialogViews/AlertDialogShipmentParcel'),
        'AlertDialogShipmentParcel',
      )
      return <AlertDialogShipmentParcelDynamic {...params} />
    },
    [GET_ENUMS.alertDialog.sendRequest]: () => {
      const AlertDialogSendRequestDynamic = DynamicImport(
        import('../common/AlertDialogViews/AlertDialogSendRequest'),
        'AlertDialogSendRequest',
      )
      return <AlertDialogSendRequestDynamic />
    },
    [GET_ENUMS.alertDialog.rejectParcel]: () => {
      const AlertDialogRejectParcelDynamic = DynamicImport(
        import('../common/AlertDialogViews/AlertDialogRejectParcel'),
        'DialogRejectParcel',
      )
      return <AlertDialogRejectParcelDynamic {...params} />
    },
    default: () => <Fragment />,
  }

  return dialogs?.[name]?.() || dialogs['default']()
}
