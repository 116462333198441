import { useRouter } from 'next/router'

import { book } from 'src/init/book'

import { HeaderTabs } from 'src/components/common'

import { getLastQueryPath } from 'src/utils'

export const ParcelsHeaderTabs = () => {
  const router = useRouter()

  const parcelsTabs = [
    {
      title: 'parcels:dirty',
      key: book.parcelsDirty,
    },
    {
      title: 'parcels:clear',
      key: book.parcelsClear,
    },
    {
      title: 'parcels:parcels-list',
      key: book.parcelsList,
    },
  ]

  const activePath = getLastQueryPath(router.pathname) as string

  return <HeaderTabs tabs={parcelsTabs} activePath={activePath} />
}
