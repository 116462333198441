import dynamic from 'next/dynamic'
import { ComponentType } from 'react'

import { Loader } from 'src/components/ui'

export const DynamicImport = <P extends Record<string, unknown>>(
  promiseImport: Promise<Record<string, ComponentType<P>>>,
  moduleName: string,
): ComponentType<P> => {
  return dynamic<P>(
    () =>
      promiseImport
        .then((mod) => mod[moduleName])
        .catch((err) => {
          throw err
        }),
    {
      ssr: false,
      loading: () => <Loader type='fixed' />,
    },
  )
}
