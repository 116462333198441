import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'next-i18next'
import { replace } from 'ramda'
import { useForm } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormFields,
  FormItem,
  FormMessage,
  Skeleton,
  Typography,
} from 'src/components/ui'
import { TButtonVariants } from 'src/components/ui/Button'

import { FormMoveParcelsItemsEnum, FormMoveParcelsItemsType, FormSubmitFn, ParcelsArticleType } from 'src/models'

import { formShapes } from 'src/bus/forms'

import { ternary } from 'src/utils'

type MoveParcelsItemsFormProps = {
  onSubmit?: FormSubmitFn<FormMoveParcelsItemsType>
  loadingCreate?: boolean
  buttonLabel: string
  buttonVariant?: TButtonVariants
  parcelsArticlesDetails: ParcelsArticleType | null
  loadingParcelsArticlesDetailsFetch?: boolean
  initialValues: Partial<ParcelsArticleType> | null
}

export const MoveParcelsItemsForm = ({
  onSubmit,
  loadingCreate,
  buttonLabel,
  buttonVariant = 'default',
  initialValues = null,
  parcelsArticlesDetails,
  loadingParcelsArticlesDetailsFetch = false,
}: MoveParcelsItemsFormProps) => {
  const { t } = useTranslation()

  const formProps = useForm<FormMoveParcelsItemsType>({
    defaultValues: { ...formShapes.moveParcelsItems.shape, ...initialValues },
    resolver: zodResolver(formShapes.moveParcelsItems.schema(t)),
  })

  const { control, handleSubmit, setError, reset } = formProps

  useDeepCompareEffect(() => {
    if (initialValues) {
      reset(initialValues)
    }
  }, [initialValues, reset])

  const onSubmitForm = handleSubmit(async (values) => {
    await onSubmit?.({ values, acts: { setError, reset } })
  })

  const quantity = parcelsArticlesDetails?.quantity

  const title = replace(/^, /, '-, ', `${parcelsArticlesDetails?.title}, ${parcelsArticlesDetails?.article}`)

  return (
    <Form {...formProps}>
      <FormFields.FormGeneral
        onSubmit={onSubmitForm}
        footer={
          <Button block variant={buttonVariant} type='submit' loading={loadingCreate}>
            {buttonLabel}
          </Button>
        }
      >
        <div className='mb-5 flex items-center justify-between gap-2'>
          <div className='basis-8/12'>
            {ternary(
              loadingParcelsArticlesDetailsFetch,
              <>
                <Skeleton className='mb-1 h-5' />
                <Skeleton className='h-5' />
              </>,
              <>
                <Typography variant='secondary'>{title}</Typography>
                <Typography variant='caption' color='gray-7'>
                  {t('parcels:quantity_items', { qty: quantity }) as string}
                </Typography>
              </>,
            )}
          </div>
          <div className='basis-4/12'>
            <FormField
              control={control}
              name={FormMoveParcelsItemsEnum.quantity}
              render={({ field }) => (
                <FormItem gutter='none'>
                  <FormControl>
                    <FormFields.InputStepper {...field} max={quantity || undefined} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
      </FormFields.FormGeneral>
    </Form>
  )
}
