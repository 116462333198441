import { z } from 'zod'

import { HttpRequestParams, BaseDataInfiniteType, metaSchema } from 'src/models'

export const orderArticleSchema = z.object({
  id: z.number(),
  article: z.string(),
  articleType: z.string(),
  brand: z.string(),
  quantity: z.number(),
  sum: z.number(),
})

export const orderDeliverySchema = z.object({
  id: z.number(),
  type: z.string(),
  paketNumber: z.string(),
  sentDate: z.number(),
  deliveryDate: z.number(),
  trackingUrl: z.string(),
})

export const orderArticleListSchema = z.object({
  items: orderArticleSchema.array(),
  meta: metaSchema,
})

export const orderDeliveryListSchema = z.object({
  items: orderDeliverySchema.array(),
})

export type OrdersArticleType = z.infer<typeof orderArticleSchema>

export type OrdersArticlesType = z.infer<typeof orderArticleListSchema>
export type OrdersDeliveriesType = z.infer<typeof orderDeliveryListSchema>
export type OrdersArticlesInfiniteType = BaseDataInfiniteType<OrdersArticleType>

export type OrdersParams = HttpRequestParams
