import dayjs, { Dayjs } from 'dayjs'
import { isNil } from 'ramda'

import { HistoryAllInfiniteType, HistoryOrdersInfiniteType, ReturnsInfiniteType } from 'src/models'

import { DATE_FORMAT_TYPES } from 'src/utils/constants'

type DateFormatType = 'dot' | 'slash' | 'timeFull' | 'dashReversed' | 'dashReversedTime' | 'slashTime' | 'slashShort'

export const formatDate = (date?: Date | Dayjs | number | string | null, type: DateFormatType = 'dot'): string =>
  isNil(date) ? '' : dayjs(date).format(DATE_FORMAT_TYPES[type])

type ShowDataType = HistoryAllInfiniteType | HistoryOrdersInfiniteType | ReturnsInfiniteType

export function getIsShowDate<T extends ShowDataType>(key: number, data: T) {
  if (key === 0) return true

  const dateProperty = 'date' in data.pages[0].items[key] ? 'date' : 'createdAt'
  const currentDate = formatDate((data.pages[0].items[key] as any)[dateProperty])
  const prevDate = formatDate((data.pages[0].items[key - 1] as any)[dateProperty])

  return prevDate !== currentDate
}
