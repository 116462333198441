import { QueryClient } from '@tanstack/react-query'
import axios, { AxiosRequestConfig } from 'axios'
import Router from 'next/router'
import QS from 'qs'

import { book } from 'src/init/book'

import { toastEnhanced } from 'src/components/common'

import { HttpStatusCode, HttpStatusMessage } from 'src/models'

import { CLIENT_API_INITIAL_ERR, ENCODE_OPTIONS, getAccessToken, removeFromStorage } from 'src/utils'

import { API_CLIENT_URL } from 'src/api/config'

export const defaultOptions: AxiosRequestConfig = {
  baseURL: API_CLIENT_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  timeout: 25000,
  paramsSerializer: (params: AxiosRequestConfig) => QS.stringify(params, ENCODE_OPTIONS),
}

export const httpAxiosProvider = axios.create(defaultOptions)

httpAxiosProvider.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken()

    if (config?.headers && accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }

    return config
  },
  (error) => Promise.reject(error),
)

httpAxiosProvider.interceptors.response.use(
  (response) => response,
  async (error) => {
    const queryClient = new QueryClient()

    if (error.response) {
      const response = error?.response

      // handle 401 code for all api
      if (response?.status === HttpStatusCode.auth) {
        queryClient.clear()
        removeFromStorage()
        await Router.replace(book.signin)
        toastEnhanced({ title: response.data.error.type, body: response.data.error.message }, { type: 'error' })
      }

      // handle 404 code and GET method's for all api /entity/:id
      if (response?.status === HttpStatusCode.notFound && response?.config.method === 'get') {
        await Router.replace(book.notFound)
        return Promise.reject(response)
      }

      if (
        response?.status === HttpStatusCode.badRequest &&
        response?.data?.error?.type &&
        response?.data?.error?.message === HttpStatusMessage.unauthenticated
      ) {
        queryClient.clear()
        removeFromStorage()
        await Router.replace(book.signin)

        toastEnhanced({ title: response.data.error.type, body: response.data.error.message }, { type: 'error' })

        return Promise.reject(response)
      }

      // handle when response has valid error (code + error),
      // all 40x codes between 400 - 500 range
      if (
        response?.status >= HttpStatusCode.badRequest &&
        response?.data?.error?.type &&
        response?.data?.error?.message
      ) {
        if (response.status !== HttpStatusCode.unprocessableEntity) {
          toastEnhanced({ title: response.data.error.type, body: response.data.error.message }, { type: 'error' })
        }
        return Promise.reject(response.data.error)
      }

      // TODO: check handle when response doesn't have valid error from API but
      // has message + code from axios util
      if (error?.message && response?.status !== HttpStatusCode.server) {
        return Promise.reject(response)
      }

      // handle when response has 50x error,
      // showing notification + create custom error
      toastEnhanced({ title: 'api error' }, { type: 'error' })

      return Promise.reject({
        status: CLIENT_API_INITIAL_ERR.status,
        data: CLIENT_API_INITIAL_ERR.error,
      })
    } else if (error.request) {
      // queryClient.clear();
      // removeFromStorage();
      // await Router.replace(book.signin);

      toastEnhanced({ title: 'Server error without response' }, { type: 'error' })

      return Promise.reject({
        status: error.request.status,
        data: { error: { type: 'error', message: 'Server error without response' } },
      })
    } else {
      toastEnhanced({ title: 'Something wrong' }, { type: 'error' })

      return Promise.reject()
    }
  },
)
