import { ManageToursQuickFilters, FiltersDynamicForm } from 'src/components/common'

import { FiltersDynamicType, FilterStaticDataType, FilterStaticEnum, FormSubmitParams } from 'src/models'

import { useManagePointsFetch, useManagePointsFilters } from 'src/bus/manageTours'

import { useUrlQuery } from 'src/hooks'

export const ManageToursDynamicFilters = () => {
  const { onUpdateQuery } = useUrlQuery()
  const { managePointsFilters } = useManagePointsFilters()
  const { managePointsFiltersStaticInitialValues, onRefetchManagePoints } = useManagePointsFetch()

  const filterStaticItems: FilterStaticDataType = {
    zones: {
      name: FilterStaticEnum.zones,
      options: managePointsFilters.zones,
      isMulti: true,
    },
    sectors: {
      name: FilterStaticEnum.sectors,
      options: managePointsFilters.sectors,
      isMulti: true,
    },
    profileExists: {
      name: FilterStaticEnum.profileExists,
      options: managePointsFilters.profileExists,
    },
    customerStatus: {
      name: FilterStaticEnum.customerStatus,
      options: managePointsFilters.customerStatus,
      isMulti: true,
    },
    potential: {
      name: FilterStaticEnum.potential,
      options: managePointsFilters.potential,
      isMulti: true,
    },
    businessType: {
      name: FilterStaticEnum.businessType,
      options: managePointsFilters.businessType,
      isMulti: true,
    },
    quantityEmployees: {
      name: FilterStaticEnum.quantityEmployees,
      options: managePointsFilters.quantityEmployees,
      isMulti: true,
    },
    plannedVisitsExist: {
      name: FilterStaticEnum.plannedVisitsExist,
      options: managePointsFilters.plannedVisitsExist,
      isVisible: false,
    },
    date: {
      name: FilterStaticEnum.date,
    },
  }

  const filterSearchItem: FilterStaticDataType = {
    search: {
      name: FilterStaticEnum.search,
      placeholder: 'manage_tours_search.placeholder',
    },
  }

  const onSubmitDynamic = async (data: FormSubmitParams<FiltersDynamicType>) => {
    const { filterData } = onUpdateQuery({
      filtersValues: data.values,
      filterItems: filterStaticItems,
    })

    await onRefetchManagePoints(filterData)
  }

  return (
    <div className='absolute z-10 flex w-full flex-col gap-2 p-2'>
      <div className='shadow-level-1'>
        <FiltersDynamicForm
          filterItemsSet={filterSearchItem}
          onSubmit={onSubmitDynamic}
          initialValues={managePointsFiltersStaticInitialValues?.filter}
        />
      </div>
      <ManageToursQuickFilters
        initialValues={managePointsFiltersStaticInitialValues?.filter}
        filterStaticItems={filterStaticItems}
        onRefetch={onRefetchManagePoints}
      />
    </div>
  )
}
