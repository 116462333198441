import { useMutation, UseMutationResult } from '@tanstack/react-query'

import { ClaimsParams, HttpErrorType } from 'src/models'

import { claimsService } from 'src/bus/claims'

import { queryFetcher } from 'src/utils'

type UseClaimsDeleteMutationType = {
  onDeleteClaimMutation: UseMutationResult<void, HttpErrorType, ClaimsParams>
}

export const useClaimsDeleteMutation = (): UseClaimsDeleteMutationType => {
  const onDeleteClaimMutation = useMutation<void, HttpErrorType, ClaimsParams>({
    mutationFn: (params) => {
      return queryFetcher({
        name: 'deleteClaim',
        fetcher: claimsService.deleteClaim,
        fetcherPayload: params,
      })
    },
  })

  return { onDeleteClaimMutation }
}
