import { useRouter } from 'next/router'

import { book } from 'src/init/book'

import { HeaderTabs } from 'src/components/common'

import { getLastQueryPath } from 'src/utils'

export const HistoryHeaderTabs = () => {
  const router = useRouter()

  const createKey = (pathname: string) => `${book.customers}/${router.query.id}${pathname}`

  const historyTabs = [
    {
      title: 'history:all',
      key: createKey(book.historyAll),
    },
    {
      title: 'history:orders',
      key: createKey(book.orders),
    },
    {
      title: 'history:returns',
      key: createKey(book.returns),
    },
  ]

  const activePath = getLastQueryPath(router.pathname) as string

  return <HeaderTabs tabs={historyTabs} activePath={activePath} />
}
