import { CustomerCredentialsType } from 'src/models'

import { customersService, customersKeys, customersState, useCustomersDetailsFetch } from 'src/bus/customers'

import { useQueryAsync } from 'src/hooks'

type UseCredentialsFetchType = {
  loadingCredentialsFetch: boolean
  credentials: CustomerCredentialsType
}

export const useCustomersCredentialsFetch = (): UseCredentialsFetchType => {
  const { customerId, customersDetails } = useCustomersDetailsFetch()

  const params = { id: customerId }

  const fetchCustomersCredentialsProps = useQueryAsync<CustomerCredentialsType>({
    key: customersKeys.credentials(customerId),
    name: 'fetchCustomersCredentials',
    fetcher: customersService.fetchCustomersCredentials,
    fetcherPayload: params,
    options: { enabled: !!customerId && !!customersDetails?.isHasLeadInvitedCredential },
  })

  return {
    loadingCredentialsFetch: fetchCustomersCredentialsProps.isLoading,
    credentials: fetchCustomersCredentialsProps.data || customersState.credentials,
  }
}
