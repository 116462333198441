import { useTranslation } from 'next-i18next'

import { Typography, Button } from 'src/components/ui'

import { useParcelsCheckCompliance, useParcelsDetailsFetch } from 'src/bus/parcels'

import { getIsInProgressOrPackedStatus } from 'src/utils'

export const ParcelsCheckComplianceButton = () => {
  const { t } = useTranslation()
  const { onCheckCompliance, checkCompliance } = useParcelsCheckCompliance()
  const { parcelsDetails } = useParcelsDetailsFetch()

  const renderButton = () => {
    const countCheckedArticles = Number(parcelsDetails?.countCheckedArticles)
    const countArticles = Number(parcelsDetails?.countArticles)

    if (countCheckedArticles !== 0 && countArticles !== 0 && parcelsDetails?.isChecked) {
      return (
        <div className='rounded bg-success p-2 text-center'>
          <Typography color='white'>{t('parcels:checked_i')}</Typography>
        </div>
      )
    }

    if (!checkCompliance && getIsInProgressOrPackedStatus(parcelsDetails) && !parcelsDetails?.isChecked) {
      return (
        <Button block onClick={() => onCheckCompliance(true)}>
          {t('buttons:check_compliance')}
        </Button>
      )
    }

    if (countCheckedArticles < countArticles) {
      return (
        <div className='rounded bg-white p-2'>
          <div className='flex items-center justify-between gap-2'>
            <Typography variant='caption' color='gray-7'>
              {t('parcels:checked_amount')}
            </Typography>
            <Typography className='font-semibold'>
              {parcelsDetails?.countCheckedArticles}/{parcelsDetails?.countArticles}
            </Typography>
          </div>
        </div>
      )
    }
  }

  return <div className='mt-2'>{renderButton()}</div>
}
