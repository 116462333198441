export const queryOptions = {
  defaultOptions: {
    queries: {
      // With SSR, we usually want to set some default staleTime
      // above 0 to avoid refetching immediately on the client
      staleTime: 60 * 1000,
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
      retry: 0, // default 3
      retryOnMount: true, // default
      refetchActive: true, // default
      refetchInactive: false, // default
      refetchOnMount: false, // default: true
      refetchOnWindowFocus: false, // default: true
      refetchOnReconnect: true, // default
    },
  },
}
