import { TourPointsStaticsType } from 'src/models'

import { tourState, tourKeys, tourService } from 'src/bus/tour'

import { useQueryAsync } from 'src/hooks'

type useTourPointsStaticFetchType = {
  loadingTourPointsStaticFetch: boolean
  tourPointsStatic: TourPointsStaticsType
}

export const useTourPointsStaticFetch = (): useTourPointsStaticFetchType => {
  const fetchTourPointsStaticProps = useQueryAsync<TourPointsStaticsType>({
    key: tourKeys.tourPointsStatic(),
    name: 'fetchTourPointsStatic',
    fetcher: tourService.fetchTourPointsStatic,
  })

  return {
    loadingTourPointsStaticFetch: fetchTourPointsStaticProps.isLoading,
    tourPointsStatic: fetchTourPointsStaticProps.data || tourState.tourPointsStatic,
  }
}
