import { BaseDataListStringType } from 'src/models'

import { otrsOptionsKeys, otrsOptionsService } from 'src/bus/otrsOptions'

import { useQueryAsync } from 'src/hooks'

type FieldsFetchType = {
  loadingOtrsOptionsFieldsFetch: boolean
  otrsOptionsFields: string[]
}

export const useFieldsFetch = (reasonId?: number, clarificationId?: number): FieldsFetchType => {
  const fetchFieldsProps = useQueryAsync<BaseDataListStringType>({
    key: otrsOptionsKeys.fields({ reasonId, clarificationId }),
    name: 'fetchFields',
    fetcher: otrsOptionsService.fieldsFetch,
    fetcherPayload: { reasonId, clarificationId },
    options: { enabled: !!reasonId || !!clarificationId },
  })

  return {
    loadingOtrsOptionsFieldsFetch: fetchFieldsProps.isLoading,
    otrsOptionsFields: fetchFieldsProps.data?.items || [],
  }
}
