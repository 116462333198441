import { useTranslation } from 'next-i18next'
import NextImage from 'next/image'
import { Congrats } from 'public/assets'

import { Button, DialogContent, Typography } from 'src/components/ui'

import { useDialog } from 'src/contexts'

import { GET_ENUMS } from 'src/utils'

export const DialogNoVisits = () => {
  const { t } = useTranslation()
  const { setOnPageDialog } = useDialog()

  return (
    <DialogContent name={GET_ENUMS.dialog.noVisits} className='flex h-full flex-col'>
      <div className='flex h-full flex-col items-center justify-center gap-2'>
        <Typography variant='head'>{t('congrats')}</Typography>
        <Typography variant='secondary' color='gray-7'>
          {t('reached_goal')}
        </Typography>
        <div className='mt-6'>{<NextImage src={Congrats} alt='congrats' />}</div>
      </div>
      <Button onClick={() => setOnPageDialog(GET_ENUMS.dialog.noVisits, false)}>{t('buttons:ok')}</Button>
    </DialogContent>
  )
}
