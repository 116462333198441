import { useRouter } from 'next/router'

// Models
import { VisitHistoryType } from 'src/models'

import { visitsHistoryService, visitsHistoryState, visitsHistoryKeys } from 'src/bus/visitsHistory'

import { useQueryAsync } from 'src/hooks'

type UseVisitsHistoryDetailsFetchType = {
  visitsHistoryDetails: VisitHistoryType | null
  loadingVisitsHistoryDetailsFetch: boolean
}

export const useVisitsHistoryDetailsFetch = (): UseVisitsHistoryDetailsFetchType => {
  const router = useRouter()

  const id = Number(router.query.id)
  const params = { id }

  const fetchVisitsHistoryDetailsProps = useQueryAsync<VisitHistoryType>({
    key: visitsHistoryKeys.historyDetails(id),
    name: 'fetchVisitsHistoryDetails',
    fetcher: visitsHistoryService.fetchVisitsHistoryDetails,
    fetcherPayload: params,
  })

  return {
    visitsHistoryDetails: fetchVisitsHistoryDetailsProps.data || visitsHistoryState.historyDetails,
    loadingVisitsHistoryDetailsFetch: fetchVisitsHistoryDetailsProps.isLoading,
  }
}
