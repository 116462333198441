import { useTranslation } from 'next-i18next'
import NextImage from 'next/image'
import { Error404 } from 'public/assets'

import { Typography } from 'src/components/ui'

export const PageNotFound = () => {
  const { t } = useTranslation()

  return (
    <div className='flex h-full flex-col pb-2 pt-40 text-center'>
      <div className='mx-auto mb-10'>
        <NextImage src={Error404} alt='not-found' unoptimized priority width={268} height={239} />
      </div>
      <div className='mb-2'>
        <Typography variant='head'>
          {t('ops')} <br />
          {t('empty.page_not_found')}
        </Typography>
      </div>
      <Typography variant='secondary' color='gray-7'>
        {t('empty.broken_link')}
      </Typography>
    </div>
  )
}
