import { StaticDataItemsType } from 'src/models'

import { useCustomersStaticDataFetch } from 'src/bus/customers'
import { useUserSectorsFetch, useUserZonesFetch } from 'src/bus/userOptions'

type CustomersFiltersType = {
  loadingCustomersFiltersFetch: boolean
  customersFilters: StaticDataItemsType
}

export const useCustomersFilters = (): CustomersFiltersType => {
  const { planVisits, businessType, quantityEmployees, customerStatus, potential, loadingCustomerStaticDataFetch } =
    useCustomersStaticDataFetch()
  const { sectors, loadingUserSectorsFetch } = useUserSectorsFetch()
  const { zones, loadingUserZonesFetch } = useUserZonesFetch()

  const filters = {
    planVisits,
    customerStatus,
    businessType,
    quantityEmployees,
    sectors,
    zones,
    potential,
  }

  return {
    customersFilters: filters,
    loadingCustomersFiltersFetch: loadingCustomerStaticDataFetch || loadingUserSectorsFetch || loadingUserZonesFetch,
  }
}
