import { ReactNode } from 'react'

import { Sheet } from 'src/components/ui'

type DrawerProps = {
  open: boolean
  onOpenChange?: (open: boolean) => void
  children: ReactNode
}

export const Drawer = ({ open, onOpenChange, children, ...rest }: DrawerProps) => {
  return (
    <Sheet open={open} onOpenChange={onOpenChange} {...rest}>
      {children}
    </Sheet>
  )
}
