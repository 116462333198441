import type { TFunction } from 'i18next'

import type { MenuProfileType } from 'src/models'

import { GET_ENUMS } from 'src/utils/constants'

type VisitsMenuProps = {
  t: TFunction
  loading: boolean
  isClosed: boolean
}

export const getCustomersMenu = ({ t, loading, isClosed }: VisitsMenuProps): MenuProfileType[] => {
  return isClosed
    ? [
        {
          title: t('buttons:open_garage'),
          key: GET_ENUMS.alertDialog.openCustomer,
          disabled: loading,
          icon: 'Stop',
          dialog: true,
        },
      ]
    : [
        {
          title: t('buttons:close_garage'),
          key: GET_ENUMS.alertDialog.closeCustomer,
          disabled: loading,
          icon: 'Stop',
          type: 'cancel',
          dialog: true,
        },
      ]
}
