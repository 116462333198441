import { StaticDataItemType, StaticDataKeys } from 'src/models'

import { useStaticDataFetch } from 'src/bus/staticData'

type UseVisitsStaticDataFetchType = {
  cancelingReasons: StaticDataItemType[]
  loadingCancelingReasonsFetch: boolean
}

export const useVisitsStaticDataFetch = (): UseVisitsStaticDataFetchType => {
  const staticDataKeys = [StaticDataKeys.cancelingReasons]

  const { staticData, loadingStaticDataFetch } = useStaticDataFetch(staticDataKeys)

  return {
    cancelingReasons: staticData?.[StaticDataKeys.cancelingReasons] || [],
    loadingCancelingReasonsFetch: loadingStaticDataFetch,
  }
}
