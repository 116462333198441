const getPageTitle = (pageKey: string): string => `page_titles.${pageKey}`

export const pageTitles: Record<string, string> = {
  '/': getPageTitle('root'),
  '/dashboard': getPageTitle('dashboard'),
  '/404': getPageTitle('page_not_found'),
  '/500': getPageTitle('server_error'),
  '/signin': getPageTitle('signin'),
  '/profile': getPageTitle('profile'),
  '/scanner': getPageTitle('scanner'),
  '/customers': getPageTitle('customers'),
  '/customers/create': getPageTitle('customer_create'),
  '/customers/[id]': getPageTitle('customers'),
  '/customers/[id]/all': getPageTitle('customer_history'),
  '/customers/[id]/edit': getPageTitle('customer_history'),
  '/customers/[id]/orders': getPageTitle('customer_history'),
  '/customers/[id]/returns': getPageTitle('customer_history'),
  '/customers/[id]/update-location': getPageTitle('customer_history'),
  '/orders': getPageTitle('orders'),
  '/orders/[id]': getPageTitle('orders'),
  '/visits': getPageTitle('visits'),
  '/visits/create': getPageTitle('visit_create'),
  '/visits/[id]/actions': getPageTitle('visits'),
  '/visits/[id]/plan': getPageTitle('visit_plan'),
  '/visits/[id]/report': getPageTitle('visit_report'),
  '/visits/history': getPageTitle('visits_history'),
  '/visits/history/[id]': getPageTitle('visits_history'),
  '/visits/history/[id]/report': getPageTitle('visits_history'),
  '/actions': getPageTitle('actions'),
  '/actions/[id]/details': getPageTitle('actions'),
  '/parcels': getPageTitle('parcels'),
  '/parcels/clear': getPageTitle('parcels'),
  '/parcels/dirty': getPageTitle('parcels'),
  '/parcels/list': getPageTitle('parcels'),
  '/parcels/list/[id]': getPageTitle('parcels'),
  '/settings': getPageTitle('settings'),
  '/claims': getPageTitle('returns'),
  '/returns': getPageTitle('returns'),
  '/returns/[id]': getPageTitle('returns'),
  '/returns/[id]/confirm': getPageTitle('returns'),
  '/tour-points': getPageTitle('tour_points'),
  '/tour-points/create': getPageTitle('tour_points'),
  '/manage-tours': getPageTitle('manage_tours'),
}
