import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useState } from 'react'

import { book } from 'src/init/book'

import { CancelVisitForm, PlanVisitForm } from 'src/components/common'
import { Button, DialogContent, DialogHeader, Typography } from 'src/components/ui'

import { FormCancelVisitSubmitType, FormSubmitParams } from 'src/models'

import {
  useVisitsStaticDataFetch,
  useVisitsCancel,
  useVisitsFetch,
  useVisitsPlanVisit, // useAuthProfile,
} from 'src/bus/visits'

import { GET_ENUMS, getTranslate } from 'src/utils'

type DialogCancelVisitProps = {
  id?: number
  title?: string
}

const steps = ['cancel', 'question', 'plan-visit']

export const DialogCancelVisit = ({ id, title }: DialogCancelVisitProps) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { cancelingReasons, loadingCancelingReasonsFetch } = useVisitsStaticDataFetch()
  const { visitFirst } = useVisitsFetch()
  const { onCancelVisit, onCancelVisitHistory, loadingVisitsCancel } = useVisitsCancel(id)
  const { onReplanVisit, loadingVisitPlan } = useVisitsPlanVisit()
  // const { isRoleServiceAgent } = useAuthProfile();

  const getCancelVisitT = getTranslate('common:cancel_visit', t)

  const [data] = useState<FormSubmitParams<FormCancelVisitSubmitType>>()
  const [stepIndex, setStepIndex] = useState(0)
  const step = steps[stepIndex]

  const isVisitHistory = router.pathname.includes(book.visitsHistory)

  const onNextStep = () => setStepIndex((prev) => prev + 1)

  const onSaveFormData = (data: FormSubmitParams<FormCancelVisitSubmitType>) => {
    // if (isRoleServiceAgent) {

    isVisitHistory ? onCancelVisitHistory(data) : onCancelVisit(data)
    // return;
    // }
    // prospector flow
    // setData(data);
    // onNextStep();
  }

  const onCancelVisitProspector = () => data && onCancelVisit(data)

  const companyName = title || visitFirst?.companyName || ''

  const visitTitle = getCancelVisitT('title', { name: companyName })

  const renderData = () => {
    switch (step) {
      case 'cancel':
        return (
          <>
            <DialogHeader>
              <Typography variant='title' color='gray-10'>
                {visitTitle}
              </Typography>
            </DialogHeader>
            <CancelVisitForm
              visitCancelReasons={cancelingReasons}
              loadingFetchStaticData={loadingCancelingReasonsFetch}
              loadingCreate={loadingVisitsCancel}
              onSubmit={onSaveFormData}
            />
          </>
        )
      case 'question':
        return (
          <>
            <DialogHeader>
              <Typography variant='title' color='gray-10'>
                {getCancelVisitT('select_next_date')}
              </Typography>
            </DialogHeader>
            <div className='flex items-center justify-between gap-2'>
              <Button variant='outline' block onClick={onCancelVisitProspector} loading={loadingVisitsCancel}>
                {t('buttons:without_plan')}
              </Button>
              <Button block onClick={onNextStep}>
                {t('buttons:with_plan')}
              </Button>
            </div>
          </>
        )
      case 'plan-visit':
        return (
          <>
            <DialogHeader>
              <Typography variant='title' color='gray-10'>
                {getCancelVisitT('plan_new_visit', { name: companyName })}
              </Typography>
              <Typography variant='secondary' color='gray-7'>
                {getCancelVisitT('new_visit')}
              </Typography>
            </DialogHeader>
            <PlanVisitForm onSubmit={onReplanVisit} loadingCreate={loadingVisitPlan} />
          </>
        )
      default:
        return null
    }
  }

  return <DialogContent name={GET_ENUMS.dialog.cancelVisit}>{renderData()}</DialogContent>
}
