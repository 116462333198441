import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { useCallback, useState } from 'react'

import { HttpErrorType, ParcelType } from 'src/models'

import { parcelsKeys, useParcelsCheckMutation } from 'src/bus/parcels'

import { useToast } from 'src/hooks'

import { useDialog } from 'src/contexts'

import { GET_ENUMS } from 'src/utils'

type CheckParcelsCb = (id: number) => void

type UseParcelsCheckType = {
  loadingParcelsCheck: boolean
  onCheckParcels: CheckParcelsCb
}

export const useParcelsCheck = (): UseParcelsCheckType => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const { onCheckParcelsMutation } = useParcelsCheckMutation()
  const showToast = useToast()
  const { setOnPageDialog } = useDialog()

  const parcelId = Number(router.query.id)

  const [isLoading, setIsLoading] = useState(false)

  const onCheckParcels = useCallback<CheckParcelsCb>(
    async (id) => {
      try {
        setIsLoading(true)

        await onCheckParcelsMutation.mutateAsync({ params: { id: String(id) } })
        await queryClient.refetchQueries({ queryKey: parcelsKeys.articles() })
        await queryClient.refetchQueries({ queryKey: parcelsKeys.lists() })
        await queryClient.refetchQueries({ queryKey: parcelsKeys.detail(parcelId) })

        const parcelsDetails: ParcelType | undefined = queryClient.getQueryData(parcelsKeys.detail(parcelId))

        if (parcelsDetails?.isChecked && parcelsDetails?.isAvailablePack) {
          setOnPageDialog(GET_ENUMS.alertDialog.packParcel, true)
        }
      } catch (error) {
        const errs = (error as HttpErrorType).errors
        if (errs) {
          const [[title, body]] = Object.entries(errs)
          showToast.error({ title, body: body.desc })
        }
      } finally {
        setIsLoading(false)
      }
    },
    [parcelId, onCheckParcelsMutation, showToast, setOnPageDialog, queryClient],
  )

  return {
    loadingParcelsCheck: isLoading,
    onCheckParcels,
  }
}
