import { useInfiniteQuery, keepPreviousData } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import QS from 'qs'
import { map } from 'ramda'
import { useCallback, useMemo } from 'react'

import { ReturnsType, ReturnsInfiniteType, FilterStaticFilterQueryParamsType, TRefetchFilterStaticCb } from 'src/models'

import { useAuthProfile } from 'src/bus/auth'
import { useCustomersDetailsFetch } from 'src/bus/customers'
import { returnsState, returnsKeys, returnsService } from 'src/bus/returns'
import { useVisitsFetch } from 'src/bus/visits'

import { getNextPageParam, getParamsFromQueryStr, queryFetcher, renameKeys } from 'src/utils'

type UseReturnsListFetchType = {
  loadingReturnsListFetch: boolean
  returnsList: ReturnsInfiniteType
  onFetchMoreReturnsList: () => void
  returnsListHasMore: boolean
  onRefetchReturnsList: TRefetchFilterStaticCb
  returnsFiltersStaticInitialValues: FilterStaticFilterQueryParamsType
}

export const useReturnsListFetch = (): UseReturnsListFetchType => {
  const router = useRouter()
  const { loadingVisitsFetch } = useVisitsFetch()
  const { customerId, loadingCustomersDetailsFetch } = useCustomersDetailsFetch()
  const { isRoleServiceAgent } = useAuthProfile()

  const initialQuery = getParamsFromQueryStr(router.query)
  const params = { ...initialQuery, ...(customerId && { customerId }) }

  const fetchReturnsListProps = useInfiniteQuery<ReturnsType>({
    queryKey: returnsKeys.list(initialQuery),
    queryFn: ({ pageParam }) =>
      queryFetcher({
        name: 'fetchReturnsList',
        fetcher: returnsService.fetchReturns,
        fetcherPayload: { params: { ...params, page: pageParam } },
      }),
    enabled: !!customerId && isRoleServiceAgent,
    initialPageParam: 1,
    getNextPageParam,
    placeholderData: keepPreviousData,
  })

  const onRefetchReturnsList = useCallback<TRefetchFilterStaticCb>(
    async (values) => {
      const newParams = { ...initialQuery, ...values?.filterData }
      await router.replace({ pathname: router.pathname, query: QS.stringify(newParams) })
    },
    [router, initialQuery],
  )

  const returnsList = useMemo(() => {
    const data = fetchReturnsListProps.data || returnsState.returns

    return {
      ...data,
      pages: data.pages.map((page) => {
        return {
          ...page,
          items: map(renameKeys({ dateCreated: 'createdAt' }), page.items),
        }
      }),
    } as unknown as ReturnsInfiniteType
  }, [fetchReturnsListProps])

  return {
    loadingReturnsListFetch: fetchReturnsListProps.isLoading || loadingVisitsFetch || loadingCustomersDetailsFetch,
    onFetchMoreReturnsList: fetchReturnsListProps.fetchNextPage,
    returnsListHasMore: fetchReturnsListProps.hasNextPage,
    returnsList,
    onRefetchReturnsList,
    returnsFiltersStaticInitialValues: initialQuery,
  }
}
