import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { book } from 'src/init/book'

import { AuthTokenType } from 'src/models'

import { authKeys, authService, useAuthProfile } from 'src/bus/auth'

import { removeFromStorage, saveTokensStorage, queryFetcher, getAccessToken, verifyBrowser } from 'src/utils'

export const useAuthenticate = () => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const { onPrefetchProfile } = useAuthProfile()

  const authenticateProps = useMutation<AuthTokenType>({
    mutationKey: authKeys.login(),
    mutationFn: () =>
      queryFetcher({
        name: 'authenticate',
        fetcher: authService.authenticate,
      }),
  })

  useEffect(() => {
    if (verifyBrowser()) {
      const storedToken = getAccessToken()

      if (!storedToken) {
        return
      }

      authenticateProps.mutate(undefined, {
        onSuccess: async (data) => {
          saveTokensStorage(data.token)
          await onPrefetchProfile()
        },
        onError: async () => {
          queryClient.clear()
          removeFromStorage()
          await router.push(book.signin)
        },
      })
    }
  }, []) // don't add dependencies
}
