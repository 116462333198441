import type { TFunction } from 'i18next'
import { z } from 'zod'

import { messages } from 'src/utils'

export const signin = {
  shape: {
    login: '',
    password: '',
  },
  schema: (t: TFunction) => {
    return z.object({
      login: z.string().trim().min(1, t(messages.login.required)),
      password: z
        .string()
        .trim()
        .regex(messages.password.patternWhitespaces, t(messages.password.invalidWhiteSpaces))
        .min(
          messages.password.min,
          t(messages.password.minText, {
            label: t(messages.password.placeholder).toLowerCase(),
            count: messages.password.min,
          }),
        ),
    })
  },
}
