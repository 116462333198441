import { AxiosResponse } from 'axios'

import { HttpResponse } from 'src/models'

const defaultServerResParseOptFields = {
  config: false,
  headers: false,
  request: false,
}

export const parseServerResponse = <T = unknown>(
  response: AxiosResponse<HttpResponse<T>>,
  { raw, fields } = {
    raw: false,
    fields: defaultServerResParseOptFields,
  },
) => {
  if (raw) {
    return response
  }
  const clone = { ...response }

  return {
    status: clone.status,
    data: clone.data,
    ...(fields.config && { config: clone.config }),
    ...(fields.headers && { headers: clone.headers }),
    ...(fields.request && { request: clone.request as unknown }),
  }
}
