import { ReactNode } from 'react'

import { FiltersDrawer, Header } from 'src/components/common'

import { FilterStaticDataType, FilterStaticEnum, FilterStaticType, FormSubmitParams } from 'src/models'

import { useCustomersDetailsFetch } from 'src/bus/customers'
import { useHistoryAllFetch, useHistoryFilters } from 'src/bus/history'

import { useUrlQuery } from 'src/hooks'

import { useDrawer } from 'src/contexts'

import { GET_ENUMS } from 'src/utils'

export const HistoryAllHeader = ({ children }: { children: ReactNode }) => {
  const { customersDetails, loadingCustomersDetailsFetch } = useCustomersDetailsFetch()
  const { setOnPageDrawer } = useDrawer()
  const { onUpdateQuery } = useUrlQuery()
  const { loadingHistoryAllFetch, onRefetchHistoryAllList, historyAllFiltersStaticInitialValues } = useHistoryAllFetch()
  const { historyFilters, loadingHistoryFiltersFetch } = useHistoryFilters()

  const filterStaticItems: FilterStaticDataType = {
    types: {
      name: FilterStaticEnum.types,
      options: historyFilters.customerHistoryType,
      isMulti: true,
    },
    date: {
      name: FilterStaticEnum.date,
    },
  }

  const onSubmit = async (data: FormSubmitParams<FilterStaticType>) => {
    const { filterData } = onUpdateQuery({
      filtersValues: data.values,
      filterItems: filterStaticItems,
    })

    await onRefetchHistoryAllList(filterData)
    setOnPageDrawer(GET_ENUMS.drawer.filters, false)
  }

  return (
    <Header
      withShadow
      title={customersDetails?.title}
      subTitle={customersDetails?.companyTitle}
      loadingFetch={loadingCustomersDetailsFetch}
      actionsRight={
        <FiltersDrawer
          onSubmit={onSubmit}
          filterItemsSet={filterStaticItems}
          buttons={['apply', 'reset']}
          loadingSubmit={loadingHistoryAllFetch}
          initialValues={historyAllFiltersStaticInitialValues.filter}
          loadingStaticDataFetch={loadingHistoryFiltersFetch}
        />
      }
    >
      {children}
    </Header>
  )
}
