import { useEffect } from 'react'

import { ServerErrorFieldDetailsType, SetErrorFnType } from 'src/models'

import { fillServerErrorsToForm } from 'src/utils'

export const useFillServerErrorsToForm = <T = ServerErrorFieldDetailsType>(
  setError: SetErrorFnType<T>,
  error?: ServerErrorFieldDetailsType,
) => {
  useEffect(() => {
    if (typeof error === 'object') {
      fillServerErrorsToForm(error, setError)
    }
  }, [error, setError])
}
