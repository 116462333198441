const BUILD_ENV_VARIABLES = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
  PROD: 'prod',
  DEV: 'dev',
}

const APP_PORT = process.env.APP_PORT || 4001
const BUILD_CI = process.env.BUILD_CI || 0
const APP_ENV = process.env.APP_ENV || BUILD_ENV_VARIABLES.DEV
const NODE_ENV = process.env.NODE_ENV || BUILD_ENV_VARIABLES.DEVELOPMENT
const BUILD_ENV = process.env.BUILD_ENV || BUILD_ENV_VARIABLES.DEVELOPMENT
const isDevelopment = BUILD_ENV === BUILD_ENV_VARIABLES.DEVELOPMENT
const isProduction = BUILD_ENV === BUILD_ENV_VARIABLES.PRODUCTION
const isAppProd = APP_ENV === BUILD_ENV_VARIABLES.PROD

module.exports = {
  BUILD_ENV,
  NODE_ENV,
  APP_ENV,
  BUILD_CI,
  APP_PORT,
  isProduction,
  isDevelopment,
  isAppProd,
}
