import { useRouter } from 'next/router'
import QS from 'qs'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { FiltersDrawer } from 'src/components/common'
import { Button, Icon } from 'src/components/ui'

import { FilterStaticDataType, FilterStaticEnum, FilterStaticType, FormSubmitParams } from 'src/models'

import { useVisitsFilters } from 'src/bus/visits'
import { useVisitsHistoryFetch } from 'src/bus/visitsHistory'

import { useUrlQuery } from 'src/hooks'

import { useDrawer } from 'src/contexts'

import { isNotNilOrNotEmpty, GET_ENUMS } from 'src/utils'

export const VisitsFilters = () => {
  const router = useRouter()
  const { setOnPageDrawer } = useDrawer()
  const { onUpdateQuery } = useUrlQuery()
  const { visitsFilters, loadingVisitsFiltersFetch } = useVisitsFilters()
  const {
    onRefetchVisitsHistory,
    visitsFiltersStaticInitialValues,
    loadingVisitsHistoryFetch,
    visitsHistorySortByInitialValues,
  } = useVisitsHistoryFetch()

  const filterStaticItems: FilterStaticDataType = {
    customerStatus: {
      name: FilterStaticEnum.customerStatus,
      options: visitsFilters.customerStatus,
      isMulti: true,
    },
    customers: {
      name: FilterStaticEnum.customers,
      options: visitsFilters.customers,
      isMulti: true,
    },
    sectors: {
      name: FilterStaticEnum.sectors,
      options: visitsFilters.sectors,
      isMulti: true,
    },
    statuses: {
      name: FilterStaticEnum.statuses,
      options: visitsFilters.statuses,
      isMulti: true,
    },
    date: {
      name: FilterStaticEnum.date,
    },
  }

  useDeepCompareEffect(() => {
    if (isNotNilOrNotEmpty(visitsHistorySortByInitialValues.sort)) {
      return
    }

    ;(async () => {
      await router.replace({
        pathname: router.pathname,
        query: QS.stringify({ sort: { date: 'desc' } }),
      })
    })()
  }, [visitsHistorySortByInitialValues.sort])

  const onSubmit = async (data: FormSubmitParams<FilterStaticType>) => {
    const { filterData } = onUpdateQuery({
      filtersValues: data.values,
      filterItems: filterStaticItems,
    })

    await onRefetchVisitsHistory(filterData)
    setOnPageDrawer(GET_ENUMS.drawer.filters, false)
  }

  const onSort = async () => {
    await onRefetchVisitsHistory({
      filterData: {
        sort: { date: visitsHistorySortByInitialValues?.sort?.date === 'asc' ? 'desc' : 'asc' },
      },
    })
  }

  return (
    <>
      <Button variant='ghost' size='icon-md' onClick={onSort}>
        <Icon
          name={visitsHistorySortByInitialValues?.sort?.date === 'asc' ? 'SortAsc' : 'SortDesc'}
          color='blue-gray-85'
        />
      </Button>
      <FiltersDrawer
        onSubmit={onSubmit}
        filterItemsSet={filterStaticItems}
        buttons={['apply', 'reset']}
        loadingSubmit={loadingVisitsHistoryFetch}
        initialValues={visitsFiltersStaticInitialValues.filter}
        loadingStaticDataFetch={loadingVisitsFiltersFetch}
      />
    </>
  )
}
