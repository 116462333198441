import { z } from 'zod'

import { gtOrEqualOne, messages } from 'src/utils'

export const visitReport = {
  shape: {
    businessActions: [],
    actions: [],
    comment: '',
  },
  schema: (t: any, isRoleProspector?: boolean) => {
    return z
      .object({
        actions: z.lazy(() => {
          if (isRoleProspector) {
            return z.array(z.string())
          }

          return z
            .array(z.string())
            .refine((value: any) => gtOrEqualOne(value.length), t(messages.createReport.actions.requiredOneOf))
        }),
        businessActions: z.array(z.string()),
        comment: z
          .string()
          .trim()
          .min(
            messages.comment.min,
            t(messages.comment.minText, {
              label: t(messages.comment.placeholder).toLowerCase(),
              count: messages.comment.min,
            }),
          )
          .max(
            messages.comment.max,
            t(messages.comment.maxText, {
              label: t(messages.comment.placeholder).toLowerCase(),
              count: messages.comment.max,
            }),
          ),
      })
      .nullable()
  },
}
