import { BaseDataListType, StaticDataItemType } from 'src/models'

import { userOptionsService, userOptionsKeys } from 'src/bus/userOptions'

import { useQueryAsync } from 'src/hooks'

type UserZonesFetchType = {
  loadingUserZonesFetch: boolean
  zones: StaticDataItemType[]
}

export const useUserZonesFetch = (): UserZonesFetchType => {
  const fetchUserOptionsZonesProps = useQueryAsync<BaseDataListType>({
    key: userOptionsKeys.zones(),
    name: 'fetchUserOptionsZones',
    fetcher: userOptionsService.zonesFetch,
  })

  return {
    loadingUserZonesFetch: fetchUserOptionsZonesProps.isLoading,
    zones: fetchUserOptionsZonesProps.data?.items || [],
  }
}
