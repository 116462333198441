import { VisitType } from 'src/models'

import { visitsKeys, visitsService } from 'src/bus/visits'

import { useQueryAsync } from 'src/hooks'

export const useVisitQuery = (id?: number) => {
  const visitQuery = useQueryAsync<VisitType>({
    key: visitsKeys.visit(id),
    name: 'fetchVisitsDetails',
    fetcher: visitsService.fetchVisitDetails,
    fetcherPayload: { id },
    options: { enabled: !!id },
  })

  return {
    visitQuery,
  }
}
