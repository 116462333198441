import {
  HttpResponse,
  CustomerType,
  CustomerParams,
  CustomersType,
  CustomerCredentialsType,
  CustomerVatVerifyType,
  CustomersStaticListType,
  FormCreateCustomerType,
  customerListSchema,
  customerSchema,
  customerVatVerifySchema,
  customerCredentialSchema,
  selectListSchema,
} from 'src/models'

import { zodValidateResponse } from 'src/utils'

import { httpAxiosProvider } from 'src/api'

export const customersService = Object.freeze({
  fetchCustomersList: async ({ params }: CustomerParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<CustomersType>>('/customer', {
      params,
    })
    zodValidateResponse(customerListSchema, response.data.data)
    return response
  },
  fetchCustomersDetails: async ({ params }: CustomerParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<CustomerType>>(`/customer/${params?.id}`)
    zodValidateResponse(customerSchema, response.data.data)
    return response
  },
  fetchCustomersCredentials: async ({ params }: CustomerParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<CustomerCredentialsType>>(
      `/customer/${params?.id}/lead-invited-credential`,
    )
    zodValidateResponse(customerCredentialSchema, response.data.data)
    return response
  },
  updateCustomer: ({ params, data }: CustomerParams) => {
    return httpAxiosProvider.put<HttpResponse<CustomerType>>(`/customer/${params?.id}`, data)
  },
  createCustomer: ({ data }: CustomerParams) => {
    return httpAxiosProvider.post<HttpResponse<FormCreateCustomerType>>('/customer', data)
  },
  editCustomersProps: ({ params, data }: CustomerParams) => {
    return httpAxiosProvider.patch<HttpResponse<CustomerType>>(`/customer/${params?.id}`, data)
  },
  fetchVat: async ({ params }: CustomerParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<CustomerVatVerifyType>>('/vat', {
      params,
    })
    zodValidateResponse(customerVatVerifySchema, response.data.data)
    return response
  },
  verifyVat: ({ data }: CustomerParams) => {
    return httpAxiosProvider.put<HttpResponse<CustomerVatVerifyType>>('/vat/verify', data)
  },
  fetchCustomersStaticList: async ({ params }: CustomerParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<CustomersStaticListType>>('/customer-options', { params })
    zodValidateResponse(selectListSchema, response.data.data)
    return response
  },
})
