import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormFields,
  FormItem,
  FormMessage,
  Separator,
  Typography,
} from 'src/components/ui'

import { FormEditCompanyEnum, FormSubmitFn, CustomerType, StaticDataItemType } from 'src/models'

import { formShapes } from 'src/bus/forms'

import { mapCountriesToIcons, mapItemWithIcon, messages } from 'src/utils'

type UpdateCustomerFormProps = {
  initialValues?: CustomerType | null
  countries: StaticDataItemType[]
  phoneCodes: StaticDataItemType[]
  businessType: StaticDataItemType[]
  quantityEmployees: StaticDataItemType[]
  loadingFetch?: boolean
  loadingCreate?: boolean
  loadingUpdate?: boolean
  loadingFetchStaticData?: boolean
  onSubmit?: FormSubmitFn<CustomerType>
  disabledFields: Record<string, boolean>
}

export const UpdateCustomerForm = ({
  initialValues = null,
  countries,
  phoneCodes,
  businessType,
  quantityEmployees,
  loadingUpdate = false,
  loadingFetch = false,
  loadingFetchStaticData = false,
  onSubmit,
  disabledFields,
}: UpdateCustomerFormProps) => {
  const { t } = useTranslation()

  const formProps = useForm<CustomerType>({
    defaultValues: { ...formShapes.updateCustomer.shape },
    resolver: zodResolver(formShapes.updateCustomer.schema(t)),
  })

  const { control, handleSubmit, setError, reset } = formProps

  const phoneCodesWithIcons = useMemo(() => mapCountriesToIcons(phoneCodes), [phoneCodes])

  useDeepCompareEffect(() => {
    if (initialValues) {
      reset({
        ...initialValues,
        phonePrefix: mapItemWithIcon(initialValues?.phonePrefix, initialValues?.phonePrefix?.value),
        contactPersons: initialValues.contactPersons.map((p) => {
          return {
            ...p,
            phones: p.phones.map((phone) => ({
              ...phone,
              phonePrefix: mapItemWithIcon(phone.phonePrefix, phone?.phonePrefix?.value),
            })),
          }
        }),
      })
    }
  }, [initialValues, reset])

  const hasLoading = loadingFetch || loadingFetchStaticData

  const onSubmitForm = handleSubmit(async (values) => {
    await onSubmit?.({ values, acts: { setError, reset } })
  })

  return (
    <Form {...formProps}>
      <FormFields.FormGeneral
        onSubmit={onSubmitForm}
        scroll
        footer={
          <>
            <Separator className='my-6' />
            <div className='px-2'>
              <Button
                block
                type='submit'
                loading={loadingUpdate}
                disabled={hasLoading}
                data-test-id='customer-edit-form-btn'
              >
                {t('buttons:save')}
              </Button>
            </div>
          </>
        }
      >
        <div className='mb-4'>
          <Typography className='font-semibold'>{t('buttons:customer_info')}</Typography>
        </div>
        <FormField
          control={control}
          name={FormEditCompanyEnum.title}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormFields.InputBase
                  {...field}
                  loading={loadingFetch}
                  placeholder={t(messages.companyTitle.placeholder)}
                  disabled={loadingUpdate || disabledFields.title}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name={FormEditCompanyEnum.country}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormFields.InputSelect
                  {...field}
                  placeholder={t(messages.updateCustomer.country.placeholder)}
                  loading={loadingFetchStaticData}
                  options={countries}
                  hasDisabled={loadingUpdate || disabledFields.country}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className='flex items-center justify-between gap-2'>
          <div className='basis-8/12'>
            <FormField
              control={control}
              name={FormEditCompanyEnum.city}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <FormFields.InputBase
                      {...field}
                      loading={loadingFetch}
                      placeholder={t(messages.updateCustomer.city.placeholder)}
                      disabled={loadingUpdate || disabledFields.city}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className='basis-5/12'>
            <FormField
              control={control}
              name={FormEditCompanyEnum.index}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <FormFields.InputBase
                      {...field}
                      loading={loadingFetch}
                      placeholder={t(messages.updateCustomer.index.placeholder)}
                      disabled={loadingUpdate || disabledFields.index}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <div className='flex items-center justify-between gap-2'>
          <div className='basis-8/12'>
            <FormField
              control={control}
              name={FormEditCompanyEnum.street}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <FormFields.InputBase
                      {...field}
                      loading={loadingFetch}
                      placeholder={t(messages.updateCustomer.street.placeholder)}
                      disabled={loadingUpdate || disabledFields.street}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className='basis-5/12'>
            <FormField
              control={control}
              name={FormEditCompanyEnum.houseNumber}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <FormFields.InputBase
                      {...field}
                      loading={loadingFetch}
                      placeholder={t(messages.updateCustomer.houseNumber.placeholder)}
                      disabled={loadingUpdate || disabledFields.houseNumber}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <FormField
          control={control}
          name={FormEditCompanyEnum.vat}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormFields.InputBase
                  {...field}
                  loading={loadingFetch}
                  placeholder={t(messages.updateCustomer.vat.placeholder)}
                  disabled={loadingUpdate || disabledFields.vat}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className='flex items-center justify-between gap-2'>
          <div className='basis-5/12'>
            <FormField
              control={control}
              name={FormEditCompanyEnum.phonePrefix}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <FormFields.InputSelect
                      {...field}
                      loading={loadingFetchStaticData}
                      options={phoneCodesWithIcons}
                      hasDisabled={loadingUpdate || disabledFields.phonePrefix}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className='basis-8/12'>
            <FormField
              control={control}
              name={FormEditCompanyEnum.phoneNumber}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <FormFields.InputBase
                      {...field}
                      loading={loadingFetch}
                      placeholder={t(messages.updateCustomer.phoneNumber.placeholder)}
                      disabled={loadingUpdate || disabledFields.phoneNumber}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <FormField
          control={control}
          name={FormEditCompanyEnum.email}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormFields.InputBase
                  {...field}
                  loading={loadingFetch}
                  placeholder={t(messages.updateCustomer.email.placeholder)}
                  disabled={loadingUpdate || disabledFields.email}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name={FormEditCompanyEnum.notes}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormFields.InputBase
                  {...field}
                  loading={loadingFetch}
                  placeholder={t(messages.updateCustomer.notes.placeholder)}
                  disabled={loadingUpdate}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name={FormEditCompanyEnum.businessType}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormFields.InputSelect
                  {...field}
                  placeholder={t(messages.updateCustomer.businessType.placeholder)}
                  options={businessType}
                  loading={loadingFetchStaticData}
                  hasDisabled={loadingUpdate}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name={FormEditCompanyEnum.quantityEmployees}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormFields.InputSelect
                  {...field}
                  placeholder={t(messages.updateCustomer.quantityEmployees.placeholder)}
                  loading={loadingFetchStaticData}
                  options={quantityEmployees}
                  hasDisabled={loadingUpdate}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </FormFields.FormGeneral>
    </Form>
  )
}
