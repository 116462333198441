import { Marker } from '@react-google-maps/api'
import { useCallback, useRef, useState } from 'react'
import usePlacesAutocomplete from 'use-places-autocomplete'

import { CreateTourPointsForm } from 'src/components/common'
import { GoogleMap } from 'src/components/ui'

import { LocationType } from 'src/models'

import { useTourPointsCreate } from 'src/bus/tour'

import { useCurrentLocation } from 'src/hooks'

import { useGoogle } from 'src/contexts'

import { googleMapDefaultOptions } from 'src/utils'

export const TourPointsCreateView = () => {
  const { isLoaded, loadError, panTo, onUnmount } = useGoogle()
  const { onCreateTourPoint, loadingTourPointsCreate } = useTourPointsCreate()
  const { geoLocation, loadingLocation } = useCurrentLocation()

  const mapRef = useRef<google.maps.Map | null>(null)

  const placesAutocomplete = usePlacesAutocomplete({ initOnMount: false })

  const [marker, setMarker] = useState<LocationType | null>(null)

  const panToHandler = useCallback(
    (latLng: LocationType | null) => {
      const { lat, lng } = latLng || {}

      if (lat && lng) {
        panTo({ latLng: { lat, lng } })
        setMarker(() => ({ lat, lng }))
      } else {
        setMarker(null)
      }
    },
    [panTo],
  )

  return (
    <CreateTourPointsForm
      onSubmit={onCreateTourPoint}
      loading={!isLoaded || loadingLocation}
      panTo={panToHandler}
      loadingCreate={loadingTourPointsCreate}
      placesAutocomplete={placesAutocomplete}
      renderMap={
        <GoogleMap
          isLoaded={isLoaded}
          loadError={loadError}
          onLoad={(map) => {
            mapRef.current = map
            placesAutocomplete.init()
          }}
          onUnmount={onUnmount}
          options={{
            center: marker || geoLocation,
            zoom: marker ? 14 : googleMapDefaultOptions.zoom,
          }}
        >
          {marker && <Marker key={`${marker.lat}-${marker.lng}`} position={{ lat: marker.lat, lng: marker.lng }} />}
        </GoogleMap>
      }
    />
  )
}
