import { useMutation } from '@tanstack/react-query'
import { UseMutationResult } from '@tanstack/react-query'

import { CouponsParams, CouponType, HttpErrorType } from 'src/models'

import { couponsService } from 'src/bus/coupons'

import { queryFetcher } from 'src/utils'

type UseCouponsCreateMutationType = {
  onCreateCouponMutation: UseMutationResult<CouponType, HttpErrorType, CouponsParams>
}

export const useCouponsCreateMutation = (): UseCouponsCreateMutationType => {
  const onCreateCouponMutation = useMutation<CouponType, HttpErrorType, CouponsParams>({
    mutationFn: (params) => {
      return queryFetcher({
        name: 'createCoupon',
        fetcher: couponsService.createCoupon,
        fetcherPayload: params,
      })
    },
  })

  return { onCreateCouponMutation }
}
