import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'next-i18next'
import { useForm } from 'react-hook-form'

import { Button, Form, FormControl, FormField, FormFields, FormItem, FormMessage } from 'src/components/ui'

import { FormSubmitFn, StopTourType, FormStopTourEnum } from 'src/models'

import { formShapes } from 'src/bus/forms'

import { messages } from 'src/utils'

type StopTourFormType = {
  loadingCreate?: boolean
  onSubmit: FormSubmitFn<StopTourType>
}

export const StopTourForm = ({ loadingCreate = false, onSubmit }: StopTourFormType) => {
  const { t } = useTranslation()

  const formProps = useForm<StopTourType>({
    defaultValues: { ...formShapes.stopTour.shape },
    resolver: zodResolver(formShapes.stopTour.schema(t)),
    mode: 'onChange',
  })

  const { control, handleSubmit, setError, reset } = formProps

  const onSubmitForm = handleSubmit(async (values) => {
    await onSubmit({ values, acts: { setError, reset } })
  })

  return (
    <Form {...formProps}>
      <FormFields.FormGeneral onSubmit={onSubmitForm}>
        <FormField
          control={control}
          name={FormStopTourEnum.reason}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormFields.InputTextArea
                  {...field}
                  placeholder={t(messages.stopTour.placeholder)}
                  disabled={loadingCreate}
                />
              </FormControl>
              <FormMessage className='absolute' />
            </FormItem>
          )}
        />
        <Button block type='submit' loading={loadingCreate} data-test-id='dialog-stop-tour-btn'>
          {t('buttons:cancel_all_visits')}
        </Button>
      </FormFields.FormGeneral>
    </Form>
  )
}
