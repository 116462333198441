import type { TFunction } from 'i18next'

import { CellSwipe } from 'src/components/common'

import type { SwipeActionType } from 'src/models'

import { getActionsWithoutHidden } from 'src/utils'

type GetTourPointsItemActionsProps = {
  onSelectCell: () => void
  t: TFunction
}

export const getTourPointsItemActions = ({ onSelectCell, t }: GetTourPointsItemActionsProps): SwipeActionType[] => {
  const actions = [
    {
      key: 'delete',
      text: CellSwipe({
        text: t('tour-points:delete'),
        icon: 'Cross',
        type: 'danger',
      }),
      onClick: onSelectCell,
    },
  ]

  return getActionsWithoutHidden(actions)
}
