export enum book {
  home = '/',
  dashboard = '/dashboard',
  forbidden = '/403',
  notFound = '/404',
  serverError = '/500',
  offline = '/_offline',
  signin = '/signin',
  test = '/test',
  profile = '/profile',
  returns = '/returns',
  returnDetailsConfirm = '/confirm',
  claims = '/claims',
  chat = '/chat',
  myKpi = '/my-kpi',
  hr = '/hr',
  settings = '/settings',
  historyAll = '/all',
  customers = '/customers',
  customersEdit = '/edit',
  customersCreate = '/customers/create',
  customersUpdateLocation = '/update-location',
  visits = '/visits',
  visitsHistory = '/visits/history',
  visitsCreate = '/visits/create',
  visitsView = '/view',
  visitPlan = '/plan',
  visitReport = '/report',
  visitActions = '/actions',
  visitActionsDetails = '/details',
  parcels = '/parcels',
  parcelsList = '/parcels/list',
  parcelsDirty = '/parcels/dirty',
  parcelsClear = '/parcels/clear',
  orders = '/orders',
  scanner = '/scanner',
  tourPoints = '/tour-points',
  tourPointsCreate = '/tour-points/create',
  manageTours = '/manage-tours',
}
