import { ReactNode, BaseSyntheticEvent, PropsWithChildren } from 'react'

import { ScrollArea } from 'src/components/ui'

import { cn, ternary } from 'src/utils'

type FormGeneralType = PropsWithChildren<{
  onSubmit?: (evt: BaseSyntheticEvent) => Promise<void>
  footer?: ReactNode | null
  scroll?: boolean
  className?: string
}>

export const FormGeneral = ({ children, onSubmit, footer, scroll = false, className = '' }: FormGeneralType) => {
  return (
    <form className={cn('flex h-full w-full flex-col', scroll && 'overflow-hidden', className)} onSubmit={onSubmit}>
      {ternary(
        scroll,
        <ScrollArea className='h-full'>{children}</ScrollArea>,
        <div className='flex h-full flex-col'>{children}</div>,
      )}
      <div className='w-full'>{footer}</div>
    </form>
  )
}
