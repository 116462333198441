import { AnyZodObject, z, ZodArray, ZodEffects, ZodTypeAny } from 'zod'

export function zodValidateResponse(
  schema: AnyZodObject | ZodArray<ZodTypeAny> | ZodEffects<ZodTypeAny>,
  payload: unknown,
  name?: string,
) {
  try {
    schema.parse(payload)
  } catch (error) {
    if (error instanceof z.ZodError) {
      console.warn(`Zod error => ${name}`, JSON.stringify(error.errors, undefined, 2))
    }
  }
}
