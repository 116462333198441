import { useTranslation } from 'next-i18next'

import {
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  Typography,
  AlertDialogAction,
  Button,
} from 'src/components/ui'

import { useParcelsReject } from 'src/bus/parcels'

export const DialogRejectParcel = ({ id }: { id?: number }) => {
  const { t } = useTranslation()
  const { onRejectParcel, loadingParcelsReject } = useParcelsReject()

  const onConfirm = async () => {
    await onRejectParcel(id)
  }

  return (
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>
          <Typography variant='title' color='gray-10'>
            {t('parcels:modal_parcel_reject.title')}
          </Typography>
        </AlertDialogTitle>
        <AlertDialogDescription>
          <Typography variant='secondary' color='gray-7' className='text-center'>
            {t('parcels:modal_parcel_reject.description')}
          </Typography>
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel variant='outline' className='w-full'>
          {t('buttons:no')}
        </AlertDialogCancel>
        <Button onClick={onConfirm} block asChild variant='cta' loading={loadingParcelsReject}>
          <AlertDialogAction variant='cta'>{t('buttons:yes')}</AlertDialogAction>
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  )
}
