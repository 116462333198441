import { z } from 'zod'

import { OPTION_OBJECT } from 'src/utils'

export const customerIssue = {
  shape: {
    reason: null,
    clarification: null,
    order: null,
    orderProduct: null,
    correctAddress: '',
    brand: '',
    article: '',
    vin: '',
    vinOrPlateNumber: '',
    comment: '',
    images: [],
  },
  schema: () => {
    return z
      .object({
        reason: z.object(OPTION_OBJECT).nullable().default(null),
        clarification: z.object(OPTION_OBJECT).nullable().default(null),
        order: z.object(OPTION_OBJECT).nullable().default(null),
        orderProduct: z.object(OPTION_OBJECT).nullable().default(null),
        brand: z.string().trim(),
        article: z.string().trim(),
        vin: z.string().trim(),
        vinOrPlateNumber: z.string().trim(),
        comment: z.string().trim(),
        images: z.array(z.string()),
      })
      .passthrough()
  },
}
