import { useMutation } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { book } from 'src/init/book'

import { FormSubmitParams, HttpErrorType, AuthLoginType, AuthTokenType } from 'src/models'

import { authKeys, authService, useAuthProfile } from 'src/bus/auth'

import { fillServerErrorsToForm, queryFetcher, removeFromStorage, saveTokensStorage } from 'src/utils'

type AuthLoginCb = (data: FormSubmitParams<AuthLoginType>) => void

type UseAuthLoginType = {
  loadingAuthLogin: boolean
  onLoginAuth: AuthLoginCb
}

export const useAuthLogin = (): UseAuthLoginType => {
  const router = useRouter()
  const { onPrefetchProfile } = useAuthProfile()

  const loginQuery = useMutation<AuthTokenType, HttpErrorType, AuthLoginType>({
    mutationKey: authKeys.login(),
    mutationFn: (params) =>
      queryFetcher({
        name: 'login',
        fetcher: authService.login,
        fetcherPayload: { data: params },
      }),
  })

  const onLogin = useCallback<AuthLoginCb>(
    async (data) => {
      await loginQuery.mutateAsync(data.values, {
        onSuccess: async (params) => {
          saveTokensStorage(params.token)
          await onPrefetchProfile()
          await router.replace(book.dashboard)
        },
        onError: (error) => {
          removeFromStorage()

          const errs = (error as HttpErrorType).errors

          errs && fillServerErrorsToForm(errs, data.acts.setError)
        },
      })
    },
    [loginQuery, router, onPrefetchProfile],
  )

  return {
    loadingAuthLogin: loginQuery.isPending,
    onLoginAuth: onLogin,
  }
}
