import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useState } from 'react'

import { FormMoveParcelsItemsType, FormSubmitParams, HttpErrorType } from 'src/models'

import { parcelsKeys, useParcelsMoveToMutation } from 'src/bus/parcels'

import { useToast } from 'src/hooks'

import { useDialog } from 'src/contexts'

import { GET_ENUMS } from 'src/utils'

type MoveToParcelsCb = (id?: number, data?: FormSubmitParams<FormMoveParcelsItemsType>) => Promise<void>

type UseParcelsMoveToType = {
  loadingParcelsMoveTo: boolean
  onMoveToParcels: MoveToParcelsCb
}

export const useParcelsMoveTo = (): UseParcelsMoveToType => {
  const queryClient = useQueryClient()
  const { onMoveToParcelsMutation } = useParcelsMoveToMutation()
  const showToast = useToast()
  const { setOnPageDialog } = useDialog()

  const [isLoading, setIsLoading] = useState(false)

  const onMoveToParcels = useCallback<MoveToParcelsCb>(
    async (id, data) => {
      try {
        setIsLoading(true)

        const quantity = data?.values.quantity || 1

        await onMoveToParcelsMutation.mutateAsync({
          data: { quantity },
          params: { id: String(id) },
        })

        setOnPageDialog(GET_ENUMS.dialog.moveToArticle, false)
        await queryClient.refetchQueries({ queryKey: parcelsKeys.articles() })
        await queryClient.refetchQueries({ queryKey: parcelsKeys.details() })
        await queryClient.refetchQueries({ queryKey: parcelsKeys.articlesDetails() })
        await queryClient.refetchQueries({ queryKey: parcelsKeys.lists() })
      } catch (error) {
        const errs = (error as HttpErrorType).errors

        if (errs) {
          const [[title, body]] = Object.entries(errs)
          showToast.error({ title, body: body.desc })
        }
      } finally {
        setIsLoading(false)
      }
    },
    [setOnPageDialog, showToast, onMoveToParcelsMutation, queryClient],
  )

  return {
    loadingParcelsMoveTo: isLoading,
    onMoveToParcels,
  }
}
