import { useRouter } from 'next/router'
import QS from 'qs'
import { useCallback, useMemo } from 'react'

import { FilterStaticParamsType, ParcelsTotalType, TRefetchFilterStaticCb } from 'src/models'

import { parcelsKeys, parcelsService } from 'src/bus/parcels'

import { useQueryAsync } from 'src/hooks'

import { getLastQueryPath } from 'src/utils'

type UseParcelsArticlesTotalFetchType = {
  loadingParcelsArticlesTotalFetch: boolean
  parcelsArticlesTotal: ParcelsTotalType | null
  onRefetchParcelsArticlesTotal: TRefetchFilterStaticCb
}

export const useParcelsArticlesTotalFetch = (
  initialFilters: FilterStaticParamsType,
): UseParcelsArticlesTotalFetchType => {
  const router = useRouter()

  const parcelId = Number(router?.query?.id)
  const type = getLastQueryPath(router.pathname)
  const params = useMemo(
    () => ({ ...initialFilters, ...(parcelId ? { parcelId } : { type }) }),
    [parcelId, type, initialFilters],
  )

  const fetchParcelsTotalProps = useQueryAsync<ParcelsTotalType>({
    key: parcelsKeys.articlesTotal(params),
    name: 'fetchReturnsArticlesTotal',
    fetcher: parcelsService.fetchParcelsArticlesTotal,
    fetcherPayload: params,
    options: { enabled: !!parcelId || !!type },
  })

  const onRefetchParcelsArticlesTotal = useCallback<TRefetchFilterStaticCb>(
    async (values) => {
      const newParams = { ...params, ...values?.filterData }
      await router.replace({ pathname: router.pathname, query: QS.stringify(newParams) })
    },
    [router, params],
  )

  return {
    loadingParcelsArticlesTotalFetch: fetchParcelsTotalProps.isLoading,
    parcelsArticlesTotal: fetchParcelsTotalProps.data || null,
    onRefetchParcelsArticlesTotal,
  }
}
