import { useMutation } from '@tanstack/react-query'
import { UseMutationResult } from '@tanstack/react-query'

import { HttpErrorType, TourPointsParams } from 'src/models'

import { tourService } from 'src/bus/tour'

import { queryFetcher } from 'src/utils'

type UseTourPointsUpdateMutationType = {
  onUpdateTourPointMutation: UseMutationResult<void, HttpErrorType, TourPointsParams>
}

export const useTourPointsUpdateMutation = (): UseTourPointsUpdateMutationType => {
  const onUpdateTourPointMutation = useMutation<void, HttpErrorType, TourPointsParams>({
    mutationFn: (params) => {
      return queryFetcher({
        name: 'updateTourPoint',
        fetcher: tourService.updateTourPoints,
        fetcherPayload: params,
      })
    },
  })

  return { onUpdateTourPointMutation }
}
