import { Col, Row } from 'antd'
import { useTranslation } from 'next-i18next'

import { SkeletonList, TourPointsItem } from 'src/components/common'
import { Empty, ScrollArea, Typography } from 'src/components/ui'

import { useTourPointsFetch } from 'src/bus/tour'

import { getHasData, rowGutters, ternary } from 'src/utils'

const tourPointsHeader = [
  { title: 'tour-points:name_of_the_point', span: 15 },
  { title: 'tour-points:type', span: 9 },
]

export const TourPointsList = () => {
  const { t } = useTranslation()
  const { tourPoints, loadingTourPointsFetch } = useTourPointsFetch()

  return (
    <div className='scroll-vertical mt-6'>
      <div className='px-2'>
        <Typography variant='secondary' className='font-semibold'>
          {t('tour-points:my_favorite_points')}
        </Typography>
      </div>
      <div className='mt-2 px-2 py-3'>
        <Row gutter={rowGutters}>
          {tourPointsHeader.map((item) => {
            return (
              <Col key={item.title} span={item.span}>
                <Typography variant='caption' className='font-semibold'>
                  {t(item.title)}
                </Typography>
              </Col>
            )
          })}
        </Row>
      </div>
      <>
        {ternary(
          loadingTourPointsFetch,
          <SkeletonList lineCount={3} type='tour-points' />,
          ternary(
            getHasData(tourPoints.items),
            <ScrollArea>
              {tourPoints.items.map((item) => {
                return <TourPointsItem key={item.id} item={item} />
              })}
            </ScrollArea>,
            <Empty title='common:empty.no_activity' description='common:empty.tour_points_list' />,
          ),
        )}
      </>
    </div>
  )
}
