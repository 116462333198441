import { UpdateCustomerForm } from 'src/components/common'

import { useCustomersDetailsFetch, useCustomersDetailsUpdate, useCustomersStaticDataFetch } from 'src/bus/customers'

export const CustomersEdit = () => {
  const { customersDetails, loadingCustomersDetailsFetch } = useCustomersDetailsFetch()
  const { onUpdateCustomersDetails, loadingCustomersDetailsUpdate } = useCustomersDetailsUpdate()
  const { countries, phoneCodes, businessType, quantityEmployees, loadingCustomerStaticDataFetch } =
    useCustomersStaticDataFetch()

  const disabledFields = {
    title: true,
    index: true,
    country: true,
    city: true,
    street: true,
    houseNumber: true,
    phonePrefix: true,
    phoneNumber: true,
    vat: true,
    email: true,
    contactPersons: true,
  }

  return (
    <div className='flex h-full flex-col py-2' data-test-id='customers-edit'>
      <UpdateCustomerForm
        onSubmit={onUpdateCustomersDetails}
        countries={countries}
        phoneCodes={phoneCodes}
        businessType={businessType}
        quantityEmployees={quantityEmployees}
        loadingFetchStaticData={loadingCustomerStaticDataFetch}
        loadingFetch={loadingCustomersDetailsFetch}
        loadingUpdate={loadingCustomersDetailsUpdate}
        initialValues={customersDetails}
        disabledFields={disabledFields}
      />
    </div>
  )
}
