import { Typography } from 'src/components/ui'

import { ContactPersonPhoneType } from 'src/models'

type CellPhonesTitleProps = {
  title: string
  value?: ContactPersonPhoneType[]
}

export const CellPhonesTitle = ({ title = '', value = [] }: CellPhonesTitleProps) => {
  return value.map((p) => (
    <div key={p.id} className='mb-2'>
      <Typography variant='secondary' color='gray-7'>
        {title}
      </Typography>
    </div>
  ))
}
