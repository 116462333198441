import { Typography } from 'antd'
import { CheckList, Collapse } from 'antd-mobile'
import { CheckListProps } from 'antd-mobile/es/components/check-list'
import { useTranslation } from 'next-i18next'
import { any } from 'ramda'
import { cn } from 'utils/helpers'

import { Icon } from 'src/components/ui'

import { StaticDataItemType } from 'src/models'

import { getHasNoData, getSelectedItemById, prepareFiltersToRequest, snakeCase, ternary } from 'src/utils'

import st from './Styles.module.css'

type InputCheckListProps = CheckListProps & {
  hasDisabled?: boolean
  loadingFetch?: boolean
  hasCollapsed?: boolean
  name: string
  options?: StaticDataItemType[]
  value?: StaticDataItemType[]
  onChange: (values: StaticDataItemType[]) => void
}

export const InputCheckList = ({
  hasDisabled = false,
  hasCollapsed = true,
  multiple = false,
  name,
  onChange,
  value = [],
  options = [],
}: InputCheckListProps) => {
  const { t } = useTranslation()

  const _value = Array.isArray(value) ? value : [value]

  const rootClasses = cn(st['input-check-list'], {
    [st['input-check-list-disabled']]: !hasDisabled,
  })

  const renderCheckList = (
    <CheckList
      activeIcon={<Icon name='Check' color='blue-gray-85' />}
      value={prepareFiltersToRequest(_value || [])}
      onChange={(id) => {
        if (!multiple && id.length === 0) {
          return
        }

        onChange?.(getSelectedItemById(options, id))
      }}
      multiple={multiple}
      className={st['check-list']}
    >
      {options.map((item) => {
        return (
          <CheckList.Item className={st['check-list-item']} key={item?.id} value={String(item?.id)}>
            {item?.label}
          </CheckList.Item>
        )
      })}
    </CheckList>
  )

  const getFilterItemLabel = () => {
    const filteredOptions = options.filter((b) => any((c) => c?.id === b?.id, _value || [])) || []

    if (!filteredOptions.length) {
      return t('default')
    }

    if (filteredOptions.length === 1) {
      return filteredOptions?.[0]?.label
    }

    return `[${filteredOptions.length}]`
  }

  if (getHasNoData(options)) {
    return null
  }

  return (
    <div className={rootClasses}>
      {ternary(
        hasCollapsed,
        <Collapse className={st['collapse-checklist']}>
          <Collapse.Panel
            key={name}
            title={t(`filters_titles.${snakeCase(name)}`)}
            arrow={
              <>
                <Typography.Text className={st['collapse-checklist-checked-name']}>
                  {getFilterItemLabel()}
                </Typography.Text>
                <Icon name='ChevronDown' color='blue-gray-85' />
              </>
            }
          >
            {renderCheckList}
          </Collapse.Panel>
        </Collapse>,
        <div className={st['checklist']}>{renderCheckList}</div>,
      )}
    </div>
  )
}
