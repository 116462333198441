import { Root } from '@radix-ui/react-alert-dialog'
import React from 'react'

import { AlertDialogUi } from 'src/components/ui'

import { orNull } from 'src/utils'

export const AlertDialog = ({ open, onOpenChange, children, ...rest }: React.ComponentPropsWithoutRef<typeof Root>) => {
  return orNull(
    open,
    <AlertDialogUi open={open} onOpenChange={onOpenChange} {...rest}>
      {children}
    </AlertDialogUi>,
  )
}
