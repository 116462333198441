import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { book } from 'src/init/book'

import { FormPlanVisitType, FormSubmitParams, HttpErrorType } from 'src/models'

import { manageToursKeys } from 'src/bus/manageTours'
import { tourKeys } from 'src/bus/tour'
import { useVisitsQuery } from 'src/bus/visits'
import { visitsHistoryKeys } from 'src/bus/visitsHistory'

import { useToast } from 'src/hooks'

import { useDialog } from 'src/contexts'

import { fillServerErrorsToForm, formatDate, GET_ENUMS } from 'src/utils'

type VisitsReplanVisitCb = (data: FormSubmitParams<FormPlanVisitType>) => Promise<void>

type UseVisitsPlanVisitType = {
  onReplanVisit: VisitsReplanVisitCb
  loadingVisitPlan: boolean
}

export const useVisitsPlanVisit = (visitId?: number): UseVisitsPlanVisitType => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const router = useRouter()
  const { setOnPageDialog } = useDialog()
  const showToast = useToast()

  const id = Number(visitId || router.query.id)

  const { onReplanVisit } = useVisitsQuery()

  const onReplanVisitCb = useCallback<VisitsReplanVisitCb>(
    async (data) => {
      const values = {
        date: formatDate(data.values.date, 'dashReversed'),
        comment: data.values.comment,
      }

      onReplanVisit.mutate(
        { params: { id }, data: values },
        {
          onSuccess: async () => {
            showToast.success({ title: t('notifications:visit_replan') })
            setOnPageDialog(GET_ENUMS.dialog.replanVisit, false)

            if (router.query.id) {
              await router.replace(book.visits)
            }
          },
          onError: (error) => {
            const errs = (error as HttpErrorType).errors
            if (errs) {
              fillServerErrorsToForm(errs, data.acts.setError)
            }
          },
          onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey: visitsHistoryKeys.all })
            await queryClient.resetQueries({ queryKey: tourKeys.tourRoutes() })
            await queryClient.resetQueries({ queryKey: manageToursKeys.allPoints() })
            await queryClient.refetchQueries({ queryKey: manageToursKeys.days(router.locale) })
          },
        },
      )
    },
    [onReplanVisit, id, router, setOnPageDialog, showToast, t, queryClient],
  )

  return {
    onReplanVisit: onReplanVisitCb,
    loadingVisitPlan: onReplanVisit.isPending,
  }
}
