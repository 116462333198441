import { useMutation } from '@tanstack/react-query'

import { CustomerParams, CustomerVatVerifyType, HttpErrorType } from 'src/models'

import { customersKeys, customersService } from 'src/bus/customers'

import { useQueryAsync } from 'src/hooks'

import { queryFetcher } from 'src/utils'

export const useCustomerVatQuery = (id?: number) => {
  const vatQuery = useQueryAsync<CustomerVatVerifyType>({
    key: customersKeys.vatInfo(id),
    name: 'fetchCustomersDetails',
    fetcher: customersService.fetchVat,
    fetcherPayload: { customerId: id },
    options: { enabled: !!id },
  })

  const onUpdateVat = useMutation<CustomerVatVerifyType, HttpErrorType, CustomerParams>({
    mutationFn: (params) => {
      return queryFetcher({
        name: 'verifyVat',
        fetcher: customersService.verifyVat,
        fetcherPayload: params,
      })
    },
  })

  return {
    vatQuery,
    onUpdateVat,
  }
}
