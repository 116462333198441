import { useTranslation } from 'next-i18next'

import { CancelVisitForm } from 'src/components/common'
import { DialogContent, DialogHeader, Typography } from 'src/components/ui'

import { useVisitsStaticDataFetch, useVisitsFetch } from 'src/bus/visits'
import { useVisitsHistoryReasonCancelFetch } from 'src/bus/visitsHistory'

import { useDialog } from 'src/contexts'

import { GET_ENUMS } from 'src/utils'

type DialogCancelVisitReasonProps = {
  title?: string
}

export const DialogCancelVisitReason = ({ title }: DialogCancelVisitReasonProps) => {
  const { t } = useTranslation()
  const { setOnPageDialog } = useDialog()
  const { cancelingReasons, loadingCancelingReasonsFetch } = useVisitsStaticDataFetch()
  const { historyReasonCancel, loadingReasonCancelFetch } = useVisitsHistoryReasonCancelFetch()
  const { visitFirst } = useVisitsFetch()

  const companyName = title || visitFirst?.companyName || ''

  const visitTitle = t('visits:reason_cancel_visit.title', { name: companyName })

  return (
    <DialogContent name={GET_ENUMS.dialog.cancelVisitReason}>
      <DialogHeader>
        <Typography variant='title' color='gray-10'>
          {visitTitle}
        </Typography>
      </DialogHeader>
      <CancelVisitForm
        initialValues={historyReasonCancel}
        visitCancelReasons={cancelingReasons}
        loadingFetchStaticData={loadingCancelingReasonsFetch || loadingReasonCancelFetch}
        allDisabled
        onClose={() => setOnPageDialog(GET_ENUMS.dialog.cancelVisitReason, false)}
      />
    </DialogContent>
  )
}
