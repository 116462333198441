import { ImageUploadItem } from 'antd-mobile/es/components/image-uploader'
import { ReactNode } from 'react'
import { FieldError } from 'react-hook-form'

import { LocationType, StaticDataItemType } from './index'

type SetResetFnType = (values?: Record<string, unknown>, options?: Record<string, boolean>) => void
export type SetErrorFnType<T> = (name: keyof T, error: { type: string; message: string }) => void

export type FormActions<T> = {
  setError: SetErrorFnType<T>
  reset: SetResetFnType
}

export type FormSubmitParams<T> = {
  values: T
  acts: FormActions<T>
}

export type FormSubmitFn<T> = (values: FormSubmitParams<T>) => void | Promise<void>

export type FieldMetaStatus = 'error' | 'success' | undefined
export type FieldMetaHelpText = { help?: ReactNode }
export type TouchedFields = {
  [key: string]: TouchedFields | boolean | TouchedFields[]
}
export type ErrorsFields = {
  [key: string]: ErrorsFields | FieldError | ErrorsFields[]
}

export type FieldsMetaType<Shape extends object = object> = {
  [key in keyof Shape]: {
    name: string
    status: FieldMetaStatus
    helpText: FieldMetaHelpText
  }
}

export enum FormSigninEnum {
  login = 'login',
  password = 'password',
}

export enum FormCancelVisitEnum {
  comment = 'comment',
  reason = 'reason',
}

export enum FormCreateFarAwayReportEnum {
  comment = 'comment',
}

export enum FormStopTourEnum {
  reason = 'reason',
}

export enum FormCustomerIssueEnum {
  reason = 'reason',
  clarification = 'clarification',
  order = 'order',
  orderProduct = 'orderProduct',
  correctAddress = 'correctAddress',
  vin = 'vin',
  vinOrPlateNumber = 'vinOrPlateNumber',
  brand = 'brand',
  article = 'article',
  images = 'images',
  comment = 'comment',
}

export enum FormEditNoteEnum {
  notes = 'notes',
}

export enum FormVisitReportEnum {
  businessActions = 'businessActions',
  actions = 'actions',
  comment = 'comment',
}

export enum FormFilterStaticEnum {
  search = 'search',
  claimType = 'claimType',
}

export enum FormReturnSignatureEnum {
  signature = 'signature',
}

export enum FormPlanVisitEnum {
  date = 'date',
  comment = 'comment',
}

export enum FormEditClaimEnum {
  images = 'images',
  cause = 'cause',
  additionalCause = 'additionalCause',
  orderId = 'orderId',
  quantity = 'quantity',
  amount = 'amount',
  comment = 'comment',
}

export enum FormFillDataClaimsEnum {
  cause = 'cause',
  additionalCause = 'additionalCause',
  orderId = 'orderId',
  quantity = 'quantity',
  amount = 'amount',
  comment = 'comment',
}

export enum FormUploadPhotoClaimsEnum {
  images = 'images',
}

export enum FormEditCompanyEnum {
  title = 'title',
  index = 'index',
  street = 'street',
  houseNumber = 'houseNumber',
  country = 'country',
  city = 'city',
  vat = 'vat',
  email = 'email',
  notes = 'notes',
  phonePrefix = 'phonePrefix',
  phoneNumber = 'phoneNumber',
  businessType = 'businessType',
  quantityEmployees = 'quantityEmployees',
  contactPersons = 'contactPersons',
}

export enum FormCreateCompanyEnum {
  title = 'title',
  index = 'index',
  street = 'street',
  house = 'house',
  city = 'city',
  notes = 'notes',
  businessType = 'businessType',
  quantityEmployees = 'quantityEmployees',
  zone = 'zone',
  sector = 'sector',
}

export enum FormVerifyInfoEnum {
  vat = 'vat',
}

export enum FormCreateTourPointsEnum {
  coordinates = 'coordinates',
  label = 'label',
}

export enum FormUpdateTourPointsEnum {
  end = 'end',
  intermediate = 'intermediate',
}

export enum FormUpdateCustomersLocationEnum {
  realLocation = 'realLocation',
}

export enum FormCreateVisitEnum {
  customer = 'customer',
  date = 'date',
  actions = 'actions',
  comment = 'comment',
}

export enum FormContactPersonEnum {
  name = 'name',
  surname = 'surname',
  position = 'position',
  email = 'email',
  phones = 'phones',
}

export enum FormPhoneEnum {
  phonePrefix = 'phonePrefix',
  phoneNumber = 'phoneNumber',
}

export enum FormMoveParcelsItemsEnum {
  quantity = 'quantity',
}

export enum FormDeleteParcelsArticleEnum {
  quantity = 'quantity',
  comment = 'comment',
}

export enum FormTrackNumberEnum {
  shipmentNumber = 'shipmentNumber',
}

export type FormUpdateClaimType = {
  images?: ImageUploadItem[]
  cause: StaticDataItemType | null
  additionalCause: StaticDataItemType | null
  orderId: number | null
  orderProductId: number | null
  quantity: StaticDataItemType | null
  amount: number | null
  comment: string
  status: string
}

export type FormUploadImagesType = {
  images: ImageUploadItem[]
}

export type FormCreateCustomerType = {
  title: string
  city: string
  index: string
  street: string
  house: string
  phonePrefix: StaticDataItemType | null
  phoneNumber: string
  zone: StaticDataItemType | null
  sector: StaticDataItemType | null
  businessType: StaticDataItemType | null
  quantityEmployees: StaticDataItemType | null
  notes: string
}

export type FormReturnSignature = {
  signature: string
}

export type StopTourType = {
  reason: string
}

export type FormUpdateTourPointsType = {
  end: StaticDataItemType | null
  intermediate: StaticDataItemType | null
}

export type FormCreateTourPointsType = {
  coordinates: LocationType | null
  label: string
}

export type FormCreateFarAwayReportType = {
  visitId: number
  distance: number
  employeeLocation: LocationType
  customerLocation: LocationType
  comment: string
}

export type FormUpdateCustomersLocationType = {
  realLocation: LocationType | null
}

export type FormReportVisitType = {
  businessActions: string[]
  actions: string[]
  comment: string
}

export type FormCancelVisitType = {
  reason: string
  comment: string
}

export type FormCancelVisitSubmitType = {
  reason: StaticDataItemType
  comment: string
}

export type FormCreateVisitType = {
  customer: string
  date: string | null
  actions: string[]
  comment: string
}

export type FormCreateVisitSubmitType = {
  customer: StaticDataItemType | null
  date: string | null
  actions: StaticDataItemType[]
  comment: string
}

export type FormCustomerIssueType = {
  reason: StaticDataItemType | null
  clarification: StaticDataItemType | null
  order: StaticDataItemType | null
  orderProduct: StaticDataItemType | null
  correctAddress: string
  vin: string
  vinOrPlateNumber: string
  brand: string
  article: string
  comment: string
  images: string[]
}

export type FormEditNoteType = {
  notes: string
}

export type FormVerifyInfoType = {
  vat: string
}

export type FormPlanVisitType = {
  date: Date
  comment: string
}

export type FormMoveParcelsItemsType = {
  quantity: number
}

export type FormDeleteParcelsArticleType = {
  quantity: number
  comment: string
}

export type FormTrackNumberType = {
  shipmentNumber: string
}
