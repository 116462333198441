import { useRouter } from 'next/router'

import { ActionsListType } from 'src/models'

import { actionsKeys, actionsState, actionsService } from 'src/bus/actions'

import { useQueryAsync } from 'src/hooks'

type UseActionsListFetchType = {
  loadingActionsListFetch: boolean
  actionsList: ActionsListType
}

export const useActionsListFetch = (): UseActionsListFetchType => {
  const router = useRouter()
  const id = Number(router.query.id)
  const params = { visitId: id }

  const fetchActionsListProps = useQueryAsync<ActionsListType>({
    key: actionsKeys.list(id),
    name: 'fetchActionsList',
    fetcher: actionsService.fetchActionsList,
    fetcherPayload: params,
    options: { enabled: !!id },
  })

  return {
    loadingActionsListFetch: fetchActionsListProps.isLoading,
    actionsList: fetchActionsListProps.data || actionsState.actionsList,
  }
}
