import { BaseDataListStringType, BaseDataListType, HttpRequestParams, HttpResponse } from 'src/models'

import { httpAxiosProvider } from 'src/api'

export const otrsOptionsService = Object.freeze({
  clarificationsFetch: ({ params }: HttpRequestParams) => {
    return httpAxiosProvider.get<HttpResponse<BaseDataListType>>('/otrs-options/clarifications', { params })
  },
  fieldsFetch: ({ params }: HttpRequestParams) => {
    return httpAxiosProvider.get<HttpResponse<BaseDataListStringType>>('/otrs-options/fields', { params })
  },
})
