import { useCallback } from 'react'

import { TCurrencyConfig } from 'src/models'

import { useAuthProfile } from 'src/bus/auth'

import { currencyFormat } from 'src/utils'

export const useCurrencyFormat = (options?: Partial<TCurrencyConfig>) => {
  const { profile } = useAuthProfile()

  const _currencyFormat = useCallback(
    (value: number | null = 0, currencyOptions?: Partial<TCurrencyConfig>) => {
      return currencyFormat(value, profile?.country?.value, { ...options, ...currencyOptions })
    },
    [profile?.country?.value, options],
  )

  const _currencyFormatNumber = useCallback(
    (value: number | null = 0, currencyOptions?: Partial<TCurrencyConfig>) => {
      return currencyFormat(value, profile?.country?.value, {
        precision: 2,
        pattern: '#',
        negativePattern: '-#',
        ...options,
        ...currencyOptions,
      })
    },
    [profile?.country?.value, options],
  )

  return { currencyFormat: _currencyFormat, currencyFormatNumber: _currencyFormatNumber }
}
