import { useTranslation } from 'next-i18next'

import { FiltersStaticForm } from 'src/components/common'
import { SheetContent, Typography } from 'src/components/ui'

import { FilterStaticDataType, FilterStaticEnum } from 'src/models'

import { useLanguagesFetch, useSettingsFetch, useLanguageUpdate } from 'src/bus/settings'

import { initialLocale } from 'src/utils'

import st from './Styles.module.css'

export const LanguageFilters = () => {
  const { t } = useTranslation()
  const { settings, loadingSettingsFetch } = useSettingsFetch()
  const { languages, loadingLanguagesFetch } = useLanguagesFetch()
  const { onUpdateLanguage, loadingLanguageUpdate } = useLanguageUpdate()

  const filterStaticItems: FilterStaticDataType = {
    languages: {
      name: FilterStaticEnum.languages,
      options: languages,
    },
  }

  return (
    <SheetContent>
      <div className={st['content']}>
        <div className='p-2'>
          <Typography className='font-semibold'>{t('profile:language')}</Typography>
        </div>
        <FiltersStaticForm
          onSubmit={onUpdateLanguage}
          filterItemsSet={filterStaticItems}
          initialValues={{ languages: settings?.language?.value || initialLocale }}
          buttons={['apply']}
          loadingSubmit={loadingLanguageUpdate}
          loadingStaticDataFetch={loadingLanguagesFetch || loadingSettingsFetch}
        />
      </div>
    </SheetContent>
  )
}
