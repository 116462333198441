export const visitsState = {
  visits: {
    items: [],
    meta: null,
  },
  visitActions: {
    items: [],
    meta: null,
  },
  visitsHistory: {
    pages: [
      {
        items: [],
        meta: null,
      },
    ],
  },
}

export const visitsKeys = {
  all: ['visits'],
  list: () => [...visitsKeys.all, 'list'],
  history: (params: any) => [...visitsKeys.all, 'history', params],
  visit: (id?: number) => [...visitsKeys.all, 'visit', id],
  availableReplanDate: (id?: number) => [...visitsKeys.all, 'available-replan-date', id],
  availableCreateDate: (id?: number) => [...visitsKeys.all, 'available-create-date', id],
  actions: (customerId?: number) => [...visitsKeys.all, 'actions', customerId],
}
