import type { TFunction } from 'i18next'
import { z } from 'zod'

import { messages } from 'src/utils'

export const verifyVatInfo = {
  shape: {
    vat: '',
  },
  schema: (t: TFunction) => {
    return z.object({
      vat: z.string().trim().min(1, t(messages.vat.required)),
    })
  },
}
