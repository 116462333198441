import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { FormCreateVisitSubmitType, FormSubmitParams, HttpErrorType } from 'src/models'

import { manageToursKeys } from 'src/bus/manageTours'
import { tourKeys } from 'src/bus/tour'
import { useVisitsQuery } from 'src/bus/visits'

import { useCurrentLocation, useToast } from 'src/hooks'

import { fillServerErrorsToForm } from 'src/utils'

type CreateVisitCb = (params: FormSubmitParams<FormCreateVisitSubmitType>) => void

type UseVisitsCreateType = {
  loadingVisitsCreate: boolean
  onCreateVisit: CreateVisitCb
}

export const useVisitsCreate = (): UseVisitsCreateType => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { geoLocation } = useCurrentLocation()
  const router = useRouter()
  const showToast = useToast()

  const { onCreateVisit } = useVisitsQuery()

  const onCreateVisitCb = useCallback<CreateVisitCb>(
    (data) => {
      onCreateVisit.mutate(
        { data: data.values },
        {
          onSuccess: () => {
            showToast.success({ title: t('notifications:visit_create_new') })
            setTimeout(() => {
              router.back()
            }, 500)
          },
          onError: (error) => {
            const errs = (error as HttpErrorType).errors
            errs && fillServerErrorsToForm(errs, data.acts.setError)
          },
          onSettled: async () => {
            geoLocation && (await queryClient.refetchQueries({ queryKey: tourKeys.tourRoute(geoLocation) }))
            await queryClient.resetQueries({ queryKey: manageToursKeys.allPoints() })
            await queryClient.refetchQueries({ queryKey: manageToursKeys.days(router.locale) })
          },
        },
      )
    },
    [onCreateVisit, router, showToast, t, geoLocation, queryClient],
  )

  return {
    loadingVisitsCreate: onCreateVisit.isPending,
    onCreateVisit: onCreateVisitCb,
  }
}
