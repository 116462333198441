import { HistoryIssueItem, HistoryStatusItem, HistoryVisitItem, SkeletonList } from 'src/components/common'
import { InfiniteScroll } from 'src/components/ui'

import { HistoryAllType } from 'src/models'

import { useHistoryAllFetch } from 'src/bus/history'

import { getInfiniteListLength, getIsShowDate } from 'src/utils'

export const HistoryAllTabContent = () => {
  const { historyAll, loadingHistoryAllFetch, onFetchMoreHistoryAll, historyAllHasMore } = useHistoryAllFetch()

  const renderItems = () =>
    historyAll.pages.map((page) => {
      return page.items.map((item: HistoryAllType, index) => {
        if ('visitId' in item) {
          return (
            <HistoryVisitItem key={item.id} item={item} index={index} showDate={getIsShowDate(index, historyAll)} />
          )
        }

        if ('ticketId' in item) {
          return (
            <HistoryIssueItem key={item.id} item={item} index={index} showDate={getIsShowDate(index, historyAll)} />
          )
        }

        return <HistoryStatusItem key={item.id} item={item} index={index} showDate={getIsShowDate(index, historyAll)} />
      })
    })

  return (
    <div className='flex h-full flex-col py-2' data-test-id='customers-history'>
      <InfiniteScroll
        hasMore={historyAllHasMore}
        onFetchMore={onFetchMoreHistoryAll}
        dataLength={getInfiniteListLength(historyAll.pages)}
        scrollableTarget='history-all'
        loadingFetch={loadingHistoryAllFetch}
        renderItems={renderItems}
        loader={<SkeletonList type='history' />}
      />
    </div>
  )
}
