import { useTranslation } from 'next-i18next'

import { WrappedNodeDefault } from 'src/components/common'
import { ListItem, SwipeAction, Typography } from 'src/components/ui'

import { TourPointType } from 'src/models'

import { useTourPointsDelete } from 'src/bus/tour'

import { getTourPointsItemActions, ternary } from 'src/utils'

type TourPointsItemProps = {
  item: TourPointType
}

export const TourPointsItem = ({ item }: TourPointsItemProps) => {
  const { t } = useTranslation()
  const { onDeleteTourPoint } = useTourPointsDelete()

  const onSelectCell = () => onDeleteTourPoint(item.id)

  const tourPointsActions = getTourPointsItemActions({ onSelectCell, t })

  const content = (
    <ListItem variant='static'>
      <div className='flex items-center justify-between gap-2'>
        <div className='basis-7.5/12'>
          <Typography variant='secondary'>{WrappedNodeDefault(item?.label)}</Typography>
        </div>
        <div className='basis-4.5/12'>
          <Typography variant='secondary'>{item.type?.label}</Typography>
        </div>
      </div>
    </ListItem>
  )

  return <>{ternary(!item.type, <SwipeAction rightActions={tourPointsActions}>{content}</SwipeAction>, content)}</>
}
