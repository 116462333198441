import type { TFunction } from 'i18next'
import { z } from 'zod'

import { messages } from 'src/utils'

export const deleteParcelsArticle = {
  shape: {
    quantity: 1,
    comment: '',
  },
  schema: (t: TFunction) => {
    return z.object({
      quantity: z.number().gte(1, t(messages.quantity.required)),
      comment: z
        .string()
        .trim()
        .min(
          messages.deleteArticle.comment.min,
          t(messages.deleteArticle.comment.minText, {
            label: t(messages.deleteArticle.comment.placeholder).toLowerCase(),
            count: messages.deleteArticle.comment.min,
          }),
        )
        .max(
          messages.deleteArticle.comment.max,
          t(messages.deleteArticle.comment.maxText, {
            label: t(messages.deleteArticle.comment.placeholder).toLowerCase(),
            count: messages.deleteArticle.comment.max,
          }),
        ),
    })
  },
}
