import { Col, Row } from 'antd'
import { useTranslation } from 'next-i18next'
import { cond, always, equals } from 'ramda'
import { MouseEvent } from 'react'

import { WrappedNodeDefault } from 'src/components/common'
import { Icon, ListItem, SwipeAction, Typography } from 'src/components/ui'

import { ParcelsArticleType } from 'src/models'

import { getParcelsDetailsActions, orNull, rowGutters } from 'src/utils'

type ParcelsDetailsItemProps = {
  item: ParcelsArticleType
  checkCompliance: boolean
  onCheckCompliance: (item: ParcelsArticleType) => void
  onMoveOut: (item: ParcelsArticleType) => void
}

export const ParcelsDetailsItem = ({
  item,
  checkCompliance,
  onCheckCompliance,
  onMoveOut,
}: ParcelsDetailsItemProps) => {
  const { t } = useTranslation()

  const renderContent = (value: string | number | null, span: number) => {
    return (
      <Col span={span}>
        <Typography variant='secondary'>{WrappedNodeDefault(value)}</Typography>
      </Col>
    )
  }

  const onSelectCell = (type: string) => (e: MouseEvent) => {
    e.stopPropagation()

    return cond([[always(equals(type, 'from-parcel')), () => onMoveOut(item)]])()
  }

  const parcelsDetailsActions = getParcelsDetailsActions({
    onSelectCell,
    isAvailableMoveOut: item?.isAvailableMoveOut,
    t,
  })

  return (
    <SwipeAction rightActions={parcelsDetailsActions}>
      <ListItem size='sm' onClick={checkCompliance ? () => onCheckCompliance(item) : () => undefined}>
        <Row align='middle' gutter={rowGutters}>
          {orNull(
            checkCompliance,
            <Col span={3}>
              <Icon name='CompletedFill' color={item?.isChecked ? 'done' : 'blue-gray-4'} />
            </Col>,
          )}
          <Col span={checkCompliance ? 9 : 12}>
            <Typography variant='secondary'>{WrappedNodeDefault(item.article)}</Typography>
            <Typography variant='secondary' color='gray-6'>
              {WrappedNodeDefault(item.title)}
            </Typography>
          </Col>
          {renderContent(item.weight, 3)}
          {renderContent(item.quantity, 3)}
          {renderContent(item.amount, 6)}
        </Row>
      </ListItem>
    </SwipeAction>
  )
}
