import { filter, head, pipe } from 'ramda'
import { useLayoutEffect, useState } from 'react'
import { prepareFilterStaticInitialValues } from 'utils/filters'
import { isNilOrEmpty } from 'utils/ramda-helpers'

import { TagCheckable } from 'src/components/common'
import { Typography } from 'src/components/ui'

import {
  FilterStaticDataType,
  FilterStaticRequestValuesType,
  ManageToursDaysListType,
  ManageToursDayType,
} from 'src/models'

type SelectDaysProps = {
  days: ManageToursDaysListType
  initialValues?: FilterStaticRequestValuesType | null
  filterItemsSet: FilterStaticDataType
  onChange?: (dates: { startDate: Date | null; endDate: Date | null }) => void
  loading?: boolean
}

export const SelectDays = ({
  days,
  initialValues = null,
  filterItemsSet,
  onChange,
  loading = false,
}: SelectDaysProps) => {
  const [selectedDay, setSelectedDay] = useState<string>()

  const onChangeHandler = (item: ManageToursDayType) => {
    if (selectedDay === item.label) {
      setSelectedDay(undefined)
      onChange?.({ startDate: null, endDate: null })
    } else {
      setSelectedDay(item.label)
      onChange?.({ startDate: new Date(item.startDate), endDate: new Date(item.endDate) })
    }
  }

  useLayoutEffect(() => {
    if (isNilOrEmpty(initialValues)) {
      setSelectedDay(undefined)
      return
    }

    const data = initialValues && prepareFilterStaticInitialValues(initialValues, filterItemsSet)
    const dates = data?.startDate && data?.endDate ? [data?.startDate, data?.endDate] : []

    if (dates.length !== 2) {
      setSelectedDay(undefined)
      return
    }

    const selectedItem = pipe(
      filter<ManageToursDayType>((item) =>
        dates.every((d) => new Date(d).toDateString() === new Date(item.startDate).toDateString()),
      ),
      head,
    )(days) as ManageToursDayType | undefined

    setSelectedDay(selectedItem ? selectedItem.label : undefined)
  }, [initialValues, filterItemsSet, selectedDay, days])

  return (
    <>
      {days.map((item) => {
        return (
          <TagCheckable
            key={item.id}
            label={item.label}
            checked={selectedDay === item.label}
            onChange={() => onChangeHandler(item)}
            className='flex gap-0.5'
            disabled={loading}
          >
            <sup>
              <Typography variant='caption' color='blue-8'>
                {item.visitCount}
              </Typography>
            </sup>
          </TagCheckable>
        )
      })}
    </>
  )
}
