import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'next-i18next'
import { ReactNode } from 'react'
import { useForm } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { HookReturn } from 'use-places-autocomplete'

import { Button, Form, FormControl, FormField, FormFields, FormItem, FormMessage } from 'src/components/ui'

import {
  FormSubmitFn,
  FormUpdateCustomersLocationEnum,
  FormUpdateCustomersLocationType,
  LocationType,
} from 'src/models'

import { formShapes } from 'src/bus/forms'

import { messages } from 'src/utils'

type UpdateCustomersLocationFormProps = {
  onSubmit: FormSubmitFn<FormUpdateCustomersLocationType>
  loading: boolean
  loadingCreate?: boolean
  loadingUpdate?: boolean
  initialValues?: FormUpdateCustomersLocationType | null
  renderMap: ReactNode
  isLoaded: boolean
  panTo: (latLng: LocationType | null) => void
  placesAutocomplete: HookReturn
}

export const UpdateCustomersLocationForm = ({
  onSubmit,
  loading,
  loadingCreate = false,
  loadingUpdate = false,
  initialValues = null,
  renderMap,
  panTo,
  isLoaded,
  placesAutocomplete,
}: UpdateCustomersLocationFormProps) => {
  const { t } = useTranslation()

  const formProps = useForm<FormUpdateCustomersLocationType>({
    defaultValues: { ...formShapes.updateCustomersLocation.shape },
    resolver: zodResolver(formShapes.updateCustomersLocation.schema(t)),
  })

  const { control, handleSubmit, setError, reset, setValue, watch } = formProps

  const realLocationValue = watch('realLocation')

  const onSubmitForm = handleSubmit(async (values) => {
    await onSubmit({ values, acts: { setError, reset } })
  })

  useDeepCompareEffect(() => {
    if (initialValues) {
      reset(initialValues)
    }
  }, [initialValues, reset])

  const onChangeSearch = (latLng: LocationType | null) => setValue('realLocation', latLng)

  return (
    <Form {...formProps}>
      <FormFields.FormGeneral
        onSubmit={onSubmitForm}
        footer={
          <div className='p-2'>
            <Button
              block
              type='submit'
              loading={loadingCreate || loadingUpdate}
              disabled={loading || !isLoaded || !realLocationValue}
              data-test-id='create-location-btn'
            >
              {t('buttons:create_real_location_point')}
            </Button>
          </div>
        }
      >
        <div className='absolute left-0 z-10 w-full p-2' data-test-id='customers-update-location'>
          <FormField
            control={control}
            name={FormUpdateCustomersLocationEnum.realLocation}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <FormFields.InputGoogleSearch
                    {...field}
                    {...placesAutocomplete}
                    placeholder={t(messages.locationPoint.placeholder)}
                    onChange={onChangeSearch}
                    panTo={panTo}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className='flex h-full flex-col'>{renderMap}</div>
      </FormFields.FormGeneral>
    </Form>
  )
}
