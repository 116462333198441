import {
  HttpResponse,
  ParcelsType,
  ParcelsArticlesType,
  ParcelsParams,
  ParcelsTotalType,
  ParcelsCheckType,
  ParcelType,
  ParcelsQRCodeType,
  ParcelsReplacesType,
  parcelSchema,
  parcelListSchema,
  parcelTotalSchema,
  parcelArticleListSchema,
  parcelQRCodeSchema,
  parcelCheckSchema,
  parcelReplaceListSchema,
} from 'src/models'

import { zodValidateResponse } from 'src/utils'

import { httpAxiosProvider } from 'src/api'

export const parcelsService = Object.freeze({
  fetchParcels: async ({ params }: ParcelsParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<ParcelsType>>('/parcel', { params })
    zodValidateResponse(parcelListSchema, response.data.data)
    return response
  },
  fetchParcelsDetails: async ({ params }: ParcelsParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<ParcelType>>(`/parcel/${params?.id}`)
    zodValidateResponse(parcelSchema, response.data.data)
    return response
  },
  fetchParcelsTotal: async ({ params }: ParcelsParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<ParcelsTotalType>>('/parcel/total', {
      params,
    })
    zodValidateResponse(parcelTotalSchema, response.data.data)
    return response
  },
  fetchParcelsArticlesTotal: async ({ params }: ParcelsParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<ParcelsTotalType>>('/parcel-article/total', { params })
    zodValidateResponse(parcelTotalSchema, response.data.data)
    return response
  },
  fetchParcelsReplace: async ({ params }: ParcelsParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<ParcelsReplacesType>>('/parcel/for-replace', { params })
    zodValidateResponse(parcelReplaceListSchema, response.data.data)
    return response
  },
  fetchParcelsArticles: async ({ params }: ParcelsParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<ParcelsArticlesType>>('/parcel-article', { params })
    zodValidateResponse(parcelArticleListSchema, response.data.data)
    return response
  },
  checkGenerateParcels: async ({ params }: ParcelsParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<ParcelsCheckType>>('/parcel/generate', { params })
    zodValidateResponse(parcelCheckSchema, response.data.data)
    return response
  },
  fetchQRCode: async ({ params }: ParcelsParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<ParcelsQRCodeType>>(`/parcel/${params?.id}/qr-code`)
    zodValidateResponse(parcelQRCodeSchema, response.data.data)
    return response
  },
  generateParcels: ({ data }: ParcelsParams) => {
    return httpAxiosProvider.post<HttpResponse<void>>('/parcel/generate', data)
  },
  rejectParcels: ({ params }: ParcelsParams) => {
    return httpAxiosProvider.put<HttpResponse<void>>(`/parcel/${params?.id}/reject`)
  },
  restoreParcels: ({ params }: ParcelsParams) => {
    return httpAxiosProvider.put<HttpResponse<void>>(`/parcel/${params?.id}/restore`)
  },
  checkAllToParcels: ({ params }: ParcelsParams) => {
    return httpAxiosProvider.get<HttpResponse<ParcelsCheckType>>('/parcel-article/all-to-parcel', {
      params,
    })
  },
  moveAllToParcels: ({ data }: ParcelsParams) => {
    return httpAxiosProvider.put<HttpResponse<void>>('/parcel-article/all-to-parcel', data)
  },
  moveToParcels: ({ data, params }: ParcelsParams) => {
    return httpAxiosProvider.put<HttpResponse<void>>(`/parcel-article/${params?.id}/to-parcel`, data)
  },
  moveOutParcels: ({ data, params }: ParcelsParams) => {
    return httpAxiosProvider.put<HttpResponse<void>>(`/parcel-article/${params?.id}/move-out`, data)
  },
  replaceParcels: ({ params, data }: ParcelsParams) => {
    return httpAxiosProvider.put<HttpResponse<void>>(`/parcel-article/${params?.id}/replace`, data)
  },
  checkParcels: ({ params }: ParcelsParams) => {
    return httpAxiosProvider.put<HttpResponse<void>>(`/parcel-article/${params?.id}/check`)
  },
  deleteParcels: ({ params, data }: ParcelsParams) => {
    return httpAxiosProvider.put<HttpResponse<void>>(`/parcel-article/${params?.id}/delete`, data)
  },
  recoverParcels: ({ params, data }: ParcelsParams) => {
    return httpAxiosProvider.put<HttpResponse<void>>(`/parcel-article/${params?.id}/restore-deleted`, data)
  },
  resetCheckParcels: ({ params }: ParcelsParams) => {
    return httpAxiosProvider.put(`/parcel/${params?.id}/reset-check`)
  },
  packParcels: ({ params, data }: ParcelsParams) => {
    return httpAxiosProvider.put<HttpResponse<void>>(`/parcel/${params?.id}/pack`, data)
  },
  shipmentParcels: ({ params, data }: ParcelsParams) => {
    return httpAxiosProvider.put<HttpResponse<void>>(`/parcel/${params?.id}/shipment-request`, data)
  },
})
