import { z } from 'zod'

import { HttpRequestParams } from './index'
import { selectListSchema } from './StaticData'

export const actionSchema = z.object({
  code: z.string(),
  context: z.string(),
  executionSteps: z.array(z.string()),
  materials: z.array(z.string()),
  goals: z.array(z.string()),
  reason: z.string(),
  type: z.string(),
})

export const actionListSchema = z.object({
  items: z.array(
    z.object({
      id: z.number(),
      title: z.string(),
    }),
  ),
})

export const actionReportListSchema = z.object({
  items: selectListSchema,
})

export type ActionType = z.infer<typeof actionSchema>
export type ActionsListType = z.infer<typeof actionListSchema>
export type ActionsParams = HttpRequestParams
export type ActionsReportsType = z.infer<typeof actionReportListSchema>
