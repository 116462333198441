import { Marker } from '@react-google-maps/api'
import { useCallback, useState } from 'react'
import usePlacesAutocomplete from 'use-places-autocomplete'
import { googleMapDefaultOptions } from 'utils/constants'

import { UpdateCustomersLocationForm } from 'src/components/common'
import { GoogleMap, Loader } from 'src/components/ui'

import { LocationType } from 'src/models'

import { useCustomersDetailsFetch, useCustomersUpdateLocation } from 'src/bus/customers'

import { useCurrentLocation } from 'src/hooks'

import { useGoogle } from 'src/contexts'

export const CustomersLocationView = () => {
  const { isLoaded, loadError, onLoad, panTo, onUnmount } = useGoogle()
  const { geoLocation, loadingLocation } = useCurrentLocation()
  const { customersDetails, loadingCustomersDetailsFetch } = useCustomersDetailsFetch()
  const { onUpdateCustomersLocation, loadingCustomersPropsUpdate } = useCustomersUpdateLocation()

  const placesAutocomplete = usePlacesAutocomplete({ initOnMount: false })

  const [marker, setMarker] = useState<LocationType | null>(null)

  const panToHandler = useCallback(
    (latLng: LocationType | null) => {
      const { lat, lng } = latLng || {}

      if (lat && lng) {
        panTo({ latLng: { lat, lng }, withZoom: false })
        setMarker(() => ({ lat, lng }))
      } else {
        setMarker(null)
      }
    },
    [panTo],
  )

  const onHandleAddMarker = ({ latLng }: google.maps.MapMouseEvent) => {
    if (latLng?.lng() && latLng?.lat()) {
      panToHandler({ lat: latLng.lat(), lng: latLng.lng() })
    }
  }

  if (!isLoaded || loadingLocation || loadingCustomersDetailsFetch) {
    return <Loader size='large' />
  }

  return (
    <UpdateCustomersLocationForm
      onSubmit={(data) => onUpdateCustomersLocation(data, customersDetails?.id)}
      initialValues={{ realLocation: marker || customersDetails?.realLocation || null }}
      loading={loadingCustomersDetailsFetch}
      loadingCreate={loadingCustomersPropsUpdate}
      loadingUpdate={loadingCustomersPropsUpdate}
      panTo={panToHandler}
      isLoaded={isLoaded}
      placesAutocomplete={placesAutocomplete}
      renderMap={
        <GoogleMap
          onUnmount={onUnmount}
          isLoaded={isLoaded}
          loadError={loadError}
          onLoad={async (map) => {
            placesAutocomplete.init()
            const bounds = await onLoad(map)

            if (customersDetails?.realLocation) {
              panToHandler(customersDetails.realLocation)
              bounds.extend(customersDetails.realLocation)
            } else if (customersDetails?.easyRetailLocation) {
              panToHandler(customersDetails.easyRetailLocation)
              bounds.extend(customersDetails.easyRetailLocation)
            }

            map.fitBounds(bounds)
          }}
          options={{
            center: marker || geoLocation,
            zoom: marker ? 14 : googleMapDefaultOptions.zoom,
          }}
          onClick={onHandleAddMarker}
        >
          <>
            {marker ? (
              <Marker key={`${marker.lat}-${marker.lng}`} position={{ lat: marker.lat, lng: marker.lng }} />
            ) : geoLocation ? (
              <Marker
                key={`${geoLocation.lat}-${geoLocation.lng}`}
                position={{ lat: geoLocation.lat, lng: geoLocation.lng }}
              />
            ) : null}
          </>
        </GoogleMap>
      }
    />
  )
}
