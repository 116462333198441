import type { TFunction } from 'i18next'
import { z } from 'zod'

import { messages } from 'src/utils'

export const stopTour = {
  shape: {
    reason: '',
  },
  schema: (t: TFunction) => {
    return z.object({
      reason: z
        .string()
        .trim()
        .min(1, t(messages.stopTour.required))
        .max(
          messages.stopTour.max,
          t(messages.stopTour.maxText, {
            label: t(messages.stopTour.placeholder).toLowerCase(),
            count: messages.stopTour.max,
          }),
        ),
    })
  },
}
