import { useRouter } from 'next/router'
import QS from 'qs'
import { useCallback, useMemo } from 'react'

import { FilterStaticParamsType, ParcelsTotalType, TRefetchFilterStaticCb } from 'src/models'

import { parcelsKeys, parcelsService } from 'src/bus/parcels'

import { useQueryAsync } from 'src/hooks'

type UseParcelsTotalFetchType = {
  loadingParcelsTotalFetch: boolean
  parcelsTotal: ParcelsTotalType | null
  onRefetchParcelsTotal: TRefetchFilterStaticCb
}

export const useParcelsTotalFetch = (initialFilters: FilterStaticParamsType): UseParcelsTotalFetchType => {
  const router = useRouter()

  const params = useMemo(() => ({ ...initialFilters }), [initialFilters])

  const fetchParcelsTotalProps = useQueryAsync<ParcelsTotalType>({
    key: parcelsKeys.total(params),
    name: 'fetchReturnsTotal',
    fetcher: parcelsService.fetchParcelsTotal,
    fetcherPayload: params,
  })

  const onRefetchParcelsTotal = useCallback<TRefetchFilterStaticCb>(
    async (values) => {
      const newParams = { ...params, ...values?.filterData }
      await router.replace({ pathname: router.pathname, query: QS.stringify(newParams) })
    },
    [router, params],
  )

  return {
    loadingParcelsTotalFetch: fetchParcelsTotalProps.isLoading,
    parcelsTotal: fetchParcelsTotalProps.data || null,
    onRefetchParcelsTotal,
  }
}
