import { useTranslation } from 'next-i18next'
import { ReactNode } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { WrappedNodeDefault } from 'src/components/common'
import { Button, Icon, Skeleton, Typography } from 'src/components/ui'

import { useToast } from 'src/hooks'

import { cn, orNull, ternary } from 'src/utils'

type DefaultCellCopyProps = {
  loadingFetch?: boolean
  copiedText?: string
  copyValue?: string
  className?: string
}

type CellCopyChildrenProps = DefaultCellCopyProps & {
  children?: ReactNode
  value?: never
  disabled?: never
}

type CellCopyValueProps = DefaultCellCopyProps & {
  children?: never
  disabled?: boolean
  value?: string | number | null
  className?: string
}

type CellCopyProps = CellCopyChildrenProps | CellCopyValueProps

export const CellCopy = ({
  value,
  copyValue = '',
  copiedText = '',
  loadingFetch = false,
  disabled = false,
  children,
  className = '',
}: CellCopyProps) => {
  const { t } = useTranslation()
  const showToast = useToast()

  const onCopy = (_: any, result: boolean) => {
    if (!result || !copiedText || disabled) {
      return
    }

    showToast.success({ title: t(`notifications:${copiedText}`) })
  }

  const cls = cn('flex justify-between items-center bg-gray-2 rounded-sm p-2', className)

  return (
    <>
      {ternary(
        loadingFetch,
        <Skeleton className='h-[38px]' />,
        <CopyToClipboard text={copyValue} onCopy={onCopy}>
          {ternary(
            children,
            children,
            <Button variant='ghost' block disabled={disabled || loadingFetch} className={cls}>
              <Typography variant='secondary' truncate='line'>
                {WrappedNodeDefault(value)}
              </Typography>
              {orNull(!disabled, <Icon name='Copy' color='blue-gray-7' />)}
            </Button>,
          )}
        </CopyToClipboard>,
      )}
    </>
  )
}
