import { ReactNode } from 'react'
import { orNull } from 'utils/ramda-helpers'

import { Typography } from 'src/components/ui'

type HistoryItemRowProps = {
  label: string
  description: ReactNode
  leftSize?: string
  show?: boolean
}

export const HistoryItemRow = ({ leftSize = 'basis-4/12', label, description, show = true }: HistoryItemRowProps) => {
  return orNull(
    show,
    <div className='flex items-center justify-between gap-2 border-b border-b-gray-1 py-2'>
      <div className={leftSize}>
        <Typography variant='secondary' color='gray-7'>
          {label}
        </Typography>
      </div>
      <div className='basis-8/12'>
        <Typography variant='secondary'>{description}</Typography>
      </div>
    </div>,
  )
}
