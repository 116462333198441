import { useTranslation } from 'next-i18next'
import { Control } from 'react-hook-form'

import { FormControl, FormField, FormFields, FormItem, FormMessage, Typography } from 'src/components/ui'

type GroupDateRangePickerProps = {
  loadingFetch: boolean
  control: Control<any>
  title: string
}

export const GroupDateRangePicker = ({ loadingFetch, control, title }: GroupDateRangePickerProps) => {
  const { t } = useTranslation()

  return (
    <div className='mt-3'>
      <div className='mb-2'>
        <Typography className='font-semibold'>{t(title)}</Typography>
      </div>
      <FormField
        control={control}
        name='startDate'
        render={({ field }) => (
          <FormItem gutter='none'>
            <FormControl>
              <FormFields.InputDatePicker
                {...field}
                placeholder={t('start_date')}
                loading={loadingFetch}
                allowClear={false}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name='endDate'
        render={({ field }) => (
          <FormItem gutter='none'>
            <FormControl>
              <FormFields.InputDatePicker
                {...field}
                placeholder={t('end_date')}
                loading={loadingFetch}
                allowClear={false}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  )
}
