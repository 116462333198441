import { AutoComplete as AntdAutoComplete, AutoCompleteProps } from 'antd'
import { isEmpty } from 'ramda'
import type { BaseSelectRef } from 'rc-select'
import { forwardRef } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { Empty, Icon, Loader } from 'src/components/ui'

import { DefaultAutocompleteOptionType } from 'src/models'

import st from './Styles.module.css'

const DEBOUNCE_LATENCY = 500
const NotFoundContent = ({ loading }: { loading: boolean }) => {
  return loading ? (
    <div className='py-2'>
      <Loader type='relative' />
    </div>
  ) : (
    <Empty title='fields:default_placeholders.no_options' />
  )
}

export type InputAutoCompletePropsType = AutoCompleteProps<string, DefaultAutocompleteOptionType> & {
  loading?: boolean
  delay?: number
  withNoData?: boolean
}

export const InputAutoComplete = forwardRef<BaseSelectRef, InputAutoCompletePropsType>(
  (
    { loading = false, withNoData = false, delay = DEBOUNCE_LATENCY, options = [], onSearch, disabled, ...rest },
    ref,
  ) => {
    const noDataJSX = withNoData ? <NotFoundContent loading={loading} /> : null

    const hasDisabled = disabled || loading

    const onSearchDebounced = useDebouncedCallback((value: string) => {
      const search = value.trim()

      if (isEmpty(search)) {
        return
      }

      onSearch?.(search)
    }, delay)

    return (
      <div className='relative'>
        <AntdAutoComplete
          {...rest}
          disabled={disabled}
          rootClassName={st['autocomplete']}
          onSearch={onSearchDebounced}
          notFoundContent={noDataJSX}
          ref={ref}
          options={options}
        />
        <div className=' absolute right-3 top-0 flex h-10 w-6 items-center justify-center'>
          <Icon name='Search' color={hasDisabled ? 'gray-7' : 'gray-5'} />
        </div>
      </div>
    )
  },
)

InputAutoComplete.displayName = 'InputAutoComplete'
