import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { HttpErrorType } from 'src/models'

import { parcelsKeys, useParcelsResetCheckMutation } from 'src/bus/parcels'

import { useToast } from 'src/hooks'

type ResetCheckParcelsCb = (id: number) => void

type UseParcelsResetCheckType = {
  loadingParcelsResetCheck: boolean
  onResetCheckParcels: ResetCheckParcelsCb
}

export const useParcelsResetCheck = (): UseParcelsResetCheckType => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const { onResetCheckParcelsMutation } = useParcelsResetCheckMutation()
  const showToast = useToast()

  const parcelId = Number(router.query.id)

  const onResetCheckParcels = useCallback<ResetCheckParcelsCb>(
    async (id) => {
      try {
        await onResetCheckParcelsMutation.mutateAsync({ params: { id: String(id) } })
        await queryClient.invalidateQueries({ queryKey: parcelsKeys.articles() })
        await queryClient.invalidateQueries({ queryKey: parcelsKeys.articlesDetails() })
        await queryClient.invalidateQueries({ queryKey: parcelsKeys.detail(parcelId) })
      } catch (error) {
        const errs = (error as HttpErrorType).errors
        if (errs) {
          const [[title, body]] = Object.entries(errs)
          showToast.error({ title, body: body.desc })
        }
      }
    },
    [onResetCheckParcelsMutation, showToast, parcelId, queryClient],
  )

  return {
    loadingParcelsResetCheck: onResetCheckParcelsMutation.isPending,
    onResetCheckParcels,
  }
}
