import { StaticDataItemsType } from 'src/models'

import { useVisitsHistoryStaticDataFetch } from 'src/bus/visitsHistory'

type UseVisitsFiltersType = {
  loadingVisitsFiltersFetch: boolean
  visitsFilters: StaticDataItemsType
}

export const useVisitsFilters = (): UseVisitsFiltersType => {
  const { customers, sectors, customerStatus, statuses, loadingVisitsStaticDataFetch } =
    useVisitsHistoryStaticDataFetch()

  const filters = { statuses, sectors, customers, customerStatus }

  return {
    visitsFilters: filters,
    loadingVisitsFiltersFetch: loadingVisitsStaticDataFetch,
  }
}
