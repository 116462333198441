import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { useCallback, useMemo, useState } from 'react'

import { book } from 'src/init/book'

import { HttpErrorType, ReturnsCreateParams, ReturnType } from 'src/models'

import { useCustomersDetailsFetch } from 'src/bus/customers'
import { returnsKeys, returnsService } from 'src/bus/returns'

import { useMutationAsync, useToast } from 'src/hooks'

type UseReturnsCreateType = {
  loadingReturnsCreate: boolean
  onCreateReturn: () => Promise<void>
}

export const useReturnsCreate = (): UseReturnsCreateType => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const showToast = useToast()
  const { customerId } = useCustomersDetailsFetch()

  const [isLoading, setIsLoading] = useState(false)

  const params = useMemo(() => ({ customerId }), [customerId])

  const createReturnProps = useMutationAsync<ReturnType, ReturnsCreateParams>({
    key: returnsKeys.list(params),
    name: 'createReturn',
    fetcher: returnsService.createReturn,
  })

  const onCreateReturn = useCallback(async () => {
    try {
      setIsLoading(true)
      const response = await createReturnProps.mutateAsync(params)

      await queryClient.invalidateQueries({ queryKey: returnsKeys.lists() })
      await router.push(`${book.returns}/${response.id}`)
      await queryClient.invalidateQueries({ queryKey: returnsKeys.details() })
      await queryClient.invalidateQueries({ queryKey: returnsKeys.totals() })
    } catch (error) {
      const err = error as HttpErrorType

      if (err.errors) {
        const [[title, body]] = Object.entries(err.errors || {})
        showToast.error({ title, body: body.desc })
      } else {
        showToast.error({ title: err.type, body: err.message })
      }
    } finally {
      setIsLoading(false)
    }
  }, [createReturnProps, router, params, showToast, queryClient])

  return {
    loadingReturnsCreate: isLoading,
    onCreateReturn,
  }
}
