import { useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { FilterStaticType, FormSubmitParams, HttpErrorType } from 'src/models'

import { settingsKeys, useSettingsUpdateMutation } from 'src/bus/settings'
import { staticDataKeys } from 'src/bus/staticData'

import { useToast } from 'src/hooks'

import { useDrawer } from 'src/contexts'

import { GET_ENUMS, isNotNilOrNotEmpty } from 'src/utils'

type LanguageUpdateCbType = (data: FormSubmitParams<FilterStaticType>) => Promise<void>
type UseLanguageUpdateType = {
  loadingLanguageUpdate: boolean
  onUpdateLanguage: LanguageUpdateCbType
}

export const useLanguageUpdate = (): UseLanguageUpdateType => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const showToast = useToast()
  const { setOnPageDrawer } = useDrawer()
  const { onUpdateSettingsMutation } = useSettingsUpdateMutation()

  const onUpdateLanguage = useCallback<LanguageUpdateCbType>(
    async (data) => {
      try {
        const language = data.values.languages?.[0]

        const values = { data: { language } }

        await onUpdateSettingsMutation.mutateAsync(values)
        setOnPageDrawer(GET_ENUMS.drawer.language, false)
        dayjs.locale(language?.value)
        await queryClient.invalidateQueries({ queryKey: settingsKeys.settings() })
        await queryClient.refetchQueries({ queryKey: staticDataKeys.all })
        await router.replace(router.asPath, undefined, { locale: language?.value })
      } catch (error) {
        const errs = (error as HttpErrorType).errors
        if (errs) {
          if (isNotNilOrNotEmpty(errs)) {
            const [[title, body]] = Object.entries(errs)
            showToast.error({ title, body: body.desc })
          }
        }
      }
    },
    [onUpdateSettingsMutation, showToast, setOnPageDrawer, router, queryClient],
  )

  return {
    loadingLanguageUpdate: onUpdateSettingsMutation.isPending,
    onUpdateLanguage,
  }
}
