import { ReactNode } from 'react'
import { cn } from 'utils/helpers'

import { Typography } from 'src/components/ui'

type TagCheckableProps = {
  checked?: boolean
  disabled?: boolean
  onChange: () => void
  label: string
  children?: ReactNode
  className?: string
}

export const TagCheckable = ({
  children,
  checked = false,
  onChange,
  label,
  className = '',
  disabled = false,
}: TagCheckableProps) => {
  return (
    <div
      className={cn(
        `flex flex-none rounded-3xl border px-3 py-1 shadow-level-1 transition-colors ${disabled && 'border-disabled-bg bg-disabled-bg'} ${
          checked ? 'bg-blue-2' + ' border-blue-8' : 'border-white bg-white'
        }`,
        className,
      )}
      onClick={onChange}
    >
      <Typography variant='secondary'>{label}</Typography>
      {children}
    </div>
  )
}
