import { BaseDataListType, ConvertCodeParamsType, ConvertCodeType, HttpResponse } from 'src/models'

import { httpAxiosProvider } from 'src/api'

export const userOptionsService = Object.freeze({
  sectorsFetch: () => {
    return httpAxiosProvider.get<HttpResponse<BaseDataListType>>('/employee-options/sectors')
  },
  zonesFetch: () => {
    return httpAxiosProvider.get<HttpResponse<BaseDataListType>>('/employee-options/zones')
  },
  convertCode: (data: ConvertCodeParamsType) => {
    return httpAxiosProvider.get<HttpResponse<ConvertCodeType>>('/convert-code', data)
  },
})
