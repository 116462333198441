import type { TFunction } from 'i18next'

import { CellSwipe } from 'src/components/common'

import type { ParcelsArticleType, SwipeActionType } from 'src/models'

import { getActionsWithoutHidden } from 'src/utils'

type GetParcelsArticlesActionsProps = {
  onSelectCell: (type: string) => void
  article: ParcelsArticleType
  t: TFunction
}

export const getParcelsArticlesRightActions = ({
  onSelectCell,
  article,
  t,
}: GetParcelsArticlesActionsProps): SwipeActionType[] => {
  const actions = [
    {
      key: 'replace',
      text: CellSwipe({
        text: t('buttons:replace'),
        icon: 'Swap',
        type: 'warning',
      }),
      isHidden: !article.isAvailableReplace,
      onClick: () => onSelectCell('replace'),
    },
    {
      key: 'to-parcel',
      text: CellSwipe({
        text: t('buttons:to_parcel'),
        icon: 'BoxPutIn',
        type: 'info',
      }),
      isHidden: !article.isAvailableMoveToParcel,
      onClick: () => onSelectCell('toParcel'),
    },
  ]

  return getActionsWithoutHidden(actions)
}
