import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'next-i18next'
import { useForm } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { Button, Form, FormControl, FormField, FormFields, FormItem, FormMessage } from 'src/components/ui'

import { FormSubmitFn, FormEditNoteType, FormEditNoteEnum } from 'src/models'

import { formShapes } from 'src/bus/forms'

import { messages } from 'src/utils'

type EditNoteFormType = {
  loadingCreate: boolean
  loadingFetch: boolean
  onSubmit: FormSubmitFn<FormEditNoteType>
  initialValues?: FormEditNoteType
}

export const EditNoteForm = ({ loadingCreate, loadingFetch, initialValues, onSubmit }: EditNoteFormType) => {
  const { t } = useTranslation()

  const formProps = useForm<FormEditNoteType>({
    defaultValues: { ...formShapes.editNote.shape },
    resolver: zodResolver(formShapes.editNote.schema(t)),
    mode: 'onChange',
  })

  const { control, handleSubmit, setError, reset } = formProps

  const onSubmitForm = handleSubmit(async (values) => {
    await onSubmit({ values, acts: { setError, reset } })
  })

  useDeepCompareEffect(() => {
    if (initialValues) {
      reset(initialValues)
    }
  }, [initialValues, reset])

  return (
    <Form {...formProps}>
      <FormFields.FormGeneral onSubmit={onSubmitForm}>
        <FormField
          control={control}
          name={FormEditNoteEnum.notes}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormFields.InputTextArea
                  {...field}
                  placeholder={t(messages.note.placeholder)}
                  disabled={loadingFetch}
                />
              </FormControl>
              <FormMessage className='absolute' />
            </FormItem>
          )}
        />
        <Button block type='submit' loading={loadingCreate} disabled={loadingFetch} data-test-id='edit-note-dialog-btn'>
          {t('buttons:save')}
        </Button>
      </FormFields.FormGeneral>
    </Form>
  )
}
