import { useQueryClient } from '@tanstack/react-query'
import toFormData from 'json-form-data'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback, useState } from 'react'

import { FormSubmitParams, FormUpdateClaimType, HttpErrorType } from 'src/models'

import { claimsKeys, useClaimsUpdateMutation } from 'src/bus/claims'
import { useCustomersDetailsFetch } from 'src/bus/customers'
import { returnsKeys } from 'src/bus/returns'

import { useToast } from 'src/hooks'

import { fillServerErrorsToForm, getConvertedObjToJson, getConvertedUploadImgToObj } from 'src/utils'

type ClaimsUpdateCbType = (data: FormSubmitParams<FormUpdateClaimType>) => Promise<void>

type UseClaimsUpdateType = {
  loadingClaimsUpdate: boolean
  onUpdateClaim: ClaimsUpdateCbType
}

export const useClaimsUpdate = (onNextStep?: () => void): UseClaimsUpdateType => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const { t } = useTranslation()
  const showToast = useToast()
  const { onUpdateClaimMutation } = useClaimsUpdateMutation()
  const { customerId } = useCustomersDetailsFetch()

  const [isLoading, setIsLoading] = useState(false)

  const id = Number(router.query.id)
  const params = { ...(id && { id }), ...(customerId && { customerId }) }

  const queryKey = claimsKeys.detail(params)

  const onUpdateClaim = useCallback<ClaimsUpdateCbType>(
    async (data) => {
      try {
        setIsLoading(true)

        const values = toFormData({
          customerId,
          ...getConvertedObjToJson(data.values),
          ...getConvertedUploadImgToObj(data.values.images || []),
        })

        const isConfirmed = data.values.status === 'confirmed'
        const toastMessage = isConfirmed ? 'return_approve' : 'return_cancel'

        await onUpdateClaimMutation.mutateAsync({ data: values, params: { id: String(id) } })

        await queryClient.invalidateQueries({ queryKey: returnsKeys.all })
        await queryClient.invalidateQueries({ queryKey: claimsKeys.all })
        await queryClient.refetchQueries({ queryKey })
        await queryClient.refetchQueries({ queryKey: claimsKeys.lists() })
        isConfirmed ? onNextStep?.() : router.back()
        showToast.success({ title: t(`notifications:${toastMessage}`) })
      } catch (error) {
        const errs = (error as HttpErrorType).errors
        errs && fillServerErrorsToForm(errs, data.acts.setError)
      } finally {
        setIsLoading(false)
      }
    },
    [customerId, queryKey, showToast, t, onUpdateClaimMutation, onNextStep, id, router, queryClient],
  )

  return {
    loadingClaimsUpdate: isLoading,
    onUpdateClaim,
  }
}
