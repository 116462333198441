import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { cond, equals } from 'ramda'
import { Draggable } from 'react-beautiful-dnd'

import { book } from 'src/init/book'

import { StatusItem } from 'src/components/common'
import { ListItem, SwipeAction, Typography } from 'src/components/ui'

import { VisitType } from 'src/models'

import { useDialog } from 'src/contexts'

import { getVisitItemRightActions, getVisitItemLeftActions, GET_ENUMS } from 'src/utils'

type VisitListItemProps = {
  item: VisitType
  index: number
  onGetDialogParams: ({ id, title }: { id: number; title: string }) => void
}

export const VisitListItem = ({ item, index, onGetDialogParams }: VisitListItemProps) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { setOnPageDialog } = useDialog()

  const onSelectCell = (type: string) => () =>
    cond([
      [
        equals('visit-plan'),
        () =>
          router.push({
            pathname: `${book.visits}/${item.id}${book.visitActions}`,
            query: { customerId: item.customerId },
          }),
      ],
      [equals('customer-details'), () => router.push(`${book.customers}/${item.customerId}`)],
      [
        equals('cancel-visit'),
        () => {
          onGetDialogParams({ id: item.id, title: item.companyName })
          setOnPageDialog(GET_ENUMS.dialog.cancelVisit, true)
        },
      ],
      [
        equals('replan-visit'),
        () => {
          onGetDialogParams({ id: item.id, title: item.companyName })
          setOnPageDialog(GET_ENUMS.dialog.replanVisit, true)
        },
      ],
    ])(type)

  const visitRightActions = getVisitItemRightActions({ onSelectCell, t })
  const visitLeftActions = getVisitItemLeftActions({ onSelectCell, t })

  return (
    <Draggable draggableId={String(item.id)} index={index} shouldRespectForcePress>
      {(provided) => {
        return (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className='mb-2'>
            <SwipeAction rightActions={visitRightActions} leftActions={visitLeftActions} className='rounded-md'>
              <ListItem size='lg' asChild className='rounded-md'>
                <div className='flex h-full flex-col p-0'>
                  <div className='flex items-center gap-2'>
                    <div className='grid basis-1/12'>
                      <Typography variant='secondary' color='gray-7'>
                        {index + 1}
                      </Typography>
                    </div>
                    <div className='grid basis-6/12'>
                      <Typography truncate='line' className='font-semibold'>
                        {item.companyName}
                      </Typography>
                      <Typography variant='caption' color='gray-7' truncate='line'>
                        {item?.companyTitle}
                      </Typography>
                    </div>
                    <div className='grid basis-2.5/12 justify-center'>
                      <StatusItem status={item.status} />
                    </div>
                    <div className='grid basis-2.5/12'>
                      <Typography className='text-center'>{item.sector}</Typography>
                    </div>
                  </div>
                </div>
              </ListItem>
            </SwipeAction>
          </div>
        )
      }}
    </Draggable>
  )
}
