import type { TFunction } from 'i18next'
import { z } from 'zod'

import { messages } from 'src/utils'

export const createTourPoints = {
  shape: {
    coordinates: null,
    label: '',
  },
  schema: (t: TFunction) => {
    return z.object({
      label: z.string().trim().min(1, t(messages.tourPointName.required)),
      coordinates: z.object(
        {
          lat: z.number(),
          lng: z.number(),
        },
        { invalid_type_error: t(messages.addressWaypoint.required) },
      ),
    })
  },
}
