import { create } from 'zustand'

import { ScannerState } from 'src/models'

export const userOptionsKeys = {
  all: ['user-options'],
  sectors: () => [...userOptionsKeys.all, 'sectors'],
  zones: () => [...userOptionsKeys.all, 'zones'],
  convertedCode: () => [...userOptionsKeys.all, 'convert-code'],
}

export const useScannerCode = create<ScannerState>((set) => ({
  code: '',
  updateCode: (code: string) => set(() => ({ code })),
}))
