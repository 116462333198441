import {
  HttpResponse,
  VisitsType,
  VisitType,
  VisitParamsType,
  VisitAvailableDateType,
  VisitActionsType,
  visitSchemaList,
  visitSchema,
} from 'src/models'

import { zodValidateResponse } from 'src/utils'

import { httpAxiosProvider } from 'src/api'

export const visitsService = Object.freeze({
  fetchVisits: async () => {
    const response = await httpAxiosProvider.get<HttpResponse<VisitsType>>('/visit')
    zodValidateResponse(visitSchemaList, response.data.data)
    return response
  },
  fetchVisitDetails: async ({ params }: VisitParamsType) => {
    const response = await httpAxiosProvider.get<HttpResponse<VisitType>>(`/visit/${params?.id}`)
    zodValidateResponse(visitSchema, response.data.data)
    return response
  },
  cancelVisit: ({ params, data }: VisitParamsType) => {
    return httpAxiosProvider.put<HttpResponse<void>>(`/visit/${params?.id}/cancel`, data)
  },
  createVisitReport: ({ params, data }: VisitParamsType) => {
    return httpAxiosProvider.post<HttpResponse<void>>(`/visit/${params?.id}/report`, data)
  },
  replanVisit: ({ params, data }: VisitParamsType) => {
    return httpAxiosProvider.put<HttpResponse<void>>(`/visit/${params?.id}/re-plan`, data)
  },
  fetchAvailableDate: ({ params }: VisitParamsType) => {
    if (params?.id) {
      return httpAxiosProvider.get<HttpResponse<VisitAvailableDateType>>(`/visit/${params.id}/re-plan-date`)
    }

    return httpAxiosProvider.get<HttpResponse<VisitAvailableDateType>>('/visit-date', { params })
  },

  fetchVisitsActions: ({ params }: VisitParamsType) => {
    return httpAxiosProvider.get<HttpResponse<VisitActionsType>>('/action-options/manual', {
      params,
    })
  },
  createVisit: ({ data }: VisitParamsType) => {
    return httpAxiosProvider.post<HttpResponse<void>>('/visit', data)
  },
  updateVisitsOrder: ({ data }: VisitParamsType) => {
    return httpAxiosProvider.put<HttpResponse<void>>('/visit-order', data)
  },
  createVisitFarAwayReport: ({ params, data }: VisitParamsType) => {
    return httpAxiosProvider.post<HttpResponse<void>>('/visit/far-away-report', data, { params })
  },
})
