import { StaticDataItemType, StaticDataKeys } from 'src/models'

import { useStaticDataFetch } from 'src/bus/staticData'

type UseLanguagesFetchType = {
  loadingLanguagesFetch: boolean
  languages: StaticDataItemType[]
}

export const useLanguagesFetch = (): UseLanguagesFetchType => {
  const staticDataKeys = [StaticDataKeys.languages]

  const { staticData, loadingStaticDataFetch } = useStaticDataFetch(staticDataKeys)

  return {
    loadingLanguagesFetch: loadingStaticDataFetch,
    languages: staticData?.languages || [],
  }
}
