import NextImage from 'next/image'
import { cn } from 'utils/helpers'

import st from './Styles.module.css'

const logo = '/assets/images/Logo.svg'

type LogoProps = {
  src?: string
  width?: number
  height?: number
  alt?: string
  children?: never
}

export const Logo = ({ src = logo, alt = 'Autodoc Pro CRM', width = 176, height = 44, ...rest }: LogoProps) => {
  const logoStyle = cn(st['logo'])

  return (
    <div className={logoStyle}>
      <NextImage {...rest} src={src} alt={alt} unoptimized priority width={width} height={height} />
    </div>
  )
}
