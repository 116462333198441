import { StaticDataItemType, StaticDataKeys } from 'src/models'

import { useStaticDataFetch } from 'src/bus/staticData'

type UseParcelsStaticDataFetchType = {
  parcelsType: StaticDataItemType[]
  parcelsStatus: StaticDataItemType[]
  parcelArticleStatus: StaticDataItemType[]
  loadingParcelsStaticDataFetch: boolean
}

export const useParcelsStaticDataFetch = (): UseParcelsStaticDataFetchType => {
  const staticDataKeys = [StaticDataKeys.parcelStatus, StaticDataKeys.parcelType, StaticDataKeys.parcelArticleStatus]

  const { staticData, loadingStaticDataFetch } = useStaticDataFetch(staticDataKeys)

  const data = {
    parcelsStatus: staticData?.['parcel-status'] || [],
    parcelsType: staticData?.['parcel-type'] || [],
    parcelArticleStatus: staticData?.['parcel-article-status'] || [],
  }

  return {
    loadingParcelsStaticDataFetch: loadingStaticDataFetch,
    ...data,
  }
}
