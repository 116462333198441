import { useCallback, useEffect, useState } from 'react'

enum Orientation {
  portrait = 'portrait',
  landscape = 'landscape',
}

export const useOrientation = () => {
  const isSSR = typeof window !== 'undefined'

  const [state, setState] = useState(Orientation.portrait)

  const onOrientationChange = useCallback(() => {
    let orientation = Orientation.portrait

    if (isSSR && window.orientation) {
      const angle = window.orientation
      orientation = Math.abs(Number(angle)) === 90 ? Orientation.landscape : Orientation.portrait
    }

    if (isSSR && window.screen.orientation) {
      orientation = window.screen.orientation.type.split('-')?.[0] as Orientation
    }

    setState(orientation)
  }, [isSSR])

  useEffect(() => {
    onOrientationChange()

    if (isSSR && window.screen.orientation && 'onchange' in window.screen.orientation) {
      window.screen.orientation.addEventListener('change', onOrientationChange)
    } else if ('onorientationchange' in window) {
      window.addEventListener('orientationchange', onOrientationChange)
    } else {
      console.warn('No orientationchange events')
    }

    return () => {
      if (isSSR && window.screen.orientation && 'onchange' in window.screen.orientation) {
        window.screen.orientation.removeEventListener('change', onOrientationChange)
      } else if ('onorientationchange' in window) {
        window.removeEventListener('orientationchange', onOrientationChange)
      }
    }
  }, [isSSR, onOrientationChange])

  return {
    isPortrait: state === Orientation.portrait,
    isLandscape: state === Orientation.landscape,
  }
}
