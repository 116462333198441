import { useInfiniteQuery } from '@tanstack/react-query'

import { OrdersArticlesInfiniteType, OrdersArticlesType } from 'src/models'

import { ordersKeys, ordersService, ordersState } from 'src/bus/orders'

import { getNextPageParam, queryFetcher } from 'src/utils'

type UseOrdersArticlesFetchType = {
  loadingOrdersArticlesListFetch: boolean
  ordersArticlesListHasMore: boolean
  onFetchMoreOrdersArticlesList: () => void
  ordersArticlesList: OrdersArticlesInfiniteType
}

type OrdersArticlesProps = {
  orderId: number
  customerId: number
}

export const useOrdersArticlesFetch = (params: OrdersArticlesProps): UseOrdersArticlesFetchType => {
  const fetchOrdersArticlesProps = useInfiniteQuery<OrdersArticlesType>({
    queryKey: ordersKeys.articlesList(params),
    queryFn: ({ pageParam }) =>
      queryFetcher({
        name: 'fetchOrdersArticles',
        fetcher: ordersService.fetchOrdersArticles,
        fetcherPayload: { params: { ...params, page: pageParam } },
      }),
    enabled: !!params,
    initialPageParam: 1,
    getNextPageParam,
  })

  return {
    loadingOrdersArticlesListFetch: fetchOrdersArticlesProps.isLoading,
    ordersArticlesListHasMore: fetchOrdersArticlesProps.hasNextPage,
    onFetchMoreOrdersArticlesList: fetchOrdersArticlesProps.fetchNextPage,
    ordersArticlesList: fetchOrdersArticlesProps.data || ordersState.ordersArticles,
  }
}
