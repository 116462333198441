import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect } from 'react'

import { TourOptimizeRouteType } from 'src/models'

import { tourService, tourKeys, useTourPlanForTodayFetch } from 'src/bus/tour'
import { useVisitsFetch, visitsKeys } from 'src/bus/visits'

import { useCurrentLocation, useMutationAsync } from 'src/hooks'

import { formatDate } from 'src/utils'

type UseTourRouteOptimizeType = {
  onTourRouteOptimize: () => void
  loadingTourRouteOptimize: boolean
  isOptimizationAvailable: boolean
}

export const useTourRouteOptimize = (): UseTourRouteOptimizeType => {
  const queryClient = useQueryClient()
  const { geoLocation, timestamp } = useCurrentLocation()
  const { visits, loadingVisitsFetch } = useVisitsFetch()
  const { planForToday, loadingPlanForTodayFetch } = useTourPlanForTodayFetch()

  const optimizeTourRouteProps = useMutationAsync<void, TourOptimizeRouteType>({
    key: tourKeys.tourRoute(geoLocation),
    name: 'optimizeTourRoute',
    fetcher: tourService.optimizeTourRoute,
  })

  const onTourRouteOptimize = useCallback(async () => {
    if (!geoLocation) {
      return
    }

    await optimizeTourRouteProps.mutateAsync(
      {
        ...geoLocation,
        time: formatDate(timestamp, 'dashReversedTime'),
      },
      {
        onSettled: async () => {
          await queryClient.invalidateQueries({ queryKey: tourKeys.all })
          geoLocation && (await queryClient.refetchQueries({ queryKey: tourKeys.tourRoute(geoLocation) }))
          await queryClient.refetchQueries({ queryKey: visitsKeys.list() })
        },
      },
    )
  }, [optimizeTourRouteProps, geoLocation, timestamp, queryClient])

  useEffect(() => {
    if (loadingPlanForTodayFetch || loadingVisitsFetch || planForToday?.isTourOptimized || !visits.items.length) {
      return
    }

    onTourRouteOptimize()
  }, [planForToday?.isTourOptimized, loadingPlanForTodayFetch, loadingVisitsFetch, visits.items.length])

  return {
    onTourRouteOptimize,
    loadingTourRouteOptimize: optimizeTourRouteProps.isLoading,
    isOptimizationAvailable: planForToday?.isOptimizationAvailable || false,
  }
}
