import { Col, Row } from 'antd'

import { WrappedNodeDefault } from 'src/components/common'
import { ListItem, Typography } from 'src/components/ui'
import { InputRadio } from 'src/components/ui/FormFields'

import { ParcelsReplaceType } from 'src/models'

import { rowGutters } from 'src/utils'

type ParcelsReplaceItemProps = {
  item: ParcelsReplaceType
  onSelectId: (id: number) => void
  isSelected: boolean
}

export const ParcelsReplaceItem = ({ item, isSelected, onSelectId }: ParcelsReplaceItemProps) => {
  const renderContent = (span: number, value?: string | number | null) => {
    return (
      <Col span={span}>
        <Typography variant='secondary'>{WrappedNodeDefault(value)}</Typography>
      </Col>
    )
  }

  return (
    <ListItem size='sm' onClick={() => onSelectId(item.id)}>
      <Row align='middle' gutter={rowGutters}>
        <Col span={3}>
          <InputRadio checked={isSelected} />
        </Col>
        {renderContent(4, item.id)}
        <Col span={9}>
          <Typography variant='secondary'>{WrappedNodeDefault(item?.status?.label)}</Typography>
          <Typography variant='secondary' color='gray-6'>
            {WrappedNodeDefault(item?.type?.label)}
          </Typography>
        </Col>
        {renderContent(4, item.weight)}
        {renderContent(4, item.quantity)}
      </Row>
    </ListItem>
  )
}
