import { useTranslation } from 'next-i18next'

import { MoveParcelsItemsForm } from 'src/components/common'
import { DialogContent, DialogHeader, Typography } from 'src/components/ui'

import { ParcelsArticleType } from 'src/models'

import { useParcelsMoveOut } from 'src/bus/parcels'

import { GET_ENUMS } from 'src/utils'

type DialogMoveOutArticleProps = {
  parcelsArticle?: ParcelsArticleType | null
}

export const DialogMoveOutArticle = ({ parcelsArticle = null }: DialogMoveOutArticleProps) => {
  const { t } = useTranslation()
  const { onMoveOutParcels, loadingParcelsMoveOut } = useParcelsMoveOut()

  return (
    <DialogContent name={GET_ENUMS.dialog.moveOutArticle}>
      <DialogHeader>
        <Typography variant='title' color='gray-10'>
          {t('parcels:modal_move_out.title')}
        </Typography>
      </DialogHeader>
      <div className='flex flex-col gap-8'>
        <Typography variant='secondary' color='gray-7' className='text-center'>
          {t('parcels:modal_move_out.description')}
        </Typography>
        <MoveParcelsItemsForm
          onSubmit={(data) => onMoveOutParcels(parcelsArticle?.id, data)}
          buttonLabel={t('parcels:modal_move_out.button')}
          loadingCreate={loadingParcelsMoveOut}
          initialValues={{ quantity: parcelsArticle?.quantity }}
          parcelsArticlesDetails={parcelsArticle}
        />
      </div>
    </DialogContent>
  )
}
