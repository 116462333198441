import dayjs from 'dayjs'
import { useRouter } from 'next/router'
import { forwardRef } from 'react'
import { cn } from 'utils/helpers'

import { DatePicker } from 'src/components/ui/DatePicker'
import { DatePickerProps } from 'src/components/ui/DatePicker/DatePicker.types'
import { Icon } from 'src/components/ui/Icon'

import { antdLocales, DATE_FORMAT_TYPES, initialLocale } from 'src/utils'

import st from './Styles.module.css'

export type InputDatePickerType = Omit<DatePickerProps, 'value'> & {
  className?: string
  hasDisabled?: boolean
  loading?: boolean
  format?: string
  value?: string | null
}

export const InputDatePicker = forwardRef<any, InputDatePickerType>(
  (
    {
      className = '',
      hasDisabled = false,
      loading = false,
      value,
      format = DATE_FORMAT_TYPES.slash,
      size = 'large',
      ...rest
    },
    ref,
  ) => {
    const router = useRouter()
    const disabled = hasDisabled || loading

    const inputStyle = cn(st['input'], { [st['disabled']]: disabled }, className)

    const newValue = value ? dayjs(dayjs(value), format) : (value as undefined)

    return (
      <DatePicker
        {...rest}
        className={inputStyle}
        popupStyle={{ pointerEvents: 'auto' }}
        size={size}
        locale={antdLocales(router.locale || initialLocale)?.DatePicker}
        value={newValue}
        format={format}
        suffixIcon={
          <Icon rotate={loading} name={loading ? 'Loading' : 'Calendar'} color={disabled ? 'gray-7' : 'gray-5'} />
        }
        disabled={disabled}
        ref={ref}
      />
    )
  },
)

InputDatePicker.displayName = 'InputDatePicker'
