export const settingsState = {
  settings: {
    language: { id: 6, value: 'fr', label: 'French' },
    navigator: { id: 1, value: 'google', label: 'Google' },
  },
}

export const settingsKeys = {
  all: ['settings'],
  settings: () => [...settingsKeys.all, 'settings'],
}
