import { BaseDataListType } from 'src/models'

import { visitsHistoryKeys, visitsHistoryState, visitsHistoryService } from 'src/bus/visitsHistory'

import { useQueryAsync } from 'src/hooks'

type UseVisitsHistorySectorsFetchType = {
  visitsHistorySectors: BaseDataListType
  loadingVisitsHistorySectorsFetch: boolean
}

export const useVisitsHistorySectorsFetch = (): UseVisitsHistorySectorsFetchType => {
  const fetchVisitsHistorySectorsProps = useQueryAsync<BaseDataListType>({
    key: visitsHistoryKeys.historySectors(),
    name: 'fetchVisitsHistorySectors',
    fetcher: visitsHistoryService.fetchVisitsHistorySectors,
  })

  return {
    visitsHistorySectors: fetchVisitsHistorySectorsProps.data || visitsHistoryState.staticData.sectors,
    loadingVisitsHistorySectorsFetch: fetchVisitsHistorySectorsProps.isLoading,
  }
}
