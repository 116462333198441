import type { TFunction } from 'i18next'
import type { MouseEvent } from 'react'

import { CellSwipe } from 'src/components/common'

import type { SwipeActionType } from 'src/models'

import { getActionsWithoutHidden } from 'src/utils'

type GetParcelsArticlesActionsProps = {
  onSelectCell: (type: string) => (e: MouseEvent) => void
  t: TFunction
  isAvailableMoveOut: boolean
}

export const getParcelsDetailsActions = ({
  onSelectCell,
  isAvailableMoveOut,
  t,
}: GetParcelsArticlesActionsProps): SwipeActionType[] => {
  const CellParcel = () =>
    CellSwipe({
      text: t('buttons:from_parcel'),
      icon: 'BoxPutOut',
      type: 'danger',
    })

  const actions = [
    {
      key: 'from-parcel',
      text: CellParcel(),
      isHidden: !isAvailableMoveOut,
      onClick: onSelectCell('from-parcel'),
    },
  ]

  return getActionsWithoutHidden(actions)
}
