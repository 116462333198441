import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { BottomNavigationBar, ViewParcelsTotal } from 'src/components/common'
import { Button } from 'src/components/ui'

import { ArticlesType } from 'src/models'

import {
  useParcelsArticlesTotalFetch,
  useParcelsCheckGenerate,
  useParcelsCheckMoveAllTo,
  useParcelsGenerate,
  useParcelsMoveAllTo,
} from 'src/bus/parcels'

import { usePageUrlContext } from 'src/contexts'

import { getLastQueryPath, orNull } from 'src/utils'

export const ParcelsArticlesFooter = () => {
  const { initialFilters } = usePageUrlContext()
  const { t } = useTranslation()
  const router = useRouter()

  const type = getLastQueryPath(router.pathname) as ArticlesType

  const { parcelsArticlesTotal, loadingParcelsArticlesTotalFetch } = useParcelsArticlesTotalFetch(initialFilters)
  const { checkGenerateParcels, loadingParcelsGenerateCheck } = useParcelsCheckGenerate(type)
  const { onGenerateParcels, loadingParcelsGenerate } = useParcelsGenerate(type)
  const { checkMoveAllToParcels, loadingParcelsMoveAllToCheck } = useParcelsCheckMoveAllTo(type)
  const { onMoveAllToParcels, loadingParcelsMoveAllTo } = useParcelsMoveAllTo(type)

  const isAvailableMoveAllToParcels = checkMoveAllToParcels?.available
  const isAvailableGenerateParcels = checkGenerateParcels?.available

  return (
    <div className='shadow-level-2'>
      <div className='p-2'>
        <ViewParcelsTotal data={parcelsArticlesTotal} loadingFetch={loadingParcelsArticlesTotalFetch} />
        <div className='mt-2'>
          {orNull(
            isAvailableMoveAllToParcels,
            <Button
              block
              onClick={onMoveAllToParcels}
              loading={loadingParcelsMoveAllTo || loadingParcelsMoveAllToCheck}
              disabled={!isAvailableMoveAllToParcels}
            >
              {t('buttons:move_all_to_parcel')}
            </Button>,
          )}
          {orNull(
            !isAvailableMoveAllToParcels,
            <Button
              block
              onClick={onGenerateParcels}
              loading={loadingParcelsGenerateCheck || loadingParcelsGenerate}
              disabled={!isAvailableGenerateParcels}
            >
              {t('buttons:generate_parcels')}
            </Button>,
          )}
        </div>
      </div>
      <BottomNavigationBar />
    </div>
  )
}
