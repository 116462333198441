import { useTranslation } from 'next-i18next'
import NextImage from 'next/image'

import { Typography } from 'src/components/ui'

import { orNull } from 'src/utils'

type EmptyProps = {
  className?: string
  title?: string
  description?: string
  imageSrc?: string
  alt?: string
  height?: number
  width?: number
  hasImage?: boolean
}

export const Empty = ({
  title,
  description = '',
  imageSrc = '/assets/images/LatestNewsEmpty.png',
  alt = 'Autodoc Pro CRM',
  width = 60,
  height = 60,
  hasImage = false,
}: EmptyProps) => {
  const { t } = useTranslation()

  return (
    <div className='flex min-h-[168px] flex-col items-center justify-center gap-4 bg-gray-1 text-center'>
      <Typography variant='head' color='gray-7'>
        {t(title || 'common:empty.no_data')}
      </Typography>
      {orNull(
        description,
        <Typography variant='secondary' color='gray-7' className='font-semibold'>
          {t(description)}
        </Typography>,
      )}
      {orNull(hasImage, <NextImage src={imageSrc} width={width} height={height} alt={alt} unoptimized priority />)}
    </div>
  )
}
