import { useTranslation } from 'next-i18next'

import { useCustomersClose } from 'src/bus/customers'

import { AlertDialogConfirm } from './AlertDialogConfirm'

export const AlertDialogOpenCustomer = () => {
  const { t } = useTranslation()
  const { onCloseCustomer, loadingCustomersClose } = useCustomersClose()

  const onConfirm = () => onCloseCustomer(false)

  return (
    <AlertDialogConfirm
      title={t('modal_open_garage.title')}
      description={t('modal_open_garage.description')}
      loadingFetch={loadingCustomersClose}
      onConfirm={onConfirm}
    />
  )
}
