import { ParcelsArticlesInfiniteType, TRefetchFilterStaticCb } from 'src/models'

import { useParcelsArticlesFetch } from 'src/bus/parcels'

type UseParcelsDirtyFetchType = {
  articlesListDirtyHasMore: boolean
  loadingArticlesDirtyFetch: boolean
  onFetchMoreArticlesListDirty: () => void
  articlesListDirty: ParcelsArticlesInfiniteType
  onRefetchArticlesListDirty: TRefetchFilterStaticCb
}

export const useParcelsDirtyFetch = (): UseParcelsDirtyFetchType => {
  const {
    articlesList,
    articlesListHasMore,
    loadingArticlesListFetch,
    onFetchMoreArticlesList,
    onRefetchParcelsArticles,
  } = useParcelsArticlesFetch({ type: 'dirty' })

  return {
    articlesListDirty: articlesList,
    articlesListDirtyHasMore: articlesListHasMore,
    onFetchMoreArticlesListDirty: onFetchMoreArticlesList,
    loadingArticlesDirtyFetch: loadingArticlesListFetch,
    onRefetchArticlesListDirty: onRefetchParcelsArticles,
  }
}
