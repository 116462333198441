import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'

import { FormSubmitParams, HttpErrorType, StopTourType } from 'src/models'

import { tourKeys, tourService } from 'src/bus/tour'
import { visitsKeys } from 'src/bus/visits'

import { useCurrentLocation, useMutationAsync, useToast } from 'src/hooks'

import { useDialog } from 'src/contexts'

import { fillServerErrorsToForm, GET_ENUMS } from 'src/utils'

type UseTourRouteCancelType = {
  loadingTourRouteCancel: boolean
  onTourCancelRoute: (data: FormSubmitParams<StopTourType>) => Promise<void>
}

export const useTourRouteCancel = (): UseTourRouteCancelType => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { geoLocation } = useCurrentLocation()
  const showToast = useToast()
  const { setOnPageDialog } = useDialog()

  const queryKey = tourKeys.tourRoute(geoLocation)

  const cancelTourRouteProps = useMutationAsync<void, StopTourType>({
    key: queryKey,
    name: 'cancelTourRoute',
    fetcher: tourService.cancelTour,
  })

  const onTourCancelRoute = async (data: FormSubmitParams<StopTourType>) => {
    try {
      await cancelTourRouteProps.mutateAsync(data.values)
      setOnPageDialog(GET_ENUMS.dialog.stopTour, false)
      geoLocation && (await queryClient.refetchQueries({ queryKey }))
      await queryClient.refetchQueries({ queryKey: tourKeys.tourPoints() })
      await queryClient.refetchQueries({ queryKey: tourKeys.tourPointsStatic() })
      await queryClient.refetchQueries({ queryKey: tourKeys.news() })
      await queryClient.refetchQueries({ queryKey: tourKeys.planForToday() })
      await queryClient.refetchQueries({ queryKey: visitsKeys.list() })
      showToast.success({ title: t('notifications:tour_stop') })
      setOnPageDialog(GET_ENUMS.dialog.noVisits, true)
    } catch (error) {
      const errs = (error as HttpErrorType).errors
      errs && fillServerErrorsToForm(errs, data.acts.setError)
    }
  }

  return {
    loadingTourRouteCancel: cancelTourRouteProps.isLoading,
    onTourCancelRoute,
  }
}
