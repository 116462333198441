import { z } from 'zod'

import { metaSchema } from './Base'
import { BaseDataInfiniteType, HttpRequestParams } from './index'
import { selectSchema } from './StaticData'

export const historyBaseSchema = z.object({
  id: z.number(),
  type: selectSchema,
  date: z.number(),
})

export const historyVisitSchema = historyBaseSchema.merge(
  z.object({
    visitId: z.number(),
    visitStatus: selectSchema,
    employee: z.string(),
    employeeRole: z.string(),
    comment: z.string(),
  }),
)

export const historyIssueSchema = historyBaseSchema.merge(
  z.object({
    ticketId: z.number(),
    comment: z.string(),
    vin: z.number().nullable(),
    vinOrPlateNumber: z.number().nullable(),
    closedAt: z.number().nullable(),
    order: selectSchema,
    status: selectSchema,
    products: z
      .array(
        z.object({
          productId: z.number().nullable(),
          brand: z.string(),
          article: z.string(),
        }),
      )
      .nullable(),
    reason: selectSchema,
    solution: z.string().nullable(),
    images: z.array(z.string()).nullable(),
    correctAddress: z.string().nullable(),
    clarifications: z.string().nullable(),
  }),
)

export const historyStatusSchema = historyBaseSchema.merge(
  z.object({
    status: selectSchema,
    reason: z.string(),
    employee: z.string(),
    employeeRole: z.string(),
  }),
)

export const historyAllSchema = z.union([historyVisitSchema, historyIssueSchema, historyStatusSchema])

export const orderSchema = z.object({
  id: z.number(),
  status: selectSchema,
  price: z.number(),
  totalQuantity: z.number(),
  isOrderPaid: z.boolean(),
  totalArticles: z.number(),
  createdAt: z.number(),
  deliveryAt: z.number().nullable(),
  expectedDeliveryAt: z.number().nullable(),
})

export const orderListSchema = z.object({
  items: orderSchema.array(),
  meta: metaSchema,
})

export const historyAllListSchema = z.object({
  items: historyAllSchema.array(),
  meta: metaSchema,
})

export type HistoryVisitType = z.infer<typeof historyVisitSchema>
export type HistoryIssueType = z.infer<typeof historyIssueSchema>
export type HistoryStatusType = z.infer<typeof historyStatusSchema>
export type HistoryAllType = z.infer<typeof historyAllSchema>
export type HistoryAllListType = z.infer<typeof historyAllListSchema>
export type HistoryOrderType = z.infer<typeof orderSchema>
export type HistoryOrdersType = z.infer<typeof orderListSchema>
export type HistoryOrdersInfiniteType = BaseDataInfiniteType<HistoryOrderType>

export type HistoryAllInfiniteType = BaseDataInfiniteType<HistoryAllType>

export type HistoryParams = HttpRequestParams
