import { MouseEventHandler, ReactNode } from 'react'

import { Button, Icon } from 'src/components/ui'

type WarningMessageProps = {
  text: ReactNode
  onClick?: () => void
  onClose?: MouseEventHandler<HTMLButtonElement>
}

export const WarningMessage = ({ text, onClick, onClose }: WarningMessageProps) => {
  return (
    <div
      className='relative flex gap-2 rounded-sm border border-orange-5 bg-orange-1 py-2 pl-4 pr-10'
      onClick={onClick}
    >
      <div>
        <Icon name='WarningCircle' />
      </div>
      {text}
      <div className='absolute right-3 top-2'>
        <Button variant='ghost' size='icon-sm' onClick={onClose}>
          <Icon name='Cross' color='gray-7' />
        </Button>
      </div>
    </div>
  )
}
