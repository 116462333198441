import { cva, VariantProps } from 'class-variance-authority'
import cn from 'clsx'
import * as React from 'react'

const historyCardVariants = cva('w-full bg-white py-2 pl-5 pr-4 rounded-md relative border-l-4', {
  variants: {
    variant: {
      order: 'border-l-success',
      visit: 'border-l-info',
      return: 'border-l-error',
      issue: 'border-l-warning',
      status: 'border-l-blue-gray-5',
    },
  },
  defaultVariants: {
    variant: 'status',
  },
})

export type HistoryCardProps = React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof historyCardVariants>

const HistoryCard = React.forwardRef<HTMLDivElement, HistoryCardProps>(
  ({ variant, children, className, ...props }, ref) => {
    return (
      <div className={cn(historyCardVariants({ variant, className }))} ref={ref} {...props}>
        {children}
      </div>
    )
  },
)

HistoryCard.displayName = 'HistoryCard'

export { HistoryCard }
