import { Control } from 'react-hook-form'

import {
  FormFields,
  Typography,
  Skeleton,
  buttonVariants,
  FormItem,
  FormControl,
  FormMessage,
  FormField,
} from 'src/components/ui'

import { StaticDataItemType } from 'src/models'

import { cn, getHasData, orNull, ternary } from 'src/utils'

type GroupVisitPlanActionsFieldsProps = {
  control: Control<any>
  loading: boolean
  options: StaticDataItemType[]
  name: string
  hasDisabled?: boolean
  className?: string
}

export const GroupVisitPlanActionsFields = ({
  control,
  options = [],
  loading,
  name,
  hasDisabled = false,
  className = '',
}: GroupVisitPlanActionsFieldsProps) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem gutter='none'>
          {orNull(
            getHasData(options),
            <FormControl>
              <FormFields.InputCheckboxGroup {...field}>
                {ternary(
                  loading,
                  <>
                    <Skeleton className='mb-2 h-10' />
                    <Skeleton className='mb-2 h-10' />
                    <Skeleton className='h-10' />
                  </>,
                  <>
                    {options.map((item) => {
                      return (
                        <FormFields.InputCheckbox
                          key={item?.id}
                          value={item?.value}
                          className={cn(
                            buttonVariants({
                              variant: 'card',
                              class: cn('mb-2 justify-start', className, {
                                'pointer-events-none border-disabled-bg bg-disabled-bg text-disabled-text': hasDisabled,
                              }),
                            }),
                          )}
                          hasDisabled={hasDisabled}
                        >
                          <Typography variant='secondary'>{item?.label}</Typography>
                        </FormFields.InputCheckbox>
                      )
                    })}
                  </>,
                )}
              </FormFields.InputCheckboxGroup>
            </FormControl>,
          )}
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
