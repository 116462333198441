import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { GroupVisitCancelReasonFields } from 'src/components/common'
import { Button, Form, FormControl, FormField, FormFields, FormItem, FormMessage } from 'src/components/ui'

import {
  FormCancelVisitEnum,
  FormSubmitFn,
  FormCancelVisitType,
  FormCancelVisitSubmitType,
  StaticDataItemType,
  UIErrorType,
  VisitReasonCancelType,
} from 'src/models'

import { formShapes } from 'src/bus/forms'

import { messages, otherVisitCancelReason, orNull } from 'src/utils'

type CancelVisitFormType = {
  initialValues?: VisitReasonCancelType | null
  loadingCreate?: boolean
  loadingFetchStaticData?: boolean
  error?: UIErrorType
  visitCancelReasons?: StaticDataItemType[]
  onSubmit?: FormSubmitFn<FormCancelVisitSubmitType>
  allDisabled?: boolean
  onClose?: () => void
}

export const CancelVisitForm = ({
  loadingCreate = false,
  loadingFetchStaticData = false,
  visitCancelReasons = [],
  onSubmit,
  initialValues = null,
  allDisabled = false,
  onClose,
}: CancelVisitFormType) => {
  const { t } = useTranslation()

  const formProps = useForm<FormCancelVisitType>({
    defaultValues: { ...formShapes.cancelVisit.shape },
    resolver: zodResolver(formShapes.cancelVisit.schema(t)),
    mode: 'onChange',
  })

  const { control, handleSubmit, setError, reset, watch } = formProps

  useDeepCompareEffect(() => {
    if (initialValues) {
      reset(initialValues)
    }
  }, [initialValues, reset])

  const reasonValue = watch('reason')

  const currentReason = useMemo(() => {
    return visitCancelReasons?.find((item) => reasonValue === item?.value)
  }, [reasonValue, visitCancelReasons])

  const isOtherReason = currentReason?.id === otherVisitCancelReason.id

  const onSubmitForm = handleSubmit((values) => {
    const { comment } = values
    const reasonObj = currentReason || otherVisitCancelReason
    const submitValues = { reason: reasonObj, comment }

    onSubmit?.({ values: submitValues, acts: { setError, reset } })
  })

  return (
    <Form {...formProps}>
      <FormFields.FormGeneral
        onSubmit={onSubmitForm}
        footer={
          <Button
            block
            type={allDisabled ? 'button' : 'submit'}
            loading={loadingCreate}
            onClick={onClose}
            data-test-id='cancel-visit-btn'
          >
            {t('buttons:ok')}
          </Button>
        }
      >
        <GroupVisitCancelReasonFields
          options={visitCancelReasons}
          control={control}
          loading={loadingFetchStaticData}
          hasDisabled={allDisabled}
        />
        {orNull(
          isOtherReason || allDisabled,
          <FormField
            control={control}
            name={FormCancelVisitEnum.comment}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <FormFields.InputTextArea
                    {...field}
                    placeholder={t(messages.cancelVisit.placeholder)}
                    disabled={loadingCreate || allDisabled}
                    rows={3}
                  />
                </FormControl>
                <FormMessage className='absolute' />
              </FormItem>
            )}
          />,
        )}
      </FormFields.FormGeneral>
    </Form>
  )
}
