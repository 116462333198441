import { useTranslation } from 'next-i18next'

import { VisitReportForm } from 'src/components/common'
import { Typography } from 'src/components/ui'

import { useActionsReportsFetch, useBusinessActionsFetch } from 'src/bus/actions'
import { useVisitsHistoryReportFetch } from 'src/bus/visitsHistory'

export const ViewVisitsHistoryReport = () => {
  const { t } = useTranslation()
  const { actionsReports, loadingActionsReportsFetch } = useActionsReportsFetch()
  const { businessActions, loadingBusinessActionsFetch } = useBusinessActionsFetch()
  const { visitsHistoryReport, loadingVisitsHistoryReportFetch } = useVisitsHistoryReportFetch()

  return (
    <div className='mt-2 space-y-2 px-2' data-test-id='visits-history-report'>
      <Typography className='font-semibold'>{t('visits:visit_plan')}</Typography>
      <VisitReportForm
        initialValues={visitsHistoryReport}
        businessActions={businessActions}
        loadingActionsFetch={loadingActionsReportsFetch || loadingVisitsHistoryReportFetch}
        loadingBusinessActionsFetch={loadingBusinessActionsFetch || loadingVisitsHistoryReportFetch}
        actionsReports={actionsReports}
        formDisabled
      />
    </div>
  )
}
