import type { TFunction } from 'i18next'

import { book } from 'src/init/book'

import { MenuProfileType } from 'src/models'

import { GET_ENUMS } from 'src/utils'

type VisitReportPanelProps = {
  customerId: string
  t: TFunction
}

export const getVisitReportPanel = ({ customerId, t }: VisitReportPanelProps): MenuProfileType[] => {
  return [
    {
      title: t('buttons:customer_info'),
      key: `${book.customers}/${customerId}`,
      icon: 'User',
    },
    {
      title: t('buttons:edit_note'),
      key: GET_ENUMS.dialog.editNote,
      icon: 'EditProfile',
      dialog: true,
    },
    {
      title: t('buttons:fill_complaint'),
      key: GET_ENUMS.dialog.customerIssue,
      icon: 'Document',
      dialog: true,
    },
  ]
}
