import { Col, Row } from 'antd'
import { useTranslation } from 'next-i18next'
import { cond, equals } from 'ramda'
import { useState } from 'react'

import {
  Dialog,
  DialogViewsSwitcher,
  ParcelsArticlesFilters,
  ParcelsArticlesItem,
  SkeletonList,
} from 'src/components/common'
import { Empty, InfiniteScroll, Typography } from 'src/components/ui'

import { ArticlesType, ParcelsArticlesInfiniteType, ParcelsArticleType } from 'src/models'

import { useParcelsMoveTo } from 'src/bus/parcels'

import { useDialog } from 'src/contexts'

import { GET_ENUMS, getInfiniteListLength, gtOne, rowGutters } from 'src/utils'

type ParcelsArticlesProps = {
  data: ParcelsArticlesInfiniteType
  loadingFetch: boolean
  onFetchMore: () => void
  hasMore: boolean
  type: ArticlesType
}

const articlesHeader = [
  { title: 'parcels:list.article_and_name', span: 8 },
  { title: 'parcels:list.parcel', span: 4 },
  { title: 'parcels:list.weight', span: 3 },
  { title: 'parcels:list.qty', span: 3 },
  { title: 'parcels:list.amount', span: 6 },
]

export const ParcelsArticles = ({ data, loadingFetch, onFetchMore, hasMore, type }: ParcelsArticlesProps) => {
  const { t } = useTranslation()
  const { onPageDialogs, setOnPageDialog } = useDialog()
  const { onMoveToParcels } = useParcelsMoveTo()

  const [selectItem, setSelectItem] = useState<ParcelsArticleType>()

  const onSelectCell = (type: string, item: ParcelsArticleType) => {
    cond([
      [
        equals('delete'),
        () => {
          setSelectItem(item)
          setOnPageDialog(GET_ENUMS.dialog.deleteArticle, true)
        },
      ],
      [
        equals('manual'),
        () => {
          console.log('Manual')
        },
      ],
      [
        equals('replace'),
        () => {
          setSelectItem(item)
          setOnPageDialog(GET_ENUMS.dialog.replaceArticle, true)
        },
      ],
      [
        equals('recover'),
        () => {
          setSelectItem(item)
          setOnPageDialog(GET_ENUMS.dialog.recoverArticle, true)
        },
      ],
      [
        equals('toParcel'),
        async () => {
          setSelectItem(item)
          if (gtOne(Number(item.quantity))) {
            setOnPageDialog(GET_ENUMS.dialog.moveToArticle, true)
          } else {
            await onMoveToParcels(item.id)
          }
        },
      ],
    ])(type)
  }

  const renderItems = () =>
    data.pages.map((page) => {
      return page.items.map((item) => <ParcelsArticlesItem key={item.id} item={item} onSelectCell={onSelectCell} />)
    })

  const isDirty = type === 'dirty'

  return (
    <div className='flex h-full flex-col py-2' data-test-id={`parcels-${type}`}>
      {[
        GET_ENUMS.dialog.deleteArticle,
        GET_ENUMS.dialog.recoverArticle,
        GET_ENUMS.dialog.moveToArticle,
        GET_ENUMS.dialog.replaceArticle,
      ].map((key) => {
        return (
          <Dialog key={key} open={onPageDialogs[key]?.visible} onOpenChange={(open) => setOnPageDialog(key, open)}>
            <DialogViewsSwitcher name={key} params={{ parcelsArticle: selectItem, id: selectItem?.id }} />
          </Dialog>
        )
      })}
      <div className='mb-3'>
        <ParcelsArticlesFilters type={type} />
      </div>
      <div className='bg-gray-1 px-2 pb-3'>
        <Row gutter={rowGutters}>
          {articlesHeader.map((item) => {
            return (
              <Col key={item.title} span={item.span}>
                <Typography variant='caption' className='font-semibold'>
                  <span dangerouslySetInnerHTML={{ __html: t(item.title) }} />
                </Typography>
              </Col>
            )
          })}
        </Row>
      </div>
      <InfiniteScroll
        hasMore={hasMore}
        loadingFetch={loadingFetch}
        onFetchMore={onFetchMore}
        dataLength={getInfiniteListLength(data.pages)}
        loader={<SkeletonList type='parcels-articles' />}
        scrollableTarget={`parcels-articles-${type}`}
        renderItems={renderItems}
        empty={
          <Empty
            title='common:empty.no_activity'
            description={isDirty ? 'common:empty.dirty_parcels_articles' : 'common:empty.clear_parcels_articles'}
          />
        }
      />
    </div>
  )
}
