import { useMutation } from '@tanstack/react-query'
import { UseMutationResult } from '@tanstack/react-query'

import { ClaimType, HttpErrorType, HttpServerRequestParams } from 'src/models'

import { claimsService } from 'src/bus/claims'

import { queryFetcher } from 'src/utils'

type ClaimsUploadImagesMutationType = {
  onUploadImagesClaimsMutation: UseMutationResult<ClaimType, HttpErrorType, HttpServerRequestParams>
}

export const useClaimsUploadImagesMutation = (): ClaimsUploadImagesMutationType => {
  const onUploadImagesClaimsMutation = useMutation<ClaimType, HttpErrorType, HttpServerRequestParams>({
    mutationFn: (params) => {
      return queryFetcher({
        name: 'uploadImagesClaims',
        fetcher: claimsService.uploadImages,
        fetcherPayload: params,
      })
    },
  })

  return { onUploadImagesClaimsMutation }
}
