import { useMutation } from '@tanstack/react-query'
import { UseMutationResult } from '@tanstack/react-query'

import { HttpErrorType, ParcelsParams } from 'src/models'

import { parcelsService } from 'src/bus/parcels'

import { queryFetcher } from 'src/utils'

type ParcelsRestoreMutationType = {
  onRestoreParcelsMutation: UseMutationResult<void, HttpErrorType, ParcelsParams>
}

export const useParcelsRestoreMutation = (): ParcelsRestoreMutationType => {
  const onRestoreParcelsMutation = useMutation<void, HttpErrorType, ParcelsParams>({
    mutationFn: (params) => {
      return queryFetcher({
        name: 'restoreParcels',
        fetcher: parcelsService.restoreParcels,
        fetcherPayload: params,
      })
    },
  })

  return { onRestoreParcelsMutation }
}
