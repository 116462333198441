import { useRouter } from 'next/router'

import { ClaimType } from 'src/models'

import { claimsService, claimsKeys, claimsState } from 'src/bus/claims'
import { useCustomersDetailsFetch } from 'src/bus/customers'

import { useQueryAsync } from 'src/hooks'

type UseClaimsDetailsFetchType = {
  loadingClaimsDetailsFetch: boolean
  claimsDetails: ClaimType | null
}

export const useClaimsDetailsFetch = (): UseClaimsDetailsFetchType => {
  const router = useRouter()
  const { customerId, loadingCustomersDetailsFetch } = useCustomersDetailsFetch()

  const id = Number(router.query.id)
  const params = {
    ...(id && { id }),
    ...(customerId && { customerId }),
  }

  const fetchClaimsDetailsProps = useQueryAsync<ClaimType>({
    key: claimsKeys.detail(params),
    name: 'fetchClaimsDetails',
    fetcher: claimsService.fetchClaimsDetails,
    fetcherPayload: params,
    options: { enabled: !!id && !!customerId },
  })

  return {
    loadingClaimsDetailsFetch: fetchClaimsDetailsProps.isLoading || loadingCustomersDetailsFetch,
    claimsDetails: fetchClaimsDetailsProps.data || claimsState.claim,
  }
}
