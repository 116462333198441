import getConfig from 'next/config'

const publicRuntimeConfig = getConfig()?.publicRuntimeConfig

export const BuildEnvVariable = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
}

export const isDevelopment = Boolean(Number(process.env.BUILD_CI))

export const verifyEnvironment = (): {
  isLocal: boolean
  isDevelopment: boolean
  isProduction: boolean
} => {
  const isDevelopment = publicRuntimeConfig?.BUILD_ENV === BuildEnvVariable.DEVELOPMENT
  const isProduction = publicRuntimeConfig?.BUILD_ENV === BuildEnvVariable.PRODUCTION
  const isLocal = Boolean(publicRuntimeConfig?.BUILD_CI)

  return {
    isLocal,
    isDevelopment,
    isProduction,
  }
}

export const __DEV__ = process.env.NODE_ENV === 'development' || verifyEnvironment().isDevelopment
