import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { VisitsActions, CustomersDetails, CustomersComment } from 'src/components/common'
import { ScrollArea, Skeleton, Typography, VisitHistoryStatus } from 'src/components/ui'

import { TVisitHistoryStatus } from 'src/models'

import { useVisitsHistoryDetailsFetch } from 'src/bus/visitsHistory'

import { ternary } from 'src/utils'

export const ViewVisitsHistoryDetails = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const { visitsHistoryDetails, loadingVisitsHistoryDetailsFetch } = useVisitsHistoryDetailsFetch()

  const status = visitsHistoryDetails?.status?.value as TVisitHistoryStatus

  return (
    <ScrollArea className='py-2' data-test-id='visits-history-details'>
      <div className='mb-2 flex items-center justify-evenly'>
        {ternary(
          loadingVisitsHistoryDetailsFetch,
          <Skeleton className='h-[22px] w-full' />,
          <>
            <div className='flex gap-1'>
              <Typography variant='secondary' color='gray-7'>
                {t('status')}:
              </Typography>
              <div className='flex items-center gap-1'>
                <VisitHistoryStatus variant={status} />
                <Typography variant='secondary'>{visitsHistoryDetails?.status?.label}</Typography>
              </div>
            </div>
            <div className='flex items-center gap-1'>
              <Typography variant='secondary' color='gray-7'>
                {t('date')}:
              </Typography>
              <Typography variant='secondary'>{visitsHistoryDetails?.date}</Typography>
            </div>
          </>,
        )}
      </div>
      <div className='mb-4'>
        <VisitsActions title={<Typography className='font-semibold'>{t('visits:actions')}</Typography>} />
      </div>
      <CustomersComment />
      <CustomersDetails visitId={Number(router.query.id)} />
    </ScrollArea>
  )
}
