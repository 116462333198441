import type { TFunction } from 'i18next'
import { z } from 'zod'

import { messages, OPTION_OBJECT } from 'src/utils'

export const updateCustomer = {
  shape: {
    title: '',
    index: '',
    country: null,
    city: '',
    street: '',
    houseNumber: '',
    vat: '',
    phonePrefix: null,
    phoneNumber: '',
    email: '',
    notes: '',
    businessType: null,
    quantityEmployees: null,
    contactPersons: [],
  },
  schema: (t: TFunction) => {
    const numberSchema = z.object({
      id: z.number().nullable(),
      phonePrefix: z.object(OPTION_OBJECT).nullable().default(null),
      phoneNumber: z.string().trim(),
    })

    const contactPersonsSchema = z
      .object({
        id: z.number().nullable(),
        name: z.string().trim(),
        surname: z.string().trim(),
        position: z
          .string()
          .trim()
          .max(
            messages.updateCustomer.position.max,
            t(messages.updateCustomer.position.maxText, {
              label: t(messages.updateCustomer.position.label).toLowerCase(),
              count: messages.updateCustomer.position.max,
            }),
          ),
        phones: z.array(numberSchema),
      })
      .nullable()
      .default(null)

    return z
      .object({
        title: z.string().trim(),
        index: z.string().trim(),
        country: z.object(OPTION_OBJECT).nullable().default(null),
        city: z.string().trim(),
        street: z.string().trim(),
        houseNumber: z.string().trim(),
        phonePrefix: z.object(OPTION_OBJECT).nullable().default(null),
        phoneNumber: z.string(),
        vat: z.string(),
        email: z.string().trim(),
        notes: z.string().trim(),
        businessType: z.object(OPTION_OBJECT, {
          invalid_type_error: t(messages.updateCustomer.businessType.required),
        }),
        quantityEmployees: z.object(OPTION_OBJECT, {
          invalid_type_error: t(messages.updateCustomer.quantityEmployees.required),
        }),
        contactPersons: z.array(contactPersonsSchema),
      })
      .nullable()
      .default(null)
  },
}
