import { useTranslation } from 'next-i18next'
import Link from 'next/link'

import { book } from 'src/init/book'

import { Icon, Skeleton, Button, Typography } from 'src/components/ui'

import { LocationType } from 'src/models'

import { useCustomersUpdateLocation } from 'src/bus/customers'

type CellLocationProps = {
  loadingFetch?: boolean
  value?: LocationType | null
  customerId?: number
}

export const CellLocation = ({ value, customerId, loadingFetch = false }: CellLocationProps) => {
  const { t } = useTranslation()
  const { onDeleteCustomersLocation } = useCustomersUpdateLocation()

  const pathname = `${book.customers}/${customerId}${book.customersUpdateLocation}`

  const linkBtnRender = (title: string) => (
    <Button variant='ghost' size='sm' className='p-0'>
      <div className='mr-1 flex'>
        <Icon name='EditProfile' color='blue-7' />
      </div>
      <Typography variant='secondary' color='blue-7'>
        <Link href={pathname}>{t(title)}</Link>
      </Typography>
    </Button>
  )

  if (loadingFetch) {
    return <Skeleton className='h-[18px]' />
  }

  if (!value) {
    return (
      <Typography variant='secondary' color='blue-8'>
        {linkBtnRender('buttons:create_point')}
      </Typography>
    )
  }

  return (
    <div className='flex items-center justify-between'>
      {linkBtnRender('buttons:edit_point')}
      <Button
        size='icon-sm'
        variant='ghost'
        onClick={() => onDeleteCustomersLocation(customerId)}
        data-test-id='delete-point-btn'
      >
        <Icon name='Delete' color='blue-8' />
      </Button>
    </div>
  )
}
