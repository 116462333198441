import Cookies from 'js-cookie'

import { AUTH_COOKIE } from 'src/utils/constants'

export const getAccessToken = () => Cookies.get(AUTH_COOKIE) || null

export const saveTokensStorage = (token: string) => {
  Cookies.set(AUTH_COOKIE, token)
}

export const removeFromStorage = () => {
  Cookies.remove(AUTH_COOKIE)
}
