import { ParcelsArticles } from 'src/components/common'

import { useParcelsClearFetch } from 'src/bus/parcels'

export const ParcelsClearTabContent = () => {
  const { articlesListClear, articlesListClearHasMore, loadingArticlesClearFetch, onFetchMoreArticlesListClear } =
    useParcelsClearFetch()

  return (
    <ParcelsArticles
      type='clear'
      data={articlesListClear}
      loadingFetch={loadingArticlesClearFetch}
      onFetchMore={onFetchMoreArticlesListClear}
      hasMore={articlesListClearHasMore}
    />
  )
}
