import { useRouter } from 'next/router'
import { Dispatch, PropsWithChildren, SetStateAction } from 'react'

import { FilterStaticParamsType, FilterStaticRequestValuesType } from 'src/models'

import { useLocalStorage } from 'src/hooks'

import { createStrictContext, getParamsFromQueryStr, removeFirstSlash, useStrictContext } from 'src/utils'

type ContentType = {
  initialFilters: FilterStaticParamsType
  setStorageFilters: Dispatch<SetStateAction<FilterStaticRequestValuesType>>
}

const PageUrlContext = createStrictContext<ContentType>()

export const PageUrlContextProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter()

  const storageKey = removeFirstSlash(router.pathname)

  const [storageFilters, setStorageFilters] = useLocalStorage<FilterStaticRequestValuesType>(storageKey, {})

  const initialUrlQuery = getParamsFromQueryStr(router.query)
  const initialFilters = { ...initialUrlQuery, filter: storageFilters }

  return <PageUrlContext.Provider value={{ initialFilters, setStorageFilters }}>{children}</PageUrlContext.Provider>
}

export const usePageUrlContext = () => useStrictContext(PageUrlContext)
