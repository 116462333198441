import { PropsWithChildren, Suspense, useMemo } from 'react'

import { Loader } from 'src/components/ui'

import { useAuthenticate } from 'src/bus/auth'

import { createStrictContext, getAccessToken } from 'src/utils'

type AuthSessionValueType = {
  isAuthenticated: boolean
  token: string
}

const AuthSessionContext = createStrictContext<AuthSessionValueType | null>()

export const AuthSessionProvider = ({ children }: PropsWithChildren) => {
  const token = getAccessToken()
  useAuthenticate()

  const value = useMemo(() => ({ token: token || '', isAuthenticated: !!token || false }), [token])

  return (
    <AuthSessionContext.Provider value={value}>
      {<Suspense fallback={<Loader />}>{children}</Suspense>}
    </AuthSessionContext.Provider>
  )
}
