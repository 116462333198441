import type { TFunction } from 'i18next'

import { book } from 'src/init/book'

import type { MenuProfileType } from 'src/models'

import { GET_ENUMS } from 'src/utils'

type GetVisitPlanPanel = {
  hasDisabled: boolean
  t: TFunction
  customerId: number
  loadingFetch: boolean
}

export const getVisitPlanPanel = ({
  t,
  hasDisabled,
  customerId,
  loadingFetch,
}: GetVisitPlanPanel): MenuProfileType[] => {
  return [
    {
      title: t('buttons:create_coupon'),
      key: GET_ENUMS.dialog.createCoupon,
      disabled: false,
      icon: 'Coupon',
      dialog: true,
    },
    {
      title: t('buttons:customer_history'),
      key: `${book.customers}/${customerId}${book.historyAll}`,
      disabled: loadingFetch,
      icon: 'History',
    },
    {
      title: t('buttons:cancel_this_visit'),
      key: GET_ENUMS.dialog.cancelVisit,
      icon: 'MinusCircle',
      type: 'cancel',
      dialog: true,
    },
    {
      title: t('buttons:reschedule_visit'),
      key: GET_ENUMS.dialog.replanVisit,
      icon: 'Calendar',
      dialog: true,
    },
    {
      title: t('buttons:initial_customer_credentials'),
      key: GET_ENUMS.dialog.customerCredentials,
      disabled: hasDisabled,
      icon: 'User',
      dialog: true,
    },
  ]
}
