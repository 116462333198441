const NOTIFICATION_SUCCESS = 'success'
const NOTIFICATION_ERROR = 'error'
const NOTIFICATION_INFO = 'info'

export const NOTIFICATION_TYPES = {
  success: NOTIFICATION_SUCCESS,
  error: NOTIFICATION_ERROR,
  info: NOTIFICATION_INFO,
}

export const basicErrMessage = 'A problem occurred while submitting your data.'
