import { useMutation } from '@tanstack/react-query'
import { UseMutationResult } from '@tanstack/react-query'

import { HttpErrorType, ParcelsParams } from 'src/models'

import { parcelsService } from 'src/bus/parcels'

import { queryFetcher } from 'src/utils'

type UseParcelsShipmentMutationType = {
  onShipmentParcelsMutation: UseMutationResult<void, HttpErrorType, ParcelsParams>
}

export const useParcelsShipmentMutation = (): UseParcelsShipmentMutationType => {
  const onShipmentParcelsMutation = useMutation<void, HttpErrorType, ParcelsParams>({
    mutationFn: (params) => {
      return queryFetcher({
        name: 'shipmentParcels',
        fetcher: parcelsService.shipmentParcels,
        fetcherPayload: params,
      })
    },
  })

  return { onShipmentParcelsMutation }
}
