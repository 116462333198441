import { useRouter } from 'next/router'
import { MouseEventHandler, useState } from 'react'

import { book } from 'src/init/book'

import { WarningMessage } from 'src/components/common'
import { Typography } from 'src/components/ui/Typography'

import { useAuthProfile } from 'src/bus/auth'

import { useLocalStorage } from 'src/hooks'

import { NPS_ID, NPS_IDS_EXCLUDED, orNull, PROJECT_VISIT_NPS } from 'src/utils'

const title = 'LE BAROMETRE EST LANCÉ !'
const text =
  "Informez vos clients pour qu'ils y répondent.Mettez en avant que leurs remontées sont\n" +
  "            prises en compte.Ils recevront une notification et un message sur l'extranet"
const text2 = 'Cliquez pour voir la fiche action'

export const NPS = () => {
  const router = useRouter()
  const { profile, isRoleServiceAgent } = useAuthProfile()

  const [visitsNPS, setStorageNPS] = useLocalStorage<string[]>(PROJECT_VISIT_NPS, [])

  const visitId = String(router.query.id)

  const [isHidden, setIsHidden] = useState(() => visitsNPS.includes(visitId))

  const onHandleClose: MouseEventHandler<HTMLSpanElement> = (e) => {
    e.stopPropagation()
    setIsHidden(true)
    setStorageNPS([...visitsNPS, visitId])
  }

  const onHandleNavigate = async () => {
    await router.push(`${book.visitActions}/${NPS_ID}${book.visitActionsDetails}`)
  }

  const isNotExcluded = !NPS_IDS_EXCLUDED.includes(Number(profile?.id))

  return orNull(
    !isHidden && isRoleServiceAgent && isNotExcluded,
    <div className='mb-4'>
      <WarningMessage
        text={
          <div className='flex flex-col gap-2'>
            <Typography variant='title' color='gray-10'>
              {title}
            </Typography>
            <Typography variant='secondary' color='gray-7'>
              {text}
            </Typography>
            <Typography variant='secondary' color='gray-7' className='font-semibold'>
              {text2}
            </Typography>
          </div>
        }
        onClick={onHandleNavigate}
        onClose={onHandleClose}
      />
    </div>,
  )
}
