import { Col, Row } from 'antd'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useMemo } from 'react'

import { ClaimsAutocompleteItem } from 'src/components/common'
import { Loader, Typography } from 'src/components/ui'

import { ClaimType } from 'src/models'

import { getHasData, getParamsFromQueryStr, rowGutters, ternary } from 'src/utils'

type ClaimsAutocompleteProps = {
  claims?: ClaimType[]
  loadingFetch?: boolean
}

const claimsHeader = [
  { title: 'returns:returns_details.article', span: 7 },
  { title: 'returns:returns_details.return_type', span: 5 },
  { title: 'returns:returns_details.order_number', span: 4 },
  { title: 'returns:returns_details.qty', span: 3 },
  { title: 'returns:returns_details.amount', span: 5 },
]

export const ClaimsAutocomplete = ({ claims = [], loadingFetch = false }: ClaimsAutocompleteProps) => {
  const { t } = useTranslation()
  const router = useRouter()

  const initialQuery = getParamsFromQueryStr(router.query)
  const search = initialQuery.filter?.search || ''

  const filteredClaims = useMemo(() => {
    return search ? claims.filter((item) => item.article.toLowerCase().includes(search.toLowerCase())) : []
  }, [claims, search])

  return (
    <>
      {ternary(
        loadingFetch,
        <div className='flex items-center justify-center py-2'>
          <Loader type='relative' />
        </div>,
        <>
          {ternary(
            getHasData(claims),
            <>
              <div className='bg-blue-gray-1 px-2 py-3'>
                <Row gutter={rowGutters}>
                  {claimsHeader.map((item) => (
                    <Col key={item.title} span={item.span}>
                      <Typography variant='caption' className='font-semibold'>
                        <span dangerouslySetInnerHTML={{ __html: t(item.title) }} />
                      </Typography>
                    </Col>
                  ))}
                </Row>
              </div>
              {filteredClaims.map((item) => (
                <ClaimsAutocompleteItem key={item.id} item={item} />
              ))}
            </>,
            <div className='mb-2 mt-5 flex items-center justify-center'>
              <Typography variant='secondary' color='gray-7'>
                {t('empty.current_returns')}
              </Typography>
            </div>,
          )}
        </>,
      )}
    </>
  )
}
