import { useTranslation } from 'next-i18next'

import { ProgressBar, Typography, Skeleton } from 'src/components/ui'
import { ProgressBarColorType } from 'src/components/ui/ProgressBar'

import { PlanForTodayProgress } from 'src/models'

import { ternary } from 'src/utils'

type YourProgressTodayProps = {
  data?: PlanForTodayProgress | null
  loadingFetch?: boolean
}

export const YourProgressToday = ({ data = null, loadingFetch = false }: YourProgressTodayProps) => {
  const { t } = useTranslation()

  const current = data?.current
  const visits = data?.visits

  return (
    <div className='flex flex-col gap-1 border-t border-blue-gray-2 py-4'>
      <div className='flex items-center justify-between'>
        <Typography variant='secondary' color='gray-7'>
          {t('plan-for-today:progress_today')}:
        </Typography>
        {ternary(
          loadingFetch,
          <Skeleton className='h-4 min-w-[20%]' />,
          <Typography variant='secondary' color='gray-6'>
            {t('plan-for-today:count_visits', {
              completed: visits?.completed,
              all: visits?.all,
            })}
          </Typography>,
        )}
      </div>
      <div className='flex items-center justify-between gap-4'>
        {ternary(
          loadingFetch,
          <Skeleton className='h-6 w-full' />,
          <>
            <div className='flex-none'>
              <Typography className='font-semibold'>{current} %</Typography>
            </div>
            <div className='w-full'>
              <ProgressBar percent={current} colorType={ProgressBarColorType.red} />
            </div>
          </>,
        )}
      </div>
    </div>
  )
}
