import Link from 'next/link'
import { useRouter } from 'next/router'

import { book } from 'src/init/book'

import { Header } from 'src/components/common'
import { Button, Icon } from 'src/components/ui'

import { useCustomersDetailsFetch } from 'src/bus/customers'

export const CustomersDetailsHeader = () => {
  const router = useRouter()
  const { customersDetails, loadingCustomersDetailsFetch } = useCustomersDetailsFetch()

  return (
    <Header
      title={customersDetails?.title}
      subTitle={customersDetails?.companyTitle}
      loadingFetch={loadingCustomersDetailsFetch}
      actionsRight={
        <Button variant='ghost' size='icon-sm' data-test-id='edit-customer-btn'>
          <Link href={`${book.customers}/${router.query.id}${book.customersEdit}`}>
            <Icon name='Edit' color='blue-gray-85' />
          </Link>
        </Button>
      }
    />
  )
}
