import { Dialog, DialogViewsSwitcher, PlanForToday, VisitList } from 'src/components/common'

import { useDialog } from 'src/contexts'

import { GET_ENUMS } from 'src/utils'

export const ViewTour = () => {
  const { onPageDialogs, setOnPageDialog } = useDialog()

  return (
    <div className='flex h-full flex-col overflow-hidden py-2' data-test-id='visits'>
      <Dialog
        open={onPageDialogs[GET_ENUMS.dialog.noVisits]?.visible}
        onOpenChange={(open) => setOnPageDialog(GET_ENUMS.dialog.noVisits, open)}
      >
        <DialogViewsSwitcher name={GET_ENUMS.dialog.noVisits} />
      </Dialog>
      <PlanForToday hasProgress />
      <VisitList />
    </div>
  )
}
