import { BaseUnknownRecordType, StaticDataItemType } from './index'

export type FiltersDynamicType = {
  search?: string
  suggestion?: string
}

export const FilterStaticEnum = {
  sectors: 'sectors',
  zones: 'zones',
  parcelsStatus: 'parcelsStatus',
  profileExists: 'profileExists',
  parcelArticleStatus: 'parcelArticleStatus',
  parcelsType: 'parcelsType',
  businessType: 'businessType',
  plannedVisitsExist: 'plannedVisitsExist',
  potential: 'potential',
  types: 'types',
  quantityEmployees: 'quantityEmployees',
  planVisits: 'planVisits',
  customerStatus: 'customerStatus',
  statuses: 'statuses',
  navigator: 'navigator',
  languages: 'languages',
  customers: 'customers',
  date: 'date',
  search: 'search',
} as const

export type FiltersButtonType = 'apply' | 'reset'

export type FilterStaticType = {
  search?: string
  date?: string[]
  startDate?: Date | null
  endDate?: Date | null
  parcelsStatus?: StaticDataItemType[]
  parcelArticleStatus?: StaticDataItemType[]
  parcelsType?: StaticDataItemType[]
  businessType?: StaticDataItemType[]
  plannedVisitsExist?: StaticDataItemType[]
  potential?: StaticDataItemType[]
  profileExists?: StaticDataItemType[]
  types?: StaticDataItemType[]
  quantityEmployees?: StaticDataItemType[]
  planVisits?: StaticDataItemType[]
  customerStatus?: StaticDataItemType[]
  statuses?: StaticDataItemType[]
  sectors?: StaticDataItemType[]
  zones?: StaticDataItemType[]
  languages?: StaticDataItemType[]
  navigator?: StaticDataItemType[]
  customers?: StaticDataItemType[]
}

export type FiltersFormFieldVariant = {
  'collapsed-checklist': string
  checklist: string
  'multi-select': string
  date: string
  suggestion: string
  search: string
}

export type FiltersFormFieldVariantKeys = keyof FiltersFormFieldVariant

export type FilterStaticFieldNamesType =
  | 'search'
  | 'planVisits'
  | 'quantityEmployees'
  | 'languages'
  | 'statuses'
  | 'navigator'
  | 'parcelsStatus'
  | 'parcelArticleStatus'
  | 'businessType'
  | 'plannedVisitsExist'
  | 'potential'
  | 'profileExists'
  | 'parcelsType'
  | 'types'
  | 'date'
  | 'customerStatus'
  | 'customers'
  | 'sectors'
  | 'zones'

type SelectorOptionsType = {
  options?: StaticDataItemType[]
  isMulti?: boolean
  isVisible?: boolean
  variant?: FiltersFormFieldVariantKeys
  name: string
}
type FilterStaticInputType = {
  placeholder?: string | [string, string]
  label?: string
}

export type FilterStaticDataItemType = FilterStaticInputType & SelectorOptionsType

export type FilterStaticRequestValuesType = {
  search?: string
  quantityEmployees?: string[]
  planVisits?: string
  customerStatus?: string[]
  statuses?: string[]
  sectors?: string[]
  zones?: string[]
  customers?: string[]
  startDate?: string
  date?: string
  endDate?: string
  businessType?: string[]
  plannedVisitsExist?: string
  potential?: string[]
  profileExists?: string
  parcelsType?: string
  types?: string
  parcelsStatus?: string[]
  parcelArticleStatus?: string
  languages?: string
  navigator?: string
}

export type FilterStaticDataType = {
  statuses?: FilterStaticDataItemType
  customerStatus?: FilterStaticDataItemType
  planVisits?: FilterStaticDataItemType
  quantityEmployees?: FilterStaticDataItemType
  sectors?: FilterStaticDataItemType
  zones?: FilterStaticDataItemType
  customers?: FilterStaticDataItemType
  startDate?: FilterStaticDataItemType
  endDate?: FilterStaticDataItemType
  businessType?: FilterStaticDataItemType
  plannedVisitsExist?: FilterStaticDataItemType
  potential?: FilterStaticDataItemType
  profileExists?: FilterStaticDataItemType
  parcelsType?: FilterStaticDataItemType
  types?: FilterStaticDataItemType
  parcelsStatus?: FilterStaticDataItemType
  parcelArticleStatus?: FilterStaticDataItemType
  languages?: FilterStaticDataItemType
  navigator?: FilterStaticDataItemType
  date?: FilterStaticDataItemType
  search?: FilterStaticDataItemType
}

export type SortEnum = 'asc' | 'desc'

export type SortType = {
  [key: string]: SortEnum
}

export type FilterStaticParamsType = {
  filter?: FilterStaticRequestValuesType | null
  page?: number | string
  size?: number | string
  sort?: SortType
}

export type TFilterStaticRefetchValue = {
  filterData?: FilterStaticParamsType
} | null

export type TRefetchFilterStaticCb = (values: TFilterStaticRefetchValue) => Promise<void>

export type FilterStaticFilterQueryParamsType = FilterStaticParamsType & BaseUnknownRecordType

export type FilterStaticBaseConfigType = {
  [key in FilterStaticFieldNamesType]: {
    label?: string
    name?: string
    placeholder?: string | [string, string]
    variant?: FiltersFormFieldVariantKeys
    isMulti?: boolean
    isVisible?: boolean
    options?: StaticDataItemType[]
  }
}
