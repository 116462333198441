import { useTranslation } from 'next-i18next'
import Link from 'next/link'

import { Button, SheetContent, Typography } from 'src/components/ui'

import { useAuthProfile } from 'src/bus/auth'

import { AVAILABLE_PROFILES_ID, formatDate, getTranslate, orNull, usefulLinks } from 'src/utils'

export const UsefulLinksPanel = () => {
  const { t } = useTranslation()
  const { profile, isRoleServiceAgent, isCountryNotNL, isCountryFR } = useAuthProfile()

  const hasAvailableProfile = AVAILABLE_PROFILES_ID.includes(String(profile?.id))

  const getLinksT = getTranslate('profile:useful_links', t)

  const subject = getLinksT('mail_to_subject')
  const body = getLinksT('mail_to_body')

  const date = new Date()
  const formattedDate = formatDate(date, 'dashReversed')

  const links = [
    {
      href: usefulLinks.vat,
      btnTitle: isCountryNotNL ? 'vat_validation' : 'vat_check',
      isShow: true,
    },
    {
      href: usefulLinks.mail(subject, body),
      btnTitle: 'mail_complaint',
      isShow: isCountryNotNL,
    },
    {
      href: isCountryNotNL ? usefulLinks.iban : usefulLinks.ibanNL,
      btnTitle: isCountryNotNL ? 'iban_verification' : 'iban_check',
      isShow: true,
    },
    {
      href: isCountryFR
        ? usefulLinks.googleFR(date, profile?.name || '')
        : usefulLinks.google(formattedDate, profile?.name || ''),
      btnTitle: 'accompanying',
      isShow: hasAvailableProfile && isCountryNotNL,
    },
    {
      href: usefulLinks.ups,
      btnTitle: 'call_ups',
      isShow: isRoleServiceAgent && isCountryNotNL,
    },
  ]

  return (
    <SheetContent>
      <div className='p-2'>
        <Typography className='font-semibold'>{getLinksT('title')}</Typography>
      </div>
      <div className='flex flex-col gap-2 px-2'>
        {links.map((item) =>
          orNull(
            item.isShow,
            <Button variant='outline' key={item.btnTitle}>
              <Link href={item.href} target='_blank'>
                {getLinksT(item.btnTitle)}
              </Link>
            </Button>,
          ),
        )}
      </div>
    </SheetContent>
  )
}
