import { FiltersDrawer, FiltersDynamicForm, Header, HistoryHeaderTabs } from 'src/components/common'

import {
  FiltersDynamicType,
  FilterStaticDataType,
  FilterStaticEnum,
  FilterStaticType,
  FormSubmitParams,
} from 'src/models'

import { useCustomersDetailsFetch } from 'src/bus/customers'
import { useHistoryOrdersFetch } from 'src/bus/history'

import { useUrlQuery } from 'src/hooks'

import { useDrawer } from 'src/contexts'

import { GET_ENUMS } from 'src/utils'

export const HistoryOrdersHeader = () => {
  const { setOnPageDrawer } = useDrawer()
  const { onUpdateQuery } = useUrlQuery()
  const { onRefetchHistoryOrdersList, loadingHistoryOrdersFetch, historyFiltersStaticInitialValues } =
    useHistoryOrdersFetch()
  const { customersDetails, loadingCustomersDetailsFetch } = useCustomersDetailsFetch()

  const filterStaticItems: FilterStaticDataType = {
    date: {
      name: FilterStaticEnum.date,
    },
  }

  const filterSearchItem: FilterStaticDataType = {
    search: {
      name: FilterStaticEnum.search,
      placeholder: 'search_history.placeholder',
    },
  }

  const onSubmitDynamic = async (data: FormSubmitParams<FiltersDynamicType>) => {
    const { filterData } = onUpdateQuery({
      filtersValues: data.values,
      filterItems: filterStaticItems,
    })

    await onRefetchHistoryOrdersList(filterData)
  }

  const onSubmitStatic = async (data: FormSubmitParams<FilterStaticType>) => {
    const { filterData } = onUpdateQuery({
      filtersValues: data.values,
      filterItems: filterStaticItems,
    })

    await onRefetchHistoryOrdersList(filterData)
    setOnPageDrawer(GET_ENUMS.drawer.filters, false)
  }

  return (
    <Header
      withShadow
      title={customersDetails?.title}
      subTitle={customersDetails?.companyTitle}
      loadingFetch={loadingCustomersDetailsFetch}
      actionsRight={
        <FiltersDrawer
          onSubmit={onSubmitStatic}
          filterItemsSet={filterStaticItems}
          buttons={['apply', 'reset']}
          loadingSubmit={loadingHistoryOrdersFetch}
          initialValues={historyFiltersStaticInitialValues.filter}
          loadingStaticDataFetch={false}
        />
      }
    >
      <HistoryHeaderTabs />
      <div className='p-2'>
        <FiltersDynamicForm
          filterItemsSet={filterSearchItem}
          onSubmit={onSubmitDynamic}
          advancedIcon='Camera'
          initialValues={historyFiltersStaticInitialValues?.filter}
        />
      </div>
    </Header>
  )
}
