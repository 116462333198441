import { cn } from 'utils/helpers'

import { Icon, Typography } from 'src/components/ui'

import st from './Styles.module.css'

type CellSwipeType = {
  text?: string
  icon?: string
  type?: 'info' | 'warning' | 'danger' | 'success' | 'orange6' | 'gray4' | 'blue-gray3' | 'tomato' | 'green' | 'yellow'
}

export const CellSwipe = ({ text = '', icon = '', type = 'info' }: CellSwipeType) => {
  const classes = cn(st['visits-action'], st[`visits-action-type-${type}`])

  return (
    <div className={classes}>
      <Icon name={icon} color='white' />
      <Typography variant='caption' color='white'>
        {text}
      </Typography>
    </div>
  )
}
