import { Libraries } from '@googlemaps/js-api-loader'
import { useLoadScript } from '@react-google-maps/api'
import { PropsWithChildren, useCallback, useRef } from 'react'

import { PanToCbProps, TGoogleMap } from 'src/models'

import { createStrictContext, googleMapLibraries, useStrictContext } from 'src/utils'

const GoogleContext = createStrictContext<TGoogleMap>()

export const GoogleContextProvider = ({ children }: PropsWithChildren) => {
  const mapInstance = useRef<google.maps.Map | null>(null)
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || '',
    libraries: googleMapLibraries as Libraries,
  })

  const onLoad = useCallback(async (map: google.maps.Map) => {
    mapInstance.current = map

    const bounds = new google.maps.LatLngBounds()
    map.fitBounds(bounds)

    return bounds
  }, [])

  const onUnmount = useCallback(() => {
    mapInstance.current = null
  }, [])

  const panTo = useCallback(({ latLng, withZoom = true }: PanToCbProps) => {
    if (!mapInstance.current) {
      return
    }

    mapInstance.current.panTo(latLng)
    if (withZoom) {
      mapInstance.current.setZoom(14)
    }
  }, [])

  return (
    <GoogleContext.Provider
      value={{
        isLoaded,
        loadError,
        onLoad,
        panTo,
        onUnmount,
      }}
    >
      {children}
    </GoogleContext.Provider>
  )
}

export const useGoogle = () => useStrictContext(GoogleContext)
