import { ArticlesType, ParcelsCheckType } from 'src/models'

import { parcelsKeys, parcelsService } from 'src/bus/parcels'

import { useQueryAsync } from 'src/hooks'

type UseParcelsCheckMoveAllToType = {
  loadingParcelsMoveAllToCheck: boolean
  checkMoveAllToParcels: ParcelsCheckType | null
}
export const useParcelsCheckMoveAllTo = (type: ArticlesType): UseParcelsCheckMoveAllToType => {
  const params = { type }

  const checkMoveAllToParcelsProps = useQueryAsync<ParcelsCheckType>({
    key: parcelsKeys.checkMoveAllTo(type),
    name: 'checkMoveAllToParcels',
    fetcher: parcelsService.checkAllToParcels,
    fetcherPayload: params,
    options: { enabled: !!type, refetchOnMount: 'always' },
  })

  return {
    loadingParcelsMoveAllToCheck: checkMoveAllToParcelsProps.isLoading,
    checkMoveAllToParcels: checkMoveAllToParcelsProps.data || null,
  }
}
