import { useTranslation } from 'next-i18next'
import Link from 'next/link'

import { book } from 'src/init/book'

import { BottomNavigationBar } from 'src/components/common'
import { Button } from 'src/components/ui'

import { useTourRouteOptimize } from 'src/bus/tour'
import { useVisitsFetch } from 'src/bus/visits'

export const ViewRoutePanel = () => {
  const { t } = useTranslation()
  const { visitFirst, loadingVisitsFetch } = useVisitsFetch()
  const { isOptimizationAvailable, onTourRouteOptimize, loadingTourRouteOptimize } = useTourRouteOptimize()

  return (
    <div className='shadow-level-2'>
      <div className='flex gap-2 p-2'>
        <Button
          onClick={onTourRouteOptimize}
          variant='outline'
          block
          disabled={loadingTourRouteOptimize || !isOptimizationAvailable}
          data-test-id='update-tour'
        >
          {t('buttons:update_tour')}
        </Button>
        <Button block disabled={loadingVisitsFetch || !visitFirst}>
          <Link href={book.visits}>{t('buttons:view_full_tour')}</Link>
        </Button>
      </div>
      <BottomNavigationBar />
    </div>
  )
}
