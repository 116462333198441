import { useTranslation } from 'next-i18next'

import { CellCopy, WrappedNodeDefault } from 'src/components/common'
import { Button, DialogContent, DialogHeader, Separator, Skeleton, Typography } from 'src/components/ui'

import { useAuthProfile } from 'src/bus/auth'
import { useCouponsCreate, useCouponsFetch } from 'src/bus/coupons'
import { useVisitsDetailsFetch } from 'src/bus/visits'

import { formatDate, GET_ENUMS, getTranslate, isNotNilOrNotEmpty, orNull, ternary } from 'src/utils'

export const DialogCreateCoupon = () => {
  const { t } = useTranslation()
  const { isRoleServiceAgent, isCountryDE, isCountryAT } = useAuthProfile()
  const { visitsDetails } = useVisitsDetailsFetch()
  const { coupons, loadingCouponsFetch } = useCouponsFetch(visitsDetails?.id)
  const { onCouponCreate, loadingCouponCreate } = useCouponsCreate()

  const coupon = coupons?.current?.code || ''
  const campaignCoupon = coupons?.campaign?.code || ''

  const getVisitsT = getTranslate('visits:coupon_create', t)

  const createCouponRow = (label: string, value?: string | number | Date | null) => {
    return (
      <div className='flex items-center justify-between gap-2'>
        <div className='basis-4/12'>
          <Typography variant='secondary' color='gray-7'>
            {getVisitsT(label)}
          </Typography>
        </div>
        <div className='basis-7/12'>
          {ternary(
            loadingCouponsFetch,
            <Skeleton className='h-[38px]' />,
            <div className='py-2'>
              <Typography variant='secondary'>{WrappedNodeDefault(value)}</Typography>
            </div>,
          )}
        </div>
      </div>
    )
  }

  const onCouponSubmit = (type?: string) => onCouponCreate(type)

  return (
    <DialogContent name={GET_ENUMS.dialog.createCoupon} className='px-0'>
      <DialogHeader>
        <Typography variant='title' color='gray-10'>
          {getVisitsT('title', { name: visitsDetails?.companyName })}
        </Typography>
      </DialogHeader>
      <div className='flex flex-col gap-2'>
        <div className='flex flex-col gap-2 px-4 pb-2'>
          {createCouponRow('last_coupon', coupons?.current?.type)}
          <Separator className='my-0' />
          <div className='flex items-center justify-between gap-2'>
            <div className='basis-4/12'>
              <Typography variant='secondary' color='gray-7'>
                {getVisitsT('coupon')}
              </Typography>
            </div>
            <div className='basis-7/12'>
              <CellCopy
                value={coupon}
                copyValue={coupon}
                disabled={!coupon || loadingCouponsFetch}
                copiedText='coupons_copied'
                loadingFetch={loadingCouponsFetch}
                className='px-4 py-2'
              />
            </div>
          </div>
          <Separator className='my-0' />
          {createCouponRow('days_left', coupons?.current?.daysLeft)}
          <Separator className='my-3' />
          {createCouponRow('next_coupon', coupons?.next?.type)}
          <Separator className='my-0' />
          {createCouponRow('date', coupons?.next?.availableDate ? formatDate(coupons.next.availableDate, 'dot') : '')}
          {orNull(
            isRoleServiceAgent || isCountryDE || isCountryAT,
            <>
              <Separator className='my-3' />
              {createCouponRow('available_quantity', coupons?.availableLoyaltyQuantity)}
              <Separator className='my-0' />
            </>,
          )}
          <Button
            variant='outline'
            block
            onClick={() => onCouponSubmit()}
            loading={loadingCouponCreate && coupons?.available}
            disabled={!coupons?.available}
          >
            {t('buttons:create_new_coupon')}
          </Button>
        </div>
        {orNull(
          isNotNilOrNotEmpty(coupons?.campaign),
          <div className='flex flex-col gap-2 border-b border-t border-blue-gray-2 bg-white px-4 py-2'>
            {createCouponRow('campaign', coupons?.campaign?.name)}
            <Separator className='my-1' />
            {createCouponRow(
              'period',
              `${formatDate(coupons?.campaign?.period?.start, 'slash')} - ${formatDate(coupons?.campaign?.period?.end, 'slash')}`,
            )}
            <Separator className='my-1' />
            <div className='flex items-center justify-between gap-2'>
              <div className='basis-4/12'>
                <Typography variant='secondary' color='gray-7'>
                  {getVisitsT('coupon')}
                </Typography>
              </div>
              <div className='basis-7/12'>
                <CellCopy
                  value={campaignCoupon}
                  copyValue={campaignCoupon}
                  disabled={!campaignCoupon}
                  copiedText='coupons_copied'
                  className='px-4 py-2'
                />
              </div>
            </div>
            <Button
              variant='outline'
              block
              onClick={() => onCouponSubmit('campaign')}
              loading={loadingCouponCreate && coupons?.campaignAvailable}
              disabled={!coupons?.campaignAvailable}
              className='mt-2'
            >
              {t('buttons:create_campaign_coupon')}
            </Button>
          </div>,
        )}
      </div>
    </DialogContent>
  )
}
