import { Col, Row } from 'antd'
import { useRouter } from 'next/router'

import { book } from 'src/init/book'

import { Link, WrappedNodeDefault, CornerEuro } from 'src/components/common'
import { ListItem, Typography } from 'src/components/ui'

import { ClaimType } from 'src/models'

import { orNull, rowGutters } from 'src/utils'

type ClaimsItemProps = {
  item: ClaimType
}

export const ClaimsAutocompleteItem = ({ item }: ClaimsItemProps) => {
  const router = useRouter()

  const returnId = router.query.id

  const pathToDetails = {
    pathname: book.claims,
    query: { returnId, id: item.id, customerId: router.query.customerId },
  }

  const renderContent = (value?: string | number | null, span?: number) => {
    return (
      <Col span={span}>
        <Typography variant='secondary'>{WrappedNodeDefault(value)}</Typography>
      </Col>
    )
  }

  return (
    <ListItem>
      {orNull(!item.isOrderPaid, <CornerEuro />)}
      <Link href={pathToDetails}>
        <Row align='middle' gutter={rowGutters}>
          {renderContent(item.article, 7)}
          {renderContent(item.cause?.label, 5)}
          {renderContent(item.orderId, 4)}
          {renderContent(item.quantity?.label, 3)}
          {renderContent(item.amount, 5)}
        </Row>
      </Link>
    </ListItem>
  )
}
