import { QueryClientProvider, HydrationBoundary, QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ConfigProvider as AntdConfigProvider } from 'antd'
import { appWithTranslation, SSRConfig } from 'next-i18next'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
// @ts-ignore
import NextI18nConfig from 'nextI18nConfig'
import { useState } from 'react'

import 'src/init/dbr'
import { queryOptions } from 'src/init/queryClient'

import { DynamicImport } from 'src/components/common'
import { ToastContainer } from 'src/components/ui'

import { DialogContextProvider, DrawerContextProvider, GoogleContextProvider } from 'src/contexts'

import { antdLocales, initialLocale, verifyEnvironment } from 'src/utils'

// import side effects. The license, engineResourcePath, so on.
import '../styles/globals.css'
import '../theme/init.css'

if (process.env.NEXT_PUBLIC_ENABLE_MOCKING) {
  const { setupMocks } = await import('src/mocks')
  await setupMocks()
}

const AuthSessionProviderDynamic = DynamicImport(import('../contexts/useAuthSessionContext'), 'AuthSessionProvider')

type PageProps = SSRConfig & {
  dehydratedState: unknown
}

type Props = Omit<AppProps<PageProps>, 'pageProps'> & {
  pageProps: PageProps
}

const MainApp = ({ Component, pageProps }: Props) => {
  const router = useRouter()
  const { _nextI18Next, dehydratedState, ...otherPageProps } = pageProps
  const activeLang = _nextI18Next?.initialLocale || router.locale || initialLocale

  const [queryClient] = useState(() => new QueryClient(queryOptions))

  return (
    <QueryClientProvider client={queryClient}>
      <HydrationBoundary state={dehydratedState}>
        <AntdConfigProvider locale={antdLocales(activeLang)}>
          <AuthSessionProviderDynamic>
            <GoogleContextProvider>
              <DialogContextProvider>
                <DrawerContextProvider>
                  <Component {...otherPageProps} />
                </DrawerContextProvider>
              </DialogContextProvider>
            </GoogleContextProvider>
          </AuthSessionProviderDynamic>
        </AntdConfigProvider>
        <ToastContainer />
        {/*{verifyEnvironment().isDevelopment && <ReactQueryDevtools initialIsOpen={false} />}*/}
      </HydrationBoundary>
    </QueryClientProvider>
  )
}

export default appWithTranslation(MainApp, NextI18nConfig)
