import { useEffect } from 'react'

import { useCheckCompliance, useParcelsDetailsFetch } from 'src/bus/parcels'

type UseParcelsCheckComplianceType = {
  onCheckCompliance: (value: boolean) => void
  checkCompliance: boolean
}

export const useParcelsCheckCompliance = (): UseParcelsCheckComplianceType => {
  const { parcelsDetails } = useParcelsDetailsFetch()
  const { checkCompliance, onCheckCompliance } = useCheckCompliance()

  useEffect(() => {
    if (parcelsDetails?.isChecked) {
      onCheckCompliance(true)
    }
  }, [parcelsDetails, onCheckCompliance])

  return {
    onCheckCompliance,
    checkCompliance,
  }
}
