import type { TFunction } from 'i18next'
import { z } from 'zod'

import { messages } from 'src/utils'

export const updateCustomersLocation = {
  shape: {
    realLocation: null,
  },
  schema: (t: TFunction) => {
    return z.object({
      realLocation: z.object(
        {
          lat: z.number(),
          lng: z.number(),
        },
        { invalid_type_error: t(messages.locationPoint.required) },
      ),
    })
  },
}
