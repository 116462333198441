import { StaticDataItemType, StaticDataKeys } from 'src/models'

import { useStaticDataFetch } from 'src/bus/staticData'

type UseHistoryStaticDataFetchType = {
  historyType: StaticDataItemType[]
  customerHistoryType: StaticDataItemType[]
  loadingHistoryStaticDataFetch: boolean
}

export const useHistoryStaticDataFetch = (): UseHistoryStaticDataFetchType => {
  const staticDataKeys = [StaticDataKeys.communicationType, StaticDataKeys.customerHistoryType]

  const { staticData, loadingStaticDataFetch } = useStaticDataFetch(staticDataKeys)

  return {
    loadingHistoryStaticDataFetch: loadingStaticDataFetch,
    historyType: staticData?.['communication-type'] || [],
    customerHistoryType: staticData?.['customer-history-type'] || [],
  }
}
