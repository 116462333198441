import { toastEnhanced } from 'src/components/common'

import { HttpErrorType, HttpStatusCode } from 'src/models'

type QueryErrorType = {
  err: HttpErrorType
  handlerError404?: () => Promise<void>
  handlerError422?: () => Promise<void>
}
export const onError = async ({ err, handlerError404, handlerError422 }: QueryErrorType) => {
  console.log('err', err)
  const { status, message, type } = err || {}
  const isError400 = status === HttpStatusCode.badRequest
  const isError404 = status === HttpStatusCode.notFound
  const isError500 = status >= HttpStatusCode.server
  const isError422 = status === HttpStatusCode.unprocessableEntity

  if (isError404 && handlerError404) {
    await handlerError404()
    return
  }
  if (isError422 && handlerError422) {
    await handlerError422()
    return
  }

  if (isError400 || isError500) {
    toastEnhanced(
      {
        title: type || 'Unavailable',
        body: message || 'Something went wrong. Please, try again later',
      },
      { type: 'error' },
    )
  }
}
