import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'next-i18next'
import { useForm } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { Button, Form, FormControl, FormField, FormFields, FormItem, FormMessage } from 'src/components/ui'

import { FormCreateFarAwayReportEnum, FormCreateFarAwayReportType, FormSubmitFn } from 'src/models'

import { formShapes } from 'src/bus/forms'

import { useDialog } from 'src/contexts'

import { GET_ENUMS, messages } from 'src/utils'

type CreateFarAwayReportFormProps = {
  initialValues: FormCreateFarAwayReportType | null
  onSubmit: FormSubmitFn<FormCreateFarAwayReportType>
  loadingCreate?: boolean
}

export const CreateFarAwayReportForm = ({
  initialValues = null,
  loadingCreate = false,
  onSubmit,
}: CreateFarAwayReportFormProps) => {
  const { t } = useTranslation()
  const { setOnPageDialog } = useDialog()

  const formProps = useForm<FormCreateFarAwayReportType>({
    defaultValues: { ...formShapes.createFarAwayReport.shape },
    resolver: zodResolver(formShapes.createFarAwayReport.schema(t)),
  })

  const { control, handleSubmit, setError, reset } = formProps

  const onSubmitForm = handleSubmit(async (values) => {
    await onSubmit({ values, acts: { setError, reset } })
  })

  const onCancel = () => setOnPageDialog(GET_ENUMS.dialog.farAwayReport, false)

  useDeepCompareEffect(() => {
    if (initialValues) {
      reset(initialValues)
    }
  }, [initialValues, reset])

  return (
    <Form {...formProps}>
      <FormFields.FormGeneral
        onSubmit={onSubmitForm}
        footer={
          <div className='grid grid-cols-2 gap-2'>
            <Button block disabled={loadingCreate} variant='cta' onClick={onCancel}>
              {t('buttons:cancel')}
            </Button>
            <Button block type='submit' loading={loadingCreate}>
              {t('buttons:finish_visit')}
            </Button>
          </div>
        }
      >
        <FormField
          control={control}
          name={FormCreateFarAwayReportEnum.comment}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormFields.InputTextArea
                  {...field}
                  placeholder={t(messages.createFarAwayReport.comment.placeholder)}
                  disabled={loadingCreate}
                  rows={3}
                />
              </FormControl>
              <FormMessage className='absolute' />
            </FormItem>
          )}
        />
      </FormFields.FormGeneral>
    </Form>
  )
}
