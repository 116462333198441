import type { TFunction } from 'i18next'
import { z } from 'zod'

import { messages } from 'src/utils'

export const createFarAwayReport = {
  shape: {
    comment: '',
  },
  schema: (t: TFunction) => {
    return z
      .object({
        comment: z
          .string()
          .trim()
          .min(
            messages.createFarAwayReport.comment.min,
            t(messages.createFarAwayReport.comment.minText, {
              label: t(messages.createFarAwayReport.comment.placeholder).toLowerCase(),
              count: messages.createFarAwayReport.comment.min,
            }),
          )
          .max(
            messages.createFarAwayReport.comment.max,
            t(messages.createFarAwayReport.comment.maxText, {
              label: t(messages.createFarAwayReport.comment.placeholder).toLowerCase(),
              count: messages.createFarAwayReport.comment.max,
            }),
          ),
      })
      .passthrough()
      .nullable()
  },
}
