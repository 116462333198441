import type { TFunction } from 'i18next'
import { z } from 'zod'

import { messages } from 'src/utils'

export const planVisit = {
  shape: {
    date: new Date(),
    comment: '',
  },
  schema: (t: TFunction) => {
    return z.object({
      date: z.date().default(() => new Date()),
      comment: z
        .string()
        .trim()
        .min(
          messages.replanVisit.comment.min,
          t(messages.replanVisit.comment.minText, {
            label: t(messages.replanVisit.comment.placeholder).toLowerCase(),
            count: messages.replanVisit.comment.min,
          }),
        )
        .max(
          messages.replanVisit.comment.max,
          t(messages.replanVisit.comment.maxText, {
            label: t(messages.replanVisit.comment.placeholder).toLowerCase(),
            count: messages.replanVisit.comment.max,
          }),
        ),
    })
  },
}
