import { ParcelsArticlesInfiniteType, TRefetchFilterStaticCb } from 'src/models'

import { useParcelsArticlesFetch } from 'src/bus/parcels'

type UseParcelsClearFetchType = {
  articlesListClearHasMore: boolean
  loadingArticlesClearFetch: boolean
  onFetchMoreArticlesListClear: () => void
  articlesListClear: ParcelsArticlesInfiniteType
  onRefetchArticlesListClear: TRefetchFilterStaticCb
}

export const useParcelsClearFetch = (): UseParcelsClearFetchType => {
  const {
    articlesList,
    articlesListHasMore,
    loadingArticlesListFetch,
    onFetchMoreArticlesList,
    onRefetchParcelsArticles,
  } = useParcelsArticlesFetch({ type: 'clear' })

  return {
    articlesListClear: articlesList,
    articlesListClearHasMore: articlesListHasMore,
    onFetchMoreArticlesListClear: onFetchMoreArticlesList,
    loadingArticlesClearFetch: loadingArticlesListFetch,
    onRefetchArticlesListClear: onRefetchParcelsArticles,
  }
}
