import { useQuery, useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { AuthProfileType, AuthRole, SettingsType } from 'src/models'

import { authKeys, authService } from 'src/bus/auth'
import { settingsKeys, settingsService } from 'src/bus/settings'

import {
  getCurrentRole,
  getIsRoleServiceAgent,
  getIsRoleProspector,
  getIsCountryDE,
  getAccessToken,
  queryFetcher,
  isNotNilOrNotEmpty,
  getIsCountryNL,
  getIsCountryAT,
  getIsCountryNotNL,
  getCurrentCountry,
  getIsCountryFR,
} from 'src/utils'

type UseAuthProfileType = {
  onPrefetchProfile: () => Promise<void>
  loadingAuthProfileFetch: boolean
  profile?: AuthProfileType
  userRole: AuthRole
  isRoleServiceAgent: boolean
  isRoleProspector: boolean
  isCountryDE: boolean
  isCountryFR: boolean
  isCountryNL: boolean
  isCountryAT: boolean
  isCountryNotNL: boolean
  country: string
}

export const useAuthProfile = (): UseAuthProfileType => {
  const router = useRouter()
  const token = getAccessToken()
  const queryClient = useQueryClient()

  const { data: profile, isLoading } = useQuery<AuthProfileType>({
    queryKey: authKeys.profile(router.locale),
    queryFn: () => queryFetcher({ name: 'fetchProfile', fetcher: authService.profile }),
    enabled: !!token,
  })

  const onPrefetchProfile = useCallback(async () => {
    await queryClient.prefetchQuery({
      queryKey: authKeys.profile(router.locale),
      queryFn: () =>
        queryFetcher({
          name: 'fetchProfile',
          fetcher: authService.profile,
        }),
    })
    await queryClient.prefetchQuery({
      queryKey: settingsKeys.settings(),
      queryFn: () =>
        queryFetcher({
          name: 'fetchSettings',
          fetcher: settingsService.fetchSettings,
        }),
    })

    const settings: SettingsType | undefined = queryClient.getQueryData(settingsKeys.settings())

    if (isNotNilOrNotEmpty(settings?.language)) {
      dayjs(settings?.language?.value)
      await router.push(router.asPath, undefined, {
        locale: settings?.language?.value,
        shallow: true,
      })
    }
  }, [router, queryClient])

  const userRole = getCurrentRole(profile)
  const country = getCurrentCountry(profile)
  const isRoleServiceAgent = getIsRoleServiceAgent(profile)
  const isRoleProspector = getIsRoleProspector(profile)
  const isCountryDE = getIsCountryDE(profile)
  const isCountryFR = getIsCountryFR(profile)
  const isCountryNL = getIsCountryNL(profile)
  const isCountryAT = getIsCountryAT(profile)
  const isCountryNotNL = getIsCountryNotNL(profile)

  return {
    onPrefetchProfile,
    loadingAuthProfileFetch: isLoading,
    profile,
    userRole,
    country,
    isRoleServiceAgent,
    isRoleProspector,
    isCountryDE,
    isCountryFR,
    isCountryNL,
    isCountryAT,
    isCountryNotNL,
  }
}
