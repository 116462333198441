import type { TFunction } from 'i18next'

import { book } from 'src/init/book'

import type { MenuProfileType } from 'src/models'

export const getProfileMenu = (t: TFunction): MenuProfileType[] => {
  return [
    {
      title: t('profile:today_tour'),
      key: book.dashboard,
      icon: 'Map',
    },
    {
      title: t('profile:plan_for_today'),
      key: book.visits,
      icon: 'List',
    },
    {
      title: t('profile:my_points_settings'),
      key: book.tourPoints,
      icon: 'ProfileLocation',
    },
    {
      title: t('buttons:create_new_visit'),
      key: book.visitsCreate,
      icon: 'Plus',
    },
    {
      title: t('profile:navigator'),
      key: 'navigation',
      disabled: false,
      icon: 'Navigation',
      arrow: true,
      type: 'panel',
    },
    {
      title: t('profile:language'),
      key: 'languages',
      disabled: false,
      icon: 'Global',
      arrow: true,
      type: 'panel',
    },
    {
      title: t('profile:useful_links.title'),
      key: 'links',
      disabled: false,
      icon: 'Link',
      arrow: true,
      type: 'panel',
    },
  ]
}
