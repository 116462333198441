import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import { useCallback, useState } from 'react'

import { HttpErrorType } from 'src/models'

import { parcelsKeys, useParcelsReplaceMutation } from 'src/bus/parcels'

import { useToast } from 'src/hooks'

import { useDialog } from 'src/contexts'

import { GET_ENUMS } from 'src/utils'

type ReplaceParcelsCb = (id?: number, parcelId?: number) => void

type UseParcelsReplaceType = {
  loadingParcelsReplace: boolean
  onReplaceParcels: ReplaceParcelsCb
}

export const useParcelsReplace = (): UseParcelsReplaceType => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { onReplaceParcelsMutation } = useParcelsReplaceMutation()
  const showToast = useToast()
  const { setOnPageDialog } = useDialog()

  const [isLoading, setIsLoading] = useState(false)

  const onReplaceParcels: ReplaceParcelsCb = useCallback(
    async (id, parcelId) => {
      try {
        setIsLoading(true)

        await onReplaceParcelsMutation.mutateAsync({
          params: { id: String(id) },
          data: { parcelId },
        })
        setOnPageDialog(GET_ENUMS.dialog.replaceArticle, false)
        showToast.success({ title: t('notifications:parcel_replace') })
        await queryClient.invalidateQueries({ queryKey: parcelsKeys.articles() })
        await queryClient.invalidateQueries({ queryKey: parcelsKeys.totals() })
        await queryClient.invalidateQueries({ queryKey: parcelsKeys.checkGenerate('clear') })
        await queryClient.invalidateQueries({ queryKey: parcelsKeys.checkGenerate('dirty') })
        parcelId && (await queryClient.invalidateQueries({ queryKey: parcelsKeys.detail(parcelId) }))
      } catch (error) {
        const errs = (error as HttpErrorType).errors
        if (errs) {
          const [[title, body]] = Object.entries(errs)
          showToast.error({ title, body: body.desc })
        }
      } finally {
        setIsLoading(false)
      }
    },
    [showToast, onReplaceParcelsMutation, t, setOnPageDialog, queryClient],
  )

  return {
    loadingParcelsReplace: isLoading,
    onReplaceParcels,
  }
}
