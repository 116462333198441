import { useTranslation } from 'next-i18next'

import { UpdateTourPointsForm, TourPointsList } from 'src/components/common'
import { Typography } from 'src/components/ui'

import { useTourPointsStaticFetch, useTourPointsUpdate } from 'src/bus/tour'

export const TourPointsView = () => {
  const { t } = useTranslation()
  const { tourPointsStatic, loadingTourPointsStaticFetch } = useTourPointsStaticFetch()
  const { onUpdateTourPoint, loadingTourPointsUpdate } = useTourPointsUpdate()

  return (
    <>
      <div className='mt-6 px-2' data-test-id='tour-points'>
        <div className='mb-4'>
          <Typography variant='secondary' className='font-semibold'>
            {t('tour-points:my_tour_points')}
          </Typography>
        </div>
        <UpdateTourPointsForm
          onSubmit={onUpdateTourPoint}
          points={tourPointsStatic}
          loadingUpdate={loadingTourPointsUpdate}
          loadingPointsFetch={loadingTourPointsStaticFetch}
        />
      </div>
      <TourPointsList />
    </>
  )
}
