import { useRouter } from 'next/router'

import { VisitCreateReportParamsType } from 'src/models'

import { visitsHistoryKeys, visitsHistoryService } from 'src/bus/visitsHistory'

import { useQueryAsync } from 'src/hooks'

type UseVisitsHistoryReportFetchType = {
  loadingVisitsHistoryReportFetch: boolean
  visitsHistoryReport: VisitCreateReportParamsType | null
}

export const useVisitsHistoryReportFetch = (): UseVisitsHistoryReportFetchType => {
  const router = useRouter()

  const id = Number(router.query.id)
  const params = { id }

  const fetchVisitsHistoryReportProps = useQueryAsync<VisitCreateReportParamsType>({
    key: visitsHistoryKeys.historyReport(id),
    name: 'fetchVisitsHistoryReport',
    fetcher: visitsHistoryService.fetchVisitsHistoryReport,
    fetcherPayload: params,
    options: { enabled: !!id },
  })

  return {
    loadingVisitsHistoryReportFetch: fetchVisitsHistoryReportProps.isLoading,
    visitsHistoryReport: fetchVisitsHistoryReportProps.data || null,
  }
}
