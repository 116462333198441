import { Col, Row } from 'antd'
import { always, cond, equals, T } from 'ramda'

import { Skeleton } from 'src/components/ui'

import { SkeletonType } from 'src/models'

import { rowGutters } from 'src/utils'

type SkeletonItemProps = {
  type?: SkeletonType
}

export const SkeletonItem = ({ type = 'default' }: SkeletonItemProps) => {
  const item = cond([
    [
      equals('metrics'),
      always(
        <div className='flex w-full'>
          {Array(4)
            .fill(null)
            .map((_, index) => {
              return (
                <Col key={index} span={6}>
                  <Skeleton className='mb-3 h-12 w-12 rounded-full' />
                  <Skeleton className='mb-1 h-4' />
                  <Skeleton className='h-[22px]' />
                </Col>
              )
            })}
        </div>,
      ),
    ],
    [
      equals('history'),
      always(
        <Col span={24}>
          <Skeleton className='my-2 h-[124px]' />
        </Col>,
      ),
    ],
    [
      equals('actions'),
      always(
        <div className='mb-1 flex w-full'>
          <Col span={24}>
            <Skeleton className='h-10' />
          </Col>
        </div>,
      ),
    ],
    [
      equals('checklist'),
      always(
        <Col span={24}>
          <Skeleton className='mb-2 h-11' />
        </Col>,
      ),
    ],
    [
      equals('returns'),
      always(
        <div className='flex w-full rounded-md border-b border-blue-gray-2 bg-white px-2 py-3'>
          <Col span={5}>
            <Skeleton className='h-[22px]' />
          </Col>
          <Col span={6}>
            <Skeleton className='h-[22px]' />
          </Col>
          <Col span={7}>
            <Skeleton className='h-[22px]' />
          </Col>
          <Col span={6}>
            <Skeleton className='h-[22px]' />
          </Col>
        </div>,
      ),
    ],
    [
      equals('claims'),
      always(
        <div className='flex w-full rounded-md border-b border-blue-gray-2 bg-white px-2 py-4'>
          <Col span={7}>
            <Skeleton className='h-[22px]' />
          </Col>
          <Col span={5}>
            <Skeleton className='h-[22px]' />
          </Col>
          <Col span={4}>
            <Skeleton className='h-[22px]' />
          </Col>
          <Col span={3}>
            <Skeleton className='h-[22px]' />
          </Col>
          <Col span={5}>
            <Skeleton className='h-[22px]' />
          </Col>
        </div>,
      ),
    ],
    [
      equals('tour-points'),
      always(
        <div className='flex w-full rounded-md border-b border-blue-gray-2 bg-white px-2 py-4'>
          <Col span={15}>
            <Skeleton className='h-[22px]' />
          </Col>
          <Col span={9}>
            <Skeleton className='h-[22px]' />
          </Col>
        </div>,
      ),
    ],
    [
      equals('customers'),
      always(
        <div className='mb-2 flex w-full rounded-md border-b border-blue-gray-2 bg-white px-4 py-5'>
          <Col span={14}>
            <Skeleton className='h-11' />
          </Col>
          <Col span={5}>
            <Skeleton className='h-11' />
          </Col>
          <Col span={5}>
            <Skeleton className='h-11' />
          </Col>
        </div>,
      ),
    ],
    [
      equals('parcels'),
      always(
        <div className='flex w-full rounded-md border-b border-blue-gray-2 bg-white px-2 py-4'>
          <Col span={4}>
            <Skeleton className='h-6' />
          </Col>
          <Col span={8}>
            <Skeleton className='h-6' />
          </Col>
          <Col span={3}>
            <Skeleton className='h-6' />
          </Col>
          <Col span={3}>
            <Skeleton className='h-6' />
          </Col>
          <Col span={6}>
            <Skeleton className='h-6' />
          </Col>
        </div>,
      ),
    ],
    [
      equals('parcels-articles'),
      always(
        <div className='flex w-full rounded-md border-b border-blue-gray-2 bg-white px-2 py-4'>
          <Col span={8}>
            <Skeleton className='h-6' />
          </Col>
          <Col span={4}>
            <Skeleton className='h-6' />
          </Col>
          <Col span={3}>
            <Skeleton className='h-6' />
          </Col>
          <Col span={3}>
            <Skeleton className='h-6' />
          </Col>
          <Col span={6}>
            <Skeleton className='h-6' />
          </Col>
        </div>,
      ),
    ],
    [
      equals('orders-articles'),
      always(
        <div className='flex w-full rounded-md border-b border-blue-gray-2 bg-white px-2 py-4'>
          <Col span={6}>
            <Skeleton className='h-[22px]' />
          </Col>
          <Col span={6}>
            <Skeleton className='h-[22px]' />
          </Col>
          <Col span={5}>
            <Skeleton className='h-[22px]' />
          </Col>
          <Col span={3}>
            <Skeleton className='h-[22px]' />
          </Col>
          <Col span={4}>
            <Skeleton className='h-[22px]' />
          </Col>
        </div>,
      ),
    ],
    [
      equals('visits-history'),
      always(
        <div className='mb-2 flex w-full rounded-md border-b border-blue-gray-2 bg-white px-4 py-3'>
          <Col span={12}>
            <Skeleton className='h-11' />
          </Col>
          <Col span={5}>
            <Skeleton className='h-11' />
          </Col>
          <Col span={7}>
            <Skeleton className='h-11' />
          </Col>
        </div>,
      ),
    ],
    [
      equals('orders-delivery'),
      always(
        <div className='flex w-full rounded-md border-b border-blue-gray-2 bg-white p-2'>
          <Col span={6}>
            <Skeleton className='h-[29px]' />
          </Col>
          <Col span={12}>
            <Skeleton className='h-[29px]' />
          </Col>
          <Col span={6}>
            <Skeleton className='h-[29px]' />
          </Col>
        </div>,
      ),
    ],
    [
      T,
      always(
        <div className='flex w-full rounded-md border-b border-blue-gray-2 bg-white p-2'>
          <Col span={24}>
            <Skeleton className='h-[22px]' />
          </Col>
        </div>,
      ),
    ],
  ])(type)

  return (
    <div className='w-full'>
      <Row gutter={rowGutters}>{item}</Row>
    </div>
  )
}
