import { useRouter } from 'next/router'

import { HistoryOrdersItem, SkeletonList } from 'src/components/common'
import { Empty, InfiniteScroll } from 'src/components/ui'

import { useHistoryOrdersFetch } from 'src/bus/history'

import { getInfiniteListLength, getIsShowDate, getUniqueID } from 'src/utils'

export const HistoryOrdersTabContent = () => {
  const router = useRouter()
  const { historyOrders, loadingHistoryOrdersFetch, historyOrdersHasMore, onFetchMoreHistoryOrders } =
    useHistoryOrdersFetch()

  const customerId = Number(router.query.id)

  const renderItems = () =>
    historyOrders.pages.map((page) => {
      return page.items.map((item, key) => {
        return (
          <HistoryOrdersItem
            key={getUniqueID()}
            index={key}
            showDate={getIsShowDate(key, historyOrders)}
            item={item}
            customerId={customerId}
          />
        )
      })
    })

  return (
    <div className='flex h-full flex-col py-2' data-test-id='customers-orders'>
      <InfiniteScroll
        hasMore={historyOrdersHasMore}
        onFetchMore={onFetchMoreHistoryOrders}
        dataLength={getInfiniteListLength(historyOrders.pages)}
        scrollableTarget='orders'
        loadingFetch={loadingHistoryOrdersFetch}
        renderItems={renderItems}
        loader={<SkeletonList type='history' />}
        empty={<Empty title='common:empty.no_activity' description='common:empty.orders_list' />}
      />
    </div>
  )
}
