import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { FormSubmitParams, FormCreateTourPointsType, HttpErrorType } from 'src/models'

import { tourKeys, useTourPointsCreateMutation } from 'src/bus/tour'

import { useToast } from 'src/hooks'

import { fillServerErrorsToForm } from 'src/utils'

type CreateTourPointCbType = (data: FormSubmitParams<FormCreateTourPointsType>) => void

type UseTourPointsCreateType = {
  loadingTourPointsCreate: boolean
  onCreateTourPoint: CreateTourPointCbType
}

export const useTourPointsCreate = (): UseTourPointsCreateType => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const router = useRouter()
  const showToast = useToast()
  const { onCreateTourPointMutation } = useTourPointsCreateMutation()

  const onCreateTourPoint = useCallback<CreateTourPointCbType>(
    async (data) => {
      const values = {
        label: data.values.label,
        ...data.values.coordinates,
      }

      try {
        await onCreateTourPointMutation.mutateAsync(
          { data: values },
          {
            onSuccess: async () => {
              data.acts.reset()
              showToast.success({ title: t('notifications:point_create') })
              setTimeout(() => {
                router.back()
              }, 500)
              await queryClient.refetchQueries({ queryKey: tourKeys.tourPoints() })
              await queryClient.refetchQueries({ queryKey: tourKeys.tourPointsStatic() })
            },
          },
        )
      } catch (error) {
        const errs = (error as HttpErrorType).errors
        errs && fillServerErrorsToForm(errs, data.acts.setError)
      }
    },
    [onCreateTourPointMutation, router, showToast, t, queryClient],
  )

  return {
    onCreateTourPoint,
    loadingTourPointsCreate: onCreateTourPointMutation.isPending,
  }
}
