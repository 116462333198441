import { ServerErrorBaseType, TourPointsType } from 'src/models'

import { tourState, tourKeys, tourService } from 'src/bus/tour'

import { useQueryAsync } from 'src/hooks'

type UseTourPointsFetchType = {
  loadingTourPointsFetch: boolean
  tourPoints: TourPointsType
  errorTourPoints?: ServerErrorBaseType | null
}

export const useTourPointsFetch = (): UseTourPointsFetchType => {
  const fetchTourPointsProps = useQueryAsync<TourPointsType>({
    key: tourKeys.tourPoints(),
    name: 'fetchTourPoints',
    fetcher: tourService.fetchTourPoints,
  })

  return {
    loadingTourPointsFetch: fetchTourPointsProps.isLoading,
    tourPoints: fetchTourPointsProps.data || tourState.tourPoints,
    errorTourPoints: fetchTourPointsProps.error,
  }
}
