import * as SeparatorPrimitive from '@radix-ui/react-separator'
import * as React from 'react'

import { cn } from 'src/utils'

const Separator = React.forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>
>(({ className, orientation = 'horizontal', decorative = true, ...rest }, ref) => (
  <SeparatorPrimitive.Root
    ref={ref}
    decorative={decorative}
    orientation={orientation}
    className={cn(
      'shrink-0 bg-blue-gray-2',
      orientation === 'horizontal' ? 'h-[1px] w-full' : 'h-4 w-[1px]',
      className,
    )}
    {...rest}
  />
))

Separator.displayName = SeparatorPrimitive.Root.displayName

export { Separator }
