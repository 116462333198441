import * as DialogPrimitive from '@radix-ui/react-dialog'
import * as React from 'react'

import { useDialog } from 'src/contexts'

import { cn } from 'src/utils'

import { Icon } from './Icon'

const DialogUi = DialogPrimitive.Root

const DialogTrigger = DialogPrimitive.Trigger

const DialogPortal = DialogPrimitive.Portal

const DialogClose = DialogPrimitive.Close

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay> & { name: string }
>(({ className, name, ...rest }, ref) => {
  const { onPageDialogs, setOnPageDialog } = useDialog()
  const dialogState = onPageDialogs[name] || { visible: false, zIndex: 110 }

  return (
    <DialogPrimitive.Overlay
      onClick={() => setOnPageDialog(name, false)}
      ref={ref}
      style={{ zIndex: dialogState.zIndex, pointerEvents: dialogState.visible ? 'auto' : 'none' }}
      className={cn(
        'fixed inset-0 z-[110] bg-gray-content/90 backdrop-blur-sm data-[state=open]:animate-in' +
          ' data-[state=closed]:animate-out' +
          ' data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
        className,
      )}
      {...rest}
    />
  )
})

DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & { name: string }
>(({ className, children, name, ...rest }, ref) => {
  const { onPageDialogs } = useDialog()

  const dialogState = onPageDialogs[name] || { visible: false, zIndex: 110 }

  return (
    <DialogPortal>
      {dialogState.visible && <DialogOverlay name={name} />}
      <DialogPrimitive.Content
        ref={ref}
        style={{ zIndex: dialogState.zIndex, pointerEvents: dialogState.visible ? 'auto' : 'none' }}
        className={cn(
          'fixed left-[50%] top-[50%] z-[111] grid max-h-full w-[calc(100%-16px)] max-w-lg grid-rows-[auto_1fr]' +
            ' translate-x-[-50%]' +
            ' translate-y-[-50%] gap-4 rounded-md bg-blue-gray-1 px-4 pb-4 pt-8 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]',
          className,
        )}
        {...rest}
      >
        {children}
        <DialogClose
          aria-label='Close'
          className='absolute right-1 top-1 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground'
        >
          <Icon name='Cross' color='gray-10' />
          <span className='sr-only'>Close</span>
        </DialogClose>
      </DialogPrimitive.Content>
    </DialogPortal>
  )
})

DialogContent.displayName = DialogPrimitive.Content.displayName

const DialogHeader = ({ className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('flex flex-col space-y-1.5 px-1 text-center', className)} {...rest} />
)

DialogHeader.displayName = 'DialogHeader'

const DialogFooter = ({ className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('flex flex-col-reverse', className)} {...rest} />
)

DialogFooter.displayName = 'DialogFooter'

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...rest }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn('text-lg font-semibold leading-none tracking-tight', className)}
    {...rest}
  />
))

DialogTitle.displayName = DialogPrimitive.Title.displayName

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...rest }, ref) => (
  <DialogPrimitive.Description ref={ref} className={cn('text-sm text-muted-foreground', className)} {...rest} />
))

DialogDescription.displayName = DialogPrimitive.Description.displayName

export {
  DialogUi,
  DialogPortal,
  DialogOverlay,
  DialogClose,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
}
