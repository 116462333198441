import { useRouter } from 'next/router'
import QS from 'qs'
import { useCallback } from 'react'

import { ClaimsSearchType, FilterStaticFilterQueryParamsType, TRefetchFilterStaticCb } from 'src/models'

import { claimsKeys, claimsService } from 'src/bus/claims'
import { useCustomersDetailsFetch } from 'src/bus/customers'

import { useQueryAsync } from 'src/hooks'

import { getParamsFromQueryStr } from 'src/utils'

type UseClaimsSearchListFetchType = {
  loadingClaimsSearchListFetch: boolean
  claimsSearchList: ClaimsSearchType
  onRefetchClaimsSearchList: TRefetchFilterStaticCb
  claimsSearchFiltersInitialValues: FilterStaticFilterQueryParamsType
}

export const useClaimsSearchListFetch = (orderId: number | null): UseClaimsSearchListFetchType => {
  const router = useRouter()
  const { customerId, loadingCustomersDetailsFetch } = useCustomersDetailsFetch()

  const initialQuery = getParamsFromQueryStr(router.query)

  const extendsQuery = {
    returnId: initialQuery.id,
    ...(customerId && { customerId }),
    filter: { ...initialQuery.filter, ...(!!orderId && { orderId }) },
  }

  const fetchClaimsSearchListProps = useQueryAsync<ClaimsSearchType>({
    key: claimsKeys.searchList(initialQuery),
    name: 'fetchClaimsSearchList',
    fetcher: claimsService.searchClaims,
    fetcherPayload: extendsQuery,
    options: { enabled: !!initialQuery?.filter?.search && !!customerId },
  })

  const onRefetchClaimsSearchList = useCallback<TRefetchFilterStaticCb>(
    async (values) => {
      const newParams = { ...initialQuery, ...values?.filterData }

      await router.replace({ pathname: router.pathname, query: QS.stringify(newParams, {}) })
    },
    [router, initialQuery],
  )

  return {
    loadingClaimsSearchListFetch: fetchClaimsSearchListProps.isLoading || loadingCustomersDetailsFetch,
    claimsSearchList: fetchClaimsSearchListProps.data || { items: [], meta: null },
    onRefetchClaimsSearchList,
    claimsSearchFiltersInitialValues: initialQuery,
  }
}
