import { Root } from '@radix-ui/react-dialog'
import React from 'react'

import { DialogUi } from 'src/components/ui'

import { orNull } from 'src/utils'

export const Dialog = React.memo(
  ({ open, onOpenChange, children, ...rest }: React.ComponentPropsWithoutRef<typeof Root>) => {
    return orNull(
      open,
      <DialogUi open={open} onOpenChange={onOpenChange} {...rest}>
        {children}
      </DialogUi>,
    )
  },
)

Dialog.displayName = 'Dialog'
