import { StaticDataItemType, StaticDataKeys } from 'src/models'

import { useStaticDataFetch } from 'src/bus/staticData'

type OtrsTicketReasonsType = {
  loadingOtrsTicketReasonsFetch: boolean
  otrsTicketReasons: StaticDataItemType[]
}

export const useOtrsTicketReasonsFetch = (): OtrsTicketReasonsType => {
  const staticDataKeys = [StaticDataKeys.otrsTicketReasons]

  const { staticData, loadingStaticDataFetch } = useStaticDataFetch(staticDataKeys)

  return {
    loadingOtrsTicketReasonsFetch: loadingStaticDataFetch,
    otrsTicketReasons: staticData?.[StaticDataKeys.otrsTicketReasons] || [],
  }
}
