import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'

import { CreateVisitForm } from 'src/components/common'

import { useCustomersStaticListFetch } from 'src/bus/customers'
import { useVisitsActionsFetch, useVisitsAvailableDateFetch, useVisitsCreate } from 'src/bus/visits'

export const VisitsCreateView = () => {
  const router = useRouter()
  const [selectedCustomerId, setSelectedCustomerId] = useState<number | undefined>(undefined)
  const { customersStaticList, loadingCustomersStaticListFetch, onRefetchStaticDataCustomers } =
    useCustomersStaticListFetch(selectedCustomerId)
  const { visitsAvailableDate, loadingVisitsAvailableDateFetch } = useVisitsAvailableDateFetch({
    customerId: selectedCustomerId,
  })
  const { visitsActions, loadingVisitsActionsFetch } = useVisitsActionsFetch(selectedCustomerId)
  const { onCreateVisit, loadingVisitsCreate } = useVisitsCreate()

  const onSelectCustomerId = useCallback((customerId: number) => setSelectedCustomerId(customerId), [])

  useEffect(() => {
    router.query.customerId && onSelectCustomerId(Number(router.query.customerId))
  }, [router, onSelectCustomerId])

  return (
    <div className='flex h-full flex-col p-2' data-test-id='visits-create'>
      <CreateVisitForm
        initialValues={{ customerId: selectedCustomerId }}
        onSubmit={onCreateVisit}
        loadingCreate={loadingVisitsCreate}
        customers={customersStaticList}
        onSelectCustomerId={onSelectCustomerId}
        dates={visitsAvailableDate}
        actions={visitsActions}
        loadingFetchCustomers={loadingCustomersStaticListFetch}
        loadingFetchActions={loadingVisitsActionsFetch}
        loadingFetchDate={loadingVisitsAvailableDateFetch}
        onSearchCustomers={onRefetchStaticDataCustomers}
      />
    </div>
  )
}
