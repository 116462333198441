import { LocationType, SettingsType } from 'src/models'

import { googleMapNavigation, googleSearch, wazeMapNavigation } from './constants'
import { isGoogleNavigator } from './helpers'

export const googleMapNavigationTo = (destination: LocationType) =>
  googleMapNavigation + `&destination=${destination?.lat},${destination?.lng}`

export const wazeMapNavigationTo = (destination: LocationType) =>
  wazeMapNavigation + `&ll=${destination?.lat},${destination?.lng}`

export const getNavigationLink = (settings: SettingsType) =>
  isGoogleNavigator(settings) ? googleMapNavigationTo : wazeMapNavigationTo

export const getGoogleAddress = (address = '') => googleSearch + address
