import update from 'immutability-helper'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'

import { Dialog, VisitListItem, SkeletonList, DialogViewsSwitcher } from 'src/components/common'
import { Empty, Typography, StrictModeDroppable } from 'src/components/ui'

import { useVisitsFetch, useVisitsOrderUpdate } from 'src/bus/visits'

import { useDialog } from 'src/contexts'

import { GET_ENUMS, getHasData, getIds, ternary } from 'src/utils'

export const VisitList = () => {
  const { t } = useTranslation()
  const { visits, loadingVisitsFetch } = useVisitsFetch()
  const { onUpdateVisitsOrder } = useVisitsOrderUpdate()
  const { onPageDialogs, setOnPageDialog } = useDialog()

  const [dialogParams, setDialogParams] = useState<{
    id?: number
    title?: string
  }>()

  const onDragEnd = async (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    // the same place
    if (result.source.index === result.destination.index) {
      return
    }

    const updated = update(visits.items, {
      $splice: [
        [result.source.index, 1],
        [result.destination.index, 0, visits.items[result.source.index]],
      ],
    })

    await onUpdateVisitsOrder(getIds(updated))
  }

  const onGetDialogParams = ({ id, title }: { id?: number; title?: string }) => setDialogParams({ id, title })

  return (
    <>
      {[GET_ENUMS.dialog.cancelVisit, GET_ENUMS.dialog.replanVisit].map((key) => {
        return (
          <Dialog key={key} open={onPageDialogs[key]?.visible} onOpenChange={(open) => setOnPageDialog(key, open)}>
            <DialogViewsSwitcher name={key} params={dialogParams} />
          </Dialog>
        )
      })}
      <div className='px-6 py-2'>
        <div className='flex items-center gap-2'>
          <div className='grid basis-1/12'>
            <Typography variant='secondary' color='gray-7'>
              {t('visits:visits_list.number')}
            </Typography>
          </div>
          <div className='grid basis-6/12'>
            <Typography variant='secondary' color='gray-7'>
              {t('visits:visits_list.customer_name')}
            </Typography>
          </div>
          <div className='grid basis-2.5/12 text-center'>
            <Typography variant='secondary' color='gray-7'>
              {t('visits:visits_list.status')}
            </Typography>
          </div>
          <div className='grid basis-2.5/12 text-center'>
            <Typography variant='secondary' color='gray-7'>
              {t('visits:visits_list.sector')}
            </Typography>
          </div>
        </div>
      </div>
      {ternary(
        loadingVisitsFetch,
        <SkeletonList type='customers' />,
        ternary(
          getHasData(visits.items),
          <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeDroppable droppableId='droppable'>
              {(provided) => {
                return (
                  <div
                    className='flex h-full flex-col overflow-y-auto px-2'
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {visits.items.map((item, index) => {
                      return (
                        <VisitListItem key={item.id} item={item} index={index} onGetDialogParams={onGetDialogParams} />
                      )
                    })}
                    {provided.placeholder}
                  </div>
                )
              }}
            </StrictModeDroppable>
          </DragDropContext>,
          <Empty title='common:hey' description='common:empty.no_visits' />,
        ),
      )}
    </>
  )
}
