import {
  HttpResponse,
  ReturnsParams,
  ReturnsType,
  ReturnTotalType,
  ReturnType,
  returnApiListSchema,
  returnTotalSchema,
} from 'src/models'

import { zodValidateResponse } from 'src/utils'

import { httpAxiosProvider } from 'src/api'

export const returnsService = Object.freeze({
  fetchReturns: async ({ params }: ReturnsParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<ReturnsType>>('/return', { params })
    zodValidateResponse(returnApiListSchema, response.data.data)
    return response
  },
  fetchReturnTotal: async ({ params }: ReturnsParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<ReturnTotalType>>(`/return/${params?.id}/total`, {
      params,
    })
    zodValidateResponse(returnTotalSchema, response.data.data)
    return response
  },
  confirmReturn: ({ params, data }: ReturnsParams) => {
    return httpAxiosProvider.post<HttpResponse<void>>(`/return/${params?.id}/confirm`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  createReturn: ({ data }: ReturnsParams) => {
    return httpAxiosProvider.post<HttpResponse<ReturnType>>('/return', data)
  },
})
