import Link from 'next/link'
import { ReactNode } from 'react'

import { book } from 'src/init/book'

import { SkeletonList } from 'src/components/common'
import { Button, Typography } from 'src/components/ui'

import { useActionsListFetch } from 'src/bus/actions'

import { getHasData, orNull, ternary } from 'src/utils'

type TVisitsActions = {
  title?: ReactNode
}

export const VisitsActions = ({ title }: TVisitsActions) => {
  const { actionsList, loadingActionsListFetch } = useActionsListFetch()

  return (
    <div className='flex flex-col gap-2'>
      {ternary(
        loadingActionsListFetch,
        <>
          {orNull(!!title, title)}
          <SkeletonList lineCount={3} type='actions' />
        </>,
        <>
          {orNull(
            getHasData(actionsList.items),
            <>
              {orNull(!!title, title)}
              {actionsList.items.map((item) => (
                <Button variant='card' key={item.id} className='text-left'>
                  <Link href={`${book.visitActions}/${item.id}${book.visitActionsDetails}`}>
                    <Typography variant='secondary'>{item.title}</Typography>
                  </Link>
                </Button>
              ))}
            </>,
          )}
        </>,
      )}
    </div>
  )
}
