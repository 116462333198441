import { Radio } from 'antd'
import { RadioProps } from 'antd'
import { CheckboxRef } from 'rc-checkbox/lib'
import { forwardRef, PropsWithChildren } from 'react'
import { cn } from 'utils/helpers'

import st from './Styles.module.css'

export type InputRadioType = PropsWithChildren<
  RadioProps & {
    className?: string
    hasDisabled?: boolean
  }
>

export const InputRadio = forwardRef<CheckboxRef, InputRadioType>(
  ({ className = '', checked, children, hasDisabled = false, ...rest }, ref) => {
    const radioClasses = cn(st['radio'], { [st['disabled']]: hasDisabled }, className)

    return (
      <Radio {...rest} className={radioClasses} disabled={hasDisabled} checked={checked} ref={ref}>
        {children}
      </Radio>
    )
  },
)

InputRadio.displayName = 'InputRadio'
