import { StaticDataItemType, StaticDataKeys } from 'src/models'

import { useStaticDataFetch } from 'src/bus/staticData'

type BusinessActionsFetchType = {
  businessActions: StaticDataItemType[]
  loadingBusinessActionsFetch: boolean
}

export const useBusinessActionsFetch = (): BusinessActionsFetchType => {
  const staticDataKeys = [StaticDataKeys.businessAction]

  const { staticData, loadingStaticDataFetch } = useStaticDataFetch(staticDataKeys)

  return {
    businessActions: staticData?.['business-action'] || [],
    loadingBusinessActionsFetch: loadingStaticDataFetch,
  }
}
