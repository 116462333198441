import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { book } from 'src/init/book'

import { Button, Icon, Skeleton, Typography } from 'src/components/ui'

import { useAuthProfile } from 'src/bus/auth'
import { useCustomersDetailsFetch } from 'src/bus/customers'
import { useReturnsCreate, useReturnsInProgress } from 'src/bus/returns'

import { orNull, ternary } from 'src/utils'

export const ReturnNavigation = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { customerId } = useCustomersDetailsFetch()
  const { hasActiveReturn, returnId, loadingReturnsListFetch } = useReturnsInProgress()
  const { onCreateReturn, loadingReturnsCreate } = useReturnsCreate()
  const { isRoleServiceAgent } = useAuthProfile()

  const onNavigateToReturn = () => router.push(`${book.returns}/${returnId}`)

  const hasReturn = hasActiveReturn && !!returnId && !!customerId

  return (
    <>
      {orNull(
        isRoleServiceAgent,
        <div className='flex flex-col gap-2'>
          <Typography className='font-semibold'>{t('visits:returns')}</Typography>
          <>
            {ternary(
              loadingReturnsListFetch || loadingReturnsCreate,
              <Skeleton className='h-10' />,
              <Button onClick={hasReturn ? onNavigateToReturn : onCreateReturn} variant='card'>
                <Typography variant='secondary' color='blue-7'>
                  {t(hasReturn ? 'buttons:have_new_return' : 'buttons:create_return')}
                </Typography>
                <Icon name='ChevronRight' color='blue-8' />
              </Button>,
            )}
          </>
        </div>,
      )}
    </>
  )
}
