import {
  HttpResponse,
  OrdersParams,
  OrdersArticlesType,
  ParcelsParams,
  HistoryOrderType,
  OrdersDeliveriesType,
  orderDeliveryListSchema,
  orderSchema,
  BaseDataListType,
  selectListSchema,
} from 'src/models'

import { zodValidateResponse } from 'src/utils'

import { httpAxiosProvider } from 'src/api'

export const ordersService = Object.freeze({
  fetchOrdersDetails: async ({ params }: OrdersParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<HistoryOrderType>>(`/order/${params?.id}`, { params })
    zodValidateResponse(orderSchema, response.data.data)
    return response
  },
  fetchOrdersArticles: ({ params }: ParcelsParams) => {
    return httpAxiosProvider.get<HttpResponse<OrdersArticlesType>>('/order-product', { params })
  },
  fetchOrdersDeliveries: async ({ params }: OrdersParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<OrdersDeliveriesType>>('/order-delivery', { params })
    zodValidateResponse(orderDeliveryListSchema, response.data.data)
    return response
  },
  fetchOrderOptions: async ({ params }: OrdersParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<BaseDataListType>>('/order/options', { params })
    zodValidateResponse(selectListSchema, response.data.data)
    return response
  },
  fetchOrderProductOptions: async ({ params }: OrdersParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<BaseDataListType>>('/order-product/options', { params })
    zodValidateResponse(selectListSchema, response.data.data)
    return response
  },
})
