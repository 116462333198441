import { Typography } from 'src/components/ui'

export const CornerEuro = () => {
  return (
    <div className='absolute right-0 top-0 h-0 w-0 border-l-[24px] border-t-[24px] border-l-transparent border-t-error'>
      <span className='-rorate-45 absolute -left-3 -top-[26px] block text-center text-black'>
        <Typography variant='caption' color='white' className='font-semibold'>
          &euro;
        </Typography>
      </span>
    </div>
  )
}
