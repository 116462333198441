import { useMutation } from '@tanstack/react-query'
import { UseMutationResult } from '@tanstack/react-query'

import { HttpErrorType, HttpServerRequestParams } from 'src/models'

import { claimsService } from 'src/bus/claims'

import { queryFetcher } from 'src/utils'

type ClaimsUpdateMutationType = {
  onUpdateClaimMutation: UseMutationResult<void, HttpErrorType, HttpServerRequestParams>
}

export const useClaimsUpdateMutation = (): ClaimsUpdateMutationType => {
  const onUpdateClaimMutation = useMutation<void, HttpErrorType, HttpServerRequestParams>({
    mutationFn: (params) => {
      return queryFetcher({
        name: 'updateClaim',
        fetcher: claimsService.updateClaim,
        fetcherPayload: params,
      })
    },
  })

  return { onUpdateClaimMutation }
}
