import type { TFunction } from 'i18next'
import { z } from 'zod'

import { messages } from 'src/utils'

export const editNote = {
  shape: {
    notes: '',
  },
  schema: (t: TFunction) => {
    return z.object({
      notes: z
        .string()
        .trim()
        .max(
          messages.note.max,
          t(messages.note.maxText, {
            label: t(messages.note.placeholder).toLowerCase(),
            count: messages.note.max,
          }),
        ),
    })
  },
}
