import { Input } from 'antd-mobile'
import { InputProps, InputRef } from 'antd-mobile/es/components/input'
import { forwardRef } from 'react'
import { cn } from 'utils/helpers'

import { Skeleton } from 'src/components/ui'

import st from './Styles.module.css'

export type InputBaseType = InputProps & {
  children?: never
  autoComplete?: 'on' | 'off'
  size?: 'middle' | 'large' | 'small'
  hasAutoComplete?: boolean
  hasTextCentered?: boolean
  loading?: boolean
}

export const InputBase = forwardRef<InputRef, InputBaseType>(
  (
    {
      className = '',
      hasAutoComplete = false,
      size = 'large',
      type = 'text',
      maxLength = 100,
      placeholder = 'Input',
      hasTextCentered = false,
      loading = false,
      ...rest
    },
    ref,
  ) => {
    const autoCompleteValue = hasAutoComplete ? 'on' : 'off'

    const inputStyle = cn(
      st['input-base'],
      {
        [st['centered']]: hasTextCentered,
        [st[`input-base-size-${size}`]]: !!size,
      },
      className,
    )

    if (loading) {
      return <Skeleton className='h-10' />
    }

    return (
      <Input
        {...rest}
        ref={ref}
        type={type}
        className={inputStyle}
        autoComplete={autoCompleteValue}
        maxLength={maxLength}
        placeholder={placeholder}
      />
    )
  },
)

InputBase.displayName = 'InputBase'
