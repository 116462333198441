import { Tabs as AntMobileTabs } from 'antd-mobile'
import { TabsProps as AntTabsProps } from 'antd-mobile/es/components/tabs'
import { ReactElement, useEffect, useState } from 'react'

import st from './Styles.module.css'

export type TabType = {
  key: string
  title: string | ReactElement
  component: () => ReactElement
  disabled?: boolean
}

export type TabsType = TabType[]

type TabsProps = AntTabsProps & {
  children?: never
  tabs?: TabsType
  activeKey?: string
  defaultActiveKey?: string
  onChangeTabs?: (tab: TabType) => Promise<void> | void
}

export const Tabs = ({
  tabs = [],
  activeLineMode = 'full',
  onChangeTabs,
  activeKey = '',
  defaultActiveKey = '',
  ...rest
}: TabsProps) => {
  const [initialKey, setInitialKey] = useState('')

  useEffect(() => {
    if (activeKey) {
      setInitialKey(activeKey)
      onChangeTabs?.(tabs.find((item) => item.key === activeKey) || tabs[0])
    } else {
      setInitialKey(tabs?.[0].key)
      onChangeTabs?.(tabs?.[0])
    }
  }, [activeKey, onChangeTabs, tabs])

  const onChange = (index: string) => {
    setInitialKey(index)
    onChangeTabs?.(tabs.find((item) => item.key === index) || tabs[0])
  }

  return (
    <>
      <AntMobileTabs
        className={st['tabs']}
        defaultActiveKey={defaultActiveKey || tabs?.[0].key}
        activeKey={initialKey}
        activeLineMode={activeLineMode}
        onChange={onChange}
        {...rest}
      >
        {tabs.map((item) => {
          return (
            <AntMobileTabs.Tab key={item.key} title={item.title} disabled={item.disabled}>
              {item.component()}
            </AntMobileTabs.Tab>
          )
        })}
      </AntMobileTabs>
    </>
  )
}
