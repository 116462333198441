import {
  HttpResponse,
  HistoryAllListType,
  HistoryOrdersType,
  HistoryParams,
  historyAllListSchema,
  orderListSchema,
} from 'src/models'

import { zodValidateResponse } from 'src/utils'

import { httpAxiosProvider } from 'src/api'

export const historyService = Object.freeze({
  fetchHistoryOrders: async ({ params }: HistoryParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<HistoryOrdersType>>('/order', {
      params,
    })
    zodValidateResponse(orderListSchema, response.data.data)
    return response
  },
  fetchHistoryAll: async ({ params }: HistoryParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<HistoryAllListType>>('/customer-history', {
      params,
    })
    zodValidateResponse(historyAllListSchema, response.data.data)

    return response
  },
})
