import { useCallback, useEffect, useState } from 'react'

import { HttpErrorType, ParcelsQRCodeType, ParcelType } from 'src/models'

import { useParcelsQRCodeFetch } from 'src/bus/parcels'

import { useToast } from 'src/hooks'

type PrintQRCodeCb = () => Promise<void>

type UseParcelsQRCodePrintType = {
  parcelsQRCode: ParcelsQRCodeType | null
  loadingFetchQRCode: boolean
}

export const useParcelsQRCodePrint = (parcelItem?: ParcelType | null): UseParcelsQRCodePrintType => {
  const { onParcelsQRCodeFetch } = useParcelsQRCodeFetch()
  const showToast = useToast()
  const [parcelsQRCode, setParcelsQRCode] = useState<ParcelsQRCodeType | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const onPrintQRCode = useCallback<PrintQRCodeCb>(async () => {
    try {
      setIsLoading(true)

      const data = await onParcelsQRCodeFetch(parcelItem?.id)
      setParcelsQRCode(data)
    } catch (error) {
      const errs = (error as HttpErrorType).errors
      if (errs) {
        const [[title, body]] = Object.entries(errs)
        showToast.error({ title, body: body.desc })
      }
    } finally {
      setIsLoading(false)
    }
  }, [onParcelsQRCodeFetch, parcelItem?.id, showToast])

  useEffect(() => {
    if (!parcelItem?.id || !parcelItem?.isAvailableQrCode) {
      return
    }

    ;(async () => {
      await onPrintQRCode()
    })()
  }, [onPrintQRCode, parcelItem?.id, parcelItem?.isAvailableQrCode])

  return {
    parcelsQRCode,
    loadingFetchQRCode: isLoading,
  }
}
