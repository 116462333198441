import { BottomNavigationBar, ViewParcelsTotal } from 'src/components/common'

import { useParcelsTotalFetch } from 'src/bus/parcels'

import { usePageUrlContext } from 'src/contexts'

export const ParcelsFooter = () => {
  const { initialFilters } = usePageUrlContext()

  const { parcelsTotal, loadingParcelsTotalFetch } = useParcelsTotalFetch(initialFilters)

  return (
    <div className='shadow-level-2'>
      <div className='p-2'>
        <ViewParcelsTotal data={parcelsTotal} loadingFetch={loadingParcelsTotalFetch} />
      </div>
      <BottomNavigationBar />
    </div>
  )
}
