import { Spin } from 'antd'
import { SpinProps } from 'antd/lib'
import { cn } from 'utils/helpers'

import { TIconColors } from 'src/components/ui/Icon'

import st from './Styles.module.css'

export type LoaderType = 'absolute' | 'fixed' | 'relative'
export type LoaderProps = SpinProps & {
  type?: LoaderType
  size?: 'default' | 'small' | 'large'
  color?: TIconColors
}

export const Loader = ({ color = 'blue-8', type = 'absolute', size = 'default', className = '' }: LoaderProps) => {
  const loaderStyles = cn(st['loader'], 'flex items-center justify-center', color, className, {
    [st[type]]: type,
    [st[size]]: size,
  })

  return (
    <div className={loaderStyles}>
      <Spin size={size} />
    </div>
  )
}
