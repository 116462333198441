import { Claims, ReturnCustomerInfo, Drawer } from 'src/components/common'
import { SheetContent } from 'src/components/ui'

import { useOrientation } from 'src/hooks'

import { useDrawer } from 'src/contexts'

import { GET_ENUMS, ternary } from 'src/utils'

import st from './Styles.module.css'

export const ViewReturnApprove = () => {
  const { isLandscape } = useOrientation()
  const { onPageDrawers, setOnPageDrawer } = useDrawer()

  const content = (
    <div className={st['visit-return']} data-test-id='returns-confirm'>
      <ReturnCustomerInfo />
      <Claims />
    </div>
  )

  return ternary(
    isLandscape,
    <Drawer
      open={onPageDrawers[GET_ENUMS.drawer.confirmList]}
      onOpenChange={(open) => setOnPageDrawer(GET_ENUMS.drawer.confirmList, open)}
    >
      <SheetContent side='left'>{content}</SheetContent>
    </Drawer>,
    content,
  )
}
