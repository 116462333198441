import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { book } from 'src/init/book'

import { ConvertCodeType } from 'src/models'

import { userOptionsKeys, useScannerCode } from 'src/bus/userOptions'

import { useConvertCodeFetch } from 'src/hooks'

type UseBarcodeScannerType = {
  onOpenScanner: () => void
  loadingConvertCode: boolean
}

type UseBarcodeScannerProps = {
  onChange?: (value: string) => void
  advancedFn?: (data: ConvertCodeType) => void
}

export const useBarcodeScanner = ({ onChange, advancedFn }: UseBarcodeScannerProps): UseBarcodeScannerType => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const { updateCode } = useScannerCode()

  const { convertCode, loadingConvertCodeFetch } = useConvertCodeFetch()

  useDeepCompareEffect(() => {
    if (convertCode?.articleNo) {
      onChange?.(convertCode.articleNo)
      advancedFn?.(convertCode)
      updateCode('')
      queryClient.removeQueries({ queryKey: userOptionsKeys.convertedCode() })
    }
  }, [convertCode, onChange, advancedFn, updateCode, queryClient])

  const onOpenScanner = useCallback(async () => {
    await router.push(book.scanner)
  }, [router])

  return {
    onOpenScanner,
    loadingConvertCode: loadingConvertCodeFetch,
  }
}
