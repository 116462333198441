import { BaseDataListType, StaticDataItemType } from 'src/models'

import { otrsOptionsKeys, otrsOptionsService } from 'src/bus/otrsOptions'

import { useQueryAsync } from 'src/hooks'

type ClarificationsFetchType = {
  loadingClarificationsFetch: boolean
  clarifications: StaticDataItemType[]
}

export const useClarificationsFetch = (reasonId?: number): ClarificationsFetchType => {
  const fetchClarificationsProps = useQueryAsync<BaseDataListType>({
    key: otrsOptionsKeys.clarifications(reasonId),
    name: 'fetchClarifications',
    fetcher: otrsOptionsService.clarificationsFetch,
    fetcherPayload: { reasonId },
    options: { enabled: !!reasonId },
  })

  return {
    loadingClarificationsFetch: fetchClarificationsProps.isLoading,
    clarifications: fetchClarificationsProps.data?.items || [],
  }
}
