type BrowserLoggerInfoType = {
  isStarted?: boolean
  isFinished?: boolean
  isError?: boolean
  operation: 'query' | 'mutation'
  name: string
  producer?: 'Client' | 'Server'
  status?: unknown | null
  params?: unknown | null
  data?: unknown | null
  time?: string
}

export const logger = (msg: BrowserLoggerInfoType) => {
  const {
    name = '',
    operation = 'query',
    producer = 'Client',
    status = null,
    isStarted = false,
    isFinished = false,
    data = null,
    params = null,
    time,
    isError = false,
  } = msg

  const stylesRequestError = 'font-size: 13px; background: transparent; color: firebrick'
  const stylesResponseError = 'font-size: 13px; background: transparent; color: #ff0005'
  const stylesRequest = isError ? stylesRequestError : 'font-size: 13px; color: deepskyblue'
  const stylesResponse = isError ? stylesResponseError : 'font-size: 13px; color: #A47104'

  const action = JSON.stringify({ name, operation, producer })
  const actionStatusCode = status ? `responseCode ${status}\n` : ''
  const actionStatus = (isStarted ? 'was started.' : '') || (isFinished ? `was finished with ${actionStatusCode}` : '')
  const response = data ? 'result: ' : ''
  const messageParams = params ? 'params:' : ''
  const messageRequest = `${action} \n` + messageParams

  console.groupCollapsed((isError ? '🔴' : '🟢') + 'Request ' + name + ' ' + actionStatus)
  if (isStarted) {
    console.info('%c' + '====Request====', stylesRequest)
    console.info('%c' + 'ReactQuery: ' + `${messageRequest}`, stylesRequest, params || '')
  }
  if (isFinished) {
    console.info('%c' + '====Time====', stylesResponse)
    console.info(time + 'ms')
    console.info('%c' + '====Response====', stylesResponse)
  }
  response && console.info('%c' + 'ReactQuery: ' + `${response}`, stylesResponse, data)
  console.groupEnd()
}
