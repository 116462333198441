import type { TFunction } from 'i18next'
import { z } from 'zod'

import { messages, otherVisitCancelReason } from 'src/utils'

export const cancelVisit = {
  shape: {
    reason: '',
    comment: '',
  },
  schema: (t: TFunction) => {
    return z
      .object({
        reason: z.string().min(1, t(messages.cancelVisit.required)),
        comment: z.string().trim(),
      })
      .superRefine((value, ctx) => {
        if (value.reason === otherVisitCancelReason.value) {
          if (value.comment.length < messages.cancelVisit.min) {
            ctx.addIssue({
              code: z.ZodIssueCode.too_small,
              path: ['comment'],
              inclusive: false,
              minimum: messages.cancelVisit.min,
              message: t(messages.cancelVisit.minText, {
                label: t(messages.cancelVisit.placeholder).toLowerCase(),
                count: messages.cancelVisit.min,
              }),
              type: 'string',
            })
          }

          if (value.comment.length >= messages.cancelVisit.max) {
            ctx.addIssue({
              code: z.ZodIssueCode.too_big,
              path: ['comment'],
              inclusive: false,
              maximum: messages.cancelVisit.max,
              message: t(messages.cancelVisit.maxText, {
                label: t(messages.cancelVisit.placeholder).toLowerCase(),
                count: messages.cancelVisit.max,
              }),
              type: 'string',
            })
          }

          return z.NEVER
        }
      })
  },
}
