export const TCountryCodes = {
  EN: 'en',
  FR: 'fr',
  DE: 'de',
  NL: 'nl',
} as const

export type TCurrencyConfig = {
  symbol: string
  currencyIso: string
  decimal: string
  separator: string
  precision: number
  pattern: string
  negativePattern: string
}
