import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { CreateReportButton, Dialog, DialogViewsSwitcher } from 'src/components/common'
import { FloatingPanel, Icon, ListItem, Typography } from 'src/components/ui'

import { MenuProfileType } from 'src/models'

import { useCustomersDetailsFetch } from 'src/bus/customers'

import { useDialog } from 'src/contexts'

import { FLOATING_PANEL_ANCHORS, GET_ENUMS, getVisitReportPanel } from 'src/utils'

type VisitReportPanelProps = {
  onSubmit: () => Promise<void>
  loadingSubmit: boolean
  disabledBtn: boolean
}

export const VisitReportPanel = ({ ...restProps }: VisitReportPanelProps) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { onPageDialogs, setOnPageDialog } = useDialog()

  const { customersDetails } = useCustomersDetailsFetch()

  const onClickHandler = async (item: MenuProfileType) => {
    if (item.dialog) {
      setOnPageDialog(item.key, true)
      return
    }

    await router.push(item.key)
  }

  const items = getVisitReportPanel({ customerId: String(customersDetails?.id), t })

  return (
    <>
      {[GET_ENUMS.dialog.editNote, GET_ENUMS.dialog.customerIssue].map((key) => {
        return (
          <Dialog key={key} open={onPageDialogs[key]?.visible} onOpenChange={(open) => setOnPageDialog(key, open)}>
            <DialogViewsSwitcher name={key} />
          </Dialog>
        )
      })}
      <FloatingPanel anchors={FLOATING_PANEL_ANCHORS.THREE_STEPS_WITHOUT_FOOTER}>
        <div className='mb-4 px-4'>
          <CreateReportButton {...restProps} />
        </div>
        {items.map((item) => {
          const isCancel = item.type === 'cancel'

          return (
            <ListItem
              key={item.key}
              disabled={item.disabled}
              onClick={async (e) => {
                e.preventDefault()
                await onClickHandler(item)
              }}
              size='panel'
            >
              <div className='flex items-center gap-4'>
                <Icon name={item.icon} color={isCancel ? 'error' : 'blue-gray-7'} />
                <Typography color={isCancel ? 'error' : 'default'}>{item.title}</Typography>
              </div>
            </ListItem>
          )
        })}
      </FloatingPanel>
    </>
  )
}
