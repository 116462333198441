import { Dialog, DialogViewsSwitcher } from 'src/components/common'
import { Button, Icon } from 'src/components/ui'

import { useDialog } from 'src/contexts'

import { GET_ENUMS } from 'src/utils'

export const ReturnsInfoMarkers = () => {
  const { onPageDialogs, setOnPageDialog } = useDialog()

  return (
    <>
      <Dialog
        open={onPageDialogs[GET_ENUMS.dialog.returnsInfoMarkers]?.visible}
        onOpenChange={(open) => setOnPageDialog(GET_ENUMS.dialog.returnsInfoMarkers, open)}
      >
        <DialogViewsSwitcher name={GET_ENUMS.dialog.returnsInfoMarkers} />
      </Dialog>
      <Button variant='ghost' size='icon-md' onClick={() => setOnPageDialog(GET_ENUMS.dialog.returnsInfoMarkers, true)}>
        <Icon name='Info' color='blue-gray-85' />
      </Button>
    </>
  )
}
