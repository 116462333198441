import { WrappedNodeDefault } from 'src/components/common'
import { Skeleton, Typography } from 'src/components/ui'

import { getHasData } from 'src/utils'

import st from './Styles.module.css'

type CellTextProps = {
  value?: string[] | string
  loadingFetch?: boolean
}

const hasHtmlTags = (htmlString = '') => /<[^>]*>/g.test(htmlString)

export const CellTextRich = ({ value, loadingFetch = false }: CellTextProps) => {
  if (loadingFetch) {
    return <Skeleton className='h-[18px]' />
  }

  const hasValue = Array.isArray(value) ? getHasData(value) : value

  const renderDangerText = (text = '') => {
    return (
      <Typography variant='secondary'>
        <span dangerouslySetInnerHTML={{ __html: text }} />
      </Typography>
    )
  }

  return hasValue ? (
    <div className={st['rich-text']}>
      {Array.isArray(value) ? (
        <ul className={st['rich-text-ul']}>
          {value.map((item) => {
            if (!hasHtmlTags(item)) {
              return (
                <li key={item}>
                  <Typography variant='secondary'>{WrappedNodeDefault(item)}</Typography>
                </li>
              )
            }

            return <li key={item}>{renderDangerText(item)}</li>
          })}
        </ul>
      ) : hasHtmlTags(value) ? (
        renderDangerText(value)
      ) : (
        <Typography variant='secondary'>{WrappedNodeDefault(value)}</Typography>
      )}
    </div>
  ) : (
    WrappedNodeDefault(null)
  )
}
