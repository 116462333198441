import { HttpRequestParams, HttpResponse } from 'src/models'

import { httpAxiosProvider } from 'src/api'

export const otrsService = Object.freeze({
  uploadImages: ({ data }: HttpRequestParams) => {
    return httpAxiosProvider.post<HttpResponse<string[]>>('/otrs/images', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },
  createIssue: ({ params, data }: HttpRequestParams) => {
    return httpAxiosProvider.post<HttpResponse<void>>('/otrs/feedback', data, { params })
  },
})
