import { useInfiniteQuery, keepPreviousData } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import QS from 'qs'
import { useCallback, useMemo } from 'react'

import {
  FilterStaticFilterQueryParamsType,
  FilterStaticParamsType,
  ParcelsInfiniteType,
  ParcelsType,
  TRefetchFilterStaticCb,
} from 'src/models'

import { parcelsService, parcelsKeys, parcelsState } from 'src/bus/parcels'

import { getNextPageParam, queryFetcher } from 'src/utils'

type UseParcelsListFetchType = {
  loadingParcelsListFetch: boolean
  parcelsListHasMore: boolean
  onFetchMoreParcelsList: () => void
  parcelsList: ParcelsInfiniteType
  parcelsFiltersStaticInitialValues: FilterStaticFilterQueryParamsType
  onRefetchParcelsList: TRefetchFilterStaticCb
}

export const useParcelsListFetch = (initialFilters: FilterStaticParamsType): UseParcelsListFetchType => {
  const router = useRouter()

  const params = useMemo(() => ({ ...initialFilters }), [initialFilters])

  const fetchParcelsListProps = useInfiniteQuery<ParcelsType>({
    queryKey: parcelsKeys.list(params),
    queryFn: ({ pageParam }) =>
      queryFetcher({
        name: 'fetchParcels',
        fetcher: parcelsService.fetchParcels,
        fetcherPayload: { params: { ...params, page: pageParam } },
      }),
    refetchOnMount: 'always',
    initialPageParam: 1,
    getNextPageParam,
    placeholderData: keepPreviousData,
  })

  const onRefetchParcelsList = useCallback<TRefetchFilterStaticCb>(
    async (values) => {
      const newParams = { ...values?.filterData }
      await router.replace({ pathname: router.pathname, query: QS.stringify(newParams) })
    },
    [router],
  )

  return {
    loadingParcelsListFetch: fetchParcelsListProps.isLoading,
    parcelsFiltersStaticInitialValues: params,
    parcelsListHasMore: fetchParcelsListProps.hasNextPage,
    onFetchMoreParcelsList: fetchParcelsListProps.fetchNextPage,
    parcelsList: fetchParcelsListProps.data || parcelsState.parcelsArticles,
    onRefetchParcelsList,
  }
}
