import { ParcelsCheckComplianceButton, ViewParcelsTotal } from 'src/components/common'

import { useParcelsDetailsFetch } from 'src/bus/parcels'

import { getIsInProgressOrPackedStatus } from 'src/utils'

export const ParcelsDetailsFooter = () => {
  const { parcelsDetails, loadingParcelsDetailsFetch } = useParcelsDetailsFetch()

  const isInProgressPackedStatus = getIsInProgressOrPackedStatus(parcelsDetails)

  return (
    <div className='shadow-level-2'>
      <div className='p-2'>
        <ViewParcelsTotal data={parcelsDetails} loadingFetch={loadingParcelsDetailsFetch} />
        {isInProgressPackedStatus && <ParcelsCheckComplianceButton />}
      </div>
    </div>
  )
}
