import { useRouter } from 'next/router'

import { VisitType } from 'src/models'

import { useVisitQuery } from 'src/bus/visits'

type UseVisitsFetchType = {
  loadingVisitsFetch: boolean
  visitsDetails: VisitType | null
}

export const useVisitsDetailsFetch = (visitId?: number): UseVisitsFetchType => {
  const router = useRouter()

  const id = Number(visitId || router.query.id)

  const { visitQuery } = useVisitQuery(id)

  return {
    loadingVisitsFetch: visitQuery.isLoading,
    visitsDetails: visitQuery.data || null,
  }
}
