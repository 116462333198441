import {
  HttpResponse,
  TourParamsType,
  TourNewsTypes,
  TourPlanForTodayType,
  TourPointsType,
  TourPointsStaticsType,
  TourPointsParams,
  TourRoutesType,
  tourPlanForTodaySchema,
  tourRouteListSchema,
  tourPointListSchema,
  tourPointStaticSchema,
} from 'src/models'

import { zodValidateResponse } from 'src/utils'

import { httpAxiosProvider } from 'src/api'

export const tourService = Object.freeze({
  cancelTour: ({ data }: TourParamsType) => {
    return httpAxiosProvider.put<HttpResponse<void>>('/tour/stop', data)
  },
  optimizeTourRoute: ({ data }: TourParamsType) => {
    return httpAxiosProvider.put<HttpResponse<void>>('/tour/optimize', data)
  },
  fetchTourRoute: async ({ params }: TourParamsType) => {
    const response = await httpAxiosProvider.get<HttpResponse<TourRoutesType>>('/tour/route', {
      params,
    })
    zodValidateResponse(tourRouteListSchema, response.data.data)
    return response
  },
  fetchNews: () => {
    return httpAxiosProvider.get<HttpResponse<TourNewsTypes>>('/news/latest')
  },
  fetchPlanForToday: async () => {
    const response = await httpAxiosProvider.get<HttpResponse<TourPlanForTodayType>>('/plan-for-today')
    zodValidateResponse(tourPlanForTodaySchema, response.data.data)
    return response
  },
  fetchTourPoints: async () => {
    const response = await httpAxiosProvider.get<HttpResponse<TourPointsType>>('/employee-point')
    zodValidateResponse(tourPointListSchema, response.data.data)
    return response
  },
  fetchTourPointsStatic: async () => {
    const response = await httpAxiosProvider.get<HttpResponse<TourPointsStaticsType>>('/employee-point-options')
    zodValidateResponse(tourPointStaticSchema, response.data.data)
    return response
  },
  deleteTourPoints: ({ params }: TourPointsParams) => {
    return httpAxiosProvider.delete<HttpResponse<void>>(`/employee-point/${params?.id}`, {
      params,
    })
  },
  createTourPoints: ({ data }: TourPointsParams) => {
    return httpAxiosProvider.post<HttpResponse<void>>('/employee-point', data)
  },
  updateTourPoints: ({ data }: TourPointsParams) => {
    return httpAxiosProvider.put<HttpResponse<void>>('/tour-point', data)
  },
})
