import { useRouter } from 'next/router'

import { ActionType } from 'src/models'

import { actionsKeys, actionsService } from 'src/bus/actions'

import { useQueryAsync } from 'src/hooks'

type UseActionsDetailsFetchType = {
  loadingActionsDetailsFetch: boolean
  actionsDetails: ActionType | null
}

export const useActionsDetailsFetch = (): UseActionsDetailsFetchType => {
  const router = useRouter()

  const id = Number(router.query.id)
  const params = { id }

  const fetchActionsDetailsProps = useQueryAsync<ActionType>({
    key: actionsKeys.details(id),
    name: 'fetchActionsDetails',
    fetcher: actionsService.fetchActionsDetails,
    fetcherPayload: params,
    options: { enabled: !!id },
  })

  return {
    loadingActionsDetailsFetch: fetchActionsDetailsProps.isLoading,
    actionsDetails: fetchActionsDetailsProps.data || null,
  }
}
