import { useRouter } from 'next/router'
import QS from 'qs'
import { useCallback } from 'react'

import {
  FilterStaticParamsType,
  FilterStaticRequestValuesType,
  FilterStaticType,
  FilterStaticDataType,
} from 'src/models'

import {
  getParamsFromQueryStr,
  getSearch,
  mapFiltersStaticRequestData,
  prepareFilterStaticInitialValues,
  QS_QUERY_CONFIG,
  withoutFilters,
  withoutSort,
} from 'src/utils'

type UpdateQueryPropsType = {
  filtersValues?: FilterStaticType
  // sortValues?: SortType
  filterItems: FilterStaticDataType
}

type UpdateQueryReturnType = {
  filter: FilterStaticRequestValuesType
  filterData: { filterData: FilterStaticParamsType }
}

type UpdateQueryCbType = ({ filtersValues, filterItems }: UpdateQueryPropsType) => UpdateQueryReturnType
type DeleteQueryFiltersCbType = () => Promise<void>
type DeleteQuerySortCbType = () => Promise<void>

type UseUrlQueryType = {
  onUpdateQuery: UpdateQueryCbType
  onDeleteQueryFilters: DeleteQueryFiltersCbType
  onDeleteQuerySort: DeleteQuerySortCbType
}

export const useUrlQuery = (): UseUrlQueryType => {
  const router = useRouter()

  const onUpdateQuery = useCallback<UpdateQueryCbType>(
    ({ filtersValues, filterItems }) => {
      const urlQuery = getParamsFromQueryStr(router.query)

      const preparedQuery = prepareFilterStaticInitialValues(urlQuery?.filter || undefined, filterItems)

      const filter = mapFiltersStaticRequestData({ ...preparedQuery, ...filtersValues }, filterItems)

      const sort = urlQuery.sort

      const filterData = { filter, sort }

      return { filterData: { filterData }, filter }
    },
    [router.query],
  )

  const onDeleteQueryFilters = useCallback<DeleteQueryFiltersCbType>(async () => {
    const urlQuery = getParamsFromQueryStr(router.query)

    const search = getSearch(urlQuery.filter || {})
    const query = { ...withoutFilters(urlQuery), filter: { ...search } }

    await router.replace({
      pathname: router.pathname,
      query: QS.stringify(query, QS_QUERY_CONFIG),
    })
  }, [router])

  const onDeleteQuerySort = useCallback<DeleteQuerySortCbType>(async () => {
    const urlQuery = getParamsFromQueryStr(router.query)
    await router.replace({
      pathname: router.pathname,
      query: QS.stringify(withoutSort(urlQuery), QS_QUERY_CONFIG),
    })
  }, [router])

  return { onUpdateQuery, onDeleteQueryFilters, onDeleteQuerySort }
}
