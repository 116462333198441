import { SkeletonItem } from 'src/components/common'

import { SkeletonType } from 'src/models'

type SkeletonListProps = {
  lineCount?: number
  type?: SkeletonType
}

export const SkeletonList = ({ type = 'default', lineCount = 4 }: SkeletonListProps) => {
  return (
    <div className='flex flex-col overflow-hidden'>
      {Array(lineCount)
        .fill(null)
        .map((_, i) => (
          <SkeletonItem key={i} type={type} />
        ))}
    </div>
  )
}
