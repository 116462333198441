import { useTranslation } from 'next-i18next'
import Link from 'next/link'

import { CellText } from 'src/components/common'
import { Button, Icon } from 'src/components/ui'

import { getGoogleAddress } from 'src/utils'

type CellCustomerAddressProps = {
  value?: string
  loadingFetch?: boolean
}

export const CellCustomerAddress = ({ value, loadingFetch = false }: CellCustomerAddressProps) => {
  const { t } = useTranslation()

  return (
    <div className='grid gap-2'>
      <CellText value={value} loadingFetch={loadingFetch} />
      <Button variant='outline' block disabled={!value} loading={loadingFetch}>
        <Link
          href={getGoogleAddress(value)}
          target='_blank'
          rel='noopener noreferrer'
          className='flex items-end justify-center gap-1'
        >
          <span className='mr-1 flex'>
            <Icon name='Google' color='white' />
          </span>
          {t('common:modal_customer_card.open_google')}
        </Link>
      </Button>
    </div>
  )
}
