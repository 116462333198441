import { useTranslation } from 'next-i18next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { PropsWithChildren } from 'react'

import { pageTitles } from 'src/data'

type PageProps = PropsWithChildren<{
  title?: string
}>

export const Page = ({ children, title }: PageProps) => {
  const { t } = useTranslation()
  const router = useRouter()

  const pageTitle = `${t('firstPartPageTitle')} | ${t(title || pageTitles[router.route] || '')}`

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta charSet='utf-8' />
        <meta content='IE=edge' httpEquiv='X-UA-Compatible' />
        <meta
          name='viewport'
          content='width=device-width,
          initial-scale=1,
          shrink-to-fit=no, user-scalable=0'
        />
        <link rel='icon' href='/assets/favicons/favicon.ico' />
      </Head>
      {children}
    </>
  )
}
