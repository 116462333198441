import { useTranslation } from 'next-i18next'

import {
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  Typography,
} from 'src/components/ui'
import { TButtonVariants } from 'src/components/ui/Button'

import { orNull } from 'src/utils'

type AlertDialogConfirmProps = {
  onConfirm?: () => void
  title?: string
  description?: string
  buttonVariant?: TButtonVariants
  loadingFetch?: boolean
}

export const AlertDialogConfirm = ({
  title = '',
  description = '',
  buttonVariant = 'default',
  loadingFetch,
  onConfirm,
}: AlertDialogConfirmProps) => {
  const { t } = useTranslation()

  return (
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>
          <Typography variant='title' color='gray-10'>
            {title}
          </Typography>
        </AlertDialogTitle>
        {orNull(
          description,
          <AlertDialogDescription>
            <Typography variant='secondary' color='gray-7'>
              {description}
            </Typography>
          </AlertDialogDescription>,
        )}
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel variant='outline' className='w-full' disabled={loadingFetch}>
          {t('buttons:no')}
        </AlertDialogCancel>
        <Button
          onClick={onConfirm}
          block
          loading={loadingFetch}
          variant={buttonVariant}
          data-test-id='alert-dialog-confirm-btn'
        >
          {t('buttons:yes')}
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  )
}
