import { WrappedNodeDefault } from 'src/components/common'
import { Button, Icon, Typography, Skeleton } from 'src/components/ui'

import { useAuthLogout, useAuthProfile } from 'src/bus/auth'

import { ternary } from 'src/utils'

export const ProfileUser = () => {
  const { loadingAuthLogout, onLogoutAuth } = useAuthLogout()
  const { profile, loadingAuthProfileFetch } = useAuthProfile()

  return (
    <div className='flex items-center justify-between bg-white px-2 py-4'>
      {ternary(
        loadingAuthProfileFetch,
        <div className='w-[80%]'>
          <Skeleton className='mb-1 h-5' />
          <Skeleton className='h-[22px]' />
        </div>,
        <div>
          <Typography className='font-semibold'>{WrappedNodeDefault(profile?.name)}</Typography>
          <Typography variant='secondary' color='gray-7'>
            {WrappedNodeDefault(profile?.role?.label)}
          </Typography>
        </div>,
      )}
      <Button size='icon-md' variant='ghost' loading={loadingAuthLogout} onClick={onLogoutAuth}>
        <Icon name='Logout' color='blue-gray-7' />
      </Button>
    </div>
  )
}
