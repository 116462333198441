import { StaticDataSectorsType } from 'src/models'

import { staticDataKeys, staticDataService } from 'src/bus/staticData'

import { useQueryAsync } from 'src/hooks'

type UseStaticDataSectorsFetchType = {
  loadingStaticDataSectorsFetch: boolean
  staticDataSectors: StaticDataSectorsType
}

export const useStaticDataSectorsFetch = (zoneId?: string): UseStaticDataSectorsFetchType => {
  const params = { zoneId }

  const fetchStaticDataSectorsProps = useQueryAsync<StaticDataSectorsType>({
    key: staticDataKeys.sectors(zoneId),
    name: 'fetchStaticDataSectors',
    fetcher: staticDataService.fetchStaticDataSectors,
    fetcherPayload: params,
    options: { enabled: !!zoneId },
  })

  return {
    loadingStaticDataSectorsFetch: fetchStaticDataSectorsProps.isLoading,
    staticDataSectors: fetchStaticDataSectorsProps.data || { sectors: [] },
  }
}
