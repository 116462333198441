import Calendar, { type CalendarProps } from 'react-calendar'

import { Skeleton } from 'src/components/ui'
import { Icon } from 'src/components/ui/Icon'

import st from './Styles.module.css'

import 'react-calendar/dist/Calendar.css'

type InputCalendarProps = CalendarProps & {
  loading?: boolean
  'aria-describedby'?: string
  'aria-invalid'?: boolean
}

export const InputCalendar = ({ minDate = new Date(), maxDate, loading = false, ...rest }: InputCalendarProps) => {
  if (loading) {
    return <Skeleton className='h-[278px]' />
  }

  return (
    <div
      className={st['input-calendar']}
      aria-describedby={rest['aria-describedby']}
      aria-invalid={rest['aria-invalid']}
    >
      <Calendar
        {...rest}
        prevLabel={<Icon name='ChevronLeft' color='gray-5' />}
        nextLabel={<Icon name='ChevronRight' color='gray-5' />}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  )
}
