import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'next-i18next'
import { useForm } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormFields,
  FormItem,
  FormMessage,
  Separator,
  Typography,
} from 'src/components/ui'

import { FormSubmitFn, FormUpdateTourPointsEnum, FormUpdateTourPointsType, TourPointsStaticsType } from 'src/models'

import { formShapes } from 'src/bus/forms'

type UpdateTourPointsProps = {
  onSubmit: FormSubmitFn<FormUpdateTourPointsType>
  points: TourPointsStaticsType
  loadingPointsFetch: boolean
  loadingUpdate: boolean
}

export const UpdateTourPointsForm = ({
  onSubmit,
  points,
  loadingUpdate,
  loadingPointsFetch,
}: UpdateTourPointsProps) => {
  const { t } = useTranslation()

  const formProps = useForm<FormUpdateTourPointsType>({
    defaultValues: { ...formShapes.updateTourPoints.shape },
    resolver: zodResolver(formShapes.updateTourPoints.schema(t)),
  })

  const { control, handleSubmit, setError, reset } = formProps

  const onSubmitForm = handleSubmit(async (values) => {
    await onSubmit({ values, acts: { setError, reset } })
  })

  useDeepCompareEffect(() => {
    reset({ end: points?.activeEnd, intermediate: points?.activeIntermediate })
  }, [points, reset])

  return (
    <Form {...formProps}>
      <FormFields.FormGeneral onSubmit={onSubmitForm}>
        <div className='flex items-center justify-between gap-2'>
          <div className='basis-5/12'>
            <Typography variant='secondary' color='gray-7'>
              {t('end')}
            </Typography>
          </div>
          <div className='basis-8/12'>
            <FormField
              control={control}
              name={FormUpdateTourPointsEnum.end}
              render={({ field }) => (
                <FormItem gutter='none'>
                  <FormControl>
                    <FormFields.InputSelect
                      {...field}
                      loading={loadingPointsFetch}
                      options={points.end}
                      hasDisabled={loadingPointsFetch}
                    />
                  </FormControl>
                  <FormMessage className='absolute' />
                </FormItem>
              )}
            />
          </div>
        </div>
        {/*<Separator className='my-2' />*/}
        {/*<div className='flex items-center justify-between gap-2'>*/}
        {/*  <div className='basis-5/12'>*/}
        {/*    <Typography variant='secondary' color='gray-7'>*/}
        {/*      {t('intermediate')}*/}
        {/*    </Typography>*/}
        {/*  </div>*/}
        {/*  <div className='basis-8/12'>*/}
        {/*    <FormField*/}
        {/*      control={control}*/}
        {/*      name={FormUpdateTourPointsEnum.intermediate}*/}
        {/*      render={({ field }) => (*/}
        {/*        <FormItem gutter='none'>*/}
        {/*          <FormControl>*/}
        {/*            <FormFields.InputSelect*/}
        {/*              {...field}*/}
        {/*              loading={loadingPointsFetch}*/}
        {/*              options={points.intermediate}*/}
        {/*              hasDisabled={loadingPointsFetch || true}*/}
        {/*            />*/}
        {/*          </FormControl>*/}
        {/*          <FormMessage className='absolute' />*/}
        {/*        </FormItem>*/}
        {/*      )}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        <Separator className='mb-4 mt-2' />
        <Button block type='submit' loading={loadingUpdate}>
          {t('buttons:apply')}
        </Button>
      </FormFields.FormGeneral>
    </Form>
  )
}
