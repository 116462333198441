import { HistoryOrderType } from 'src/models'

import { ordersKeys, ordersState, ordersService } from 'src/bus/orders'

import { useQueryAsync } from 'src/hooks'

type UseOrdersDetailsFetchType = {
  loadingOrdersDetailsFetch: boolean
  ordersDetails: HistoryOrderType | null
}

type OrdersDetailsProps = {
  customerId: number
  orderId: number
}

export const useOrdersDetailsFetch = ({ orderId, customerId }: OrdersDetailsProps): UseOrdersDetailsFetchType => {
  const params = { id: orderId, customerId }

  const fetchOrdersDetailsProps = useQueryAsync<HistoryOrderType>({
    key: ordersKeys.ordersDetail(params),
    name: 'fetchOrdersDetails',
    fetcher: ordersService.fetchOrdersDetails,
    fetcherPayload: params,
    options: { enabled: !!params },
  })

  return {
    loadingOrdersDetailsFetch: fetchOrdersDetailsProps.isLoading,
    ordersDetails: fetchOrdersDetailsProps.data || ordersState.ordersDetails,
  }
}
