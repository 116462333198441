import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { book } from 'src/init/book'

import { FormCancelVisitSubmitType, FormSubmitParams, HttpErrorType, TourPlanForTodayType } from 'src/models'

import { customersKeys } from 'src/bus/customers'
import { manageToursKeys } from 'src/bus/manageTours'
import { tourKeys } from 'src/bus/tour'
import { useVisitsQuery } from 'src/bus/visits'
import { visitsHistoryKeys } from 'src/bus/visitsHistory'

import { useCurrentLocation, useToast } from 'src/hooks'

import { useDialog } from 'src/contexts'

import { fillServerErrorsToForm, GET_ENUMS, TOUR_SUCCESS_VALUE } from 'src/utils'

type UseVisitsCancelType = {
  loadingVisitsCancel: boolean
  onCancelVisit: (params: FormSubmitParams<FormCancelVisitSubmitType>) => void
  onCancelVisitHistory: (params: FormSubmitParams<FormCancelVisitSubmitType>) => void
}

export const useVisitsCancel = (visitId?: number): UseVisitsCancelType => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const { t } = useTranslation()
  const { geoLocation } = useCurrentLocation()
  const showToast = useToast()
  const { setOnPageDialog } = useDialog()

  const id = Number(visitId || router.query.id)

  const { onCancelVisit } = useVisitsQuery()

  const onCancelVisitCb = useCallback(
    (data: FormSubmitParams<FormCancelVisitSubmitType>) => {
      const isVisitPage = router.pathname.includes(book.visits)

      onCancelVisit.mutate(
        { params: { id }, data: data.values },
        {
          onSuccess: async () => {
            setOnPageDialog(GET_ENUMS.dialog.cancelVisit, false)
            if (isVisitPage) {
              await router.replace(book.visits)
            }
            showToast.success({ title: t('notifications:visit_cancel') })

            const planForToday: TourPlanForTodayType | undefined = await queryClient.getQueryData(
              tourKeys.planForToday(),
            )

            if (planForToday?.progress?.current === TOUR_SUCCESS_VALUE) {
              setOnPageDialog(GET_ENUMS.dialog.noVisits, true)
            }
          },
          onError: (error) => {
            const errs = (error as HttpErrorType).errors
            errs && fillServerErrorsToForm(errs, data.acts.setError)
          },
          onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey: visitsHistoryKeys.historyAll() })
            await queryClient.invalidateQueries({ queryKey: customersKeys.lists() })
            await queryClient.resetQueries({ queryKey: tourKeys.tourRoutes() })
            await queryClient.resetQueries({ queryKey: manageToursKeys.allPoints() })
            await queryClient.refetchQueries({ queryKey: manageToursKeys.days(router.locale) })
          },
        },
      )
    },
    [onCancelVisit, id, router, showToast, setOnPageDialog, t, queryClient, router.locale],
  )

  const onCancelVisitHistory = useCallback(
    (data: FormSubmitParams<FormCancelVisitSubmitType>) => {
      onCancelVisit.mutate(
        { params: { id }, data: data.values },
        {
          onSuccess: () => {
            setOnPageDialog(GET_ENUMS.dialog.cancelVisit, false)
            showToast.success({ title: t('notifications:visit_cancel') })
          },
          onError: (error) => {
            const errs = (error as HttpErrorType).errors
            errs && fillServerErrorsToForm(errs, data.acts.setError)
          },
          onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey: visitsHistoryKeys.all })
            geoLocation && (await queryClient.refetchQueries({ queryKey: tourKeys.tourRoute(geoLocation) }))
            await queryClient.refetchQueries({ queryKey: manageToursKeys.allPoints() })
            await queryClient.refetchQueries({ queryKey: manageToursKeys.days(router.locale) })
          },
        },
      )
    },
    [onCancelVisit, id, setOnPageDialog, showToast, t, geoLocation, queryClient, router.locale],
  )

  return {
    loadingVisitsCancel: onCancelVisit.isPending,
    onCancelVisit: onCancelVisitCb,
    onCancelVisitHistory,
  }
}
