import type { TFunction } from 'i18next'
import { z } from 'zod'

import { CAUSE_COMMERCIAL, isNilOrEmpty, messages, OPTION_OBJECT } from 'src/utils'

export const updateClaim = {
  shape: {
    images: [],
    cause: null,
    additionalCause: null,
    orderId: null,
    quantity: null,
    amount: 0,
    comment: '',
    status: '',
  },
  schema: (t: TFunction) => {
    return z
      .object({
        images: z
          .array(
            z.object({
              key: z.union([z.string(), z.number()]).optional(),
              url: z.string(),
              thumbnailUrl: z.string().optional(),
              extra: z.custom().optional(),
            }),
          )
          .min(messages.claimImages.min, t(messages.claimImages.minText, { count: messages.claimImages.min }))
          .optional(),
        cause: z.object(OPTION_OBJECT, { invalid_type_error: t(messages.cause.required) }),
        additionalCause: z.object(OPTION_OBJECT).nullable().default(null),
        orderId: z.number().nullable(),
        quantity: z.object(OPTION_OBJECT, {
          invalid_type_error: t(messages.quantity.required),
        }),
        amount: z.number().nullable(),
        comment: z.string().trim(),
        status: z.enum(['in-progress', 'confirmed', 'refused', '']),
      })
      .passthrough()
      .superRefine((value, ctx) => {
        if (value.cause?.value === CAUSE_COMMERCIAL && isNilOrEmpty(value.additionalCause)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['additionalCause'],
            message: t(messages.additionalCause.required),
          })

          return z.NEVER
        }

        if (value.status === 'refused') {
          if (value.comment.length < messages.comment.min) {
            ctx.addIssue({
              code: z.ZodIssueCode.too_small,
              path: ['comment'],
              inclusive: false,
              minimum: messages.comment.min,
              message: t(messages.comment.minText, {
                label: t(messages.comment.placeholder).toLowerCase(),
                count: messages.comment.min,
              }),
              type: 'string',
            })
          }

          if (value.comment.length >= messages.comment.max) {
            ctx.addIssue({
              code: z.ZodIssueCode.too_big,
              path: ['comment'],
              inclusive: false,
              maximum: messages.comment.max,
              message: t(messages.comment.maxText, {
                label: t(messages.comment.placeholder).toLowerCase(),
                count: messages.comment.max,
              }),
              type: 'string',
            })
          }

          return z.NEVER
        }
      })
  },
}
