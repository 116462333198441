import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'

import { FormEditNoteType, FormSubmitParams, HttpErrorType } from 'src/models'

import { useCustomerQuery, useCustomersDetailsFetch } from 'src/bus/customers'

import { useToast } from 'src/hooks'

import { useDialog } from 'src/contexts'

import { fillServerErrorsToForm, GET_ENUMS } from 'src/utils'

type CustomersEditNoteCbType = (data: FormSubmitParams<FormEditNoteType>) => void

type UseCustomersNoteEditType = {
  loadingCustomersNoteEdit: boolean
  onEditCustomersNote: CustomersEditNoteCbType
}

export const useCustomersNoteEdit = (): UseCustomersNoteEditType => {
  const { t } = useTranslation()
  const showToast = useToast()
  const { setOnPageDialog } = useDialog()
  const { customersDetails } = useCustomersDetailsFetch()

  const customerId = Number(customersDetails?.id)

  const { onUpdateCustomer } = useCustomerQuery(customerId)

  const onEditCustomersNote = useCallback<CustomersEditNoteCbType>(
    (data) => {
      onUpdateCustomer.mutate(
        {
          params: { ...(customerId && { id: customerId }) },
          data: { notes: data.values.notes },
          updateMethod: 'partial',
        },
        {
          onSuccess: () => {
            setOnPageDialog(GET_ENUMS.dialog.editNote, false)
            showToast.success({ title: t('notifications:note_edit') })
          },
          onError: (error) => {
            const errs = (error as HttpErrorType).errors
            errs && fillServerErrorsToForm(errs, data.acts.setError)
          },
        },
      )
    },
    [onUpdateCustomer, customerId, t, showToast, setOnPageDialog],
  )

  return {
    loadingCustomersNoteEdit: onUpdateCustomer.isPending,
    onEditCustomersNote,
  }
}
