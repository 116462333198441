import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'

import { FormSubmitParams, FormUpdateTourPointsType, HttpErrorType } from 'src/models'

import { tourKeys, useTourPointsUpdateMutation } from 'src/bus/tour'

import { useCurrentLocation, useToast } from 'src/hooks'

import { fillServerErrorsToForm } from 'src/utils'

type UpdateTourPointCbType = (data: FormSubmitParams<FormUpdateTourPointsType>) => void

type UseTourPointsUpdateType = {
  loadingTourPointsUpdate: boolean
  onUpdateTourPoint: UpdateTourPointCbType
}

export const useTourPointsUpdate = (): UseTourPointsUpdateType => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { geoLocation } = useCurrentLocation()
  const showToast = useToast()
  const { onUpdateTourPointMutation } = useTourPointsUpdateMutation()

  const onUpdateTourPoint = useCallback<UpdateTourPointCbType>(
    async (data) => {
      try {
        await onUpdateTourPointMutation.mutateAsync({ data: data.values })
        data.acts.reset()
        showToast.success({ title: t('notifications:point_update') })
        await queryClient.refetchQueries({ queryKey: tourKeys.tourPoints() })
        await queryClient.refetchQueries({ queryKey: tourKeys.tourPointsStatic() })
        geoLocation && (await queryClient.refetchQueries({ queryKey: tourKeys.tourRoute(geoLocation) }))
      } catch (error) {
        const errs = (error as HttpErrorType).errors
        errs && fillServerErrorsToForm(errs, data.acts.setError)
      }
    },
    [onUpdateTourPointMutation, showToast, t, geoLocation, queryClient],
  )

  return {
    onUpdateTourPoint,
    loadingTourPointsUpdate: onUpdateTourPointMutation.isPending,
  }
}
