import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'

import { FormCustomerIssueType, FormSubmitParams, HttpErrorType } from 'src/models'

import { historyKeys } from 'src/bus/history'
import { useIssueCreateMutation } from 'src/bus/otrs/mutaions'

import { useToast } from 'src/hooks'

import { useDialog } from 'src/contexts'

import { fillServerErrorsToForm, GET_ENUMS } from 'src/utils'

type IssueCreateCb = (params: FormSubmitParams<FormCustomerIssueType>) => void

type IssueCreateType = {
  loadingIssueCreate: boolean
  onCreateIssue: IssueCreateCb
}

export const useIssueCreate = (visitId: number): IssueCreateType => {
  const { t } = useTranslation()
  const showToast = useToast()
  const queryClient = useQueryClient()
  const { setOnPageDialog } = useDialog()

  const { onCreateIssueMutation } = useIssueCreateMutation()

  const onCreateIssue = useCallback<IssueCreateCb>(
    (data) => {
      onCreateIssueMutation.mutate(
        { data: { ...data.values, visitId } },
        {
          onSuccess: async () => {
            setOnPageDialog(GET_ENUMS.dialog.customerIssue, false)
            showToast.success({ title: t('notifications:issue_create') })
            await queryClient.refetchQueries({ queryKey: historyKeys.historiesAll() })
          },
          onError: (error) => {
            const errs = (error as HttpErrorType).errors
            errs && fillServerErrorsToForm(errs, data.acts.setError)
          },
        },
      )
    },
    [onCreateIssueMutation, setOnPageDialog, t, showToast, visitId, queryClient],
  )

  return {
    loadingIssueCreate: onCreateIssueMutation.isPending,
    onCreateIssue,
  }
}
