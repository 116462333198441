import { HttpResponse, CustomerParams, KamCustomerType, kamCustomerSchema } from 'src/models'

import { zodValidateResponse } from 'src/utils'

import { httpAxiosProvider } from 'src/api'

export const kamCustomersService = Object.freeze({
  fetchKamCustomers: async ({ params }: CustomerParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<KamCustomerType>>(`/kam-customer/${params?.id}`)
    zodValidateResponse(kamCustomerSchema, response.data.data)
    return response
  },
})
