import { cond, lte, equals } from 'ramda'

import { ProgressBarColorType } from 'src/components/ui/ProgressBar'

export const createGradient = (colors: string[]): string => {
  return `linear-gradient(90deg, var(--states-gradient-from) 0%, ${colors})`
}

const getColorByPercent = (percent: number) => {
  switch (true) {
    case lte(percent, 0):
      return createGradient(['var(--states-gradient-from)'])
    case lte(percent, 10):
      return createGradient(['var(--progress-gradient-10) 100%'])
    case lte(percent, 25):
      return createGradient(['var(--progress-gradient-25) 100%'])
    case lte(percent, 50):
      return createGradient(['var(--progress-gradient-50) 100%'])
    case lte(percent, 75):
      return createGradient(['var(--progress-gradient-50) 65.76%', 'var(--progress-gradient-75) 100%'])
    case lte(percent, 100):
      return createGradient(['var(--progress-gradient-50) 51.56%', 'var(--progress-gradient-100) 100%'])
    default:
      return createGradient(['var(--states-gradient-from)'])
  }
}

export const getColorType = cond([
  [equals('Silver'), () => ProgressBarColorType.silver],
  [equals('Gold'), () => ProgressBarColorType.gold],
  [equals('Platinum'), () => ProgressBarColorType.platinum],
])

export const getProgressBarInlineStyles = (
  percent: number,
  type: ProgressBarColorType = ProgressBarColorType.default,
): Record<string, string> => {
  const linearGradientTypes = {
    [ProgressBarColorType.red]: (percent: number) => ({
      '--fill-color': getColorByPercent(percent),
      '--track-color': 'var(--gray-3)',
    }),
    [ProgressBarColorType.silver]: () => ({
      '--fill-color': createGradient([
        'var(--loyalty-progress-bar-silver-gradient-from) 0%',
        'var(--loyalty-progress-bar-silver-gradient-to) 100%',
      ]),
      '--track-color': 'var(--gray-3)',
    }),
    [ProgressBarColorType.gold]: () => ({
      '--fill-color': createGradient([
        'var(--loyalty-progress-bar-gold-gradient-from) 0%',
        'var(--loyalty-progress-bar-gold-gradient-to) 100%',
      ]),
      '--track-color': 'var(--gray-3)',
    }),
    [ProgressBarColorType.platinum]: () => ({
      '--fill-color': createGradient([
        'var(--loyalty-progress-bar-platinum-gradient-from) 0%',
        'var(--loyalty-progress-bar-platinum-gradient-to) 100%',
      ]),
      '--track-color': 'var(--gray-3)',
    }),
    [ProgressBarColorType.default]: () => ({
      '--fill-color': 'var(--primary-blue-8)',
      '--track-color': 'var(--gray-3)',
    }),
  }

  return linearGradientTypes[type as ProgressBarColorType](percent)
}
