import { useTranslation } from 'next-i18next'

import { CellCopy } from 'src/components/common'
import { Button, DialogContent, DialogHeader, Loader, Typography } from 'src/components/ui'

import { useCustomersCredentialsFetch, useCustomersDetailsFetch } from 'src/bus/customers'

import { GET_ENUMS, getTranslate } from 'src/utils'

export const DialogCustomerCredentials = () => {
  const { t } = useTranslation()
  const { credentials, loadingCredentialsFetch } = useCustomersCredentialsFetch()
  const { customersDetails, loadingCustomersDetailsFetch } = useCustomersDetailsFetch()

  const { login, password } = credentials

  const title = customersDetails?.title

  const getCredentialsT = getTranslate('visits:credentials', t)

  const credentialsText = `${getCredentialsT('title', { title })}:\n${getCredentialsT(
    'username',
  )}: ${login}\n${getCredentialsT('password')}: ${password}`

  return (
    <DialogContent name={GET_ENUMS.dialog.customerCredentials}>
      {(loadingCustomersDetailsFetch || loadingCredentialsFetch) && <Loader />}
      <DialogHeader>
        <Typography variant='title' color='gray-10'>
          {getCredentialsT('title', { title })}
        </Typography>
      </DialogHeader>
      <div className='flex items-center justify-between'>
        <div className='flex basis-5/12'>
          <Typography variant='secondary'>{getCredentialsT('username')}</Typography>
        </div>
        <div className='flex basis-7/12'>
          <CellCopy
            className='w-full'
            value={login}
            copyValue={login}
            copiedText='login_copied'
            loadingFetch={loadingCredentialsFetch}
            disabled={!login || loadingCredentialsFetch}
          />
        </div>
      </div>
      <div className='flex items-center justify-between'>
        <div className='flex basis-5/12'>
          <Typography variant='secondary'>{getCredentialsT('password')}</Typography>
        </div>
        <div className='flex basis-7/12'>
          <CellCopy
            className='w-full'
            value={password}
            copyValue={password}
            copiedText='password_copied'
            loadingFetch={loadingCredentialsFetch}
            disabled={!password || loadingCredentialsFetch}
          />
        </div>
      </div>
      <CellCopy
        copyValue={credentialsText}
        copiedText='credentials_copied'
        loadingFetch={loadingCredentialsFetch}
        className='p-0'
      >
        <Button block disabled={!login || !password}>
          {getCredentialsT('copy_both')}
        </Button>
      </CellCopy>
    </DialogContent>
  )
}
