import { WrappedNodeDefault } from 'src/components/common'
import { Typography, Skeleton } from 'src/components/ui'

import { formatDate } from 'src/utils'

type CellDateProps = {
  value?: Date | number | string
  loadingFetch?: boolean
}

export const CellDate = ({ value = new Date(), loadingFetch = false }: CellDateProps) => {
  if (loadingFetch) {
    return <Skeleton className='h-4' />
  }

  return (
    <Typography variant='secondary' color='gray-7'>
      {WrappedNodeDefault(formatDate(value))}
    </Typography>
  )
}
