import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useMemo } from 'react'

import { book } from 'src/init/book'

import { Dialog, DialogViewsSwitcher } from 'src/components/common'
import { ListItem, Button, Typography, Icon, FloatingPanel } from 'src/components/ui'

import { MenuProfileType } from 'src/models'

import { useSettingsFetch } from 'src/bus/settings'
import { useVisitsFetch } from 'src/bus/visits'

import { useDialog } from 'src/contexts'

import { FLOATING_PANEL_ANCHORS, GET_ENUMS, getNavigationLink, getVisitsMenu } from 'src/utils'

export const VisitsPanel = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const { onPageDialogs, setOnPageDialog } = useDialog()
  const { visitFirst, loadingVisitsFetch } = useVisitsFetch()
  const { settings } = useSettingsFetch()

  const items = getVisitsMenu({ visitFirst, t })

  const onNavigationHandler = async () => {
    visitFirst &&
      (await router.push({
        pathname: `${book.visits}/${visitFirst.id}${book.visitPlan}`,
        query: { customerId: visitFirst.customerId },
      }))
  }

  const getNavLink = useMemo(() => {
    return visitFirst ? getNavigationLink(settings)(visitFirst.location) : ''
  }, [visitFirst, settings])

  const onClickHandler = (item: MenuProfileType) => async () => {
    if (item.dialog) {
      setOnPageDialog(item.key, true)
      return
    }

    await router.push(item.key)
  }

  return (
    <>
      <Dialog
        open={onPageDialogs[GET_ENUMS.dialog.stopTour]?.visible}
        onOpenChange={(open) => setOnPageDialog(GET_ENUMS.dialog.stopTour, open)}
      >
        <DialogViewsSwitcher name={GET_ENUMS.dialog.stopTour} />
      </Dialog>
      <FloatingPanel anchors={FLOATING_PANEL_ANCHORS.TWO_STEPS}>
        <div className='mb-4 px-4'>
          <Button disabled={loadingVisitsFetch || !visitFirst} onClick={onNavigationHandler} block>
            <Link href={getNavLink} target='_blank' rel='noopener noreferrer'>
              {t('buttons:go_to_navigation')}
            </Link>
          </Button>
        </div>
        {items.map((item) => {
          const isCancel = item.type === 'cancel'

          return (
            <ListItem key={item.key} disabled={item.disabled} onClick={onClickHandler(item)} size='panel'>
              <div className='flex items-center gap-4'>
                <Icon name={item.icon} color={isCancel ? 'error' : 'blue-gray-7'} />
                <Typography color={isCancel ? 'error' : 'default'}>{item.title}</Typography>
              </div>
            </ListItem>
          )
        })}
      </FloatingPanel>
    </>
  )
}
