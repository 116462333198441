import { ParcelsReplacesType } from 'src/models'

import { parcelsKeys, parcelsService } from 'src/bus/parcels'

import { useQueryAsync } from 'src/hooks'

export const useParcelsReplaceFetch = (parcelArticleId?: number) => {
  const params = { parcelArticleId }

  const fetchParcelsReplaceProps = useQueryAsync<ParcelsReplacesType>({
    key: parcelsKeys.replace(parcelArticleId),
    name: 'fetchParcelsReplace',
    fetcher: parcelsService.fetchParcelsReplace,
    fetcherPayload: params,
    options: { enabled: !!parcelArticleId },
  })

  return {
    parcelsReplace: fetchParcelsReplaceProps.data || { items: [] },
    loadingParcelsReplaceFetch: fetchParcelsReplaceProps.isLoading,
  }
}
