import { isNil } from 'ramda'
import { useState } from 'react'

import { isNotNilOrNotEmpty, verifyBrowser } from 'src/utils'

export const useLocalStorage = <T>(key: string, initialValue: T) => {
  const isBrowser = verifyBrowser()

  const [storedValue, setStoredValue] = useState<T>(() => {
    if (!isBrowser) {
      return initialValue
    }

    try {
      const item = window.localStorage.getItem(key)

      if (isNil(item)) {
        return initialValue
      }

      const parsedValue = JSON.parse(item)
      return isNotNilOrNotEmpty(parsedValue) ? parsedValue : initialValue
    } catch (err) {
      console.log('useLocalStorage get: ', err)
      return initialValue
    }
  })

  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)

      if (isBrowser) {
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      }
    } catch (err) {
      console.log('useLocalStorage set: ', err)
    }
  }
  return [storedValue, setValue] as const
}
