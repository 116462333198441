import { useTranslation } from 'next-i18next'

import { DialogContent, DialogHeader, Icon, Typography } from 'src/components/ui'

import { ReturnsInfoMarkersType } from 'src/models'

import { GET_ENUMS, getTranslate } from 'src/utils'

import st from './Styles.module.css'

export const DialogReturnsInfoMarkers = () => {
  const { t } = useTranslation()

  const getReturnsT = getTranslate('returns:returns_info_markers_modal', t)

  const infoList: ReturnsInfoMarkersType = [
    {
      id: 1,
      icon: 'C',
      title: getReturnsT('commercial_return.title'),
      description: getReturnsT('commercial_return.description'),
    },
    {
      id: 2,
      icon: 'P',
      title: getReturnsT('pfand_return.title'),
      description: getReturnsT('pfand_return.description'),
    },
    {
      id: 3,
      icon: 'E',
      title: getReturnsT('expired_return.title'),
      description: getReturnsT('expired_return.description'),
    },
    {
      id: 4,
      icon: 'R',
      title: getReturnsT('returned_article.title'),
      description: getReturnsT('returned_article.description'),
    },
  ]

  return (
    <DialogContent name={GET_ENUMS.dialog.returnsInfoMarkers}>
      <DialogHeader>
        <Typography className='font-semibold'>{getReturnsT('title')}</Typography>
        <Typography variant='secondary' color='gray-7'>
          {getReturnsT('description')}
        </Typography>
      </DialogHeader>
      <div className='flex flex-col gap-2'>
        {infoList.map((item) => {
          return (
            <div key={item.id} className='flex items-center gap-2 border border-gray-2 bg-white px-4 py-2'>
              <div>
                <Icon name={item.icon} />
              </div>
              <div className={st['marker-content']}>
                <Typography className='font-semibold'>{item.title}</Typography>
                <Typography>{item.description}</Typography>
              </div>
            </div>
          )
        })}
      </div>
    </DialogContent>
  )
}
