import { TourRoutesType } from 'src/models'

import { tourState, tourKeys, tourService } from 'src/bus/tour'

import { useCurrentLocation, useQueryAsync } from 'src/hooks'

import { isNotNilOrNotEmpty } from 'src/utils'

type UseTourRoutesFetchType = {
  loadingTourRoutesFetch: boolean
  tourRoutes: TourRoutesType
}

export const useTourRoutesFetch = (): UseTourRoutesFetchType => {
  const { geoLocation, isGeolocationAvailable, isGeolocationEnabled, loadingLocation } = useCurrentLocation()

  const params = geoLocation || {}

  const fetchTourRoutesProps = useQueryAsync<TourRoutesType>({
    key: tourKeys.tourRoute(geoLocation),
    name: 'fetchTourRoutes',
    fetcher: tourService.fetchTourRoute,
    fetcherPayload: params,
    options: {
      enabled: isNotNilOrNotEmpty(geoLocation) && isGeolocationEnabled && isGeolocationAvailable,
      refetchOnMount: true,
    },
  })

  return {
    loadingTourRoutesFetch: fetchTourRoutesProps.isLoading || loadingLocation,
    tourRoutes: fetchTourRoutesProps.data || tourState.tourRoutes,
  }
}
