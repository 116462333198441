import { Col, Row } from 'antd'
import { useTranslation } from 'next-i18next'

import { OrderAutocompleteItem } from 'src/components/common'
import { Loader, Typography } from 'src/components/ui'

import { AutocompleteOrderType } from 'src/models'

import { getHasData, rowGutters, ternary } from 'src/utils'

type OrdersAutocompleteProps = {
  orders?: AutocompleteOrderType[]
  loadingFetch?: boolean
}

const ordersHeader = [
  { title: 'returns:returns_details.article', span: 7 },
  { title: 'returns:returns_details.order_number', span: 5 },
  { title: 'returns:returns_details.item', span: 6 },
  { title: 'returns:returns_details.item_brand', span: 6 },
]

export const OrdersAutocomplete = ({ orders = [], loadingFetch = false }: OrdersAutocompleteProps) => {
  const { t } = useTranslation()

  return (
    <>
      {ternary(
        loadingFetch,
        <div className='flex items-center justify-center py-2'>
          <Loader type='relative' />
        </div>,
        <>
          {ternary(
            getHasData(orders),
            <>
              <div className='bg-blue-gray-1 px-2 py-3'>
                <Row gutter={rowGutters}>
                  {ordersHeader.map((item) => (
                    <Col key={item.title} span={item.span}>
                      <Typography variant='caption' className='font-semibold'>
                        {t(item.title)}
                      </Typography>
                    </Col>
                  ))}
                </Row>
              </div>
              {orders.map((item) => (
                <OrderAutocompleteItem key={item.orderProductId} item={item} />
              ))}
            </>,
            <div className='mb-2 mt-5 flex items-center justify-center'>
              <Typography variant='secondary' color='gray-7'>
                {t('empty.current_orders')}
              </Typography>
            </div>,
          )}
        </>,
      )}
    </>
  )
}
