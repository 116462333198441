import useDeepCompareEffect from 'use-deep-compare-effect'

import { FiltersDrawer } from 'src/components/common'

import { ArticlesType, FilterStaticDataType, FilterStaticEnum, FilterStaticType, FormSubmitParams } from 'src/models'

import { useParcelsArticlesFetch, useParcelsArticlesFilters, useParcelsArticlesTotalFetch } from 'src/bus/parcels'

import { useUrlQuery } from 'src/hooks'

import { useDrawer, usePageUrlContext } from 'src/contexts'

import { GET_ENUMS, isNotNilOrNotEmpty } from 'src/utils'

type ArticlesFiltersProps = {
  type: ArticlesType
}

export const ArticlesFilters = ({ type }: ArticlesFiltersProps) => {
  const { initialFilters, setStorageFilters } = usePageUrlContext()
  const { setOnPageDrawer } = useDrawer()
  const { onUpdateQuery } = useUrlQuery()
  const { parcelsArticlesFilters, loadingParcelsArticlesFiltersFetch } = useParcelsArticlesFilters()
  const { loadingArticlesListFetch, onRefetchParcelsArticles, parcelsArticlesFiltersStaticInitialValues } =
    useParcelsArticlesFetch({
      type,
    })
  const { onRefetchParcelsArticlesTotal, loadingParcelsArticlesTotalFetch } =
    useParcelsArticlesTotalFetch(initialFilters)

  const filterStaticItems: FilterStaticDataType = {
    parcelArticleStatus: {
      name: FilterStaticEnum.parcelArticleStatus,
      options: parcelsArticlesFilters.parcelArticleStatus,
    },
  }

  useDeepCompareEffect(() => {
    if (isNotNilOrNotEmpty(initialFilters.filter)) {
      return
    }

    setStorageFilters({ parcelArticleStatus: '1' })
  }, [initialFilters])

  const onSubmit = async (data: FormSubmitParams<FilterStaticType>) => {
    const { filterData, filter } = onUpdateQuery({
      filtersValues: data.values,
      filterItems: filterStaticItems,
    })

    setStorageFilters(filter)
    await onRefetchParcelsArticles(filterData)
    await onRefetchParcelsArticlesTotal(filterData)
    setOnPageDrawer(GET_ENUMS.drawer.filters, false)
  }

  return (
    <FiltersDrawer
      onSubmit={onSubmit}
      filterItemsSet={filterStaticItems}
      buttons={['apply', 'reset']}
      loadingSubmit={loadingArticlesListFetch || loadingParcelsArticlesTotalFetch}
      initialValues={parcelsArticlesFiltersStaticInitialValues.filter}
      loadingStaticDataFetch={loadingParcelsArticlesFiltersFetch}
    />
  )
}
