import type { TFunction } from 'i18next'
import type { MouseEvent } from 'react'

import { book } from 'src/init/book'

import { CellSwipe } from 'src/components/common'

import type { SwipeActionType } from 'src/models'

import { getActionsWithoutHidden } from 'src/utils'

type CustomerItemActionsProps = {
  onSelectCell: (link: string) => (event: MouseEvent) => void
  t: TFunction
  hasClosed: boolean
}

export const getCustomerItemActions = ({ onSelectCell, t, hasClosed }: CustomerItemActionsProps): SwipeActionType[] => {
  const actions = [
    {
      key: 'createVisit',
      text: CellSwipe({
        text: t('buttons:create_new_visit'),
        icon: 'Calendar',
        type: 'info',
      }),
      onClick: onSelectCell(`${book.visitsCreate}`),
      isHidden: hasClosed,
    },
  ]

  return getActionsWithoutHidden(actions)
}
