import { useTranslation } from 'next-i18next'

import { Button, DialogContent, DialogHeader, Typography } from 'src/components/ui'

import { useDialog } from 'src/contexts'

import { GET_ENUMS, orNull } from 'src/utils'

type DialogInfoProps = {
  title?: string
  description?: string
}

export const DialogInfo = ({ title, description }: DialogInfoProps) => {
  const { setOnPageDialog } = useDialog()
  const { t } = useTranslation()

  return (
    <DialogContent name={GET_ENUMS.dialog.info}>
      <DialogHeader>
        <Typography variant='title' color='gray-10'>
          {title}
        </Typography>
      </DialogHeader>
      {orNull(
        description,
        <Typography variant='secondary' color='gray-7' className='whitespace-pre-line text-justify'>
          {description}
        </Typography>,
      )}
      <Button onClick={() => setOnPageDialog(GET_ENUMS.dialog.info, false)}>{t('buttons:ok')}</Button>
    </DialogContent>
  )
}
