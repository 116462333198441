import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'

import { book } from 'src/init/book'

import { HttpErrorType } from 'src/models'

import { authKeys, authService } from 'src/bus/auth'

import { useToast } from 'src/hooks'

import { initialLocale, queryFetcher, removeFromStorage } from 'src/utils'

type UseAuthLogoutType = {
  loadingAuthLogout: boolean
  onLogoutAuth: () => void
}

export const useAuthLogout = (): UseAuthLogoutType => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const showToast = useToast()

  const authLogoutProps = useMutation({
    mutationKey: authKeys.login(),
    mutationFn: () =>
      queryFetcher({
        name: 'authLogout',
        fetcher: authService.logout,
      }),
  })

  const onLogoutAuth = () => {
    authLogoutProps.mutate(undefined, {
      onSuccess: async () => {
        removeFromStorage()
        await router.push(book.signin)
        queryClient.clear()
        await router.replace(book.signin, undefined, { locale: initialLocale })
      },
      onError: (error) => {
        const errors = error as HttpErrorType
        showToast.error({ title: errors.type, body: errors.message })
      },
    })
  }

  return {
    loadingAuthLogout: authLogoutProps.isPending,
    onLogoutAuth,
  }
}
