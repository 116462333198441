import { useMemo } from 'react'
import { useGeolocated } from 'react-geolocated'

import { LocationType } from 'src/models'

type UseCurrentLocationType = {
  coords?: GeolocationCoordinates
  loadingLocation: boolean
  isGeolocationAvailable: boolean
  isGeolocationEnabled: boolean
  timestamp?: number
  positionError?: GeolocationPositionError
  geoLocation: LocationType | null
}

export const useCurrentLocation = (): UseCurrentLocationType => {
  const { coords, isGeolocationAvailable, isGeolocationEnabled, timestamp, positionError } = useGeolocated()

  const geoLocation = useMemo(() => {
    if (!coords) {
      return null
    }
    return { lat: coords.latitude, lng: coords.longitude }
  }, [coords])

  return {
    loadingLocation: !coords && isGeolocationEnabled && isGeolocationAvailable,
    isGeolocationAvailable,
    isGeolocationEnabled,
    coords,
    geoLocation,
    timestamp,
    positionError,
  }
}
