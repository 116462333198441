export const otrsTicketReasons = [
  {
    id: 1,
    value: 'cc_didnt_reach_client',
    label: "СС didn't reach client",
  },
  {
    id: 2,
    value: 'payment_refund_issue',
    label: 'Payment / refund issue',
  },
  {
    id: 3,
    value: 'delivery_issues',
    label: 'Delivery issues',
  },
  {
    id: 4,
    value: 'technical_issues',
    label: 'Technical issues',
  },
  {
    id: 5,
    value: 'visual_catalog_issues',
    label: 'Visual catalog issues (photo mismatch)',
  },
  {
    id: 6,
    value: 'pricing_issue',
    label: 'Pricing issue',
  },
  {
    id: 7,
    value: 'other',
    label: 'Other',
  },
]

export const clarifications = (reasonId?: number) => {
  if (reasonId === 3) {
    return [
      {
        id: 1,
        value: 'item_does_not_match_order',
        label: 'Received item does not match the order',
      },
      {
        id: 2,
        value: 'incorrect_delivery_address',
        label: 'Incorrect delivery address',
      },
      {
        id: 3,
        value: 'damaged_packaging',
        label: 'Damaged packaging',
      },
      {
        id: 4,
        value: 'other',
        label: 'Other reason',
      },
    ]
  }

  if (reasonId === 4) {
    return [
      {
        id: 1,
        value: 'item_does_not_fit_car',
        label: "Item doesn't fit the car",
      },
      {
        id: 2,
        value: 'delivered_item_specs_differ',
        label: 'Delivered item specs differ from website',
      },
      {
        id: 3,
        value: 'inaccurate_item_details',
        label: 'Inaccurate item details on website',
      },
      {
        id: 4,
        value: 'vin_plate',
        label: 'VIN/plate misidentification',
      },
    ]
  }

  return []
}

export const fields = (reasonId?: number, clarificationId?: number) => {
  if (reasonId === 1 || reasonId === 7) {
    return []
  }

  if (reasonId === 2) {
    return ['order']
  }

  if (reasonId === 3) {
    if (clarificationId === 1) {
      return ['order', 'images']
    }
    if (clarificationId === 2) {
      return ['order', 'correctAddress']
    }
    if (clarificationId === 3) {
      return ['order', 'images']
    }
    if (clarificationId === 4) {
      return ['order']
    }
  }

  if (reasonId === 4) {
    if (clarificationId === 1) {
      return ['vin', 'images']
    }
    if (clarificationId === 2) {
      return ['order', 'orderProduct', 'images']
    }
    if (clarificationId === 3) {
      return ['brand', 'article']
    }
    if (clarificationId === 4) {
      return ['vinOrPlateNumber', 'brand', 'article']
    }
  }

  if (reasonId === 5) {
    return ['article', 'brand']
  }

  if (reasonId === 6) {
    return ['order', 'orderProduct', 'article']
  }

  return []
}

export const orderOptions = [
  {
    id: 108267231,
    value: '108267231',
    label: '108267231',
  },
  {
    id: 108267230,
    value: '108267230',
    label: '108267230',
  },
  {
    id: 108267229,
    value: '108267229',
    label: '108267229',
  },
  {
    id: 108267142,
    value: '108267142',
    label: '108267142',
  },
]

export const orderProductOptions = [
  {
    id: 318595907,
    value: '13644996',
    label: 'PETEC 98510',
  },
  {
    id: 318595908,
    value: '13644997',
    label: 'PETEC 98511',
  },
]
