import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'next-i18next'
import { useForm } from 'react-hook-form'

import { Button, Form, FormControl, FormField, FormFields, FormItem, FormMessage } from 'src/components/ui'

import { FormTrackNumberEnum, FormSubmitFn, FormTrackNumberType } from 'src/models'

import { formShapes } from 'src/bus/forms'

import { messages } from 'src/utils'

type TrackNumberFormProps = {
  loadingSubmit: boolean
  onSubmit: FormSubmitFn<FormTrackNumberType>
}

export const TrackNumberForm = ({ loadingSubmit = false, onSubmit }: TrackNumberFormProps) => {
  const { t } = useTranslation()

  const formProps = useForm<FormTrackNumberType>({
    defaultValues: { ...formShapes.trackNumber.shape },
    resolver: zodResolver(formShapes.trackNumber.schema(t)),
    mode: 'onChange',
  })

  const { control, handleSubmit, setError, reset } = formProps

  const onSubmitForm = handleSubmit(async (values) => {
    await onSubmit({ values, acts: { setError, reset } })
  })

  return (
    <Form {...formProps}>
      <FormFields.FormGeneral onSubmit={onSubmitForm}>
        <FormField
          control={control}
          name={FormTrackNumberEnum.shipmentNumber}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormFields.InputBase
                  {...field}
                  placeholder={t(messages.trackNumber.shipmentNumber.placeholder)}
                  disabled={loadingSubmit}
                />
              </FormControl>
              <FormMessage className='absolute' />
            </FormItem>
          )}
        />
        <Button block type='submit' loading={loadingSubmit}>
          {t('parcels:modal_track_number_nl.button')}
        </Button>
      </FormFields.FormGeneral>
    </Form>
  )
}
