import axios from 'axios'

import {
  ActionsReportsType,
  ActionsListType,
  ActionType,
  ActionsParams,
  HttpResponse,
  actionListSchema,
  actionSchema,
  actionReportListSchema,
} from 'src/models'

import { NPS_ID, zodValidateResponse } from 'src/utils'

import { httpAxiosProvider } from 'src/api'

const httpFake = axios.create({
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

export const actionsService = Object.freeze({
  fetchActionsReports: async ({ params }: ActionsParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<ActionsReportsType>>('/action-options', { params })
    zodValidateResponse(actionReportListSchema, response.data.data)
    return response
  },
  fetchActionsList: async ({ params }: ActionsParams) => {
    const response = await httpAxiosProvider.get<HttpResponse<ActionsListType>>('/action', {
      params,
    })
    zodValidateResponse(actionListSchema, response.data.data)
    return response
  },
  fetchActionsDetails: async ({ params }: ActionsParams) => {
    if (params?.id === NPS_ID) {
      const response = await httpFake.get<HttpResponse<ActionType>>(`/api/action/${params?.id}/details`)
      zodValidateResponse(actionSchema, response.data.data)
      return response
    }

    const response = await httpAxiosProvider.get<HttpResponse<ActionType>>(`/action/${params?.id}/detail`)
    zodValidateResponse(actionSchema, response.data.data)
    return response
  },
})
