import { useRouter } from 'next/router'
import { isNil } from 'ramda'

import { ClaimTotalPriceType } from 'src/models'

import { claimsKeys, claimsState, claimsService, useClaimsDetailsFetch } from 'src/bus/claims'
import { useCustomersDetailsFetch } from 'src/bus/customers'

import { useQueryAsync } from 'src/hooks'

import { getParamsFromQueryStr } from 'src/utils'

type UseClaimsTotalPriceFetchType = {
  loadingClaimsTotalPriceFetch: boolean
  claimsTotalPrice: number
}

export const useClaimsTotalPriceFetch = (quantity?: number, causeId?: number): UseClaimsTotalPriceFetchType => {
  const router = useRouter()
  const { claimsDetails } = useClaimsDetailsFetch()
  const { customerId } = useCustomersDetailsFetch()

  const initialQuery = getParamsFromQueryStr(router.query)

  const orderProductId = Number(claimsDetails?.orderProductId || initialQuery?.orderProductId)
  const claimId = Number(claimsDetails?.id)
  const params = { ...(claimId ? { claimId } : { orderProductId }), customerId, causeId, quantity }

  const fetchClaimsOptionsProps = useQueryAsync<ClaimTotalPriceType>({
    key: claimsKeys.totalPrice(params),
    name: 'fetchClaimsTotalPrice',
    fetcher: claimsService.fetchPrice,
    fetcherPayload: params,
    options: {
      enabled: (!!orderProductId || !!claimId) && !!customerId && !!causeId && !isNil(quantity),
    },
  })

  return {
    loadingClaimsTotalPriceFetch: fetchClaimsOptionsProps.isLoading,
    claimsTotalPrice: fetchClaimsOptionsProps.data?.total || claimsState.claimTotalPrice,
  }
}
