import { StaticDataItemType, StaticDataKeys } from 'src/models'

import { useStaticDataFetch } from 'src/bus/staticData'
import { useVisitsHistoryCustomersFetch, useVisitsHistorySectorsFetch } from 'src/bus/visitsHistory'

type UseVisitsHistoryStaticDataFetchType = {
  statuses: StaticDataItemType[]
  sectors: StaticDataItemType[]
  customers: StaticDataItemType[]
  customerStatus: StaticDataItemType[]
  loadingVisitsStaticDataFetch: boolean
}

export const useVisitsHistoryStaticDataFetch = (): UseVisitsHistoryStaticDataFetchType => {
  const staticDataKeys = [StaticDataKeys.visitStatus, StaticDataKeys.customerStatus]

  const { staticData, loadingStaticDataFetch } = useStaticDataFetch(staticDataKeys)
  const { visitsHistorySectors, loadingVisitsHistorySectorsFetch } = useVisitsHistorySectorsFetch()
  const { visitsHistoryCustomers, loadingVisitsHistoryCustomersFetch } = useVisitsHistoryCustomersFetch()

  return {
    statuses: staticData?.['visit-status'] || [],
    customerStatus: staticData?.['customer-status'] || [],
    sectors: visitsHistorySectors.items || [],
    customers: visitsHistoryCustomers.items || [],
    loadingVisitsStaticDataFetch:
      loadingStaticDataFetch || loadingVisitsHistorySectorsFetch || loadingVisitsHistoryCustomersFetch,
  }
}
