import { useMutation, useQueryClient } from '@tanstack/react-query'

import { CustomerParams, CustomerType, HttpErrorType } from 'src/models'

import { customersKeys, customersService } from 'src/bus/customers'

import { useQueryAsync } from 'src/hooks'

import { queryFetcher } from 'src/utils'

export const useCustomerQuery = (id?: number) => {
  const queryClient = useQueryClient()

  const customerQuery = useQueryAsync<CustomerType>({
    key: customersKeys.details(id),
    name: 'fetchCustomersDetails',
    fetcher: customersService.fetchCustomersDetails,
    fetcherPayload: { id },
    options: { enabled: !!id },
  })

  const onUpdateCustomer = useMutation<
    CustomerType,
    HttpErrorType,
    CustomerParams & { updateMethod?: 'default' | 'partial' }
  >({
    mutationKey: customersKeys.details(id),
    mutationFn: ({ updateMethod, ...params }) =>
      queryFetcher({
        name: 'updateCustomerPartial',
        fetcher: updateMethod === 'partial' ? customersService.editCustomersProps : customersService.updateCustomer,
        fetcherPayload: params,
      }),
    onMutate: async (params) => {
      await queryClient.cancelQueries({ queryKey: customersKeys.details(id) })
      const previousData = queryClient.getQueryData(customersKeys.details(id))

      if (previousData) {
        queryClient.setQueryData(customersKeys.details(id), { ...previousData, ...params.data })
      }

      return previousData
    },
    onError: (_, __, context) => {
      context && queryClient.setQueryData(customersKeys.details(id), context)
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: customersKeys.details(id) })
    },
  })

  return {
    customerQuery,
    onUpdateCustomer,
  }
}
