import { useRouter } from 'next/router'

import { VisitCancelParamsType, VisitReasonCancelType } from 'src/models'

import { visitsHistoryKeys, visitsHistoryService } from 'src/bus/visitsHistory'

import { useQueryAsync } from 'src/hooks'

type UseVisitsHistoryReasonCancelFetchType = {
  loadingReasonCancelFetch: boolean
  historyReasonCancel: VisitReasonCancelType | null
}

export const useVisitsHistoryReasonCancelFetch = (): UseVisitsHistoryReasonCancelFetchType => {
  const router = useRouter()

  const id = Number(router.query.id)
  const params = { id }

  const fetchVisitsReasonCancelProps = useQueryAsync<VisitCancelParamsType>({
    key: visitsHistoryKeys.historyReport(id),
    name: 'fetchVisitsHistoryReasonCancel',
    fetcher: visitsHistoryService.fetchVisitsHistoryReasonCancel,
    fetcherPayload: params,
  })

  const historyReasonCancel = {
    ...fetchVisitsReasonCancelProps.data,
    reason: fetchVisitsReasonCancelProps.data?.reason?.value || '',
  } as VisitReasonCancelType

  return {
    loadingReasonCancelFetch: fetchVisitsReasonCancelProps.isLoading,
    historyReasonCancel,
  }
}
