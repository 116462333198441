export const returnsState = {
  returns: {
    pages: [
      {
        items: [],
        meta: null,
      },
    ],
  },
  returnTotal: null,
}

export const returnsKeys = {
  all: ['returns'],
  lists: () => [...returnsKeys.all, 'list'],
  list: (params: any) => [...returnsKeys.lists(), params],
  details: () => [...returnsKeys.all, 'detail'],
  detail: (id: number) => [...returnsKeys.details(), id],
  totals: () => [...returnsKeys.all, 'total'],
  total: (params: any) => [...returnsKeys.totals(), params],
}
