import { Typography } from 'src/components/ui'

export const Copyrights = () => {
  return (
    <div className='p-6'>
      <Typography variant='secondary' color='gray-7' className='text-center'>
        &copy; AutodocPro {new Date().getFullYear()}
      </Typography>
    </div>
  )
}
