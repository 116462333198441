import { ParcelsArticles } from 'src/components/common'

import { useParcelsDirtyFetch } from 'src/bus/parcels'

export const ParcelsDirtyTabContent = () => {
  const { articlesListDirty, articlesListDirtyHasMore, loadingArticlesDirtyFetch, onFetchMoreArticlesListDirty } =
    useParcelsDirtyFetch()

  return (
    <ParcelsArticles
      type='dirty'
      data={articlesListDirty}
      loadingFetch={loadingArticlesDirtyFetch}
      onFetchMore={onFetchMoreArticlesListDirty}
      hasMore={articlesListDirtyHasMore}
    />
  )
}
