import { useInfiniteQuery, keepPreviousData } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import QS from 'qs'
import { useCallback, useMemo } from 'react'

import {
  ArticlesType,
  FilterStaticFilterQueryParamsType,
  ParcelsArticlesInfiniteType,
  ParcelsArticlesType,
  TRefetchFilterStaticCb,
} from 'src/models'

import { parcelsService, parcelsKeys, parcelsState } from 'src/bus/parcels'

import { usePageUrlContext } from 'src/contexts'

import { getHasData, getNextPageParam, queryFetcher } from 'src/utils'

type UseParcelsArticlesFetchType = {
  loadingArticlesListFetch: boolean
  hasArticlesList: boolean
  articlesListHasMore: boolean
  onFetchMoreArticlesList: () => void
  articlesList: ParcelsArticlesInfiniteType
  onRefetchParcelsArticles: TRefetchFilterStaticCb
  parcelsArticlesFiltersStaticInitialValues: FilterStaticFilterQueryParamsType
}

type UseParcelsArticlesFetchProps = {
  type?: ArticlesType
  parcelId?: number
}

export const useParcelsArticlesFetch = ({
  type,
  parcelId,
}: UseParcelsArticlesFetchProps): UseParcelsArticlesFetchType => {
  const router = useRouter()
  const { initialFilters } = usePageUrlContext()

  const params = useMemo(
    () => ({ ...initialFilters, ...(parcelId ? { parcelId } : { type }) }),
    [parcelId, type, initialFilters],
  )

  const fetchParcelsArticlesProps = useInfiniteQuery<ParcelsArticlesType>({
    queryKey: parcelsKeys.articlesList(params),
    queryFn: ({ pageParam }) =>
      queryFetcher({
        name: 'fetchParcelsArticles',
        fetcher: parcelsService.fetchParcelsArticles,
        fetcherPayload: { params: { ...params, page: pageParam } },
      }),
    initialPageParam: 1,
    refetchOnMount: 'always',
    placeholderData: keepPreviousData,
    getNextPageParam,
  })

  const onRefetchParcelsArticles = useCallback<TRefetchFilterStaticCb>(
    async (values) => {
      const newParams = { ...params, ...values?.filterData }

      await router.replace({ pathname: router.pathname, query: QS.stringify(newParams) })
    },
    [router, params],
  )

  const articlesList = fetchParcelsArticlesProps.data || parcelsState.parcelsArticles

  return {
    loadingArticlesListFetch: fetchParcelsArticlesProps.isLoading,
    articlesListHasMore: fetchParcelsArticlesProps.hasNextPage,
    onFetchMoreArticlesList: fetchParcelsArticlesProps.fetchNextPage,
    parcelsArticlesFiltersStaticInitialValues: initialFilters,
    articlesList,
    hasArticlesList: getHasData(articlesList.pages[0].items),
    onRefetchParcelsArticles,
  }
}
