import { z } from 'zod'

import { locationSchema } from 'src/models/Base'
import { HttpServerRequestParams } from 'src/models/Http'

export const managePointsSchema = z.object({
  id: z.number(),
  title: z.string(),
  location: locationSchema,
  groupAlias: z.enum(['active', 'notActive', 'lead', 'closed']).nullable(),
  daysFromLastVisit: z.number(),
  customerId: z.number(),
  visitId: z.number(),
  visitOwnershipType: z.enum(['withoutVisit', 'ownVisit', 'someoneElsesVisit']).nullable(),
})

export const manageToursDaysSchema = z.object({
  id: z.number(),
  value: z.string(),
  label: z.string(),
  visitCount: z.number(),
  startDate: z.number(),
  endDate: z.number(),
})

export const managePointsSchemaList = managePointsSchema.array()
export const manageToursDaysSchemaList = manageToursDaysSchema.array()

export const managePointsSchemaItems = z.object({
  items: managePointsSchemaList,
})

export const manageToursDaysSchemaItems = z.object({
  items: manageToursDaysSchemaList,
})

export const customerMarkerSchema = z.object({
  id: z.number(),
  title: z.string(),
  location: locationSchema,
  groupAlias: z.enum(['active', 'notActive', 'lead', 'closed']).nullable(),
  daysFromLastVisit: z.number().nullable(),
})

export type ManagePointType = z.infer<typeof managePointsSchema>
export type ManagePointsItemsType = z.infer<typeof managePointsSchemaItems>
export type ManagePointsListType = z.infer<typeof managePointsSchemaList>
export type ManageToursDayType = z.infer<typeof manageToursDaysSchema>
export type ManageToursDaysItemsType = z.infer<typeof manageToursDaysSchemaItems>
export type ManageToursDaysListType = z.infer<typeof manageToursDaysSchemaList>
export type CustomerMarkerType = z.infer<typeof customerMarkerSchema>

export type ManageTourBoundsParams = {
  southWestLat?: number
  southWestLng?: number
  northEastLat?: number
  northEastLng?: number
}

export type ManageTourParams = HttpServerRequestParams
