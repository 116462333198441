import { ProgressBar as AntMobileProgressBar } from 'antd-mobile'
import { cn } from 'utils/helpers'

import { BaseSizeType } from 'src/models'

import { getProgressBarInlineStyles } from 'src/utils/colors'

import st from './Styles.module.css'

export enum ProgressBarColorType {
  default = 0,
  red,
  silver,
  gold,
  platinum,
}

type ProgressBarStatusType = 'default' | 'active'

type ProgressBarProps = {
  percent?: number
  fillColor?: string
  colorType?: ProgressBarColorType
  size?: BaseSizeType
  status?: ProgressBarStatusType
}

export const ProgressBar = ({
  percent = 0,
  size = 'md',
  colorType = ProgressBarColorType.default,
  status = 'active',
  ...rest
}: ProgressBarProps) => {
  const inlineStyles = getProgressBarInlineStyles(percent, colorType)

  return (
    <AntMobileProgressBar
      className={cn(st['progress-bar'], st[`progress-size-${size}`], st[`progress-${status}`])}
      percent={percent}
      style={inlineStyles}
      {...rest}
    />
  )
}
