import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { book } from 'src/init/book'

import { HistoryCard, HistoryItem, HistoryItemRow, WrappedNodeDefault } from 'src/components/common'
import { Typography } from 'src/components/ui'

import { ReturnType } from 'src/models'

import { useCurrencyFormat } from 'src/hooks'

import { getTranslate } from 'src/utils'

type HistoryReturnsItemType = {
  item: ReturnType
  showDate: boolean
  index: number
}

export const HistoryReturnsItem = ({ item, index, showDate }: HistoryReturnsItemType) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { currencyFormat } = useCurrencyFormat()

  const getHistoryT = getTranslate('history', t)

  const pathToDetails = {
    pathname: `${book.returns}/${item.id}`,
    query: { customerId: Number(router.query.id) },
  }

  return (
    <HistoryItem date={item?.createdAt} index={index} showDate={showDate}>
      <HistoryCard variant='return'>
        <Link href={pathToDetails}>
          <div className='mb-2'>
            <Typography className='font-semibold'>{getHistoryT('return_number', { number: item.id })}</Typography>
          </div>
          <HistoryItemRow label={getHistoryT('status')} description={WrappedNodeDefault(item?.status?.label)} />
          <HistoryItemRow label={getHistoryT('amount')} description={currencyFormat(item?.totalSum)} />
          <HistoryItemRow
            label={getHistoryT('total_qty')}
            description={
              <>
                {WrappedNodeDefault(getHistoryT('quantity_value', { value: item?.quantity }))}
                &nbsp;
                {WrappedNodeDefault(getHistoryT('articles_value', { value: item?.claimsCount }))}
              </>
            }
          />
        </Link>
      </HistoryCard>
    </HistoryItem>
  )
}
