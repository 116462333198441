export const tourState = {
  news: {
    items: [],
    meta: null,
  },
  tourPoints: {
    items: [],
    meta: null,
  },
  tourPointsStatic: {
    end: [],
    intermediate: [],
    activeEnd: null,
    activeIntermediate: null,
  },
  tourRoutes: {
    items: [],
    meta: null,
  },
}

export const tourKeys = {
  all: ['tour'],
  planForToday: () => [...tourKeys.all, 'plan-for-today'],
  news: () => [...tourKeys.all, 'news'],
  tourPoints: () => [...tourKeys.all, 'tour-points'],
  tourPointsStatic: () => [...tourKeys.all, 'tour-points-static'],
  tourRoutes: () => [...tourKeys.all, 'tour-routes'],
  tourRoute: (params?: any) => [...tourKeys.tourRoutes(), 'tour-route', params],
}
