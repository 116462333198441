import {
  __,
  both,
  complement,
  compose,
  curry,
  either,
  equals,
  gt,
  gte,
  identity,
  is,
  isEmpty,
  isNil,
  length,
  lt,
  mapObjIndexed,
  not,
  pipe,
  pluck,
  propOr,
  reject,
  replace,
  toString,
  when,
} from 'ramda'

export const getIds = pluck('id')
export const getId = propOr(undefined, 'id')
export const getStringId = compose(toString, getId)

export const eqZero = equals(0)
export const notEqZero = complement(eqZero)
export const gtOne = gt(__, 1)
export const gtOrEqualOne = gte(__, 1)
export const ltTwo = lt(__, 2)

export const ternary = curry((bool, truth, faulty) => (bool ? truth : faulty))
export const orNull = ternary(__, __, null)

export const getConvertedObjToJson = mapObjIndexed(
  when(both(is(Object), complement(is(Array))), compose(JSON.stringify, identity)),
)

export const isNilOrEmpty = either(isNil, isEmpty)
export const isNotNilOrNotEmpty = complement(isNilOrEmpty)
export const getNotNil = reject(isNil)

export const getHasData = pipe(length, equals(0), not)
export const getHasNoData = complement(getHasData)

export const removeFirstSlash = pipe(replace(/^\/+/, ''))
