import { useTranslation } from 'next-i18next'

import { TotalItem, TotalItemLabel, TotalItemValue } from 'src/components/common'

import { ParcelType, ParcelsTotalType } from 'src/models'

import { getTranslate } from 'src/utils'

type ViewParcelsTotalProps = {
  data: ParcelType | ParcelsTotalType | null
  loadingFetch: boolean
}

export const ViewParcelsTotal = ({ data, loadingFetch }: ViewParcelsTotalProps) => {
  const { t } = useTranslation()

  const getParcelsT = getTranslate('parcels:articles_footer', t)

  return (
    <div className='grid grid-cols-2 gap-2 bg-gray-1'>
      <TotalItem label={<TotalItemLabel>{getParcelsT('total_weight')}</TotalItemLabel>} variant='parcel-total'>
        <TotalItemValue loadingFetch={loadingFetch} isNumber>
          {data?.weight}
        </TotalItemValue>
      </TotalItem>
      <TotalItem label={<TotalItemLabel>{getParcelsT('total_sum')}</TotalItemLabel>} variant='parcel-total'>
        <TotalItemValue loadingFetch={loadingFetch}>{data?.amount}</TotalItemValue>
      </TotalItem>
    </div>
  )
}
