import { BaseDataListType, StaticDataItemType } from 'src/models'

import { ordersKeys, ordersService } from 'src/bus/orders'

import { useQueryAsync } from 'src/hooks'

type UseOrderOptionsFetchType = {
  loadingOrderOptionsFetch: boolean
  orderOptions: StaticDataItemType[]
}

export const useOrderOptionsFetch = (customerId?: number): UseOrderOptionsFetchType => {
  const fetchOrderOptionsProps = useQueryAsync<BaseDataListType>({
    key: ordersKeys.orderOptions(customerId),
    name: 'fetchOrderOptions',
    fetcher: ordersService.fetchOrderOptions,
    fetcherPayload: { customerId },
    options: { enabled: !!customerId },
  })

  return {
    loadingOrderOptionsFetch: fetchOrderOptionsProps.isLoading,
    orderOptions: fetchOrderOptionsProps.data?.items || [],
  }
}
