import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'

import { ParcelsQRCodeType } from 'src/models'

import { parcelsKeys, parcelsService } from 'src/bus/parcels'

import { queryFetcher } from 'src/utils'

type QRCodeFetchCbType = (id?: number) => Promise<ParcelsQRCodeType | null>

type UseParcelsQRCodeFetchType = {
  onParcelsQRCodeFetch: QRCodeFetchCbType
}

export const useParcelsQRCodeFetch = (): UseParcelsQRCodeFetchType => {
  const queryClient = useQueryClient()

  const onParcelsQRCodeFetch = useCallback<QRCodeFetchCbType>(
    async (id) => {
      await queryClient.prefetchQuery({
        queryKey: parcelsKeys.qrCode(id),
        queryFn: () =>
          queryFetcher({
            name: 'fetchParcelsQRCode',
            fetcher: parcelsService.fetchQRCode,
            fetcherPayload: { params: { id } },
          }),
      })

      const data: ParcelsQRCodeType | undefined = queryClient.getQueryData(parcelsKeys.qrCode(id))

      return data || null
    },
    [queryClient],
  )

  return {
    onParcelsQRCodeFetch,
  }
}
