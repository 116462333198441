import { useMutation } from '@tanstack/react-query'
import { UseMutationResult } from '@tanstack/react-query'

import { HttpErrorType, HttpRequestParams } from 'src/models'

import { otrsService } from 'src/bus/otrs'

import { queryFetcher } from 'src/utils'

type ImagesUploadMutationType = {
  onImagesUploadMutation: UseMutationResult<string[], HttpErrorType, HttpRequestParams>
}

export const useOtrsImagesUploadMutation = (): ImagesUploadMutationType => {
  const onImagesUploadMutation = useMutation<string[], HttpErrorType, HttpRequestParams>({
    mutationFn: (params) => {
      return queryFetcher({
        name: 'uploadImages',
        fetcher: otrsService.uploadImages,
        fetcherPayload: params,
      })
    },
  })

  return { onImagesUploadMutation }
}
