import { cond, always, equals, T } from 'ramda'

import { CellSwipe } from 'src/components/common'

import { ClaimsActionsType, ClaimType, SwipeActionType } from 'src/models'

import { getActionsWithoutHidden } from 'src/utils'

type GetClaimItemActionsProps = {
  onSelectCell: (type: ClaimsActionsType) => () => void
  item: ClaimType
}

export const getClaimItemActions = ({ onSelectCell, item }: GetClaimItemActionsProps): SwipeActionType[] => {
  const DeleteButton = {
    key: 'delete',
    text: CellSwipe({
      text: '',
      icon: 'Cross',
      type: 'danger',
    }),
    onClick: onSelectCell('delete'),
  }

  const actions = cond([
    [always(equals(item.status, 'refused')), always([DeleteButton])],
    [T, always([DeleteButton])],
  ])()

  return getActionsWithoutHidden(actions)
}
