import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { FormSubmitParams, HttpErrorType, FormCreateCustomerType } from 'src/models'

import { useCustomersQuery } from 'src/bus/customers'

import { useToast } from 'src/hooks'

import { fillServerErrorsToForm } from 'src/utils'

type UseCustomersCreateType = {
  loadingCustomersCreate: boolean
  onCreateCustomer: (data: FormSubmitParams<FormCreateCustomerType>) => void
}

export const useCustomersCreate = (): UseCustomersCreateType => {
  const { t } = useTranslation()
  const router = useRouter()
  const showToast = useToast()
  const { onCreateCustomer } = useCustomersQuery()

  const onCreateCustomerCb = useCallback(
    (data: FormSubmitParams<FormCreateCustomerType>) => {
      onCreateCustomer.mutate(
        { data: data.values },
        {
          onSuccess: () => {
            router.back()
            showToast.success({ title: t('notifications:customer_create') })
          },
          onError: (error) => {
            const errs = (error as HttpErrorType).errors
            errs && fillServerErrorsToForm(errs, data.acts.setError)
          },
        },
      )
    },
    [onCreateCustomer, showToast, t, router],
  )

  return {
    loadingCustomersCreate: onCreateCustomer.isPending,
    onCreateCustomer: onCreateCustomerCb,
  }
}
