import type { TFunction } from 'i18next'

import { CellSwipe } from 'src/components/common'

import type { SwipeActionType } from 'src/models'

import { getActionsWithoutHidden } from 'src/utils'

type GetOrderItemActionsProps = {
  onSelectCell: () => () => void
  t: TFunction
}

export const getOrderItemActions = ({ onSelectCell, t }: GetOrderItemActionsProps): SwipeActionType[] => {
  const actions = [
    {
      key: 'copy',
      text: CellSwipe({
        text: t('orders:copy_hash'),
        icon: 'Copy',
        type: 'info',
      }),
      onClick: onSelectCell(),
    },
  ]

  return getActionsWithoutHidden(actions)
}
