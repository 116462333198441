import { pipe, view, find, lensProp, lensIndex } from 'ramda'
import { useMemo } from 'react'

import { useReturnsListFetch } from 'src/bus/returns'

import { getId, getInProgressStatus, isNotNilOrNotEmpty } from 'src/utils'

type UseReturnsInProgressType = {
  hasActiveReturn: boolean
  loadingReturnsListFetch: boolean
  returnId: number | undefined
}

export const useReturnsInProgress = (): UseReturnsInProgressType => {
  const { returnsList, loadingReturnsListFetch } = useReturnsListFetch()

  const { hasActiveReturn, returnId } = useMemo(() => {
    const returnsItemsLens = pipe(lensProp<any>('items'), lensIndex(0))
    const returnItems = view(returnsItemsLens, returnsList.pages)
    const returnId: number | undefined = getId(find(getInProgressStatus, returnItems))

    return {
      returnId,
      hasActiveReturn: isNotNilOrNotEmpty(returnId),
    }
  }, [returnsList.pages])

  return {
    hasActiveReturn,
    loadingReturnsListFetch,
    returnId,
  }
}
