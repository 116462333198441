import { VisitAvailableDateType } from 'src/models'

import { visitsKeys, visitsService } from 'src/bus/visits'

import { useQueryAsync } from 'src/hooks'

type UseVisitsAvailableDateFetchType = {
  loadingVisitsAvailableDateFetch: boolean
  visitsAvailableDate: VisitAvailableDateType | null
}

type VisitsAvailableDateFetchProps = {
  visitId?: number
  customerId?: number
}

export const useVisitsAvailableDateFetch = ({
  visitId,
  customerId,
}: VisitsAvailableDateFetchProps): UseVisitsAvailableDateFetchType => {
  const params = { ...(visitId && { id: visitId }), ...(customerId && { customerId }) }

  const fetchVisitsAvailableDateProps = useQueryAsync<VisitAvailableDateType>({
    key: visitId ? visitsKeys.availableReplanDate(visitId) : visitsKeys.availableCreateDate(customerId),
    name: 'fetchVisitsAvailableDate',
    fetcher: visitsService.fetchAvailableDate,
    fetcherPayload: params,
    options: { enabled: !!visitId || !!customerId },
  })

  return {
    loadingVisitsAvailableDateFetch: fetchVisitsAvailableDateProps.isLoading,
    visitsAvailableDate: fetchVisitsAvailableDateProps.data || null,
  }
}
