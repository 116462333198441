import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { HttpErrorType } from 'src/models'

import { couponsKeys, useCouponsCreateMutation } from 'src/bus/coupons'

import { useToast } from 'src/hooks'

type UseCouponCreateType = {
  loadingCouponCreate: boolean
  onCouponCreate: (type?: string) => void
}

export const useCouponsCreate = (): UseCouponCreateType => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const router = useRouter()
  const showToast = useToast()
  const { onCreateCouponMutation } = useCouponsCreateMutation()

  const visitId = Number(router.query.id)

  const onCouponCreate = useCallback(
    async (type?: string) => {
      try {
        await onCreateCouponMutation.mutateAsync({ data: { visitId, ...(type && { type }) } })
        await queryClient.refetchQueries({ queryKey: couponsKeys.coupons(visitId) })
        showToast.success({ title: t('notifications:coupon_create') })
      } catch (error) {
        const errs = (error as HttpErrorType).errors
        const [[title, body]] = Object.entries(errs || {})
        showToast.error({ title, body: body.desc })
      }
    },
    [visitId, onCreateCouponMutation, showToast, t, queryClient],
  )

  return {
    loadingCouponCreate: onCreateCouponMutation.isPending,
    onCouponCreate,
  }
}
