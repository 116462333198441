import { useCallback, useState } from 'react'

import { CreateCustomerForm } from 'src/components/common'

import { useCustomersCreate, useCustomersStaticDataFetch } from 'src/bus/customers'
import { useStaticDataSectorsFetch, useStaticDataZonesFetch } from 'src/bus/staticData'

export const CustomersCreate = () => {
  const { onCreateCustomer, loadingCustomersCreate } = useCustomersCreate()
  const { businessType, quantityEmployees, loadingCustomerStaticDataFetch } = useCustomersStaticDataFetch()

  const [selectedZoneId, setSelectedZoneId] = useState<string>()

  const onSelectZoneId = useCallback((id: string) => setSelectedZoneId(id), [])

  const { staticDataZones, loadingStaticDataZonesFetch } = useStaticDataZonesFetch()
  const { staticDataSectors, loadingStaticDataSectorsFetch } = useStaticDataSectorsFetch(selectedZoneId)

  return (
    <div className='flex h-full flex-col py-2' data-test-id='customers-create'>
      <CreateCustomerForm
        onSubmit={onCreateCustomer}
        businessType={businessType}
        onSelectZoneId={onSelectZoneId}
        zonesOptions={staticDataZones.zones}
        sectorsOptions={staticDataSectors.sectors}
        quantityEmployees={quantityEmployees}
        loadingFetchStaticData={loadingCustomerStaticDataFetch}
        loadingCreate={loadingCustomersCreate}
        loadingZonesFetch={loadingStaticDataZonesFetch}
        loadingSectorsFetch={loadingStaticDataSectorsFetch}
      />
    </div>
  )
}
