import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { HttpErrorType } from 'src/models'

import { customersKeys, useCustomerQuery } from 'src/bus/customers'

import { useToast } from 'src/hooks'

import { useDialog } from 'src/contexts'

import { GET_ENUMS } from 'src/utils'

type CustomersCloseCb = (isClosed: boolean) => void

type CustomersCloseType = {
  loadingCustomersClose: boolean
  onCloseCustomer: CustomersCloseCb
}

export const useCustomersClose = (): CustomersCloseType => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const router = useRouter()
  const { setOnPageDialog } = useDialog()
  const showToast = useToast()

  const customerId = Number(router.query.id)

  const { onUpdateCustomer } = useCustomerQuery(customerId)

  const onCloseCustomerCb = useCallback<CustomersCloseCb>(
    (isClosed) => {
      onUpdateCustomer.mutate(
        {
          params: { ...(customerId && { id: customerId }) },
          data: { isClosed },
          updateMethod: 'partial',
        },
        {
          onSuccess: () => {
            setOnPageDialog(GET_ENUMS.alertDialog[isClosed ? 'closeCustomer' : 'openCustomer'], false)
            showToast.success({
              title: t(`notifications:${isClosed ? 'customer_close' : 'customer_open'}`),
            })
          },
          onError: (error) => {
            const errs = (error as HttpErrorType).errors
            if (errs) {
              const [[title, body]] = Object.entries(errs)
              showToast.error({ title, body: body.desc })
            }
          },
          onSettled: async () => {
            await queryClient.refetchQueries({ queryKey: customersKeys.lists() })
          },
        },
      )
    },
    [onUpdateCustomer, showToast, t, customerId, setOnPageDialog, queryClient],
  )

  return {
    loadingCustomersClose: onUpdateCustomer.isPending,
    onCloseCustomer: onCloseCustomerCb,
  }
}
