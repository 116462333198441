import { ReactNode } from 'react'
import { toast as reactToast } from 'react-toastify'
import { ToastOptions, TypeOptions } from 'react-toastify/dist/types'

import { Icon, Typography } from 'src/components/ui'

import { orNull, TOAST_DURATION } from 'src/utils'

import st from './Styles.module.css'

export interface ToastEnhancedContent {
  title?: string | ReactNode
  body?: string | ReactNode
}

const icons: Record<TypeOptions, string> = {
  success: 'ToastSuccess',
  error: 'ToastError',
  warning: 'ToastWarning',
  info: 'ToastInfo',
  default: '',
}

type ToastMessageProps = ToastEnhancedContent & {
  type: TypeOptions
}

const ToastMessage = ({ title, body, type }: ToastMessageProps) => {
  return (
    <>
      <div>{orNull(type, <Icon name={icons[type]} />)}</div>
      <div className='flex flex-col'>
        {orNull(
          title,
          <Typography color='white' className='font-semibold'>
            {title}
          </Typography>,
        )}
        {orNull(
          body,
          <Typography variant='secondary' color='white'>
            {body}
          </Typography>,
        )}
      </div>
    </>
  )
}

export const toastEnhanced = ({ title = '', body = '' }: ToastEnhancedContent = {}, options: ToastOptions) => {
  if (!title && !body) {
    return
  }

  const type = options?.type as TypeOptions

  // ToastId is needed to prevent toast duplicates.
  let toastId: string | number = ''

  if (options?.toastId) {
    toastId = options.toastId
  } else {
    if (title && typeof title === 'string') {
      toastId += title
    }

    if (body && typeof body === 'string') {
      toastId += body
    }
  }

  if (toastId && reactToast.isActive(toastId)) {
    console.log('🤔 toastId should update => ', toastId)
    reactToast.update(toastId, {
      render: () => {
        return <ToastMessage title={title} body={body} type={type} />
      },
    })
  } else {
    console.log('🤔 toastId should be new => ', toastId)
    ;(reactToast as any)[type]?.(<ToastMessage title={title} body={body} type={type} />, {
      toastId,
      closeOnClick: true,
      autoClose: TOAST_DURATION,
      icon: false,
      hideProgressBar: true,
      className: st['toaster'],
      draggablePercent: 30,
      ...options,
    })
  }
}
