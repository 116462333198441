import { useIsMutating, useMutation, QueryKey, UseMutationOptions } from '@tanstack/react-query'

import { BaseMutationPayloadType, BaseUnknownRecordType, FetchDataType, HttpErrorType } from 'src/models'

import { queryFetcher } from 'src/utils'

type UseMutationAsyncOptionsType<TQueryFnData, TVariables, TContext, TQueryKey, TFetcherPayload> = {
  key: TQueryKey
  name: string
  fetcher: FetchDataType<TQueryFnData>
  fetcherPayload?: TFetcherPayload
  fetcherType?: 'query' | 'mutation'
  options?: Omit<UseMutationOptions<TQueryFnData, HttpErrorType, TVariables, TContext>, 'mutationKey' | 'mutationFn'>
}

type MutationDataType = { id?: number } | void

/* core */
export const useMutationAsync = <
  TQueryFnData = unknown,
  TVariables = void,
  TContext = unknown,
  TQueryKey extends QueryKey = QueryKey,
  TFetcherPayload = BaseUnknownRecordType,
>({
  key,
  name,
  fetcher,
  fetcherPayload: params,
  fetcherType = 'mutation',
  options,
}: UseMutationAsyncOptionsType<TQueryFnData, TVariables & MutationDataType, TContext, TQueryKey, TFetcherPayload>) => {
  const isMutating = !!useIsMutating({ mutationKey: key })
  const mutationProps = useMutation({
    mutationKey: key,
    mutationFn: (data) => {
      const fetcherPayload = {
        ...(params && { params }),
        ...(data && { data }),
        ...(data?.id && { id: data.id }),
      }

      return queryFetcher<TQueryFnData, BaseMutationPayloadType<TFetcherPayload, TVariables>>({
        name,
        fetcher,
        fetcherType,
        fetcherPayload,
      })
    },
    ...options,
  })

  return { ...mutationProps, isLoading: isMutating }
}
