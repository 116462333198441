import { ArticlesType, ParcelsCheckType } from 'src/models'

import { parcelsKeys, parcelsService } from 'src/bus/parcels'

import { useQueryAsync } from 'src/hooks'

type UseParcelsCheckGenerateType = {
  loadingParcelsGenerateCheck: boolean
  checkGenerateParcels: ParcelsCheckType | null
}
export const useParcelsCheckGenerate = (type: ArticlesType): UseParcelsCheckGenerateType => {
  const params = { type }

  const checkGenerateParcelsProps = useQueryAsync<ParcelsCheckType>({
    key: parcelsKeys.checkGenerate(type),
    name: 'checkGenerateParcels',
    fetcher: parcelsService.checkGenerateParcels,
    fetcherPayload: params,
    options: { enabled: !!type, refetchOnMount: 'always' },
  })

  return {
    loadingParcelsGenerateCheck: checkGenerateParcelsProps.isLoading,
    checkGenerateParcels: checkGenerateParcelsProps.data || null,
  }
}
