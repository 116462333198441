import { useTranslation } from 'next-i18next'

import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  Typography,
} from 'src/components/ui'

type AlertDialogLeavePageProps = {
  onConfirm?: () => void
  loadingFetch?: boolean
}

export const AlertDialogLeavePage = ({ loadingFetch, onConfirm }: AlertDialogLeavePageProps) => {
  const { t } = useTranslation()

  return (
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>
          <Typography variant='title' color='gray-10'>
            {t('parcels:modal_leave_page.title')}
          </Typography>
        </AlertDialogTitle>
        <AlertDialogDescription>
          <Typography variant='secondary' color='gray-7'>
            {t('parcels:modal_leave_page.description')}
          </Typography>
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <Button onClick={onConfirm} block loading={loadingFetch} variant='outline' asChild>
          <AlertDialogAction>{t('buttons:yes')}</AlertDialogAction>
        </Button>
        <AlertDialogCancel className='w-full'>{t('buttons:no')}</AlertDialogCancel>
      </AlertDialogFooter>
    </AlertDialogContent>
  )
}
