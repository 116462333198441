import { useCallback } from 'react'

import { FormCreateFarAwayReportType, FormSubmitParams, HttpErrorType } from 'src/models'

import { useVisitsCreateFarAwayReportMutation } from 'src/bus/visits'

import { fillServerErrorsToForm } from 'src/utils'

type CreateVisitFarAwayReport = (data: FormSubmitParams<FormCreateFarAwayReportType>) => Promise<void>

type VisitsFarAwayReportCreateType = {
  loadingVisitsFarAwayReportCreate: boolean
  onCreateVisitsFarAwayReport: CreateVisitFarAwayReport
}

export const useVisitsFarAwayReportCreate = (visitId: number): VisitsFarAwayReportCreateType => {
  const { onCreateVisitFarAwayReportMutation } = useVisitsCreateFarAwayReportMutation()

  const onCreateVisitsFarAwayReport = useCallback<CreateVisitFarAwayReport>(
    async (data) => {
      const values = data.values

      onCreateVisitFarAwayReportMutation.mutate(
        { params: { visitId }, data: values },
        {
          onError: (error) => {
            const errs = (error as HttpErrorType).errors
            errs && fillServerErrorsToForm(errs, data.acts.setError)
          },
        },
      )
    },
    [visitId, onCreateVisitFarAwayReportMutation],
  )

  return {
    onCreateVisitsFarAwayReport,
    loadingVisitsFarAwayReportCreate: onCreateVisitFarAwayReportMutation.isPending,
  }
}
