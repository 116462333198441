import { CustomerVatVerifyType } from 'src/models'

import { useCustomersDetailsFetch, useCustomerVatQuery } from 'src/bus/customers'

type UseCustomersVatFetchType = {
  loadingCustomersVatInfo: boolean
  customersVatInfo: CustomerVatVerifyType | null
}

export const useCustomersVatFetch = (): UseCustomersVatFetchType => {
  const { customerId, loadingCustomersDetailsFetch } = useCustomersDetailsFetch()
  const { vatQuery } = useCustomerVatQuery(customerId)

  return {
    loadingCustomersVatInfo: vatQuery.isLoading || loadingCustomersDetailsFetch,
    customersVatInfo: vatQuery.data || null,
  }
}
