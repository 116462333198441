import { useTranslation } from 'next-i18next'

import { Dialog, DialogViewsSwitcher } from 'src/components/common'
import { Typography, Skeleton, Icon, Button } from 'src/components/ui'

import { useDialog } from 'src/contexts'

import { GET_ENUMS, orNull, ternary } from 'src/utils'

type CellTextProps = {
  value?: string
  loadingFetch?: boolean
}

export const CellTextEdit = ({ value = '', loadingFetch = false }: CellTextProps) => {
  const { t } = useTranslation()
  const { onPageDialogs, setOnPageDialog } = useDialog()

  return (
    <>
      <Dialog
        open={onPageDialogs[GET_ENUMS.dialog.editNote]?.visible}
        onOpenChange={(open) => setOnPageDialog(GET_ENUMS.dialog.editNote, open)}
      >
        <DialogViewsSwitcher name={GET_ENUMS.dialog.editNote} />
      </Dialog>
      {ternary(
        loadingFetch,
        <Skeleton className='h-[22px]' />,
        <div className='grid gap-2'>
          {orNull(
            value,
            <Typography variant='secondary' className='inline align-text-bottom'>
              {value}
            </Typography>,
          )}
          <div className='flex'>
            <Button
              variant='ghost'
              size='sm'
              className='p-0'
              onClick={() => setOnPageDialog(GET_ENUMS.dialog.editNote, true)}
              data-test-id='edit-note-btn'
            >
              <div className='mr-1 flex'>
                <Icon name='EditProfile' color='blue-7' />
              </div>
              <Typography variant='secondary' color='blue-7'>
                {t(value ? 'customers:edit_note' : 'customers:create_note')}
              </Typography>
            </Button>
          </div>
        </div>,
      )}
    </>
  )
}
