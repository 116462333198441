import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  Typography,
} from 'src/components/ui'

import { useParcelsPack } from 'src/bus/parcels'

export const AlertDialogPackParcel = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { onPackParcels, loadingParcelsPack } = useParcelsPack()

  const onConfirm = async () => {
    await onPackParcels(Number(router.query.id), 'pack')
    router.back()
  }

  return (
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>
          <Typography variant='title' color='gray-10'>
            {t('parcels:modal_pack.title')}
          </Typography>
        </AlertDialogTitle>
        <AlertDialogDescription>
          <Typography variant='secondary' color='gray-7'>
            {t('parcels:modal_pack.description')}
          </Typography>
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel variant='outline' className='w-full'>
          {t('buttons:no')}
        </AlertDialogCancel>
        <Button onClick={onConfirm} block loading={loadingParcelsPack} asChild>
          <AlertDialogAction>{t('buttons:yes')}</AlertDialogAction>
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  )
}
