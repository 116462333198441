import { DirectionsRenderer, Marker } from '@react-google-maps/api'
import { useCallback, useMemo, useState } from 'react'

import { Dialog, DialogViewsSwitcher } from 'src/components/common'
import { GoogleMap } from 'src/components/ui'

import { TourRoute, TourRoutesType } from 'src/models'

import { useToast } from 'src/hooks'

import { useDialog, useGoogle } from 'src/contexts'

import { GET_ENUMS, getHasData, MAX_WAYPOINTS_CHUNK } from 'src/utils'

type PlanMapProps = {
  places?: TourRoutesType['items']
}

export const PlanMap = ({ places = [] }: PlanMapProps) => {
  const { isLoaded, loadError, onLoad, onUnmount } = useGoogle()
  const showToast = useToast()
  const { onPageDialogs, setOnPageDialog } = useDialog()

  const [directions, setDirections] = useState<google.maps.DirectionsResult[] | null>(null)
  const [markers, setMarkers] = useState<TourRoute[]>([])

  const [activeMarker, setActiveMarker] = useState<TourRoute | null>(null)

  const waypoints = useMemo(() => {
    return places.map((route) => ({ lat: route?.location?.lat || 0, lng: route?.location?.lng || 0 }))
  }, [places])

  const onLoadHandler = useCallback(
    async (map: google.maps.Map) => {
      await onLoad(map)

      const directionsDisplay = new google.maps.DirectionsRenderer()
      const directionsService = new google.maps.DirectionsService()

      const chunks = []

      for (let i = 0; i < (waypoints.length || 0); i += MAX_WAYPOINTS_CHUNK) {
        chunks.push(waypoints.slice(i, i + MAX_WAYPOINTS_CHUNK))
      }

      const requests = chunks.map((chunk) => {
        return new Promise<google.maps.DirectionsResult>((resolve, reject) => {
          const origin = chunk[0]
          const destination = chunk[chunk.length - 1]
          const intermediateWaypoints = chunk.slice(1, -1).map((location) => ({
            location: new google.maps.LatLng(location.lat, location.lng),
            stopover: true,
          }))

          directionsService.route(
            {
              origin: new google.maps.LatLng(origin.lat, origin.lng),
              destination: new google.maps.LatLng(destination.lat, destination.lng),
              waypoints: intermediateWaypoints,
              travelMode: google.maps.TravelMode.DRIVING,
            },
            (response, status) => {
              if (status === google.maps.DirectionsStatus.OK) {
                resolve(response as google.maps.DirectionsResult)
                directionsDisplay.setDirections(response)
              } else {
                reject(status)
                setDirections(null)
                showToast.error({ title: status })
              }
            },
          )
        })
      })

      setMarkers(places || [])

      Promise.all(requests)
        .then((response) => {
          setDirections(response)

          const bounds = new google.maps.LatLngBounds()
          waypoints.forEach((point) => {
            bounds.extend(new google.maps.LatLng(point.lat, point.lng))
          })

          map.fitBounds(bounds)
        })
        .catch((error) => {
          console.error('Error fetching directions', error)
          setDirections(null)
        })
    },
    [onLoad, places, waypoints, showToast],
  )

  const onOpenPopupDetails = (marker: TourRoute) => {
    setActiveMarker(marker)
    setOnPageDialog(GET_ENUMS.dialog.customerCard, true)
  }

  const onClosePopupDetails = () => {
    setActiveMarker(null)
    setOnPageDialog(GET_ENUMS.dialog.customerCard, false)
  }

  const onOpenPopupVisit = (key: string) => {
    setOnPageDialog(GET_ENUMS.dialog.customerCard, false)
    setOnPageDialog(key, true)
  }

  return (
    <GoogleMap
      isLoaded={isLoaded}
      loadError={loadError}
      onLoad={onLoadHandler}
      onUnmount={onUnmount}
      onClick={() => onClosePopupDetails()}
    >
      <>
        <Dialog
          open={onPageDialogs[GET_ENUMS.dialog.customerCard]?.visible}
          onOpenChange={(open) => setOnPageDialog(GET_ENUMS.dialog.customerCard, open)}
        >
          <DialogViewsSwitcher
            name={GET_ENUMS.dialog.customerCard}
            params={{
              id: activeMarker?.customerId,
              visitId: activeMarker?.visitId,
              onOpenOtherPopup: onOpenPopupVisit,
            }}
          />
        </Dialog>
        {[GET_ENUMS.dialog.cancelVisit, GET_ENUMS.dialog.replanVisit].map((key) => {
          return (
            <Dialog key={key} open={onPageDialogs[key]?.visible} onOpenChange={(open) => setOnPageDialog(key, open)}>
              <DialogViewsSwitcher name={key} params={{ id: activeMarker?.visitId, title: activeMarker?.title }} />
            </Dialog>
          )
        })}
        {getHasData(markers) &&
          markers.map((marker, index) => {
            const isStart = index === 0
            const isEnd = index === markers.length - 1

            return (
              <Marker
                key={marker.id}
                position={marker.location}
                icon={
                  isStart
                    ? {
                        url: '/assets/icons/start-location.svg',
                        size: new google.maps.Size(25, 40),
                      }
                    : isEnd
                      ? {
                          url: '/assets/icons/finish-location.svg',
                          size: new google.maps.Size(26, 40),
                        }
                      : undefined
                }
                label={
                  !isStart && !isEnd
                    ? {
                        text: String(index),
                        color: 'white',
                        fontFamily: 'Inter, sans-serif',
                        fontSize: '18px',
                        fontWeight: 'bold',
                      }
                    : undefined
                }
                onClick={() => !isStart && !isEnd && onOpenPopupDetails(marker)}
              />
            )
          })}
        {directions &&
          directions.map((direction, index) => (
            <DirectionsRenderer key={index} directions={direction} options={{ suppressMarkers: !!markers.length }} />
          ))}
      </>
    </GoogleMap>
  )
}
