import { Col, Row } from 'antd'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'

import { AlertDialog, AlertDialogViewsSwitcher, ClaimItem, SkeletonList } from 'src/components/common'
import { Empty, InfiniteScroll, Typography } from 'src/components/ui'

import { useClaimsDelete, useClaimsListFetch } from 'src/bus/claims'
import { useCustomersDetailsFetch } from 'src/bus/customers'

import { useDialog } from 'src/contexts'

import { GET_ENUMS, rowGutters } from 'src/utils'

const claimsHeader = [
  { title: 'returns:returns_details.article', span: 7 },
  { title: 'returns:returns_details.return_type', span: 5 },
  { title: 'returns:returns_details.order_number', span: 4 },
  { title: 'returns:returns_details.qty', span: 3 },
  { title: 'returns:returns_details.amount', span: 5 },
]

export const Claims = () => {
  const { t } = useTranslation()
  const { onPageDialogs, setOnPageDialog } = useDialog()
  const { claimsList, loadingClaimsListFetch } = useClaimsListFetch()
  const { onDeleteClaim, loadingClaimsDelete } = useClaimsDelete()
  const { customersDetails } = useCustomersDetailsFetch()

  const [deleteId, setDeleteId] = useState<number>()

  const renderItems = () => {
    return claimsList.items.map((item) => (
      <ClaimItem key={item.id} item={item} onDelete={setDeleteId} customerId={customersDetails?.id} />
    ))
  }

  return (
    <>
      <AlertDialog
        open={onPageDialogs[GET_ENUMS.alertDialog.confirm]?.visible}
        onOpenChange={(open) => setOnPageDialog(GET_ENUMS.alertDialog.confirm, open)}
      >
        <AlertDialogViewsSwitcher
          name={GET_ENUMS.alertDialog.confirm}
          params={{
            title: t('are_you_sure'),
            buttonVariant: 'cta',
            loadingFetch: loadingClaimsDelete,
            onConfirm: async () => deleteId && (await onDeleteClaim(deleteId)),
          }}
        />
      </AlertDialog>
      <div className='bg-gray-1 px-2 py-3'>
        <Row gutter={rowGutters}>
          {claimsHeader.map((item) => {
            return (
              <Col key={item.title} span={item.span}>
                <Typography variant='caption' className='font-semibold'>
                  {t(item.title)}
                </Typography>
              </Col>
            )
          })}
        </Row>
      </div>
      <InfiniteScroll
        hasMore={false}
        loadingFetch={loadingClaimsListFetch}
        onFetchMore={() => undefined}
        dataLength={claimsList.items.length}
        loader={<SkeletonList type='claims' />}
        scrollableTarget='claims'
        renderItems={renderItems}
        empty={<Empty title='common:empty.no_activity' description='common:empty.claims_list' />}
      />
    </>
  )
}
