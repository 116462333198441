import { BaseDataListType } from 'src/models'

import { visitsHistoryKeys, visitsHistoryState, visitsHistoryService } from 'src/bus/visitsHistory'

import { useQueryAsync } from 'src/hooks'

type UseVisitsHistoryCustomersFetchType = {
  visitsHistoryCustomers: BaseDataListType
  loadingVisitsHistoryCustomersFetch: boolean
}

export const useVisitsHistoryCustomersFetch = (): UseVisitsHistoryCustomersFetchType => {
  const fetchVisitsHistoryCustomersProps = useQueryAsync<BaseDataListType>({
    key: visitsHistoryKeys.historyCustomers(),
    name: 'fetchVisitsHistoryCustomers',
    fetcher: visitsHistoryService.fetchVisitsHistoryCustomers,
  })

  return {
    visitsHistoryCustomers: fetchVisitsHistoryCustomersProps.data || visitsHistoryState.staticData.sectors,
    loadingVisitsHistoryCustomersFetch: fetchVisitsHistoryCustomersProps.isLoading,
  }
}
