import { Logo, ProfileUser, ProfileMenu } from 'src/components/common'

export const IntroProfile = () => {
  return (
    <div className='flex h-full flex-col overflow-hidden' data-test-id='profile'>
      <div className='my-4'>
        <Logo />
      </div>
      <div className='mb-5'>
        <ProfileUser />
      </div>
      <ProfileMenu />
    </div>
  )
}
