import { keys, mergeAll, omit } from 'ramda'
import { z } from 'zod'

import { FilterStaticDataType, FilterStaticType } from 'src/models'

import { OPTION_OBJECT } from 'src/utils'

const getKeys = (filterItems: FilterStaticDataType) => keys(omit(['search', 'date'], filterItems))

const getShape = (filterItems: FilterStaticDataType) => {
  return mergeAll(
    getKeys(filterItems).map((key) => {
      return { [key]: [] }
    }),
  )
}

const getSchema = (filterItems: FilterStaticDataType) => {
  return mergeAll(
    getKeys(filterItems).map((key) => {
      return {
        [key]: z.array(z.object(OPTION_OBJECT)),
      }
    }),
  )
}

export const staticFiltersForm = {
  shape: (filterItems: FilterStaticDataType): FilterStaticType => {
    return {
      ...getShape(filterItems),
      search: '',
      startDate: null,
      endDate: null,
    }
  },
  schema: (filterItems: FilterStaticDataType) => {
    return z.object({
      ...getSchema(filterItems),
      search: z.string().trim(),
      startDate: z.custom().nullable().default(null),
      endDate: z.custom().nullable().default(null),
    })
  },
}
