import { useRouter } from 'next/router'

import { ReturnTotalType } from 'src/models'

import { useAuthProfile } from 'src/bus/auth'
import { useCustomersDetailsFetch } from 'src/bus/customers'
import { returnsState, returnsKeys, returnsService } from 'src/bus/returns'

import { useQueryAsync } from 'src/hooks'

type UseReturnsTotalFetchType = {
  loadingReturnsTotalFetch: boolean
  returnsTotal: ReturnTotalType | null
}

export const useReturnsTotalFetch = (): UseReturnsTotalFetchType => {
  const router = useRouter()
  const { isRoleServiceAgent } = useAuthProfile()
  const { customerId, loadingCustomersDetailsFetch } = useCustomersDetailsFetch()
  const id = Number(router.query.id)
  const claimStatus = router.query.claimStatus
  const params = {
    id,
    ...(customerId && { customerId }),
    ...(claimStatus && { filter: { claimStatus } }),
  }

  const fetchReturnsTotalProps = useQueryAsync<ReturnTotalType>({
    key: returnsKeys.total(params),
    name: 'fetchReturnsTotal',
    fetcher: returnsService.fetchReturnTotal,
    fetcherPayload: params,
    options: {
      enabled: !!id && !!customerId && isRoleServiceAgent,
      refetchOnMount: 'always',
    },
  })

  return {
    loadingReturnsTotalFetch: fetchReturnsTotalProps.isLoading || loadingCustomersDetailsFetch,
    returnsTotal: fetchReturnsTotalProps.data || returnsState.returnTotal,
  }
}
