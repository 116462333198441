import { FilterStaticFilterQueryParamsType } from 'src/models'

export const customersState = {
  credentials: {
    login: '',
    password: '',
  },
  customers: {
    pages: [
      {
        items: [],
        meta: null,
      },
    ],
  },
}

export const customersKeys = {
  all: ['customers'],
  lists: () => [...customersKeys.all, 'lists'],
  list: (params?: FilterStaticFilterQueryParamsType) => [...customersKeys.lists(), 'list', params],
  staticList: (params?: any) => [...customersKeys.all, 'staticList', params],
  details: (id?: number) => [...customersKeys.all, 'details', id],
  credentials: (id?: number) => [...customersKeys.all, 'credentials', id],
  vatInfo: (id?: number) => [...customersKeys.all, 'vat-info', id],
}
