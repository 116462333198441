import { Claims, ReturnCustomerInfo, ReturnFilter } from 'src/components/common'

export const ViewReturnDetails = () => {
  return (
    <div className='flex h-full flex-col py-2' data-test-id='returns-details'>
      <ReturnCustomerInfo />
      <ReturnFilter />
      <Claims />
    </div>
  )
}
