import { useMutation } from '@tanstack/react-query'
import { UseMutationResult } from '@tanstack/react-query'

import { HttpErrorType, VisitParamsType } from 'src/models'

import { visitsService } from 'src/bus/visits'

import { queryFetcher } from 'src/utils'

type VisitsCreateReportMutationType = {
  onCreateVisitReportMutation: UseMutationResult<void, HttpErrorType, VisitParamsType>
}

export const useVisitsCreateReportMutation = (): VisitsCreateReportMutationType => {
  const onCreateVisitReportMutation = useMutation<void, HttpErrorType, VisitParamsType>({
    mutationFn: (params) => {
      return queryFetcher({
        name: 'createVisitsReport',
        fetcher: visitsService.createVisitReport,
        fetcherPayload: params,
      })
    },
  })

  return { onCreateVisitReportMutation }
}
