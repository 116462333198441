import { ReactNode } from 'react'

import { WrappedNodeDefault } from 'src/components/common'
import { Separator, Typography } from 'src/components/ui'

import { formatDate, orNull, ternary } from 'src/utils'

type HistoryItemProps = {
  date: number
  index: number
  showDate: boolean
  children: ReactNode
}

export const HistoryItem = ({ date, index, showDate, children }: HistoryItemProps) => {
  const verticalSeparator = <Separator orientation='vertical' className='h-4 w-0.5 bg-blue-gray-3' />

  return (
    <div className='flex flex-col items-center bg-blue-gray-1'>
      {ternary(
        showDate,
        <div className='flex flex-col items-center'>
          {orNull(index !== 0, verticalSeparator)}
          <div className='rounded-3xl bg-blue-gray-3 px-3 py-0.5'>
            <Typography variant='caption' color='blue-gray-9' className='font-semibold'>
              {WrappedNodeDefault(formatDate(date))}
            </Typography>
          </div>
          {verticalSeparator}
        </div>,
        verticalSeparator,
      )}
      {children}
    </div>
  )
}
