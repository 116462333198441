import { useTranslation } from 'next-i18next'

import { StopTourForm } from 'src/components/common'
import { DialogContent, DialogHeader, Typography } from 'src/components/ui'

import { useTourRouteCancel } from 'src/bus/tour'

import { GET_ENUMS } from 'src/utils'

export const DialogStopTour = () => {
  const { t } = useTranslation()
  const { onTourCancelRoute, loadingTourRouteCancel } = useTourRouteCancel()

  return (
    <DialogContent name={GET_ENUMS.dialog.stopTour} className='bg-blue-gray-1'>
      <DialogHeader>
        <Typography variant='title' color='gray-10'>
          {t('visits:stop_tour.title')}
        </Typography>
      </DialogHeader>
      <StopTourForm loadingCreate={loadingTourRouteCancel} onSubmit={onTourCancelRoute} />
    </DialogContent>
  )
}
