import { Col, Row } from 'antd'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'

import { WrappedNodeDefault, CellText, SkeletonList, CellCopy } from 'src/components/common'
import { Button, Icon, ListItem, Typography } from 'src/components/ui'

import { useOrdersDeliveriesFetch, useOrdersDetailsFetch } from 'src/bus/orders'

import { eqZero, formatDate, getHasData, getTranslate, gtOne, orNull, rowGutters, ternary } from 'src/utils'

const deliveriesHeader = [
  { title: 'orders:courier', span: 4 },
  { title: 'orders:paket', span: 8 },
  { title: 'orders:sent', span: 6 },
  { title: 'orders:delivered', span: 6 },
]

export const OrdersDetails = () => {
  const router = useRouter()
  const { t } = useTranslation()

  const getOrdersDetailsT = getTranslate('orders', t)

  const orderId = Number(router.query.id)
  const customerId = Number(router.query.customerId)

  const params = { orderId, customerId }

  const { ordersDetails, loadingOrdersDetailsFetch } = useOrdersDetailsFetch(params)
  const { ordersDeliveriesList, loadingOrdersDeliveriesListFetch } = useOrdersDeliveriesFetch(params)

  const [showIndex, setShowIndex] = useState(0)

  const isZeroIndex = eqZero(showIndex)
  const deliveryInfo = ordersDetails?.deliveryAt
    ? getOrdersDetailsT('delivered_date', { date: formatDate(ordersDetails.deliveryAt, 'slash') })
    : ordersDetails?.expectedDeliveryAt
      ? getOrdersDetailsT('expected_date', { date: formatDate(ordersDetails?.expectedDeliveryAt, 'slash') })
      : null

  const btnText = isZeroIndex ? 'see_all' : 'hide'
  const btnIcon = <Icon name={isZeroIndex ? 'ChevronDown' : 'ChevronUp'} color='blue-8' />

  const renderContent = (value: string | number | null, span: number) => {
    return (
      <Col span={span}>
        <Typography variant='secondary'>{WrappedNodeDefault(value)}</Typography>
      </Col>
    )
  }

  const onToggleIndex = () => setShowIndex((prev) => (prev === 0 ? ordersDeliveriesList.items.length : 0))

  return (
    <div className='mt-3 px-2'>
      <Typography variant='secondary' className='font-semibold'>
        {getOrdersDetailsT('general_order_data')}
      </Typography>
      <div className='flex h-full flex-col'>
        <div className='border-b border-blue-gray-2 py-3'>
          <div className='flex items-center justify-between gap-2'>
            <div className='basis-4.5/12'>
              <Typography variant='secondary' color='gray-7'>
                {getOrdersDetailsT('order_hash')}
              </Typography>
            </div>
            <div className='basis-7.5/12'>
              <CellCopy value={orderId} copyValue={String(orderId)} copiedText='order_id_copied' />
            </div>
          </div>
        </div>
        <div className='border-b border-blue-gray-2 py-3'>
          <div className='flex items-center justify-between gap-2'>
            <div className='basis-4.5/12'>
              <Typography variant='secondary' color='gray-7'>
                {getOrdersDetailsT('status')}
              </Typography>
            </div>
            <div className='basis-7.5/12'>
              <CellText value={ordersDetails?.status?.label} loadingFetch={loadingOrdersDetailsFetch} />
            </div>
          </div>
        </div>
        <div className='mb-2 border-b border-blue-gray-2 py-3'>
          <div className='flex items-center justify-between gap-2'>
            <div className='basis-4.5/12'>
              <Typography variant='secondary' color='gray-7'>
                {getOrdersDetailsT('delivery_info')}
              </Typography>
            </div>
            <div className='basis-7.5/12'>
              <CellText value={deliveryInfo} loadingFetch={loadingOrdersDetailsFetch} />
            </div>
          </div>
        </div>

        {orNull(
          getHasData(ordersDeliveriesList.items) || loadingOrdersDeliveriesListFetch,
          <div className='p-2'>
            <Row gutter={rowGutters}>
              {deliveriesHeader.map((item) => {
                return (
                  <Col key={item.title} span={item.span}>
                    <Typography variant='caption' className='font-semibold'>
                      <span dangerouslySetInnerHTML={{ __html: t(item.title) }} />
                    </Typography>
                  </Col>
                )
              })}
            </Row>
          </div>,
        )}
        {ternary(
          loadingOrdersDeliveriesListFetch,
          <SkeletonList lineCount={1} type='orders-delivery' />,
          <>
            {ordersDeliveriesList.items.map((item, index) => {
              return orNull(
                index <= showIndex,
                <ListItem variant='order' size='md' key={item.id}>
                  <Row gutter={rowGutters}>
                    {renderContent(item.type, 4)}
                    <Col span={8}>
                      <Link href={item.trackingUrl || '#'}>
                        <Typography variant='secondary' color='blue-8'>
                          {WrappedNodeDefault(item.paketNumber)}
                        </Typography>
                      </Link>
                    </Col>
                    {renderContent(formatDate(item.sentDate, 'slashShort'), 6)}
                    {renderContent(formatDate(item.deliveryDate, 'slashShort'), 6)}
                  </Row>
                </ListItem>,
              )
            })}
          </>,
        )}
        {orNull(
          gtOne(ordersDeliveriesList.items.length),
          <Button className='flex items-center' block variant='ghost' onClick={onToggleIndex}>
            {getOrdersDetailsT(btnText)} {btnIcon}
          </Button>,
        )}
      </div>
    </div>
  )
}
