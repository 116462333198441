import { Col, Row } from 'antd'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { cond, equals } from 'ramda'

import { book } from 'src/init/book'

import { StatusItem, WrappedNodeDefault } from 'src/components/common'
import { ListItem, SwipeAction, Typography, VisitHistoryStatus } from 'src/components/ui'

import { VisitHistoryType, TVisitHistoryStatus } from 'src/models'

import { useDialog } from 'src/contexts'

import {
  rowGutters,
  getVisitItemRightActions,
  getVisitItemLeftActions,
  getPlanedStatus,
  formatDate,
  GET_ENUMS,
} from 'src/utils'

type VisitsListHistoryItemProps = {
  item: VisitHistoryType
  onClick: () => void
  onGetDialogParams: ({ id, title }: { id?: number; title?: string }) => void
}

export const VisitsListHistoryItem = ({ item, onClick, onGetDialogParams }: VisitsListHistoryItemProps) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { setOnPageDialog } = useDialog()

  const onSelectCell = (type: string) => () =>
    cond([
      [equals('visit-plan'), () => router.push(`${book.visits}/${item.id}${book.visitActions}`)],
      [equals('customer-details'), () => router.push(`${book.customers}/${item.customerId}`)],
      [
        equals('cancel-visit'),
        () => {
          onGetDialogParams({ id: item.id, title: item.companyName })
          setOnPageDialog(GET_ENUMS.dialog.cancelVisit, true)
        },
      ],
      [
        equals('replan-visit'),
        () => {
          onGetDialogParams({ id: item.id, title: item.companyName })
          setOnPageDialog(GET_ENUMS.dialog.replanVisit, true)
        },
      ],
    ])(type)

  const status = item?.status?.value as TVisitHistoryStatus
  const isPlanedStatus = getPlanedStatus(item)

  const pathToDetails = {
    pathname: `${book.visitsHistory}/${item.id}`,
    query: { customerId: item.customerId },
  }

  const visitRightActions = getVisitItemRightActions({
    onSelectCell,
    isHidden: !isPlanedStatus,
    t,
  })
  const visitLeftActions = getVisitItemLeftActions({ onSelectCell, t })

  return (
    <SwipeAction
      rightActions={visitRightActions}
      leftActions={visitLeftActions}
      className='rounded-md [&:not(:last-child)]:mb-2'
    >
      <ListItem size='history' onClick={onClick}>
        <Link href={pathToDetails}>
          <Row align='middle' gutter={rowGutters}>
            <Col span={12}>
              <Typography className='font-semibold'>{WrappedNodeDefault(item.companyName)}</Typography>
              <Typography variant='caption' color='gray-7' truncate='line'>
                {item?.companyTitle}
              </Typography>
            </Col>
            <Col span={5}>
              <StatusItem status={item.customerStatus} />
            </Col>
            <Col span={7}>
              <div className='flex items-center justify-end gap-[3px]'>
                <Typography>{WrappedNodeDefault(formatDate(item.date, 'slashShort'))}</Typography>
                <VisitHistoryStatus variant={status} />
              </div>
            </Col>
          </Row>
        </Link>
      </ListItem>
    </SwipeAction>
  )
}
