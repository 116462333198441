import { BarcodeReader } from 'dynamsoft-javascript-barcode'
import Router from 'next/router'
import { Component, ComponentType } from 'react'

import { toastEnhanced } from 'src/components/common'
import { VideoDecode } from 'src/components/ui'

import { ScannerState } from 'src/models'

import { useScannerCode } from 'src/bus/userOptions'

import { DBR_LICENCE_ERROR } from 'src/utils'

function withScannerCode<T>(Component: ComponentType<T>) {
  return (props: T) => {
    const { updateCode } = useScannerCode()

    return <Component updateCode={updateCode} {...props} />
  }
}

class Scanner extends Component<Partial<ScannerState>> {
  async componentDidMount() {
    try {
      if (!BarcodeReader.isWasmLoaded()) {
        await BarcodeReader.loadWasm()
      }
    } catch (err) {
      const error = err as Error

      if (error.message.indexOf('network connection error')) {
        toastEnhanced({ title: DBR_LICENCE_ERROR }, { type: 'error' })
      }

      throw error
    }
  }

  shouldComponentUpdate() {
    return false
  }

  onScanResult = (value: string) => {
    if (value) {
      this.props.updateCode?.(value)
      Router.back()
    }
  }

  render() {
    return <VideoDecode onScanResult={this.onScanResult} />
  }
}

export default withScannerCode(Scanner)
