import { useQueryClient } from '@tanstack/react-query'
import toFormData from 'json-form-data'
import { useRouter } from 'next/router'
import { useCallback, useMemo, useState } from 'react'

import { book } from 'src/init/book'

import { FormSubmitParams, HttpErrorType, FormUpdateClaimType } from 'src/models'

import { claimsKeys, useClaimsCreateMutation } from 'src/bus/claims'
import { useCustomersDetailsFetch } from 'src/bus/customers'
import { useReturnsInProgress, returnsKeys } from 'src/bus/returns'

import { fillServerErrorsToForm, getConvertedObjToJson, getConvertedUploadImgToObj } from 'src/utils'

type ClaimsCreateCbType = (data: FormSubmitParams<FormUpdateClaimType>) => Promise<boolean>

type UseClaimsCreateType = {
  loadingClaimsCreate: boolean
  onCreateClaim: ClaimsCreateCbType
}

export const useClaimsCreate = (onNextStep: () => void): UseClaimsCreateType => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const { returnId } = useReturnsInProgress()
  const { customerId } = useCustomersDetailsFetch()
  const { onCreateClaimMutation } = useClaimsCreateMutation()

  const [isLoading, setIsLoading] = useState(false)

  const params = useMemo(() => ({ returnId, customerId }), [returnId, customerId])

  const onCreateClaim = useCallback<ClaimsCreateCbType>(
    async (data) => {
      try {
        setIsLoading(true)

        const values = toFormData({
          returnId,
          customerId,
          ...getConvertedObjToJson(data.values),
          ...getConvertedUploadImgToObj(data.values.images || []),
        })

        const response = await onCreateClaimMutation.mutateAsync({ data: values })

        await queryClient.invalidateQueries({ queryKey: returnsKeys.all })
        await queryClient.refetchQueries({ queryKey: claimsKeys.list(params) })

        await router.replace({ pathname: book.claims, query: { id: response.id } })

        onNextStep()

        return true
      } catch (error) {
        const errs = (error as HttpErrorType).errors
        errs && fillServerErrorsToForm(errs, data.acts.setError)

        return false
      } finally {
        setIsLoading(false)
      }
    },
    [router, onCreateClaimMutation, customerId, onNextStep, returnId, params, queryClient],
  )

  return {
    loadingClaimsCreate: isLoading,
    onCreateClaim,
  }
}
