import { TourNewsTypes } from 'src/models'

import { tourState, tourKeys, tourService } from 'src/bus/tour'

import { useQueryAsync } from 'src/hooks'

type UseTourNewsFetchType = {
  loadingNewsFetch: boolean
  news: TourNewsTypes
}

export const useTourNewsFetch = (): UseTourNewsFetchType => {
  const fetchTourNewsProps = useQueryAsync<TourNewsTypes>({
    key: tourKeys.news(),
    name: 'fetchTourNews',
    fetcher: tourService.fetchNews,
  })

  return {
    loadingNewsFetch: fetchTourNewsProps.isLoading,
    news: fetchTourNewsProps.data || tourState.news,
  }
}
