import { Col, Row } from 'antd'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'

import { book } from 'src/init/book'

import { SkeletonList } from 'src/components/common'
import { InfiniteScroll, Empty, Typography, ListItem } from 'src/components/ui'

import { ReturnsInfiniteType } from 'src/models'

import { getInfiniteListLength, rowGutters, visitReturnsModel } from 'src/utils'

type ReturnsPageType = {
  onFetchMore: () => void
  loadingFetch: boolean
  hasMore: boolean
  data: ReturnsInfiniteType
}

const returnsHeader = [
  { title: 'returns:returns_list.return_number', span: 5 },
  { title: 'returns:returns_list.articles_number', span: 6 },
  { title: 'returns:returns_list.status', span: 7 },
  { title: 'returns:returns_list.date', span: 6 },
]

export const Returns = (props: ReturnsPageType) => {
  const { data, loadingFetch, onFetchMore, hasMore } = props
  const { t } = useTranslation()

  const returnsModel = visitReturnsModel({
    data,
    loadingFetch: false,
  })

  const renderItems = () => {
    return returnsModel.map((page) => {
      return page.map((item) => (
        <ListItem key={item.id}>
          <Link href={`${book.returns}/${item.id}`}>
            <Row gutter={rowGutters}>
              <Col span={5}>{item.return()}</Col>
              <Col span={6}>{item.article()}</Col>
              <Col span={7}>{item.status()}</Col>
              <Col span={6}>{item.date()}</Col>
            </Row>
          </Link>
        </ListItem>
      ))
    })
  }

  return (
    <>
      <div className='bg-gray-1 px-2 py-3'>
        <Row gutter={rowGutters}>
          {returnsHeader.map((item) => {
            return (
              <Col key={item.title} span={item.span}>
                <Typography variant='caption' color='gray-7' className='text-center'>
                  {t(item.title)}
                </Typography>
              </Col>
            )
          })}
        </Row>
      </div>
      <InfiniteScroll
        hasMore={hasMore}
        onFetchMore={onFetchMore}
        dataLength={getInfiniteListLength(data.pages)}
        loadingFetch={loadingFetch}
        loader={<SkeletonList type='returns' />}
        renderItems={renderItems}
        scrollableTarget='returns'
        empty={<Empty title='common:empty.no_activity_returns' description='common:empty.returns_list' />}
      />
    </>
  )
}
