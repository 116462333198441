import { z } from 'zod'

import { HttpRequestParams } from './index'
import { selectSchema } from './StaticData'

export const settingsSchema = z.object({
  language: selectSchema.optional(),
  navigator: selectSchema.optional(),
})

export type SettingsType = z.infer<typeof settingsSchema>

export type SettingsParams = HttpRequestParams<SettingsType>
