import { CellLink } from 'src/components/common'

import { ContactPersonPhoneType } from 'src/models'

import { createCustomerPhone } from 'src/utils'

type CellPhonesValueProps = {
  value?: ContactPersonPhoneType[]
  loadingFetch?: boolean
}

export const CellPhonesValue = ({ value = [], loadingFetch = false }: CellPhonesValueProps) => {
  return (
    <>
      {value.map((p) => (
        <div className='mb-2' key={p.id}>
          <CellLink value={createCustomerPhone(p)} type='tel' loadingFetch={loadingFetch} />
        </div>
      ))}
    </>
  )
}
