import { useInfiniteQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import QS from 'qs'
import { useCallback } from 'react'

import {
  FilterStaticFilterQueryParamsType,
  HistoryOrdersInfiniteType,
  HistoryOrdersType,
  TRefetchFilterStaticCb,
} from 'src/models'

import { historyKeys, historyState, historyService } from 'src/bus/history'

import { getNextPageParam, getParamsFromQueryStr, queryFetcher } from 'src/utils'

type UseHistoryOrdersFetchType = {
  loadingHistoryOrdersFetch: boolean
  onFetchMoreHistoryOrders: () => void
  historyOrdersHasMore: boolean
  historyOrders: HistoryOrdersInfiniteType
  onRefetchHistoryOrdersList: TRefetchFilterStaticCb
  historyFiltersStaticInitialValues: FilterStaticFilterQueryParamsType
}

export const useHistoryOrdersFetch = (): UseHistoryOrdersFetchType => {
  const router = useRouter()

  const initialQuery = getParamsFromQueryStr(router.query)

  const customerId = Number(router.query.id)
  const params = { ...initialQuery, customerId }

  const fetchHistoryOrdersProps = useInfiniteQuery<HistoryOrdersType>({
    queryKey: historyKeys.orders(initialQuery),
    queryFn: ({ pageParam }) =>
      queryFetcher({
        name: 'fetchHistoryOrders',
        fetcher: historyService.fetchHistoryOrders,
        fetcherPayload: { params: { ...params, page: pageParam } },
      }),
    initialPageParam: 1,
    enabled: !!customerId,
    getNextPageParam,
  })

  const onRefetchHistoryOrdersList = useCallback<TRefetchFilterStaticCb>(
    async (values) => {
      const newParams = { ...initialQuery, ...values?.filterData }
      await router.replace({ pathname: router.pathname, query: QS.stringify(newParams) })
    },
    [router, initialQuery],
  )

  return {
    loadingHistoryOrdersFetch: fetchHistoryOrdersProps.isLoading,
    historyFiltersStaticInitialValues: initialQuery,
    onFetchMoreHistoryOrders: fetchHistoryOrdersProps.fetchNextPage,
    historyOrdersHasMore: fetchHistoryOrdersProps.hasNextPage,
    historyOrders: fetchHistoryOrdersProps.data || historyState.orders,
    onRefetchHistoryOrdersList,
  }
}
