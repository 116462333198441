import QRCode from 'react-qr-code'

type ShowQRCodeProps = {
  value?: string
  size?: number
  viewBox?: string
  level?: string // Can be one of 'L,M,H,Q'
}

export const ShowQRCode = ({ value = '', size = 256, viewBox = '0 0 256 256', level = 'H' }: ShowQRCodeProps) => {
  return <QRCode size={size} className='h-auto w-full max-w-full' value={value} viewBox={viewBox} level={level} />
}
