import type { TFunction } from 'i18next'
import { z } from 'zod'

import { messages } from 'src/utils'

export const returnSignature = {
  shape: {
    signature: '',
  },
  schema: (t: TFunction) => {
    return z.object({
      signature: z
        .string()
        .min(1, t(messages.sign.required))
        .regex(messages.sign.patternSignFormat, t(messages.sign.signFormat)),
    })
  },
}
