import { useTranslation } from 'next-i18next'
import { useState } from 'react'

import { ClaimsFillData, ClaimsUploadPhoto, WarningMessage } from 'src/components/common'
import { Tabs, Typography } from 'src/components/ui'
import { TabsType, TabType } from 'src/components/ui/Tabs'

import { useClaimsDetailsFetch } from 'src/bus/claims'

import { isNilOrEmpty, orNull } from 'src/utils'

import st from './Styles.module.css'

export const ClaimsTabs = () => {
  const { t } = useTranslation()
  const { claimsDetails, loadingClaimsDetailsFetch } = useClaimsDetailsFetch()

  const [activeTabKey, setActiveTabKey] = useState('')
  const [showWarning, setShowWarning] = useState(claimsDetails?.isOrderPaid ? !claimsDetails.isOrderPaid : false)

  const onChangeTabs = (tab: TabType) => setActiveTabKey(tab.key)

  const onPrevStep = () => setActiveTabKey('fillTheData')
  const onNextStep = () => setActiveTabKey('uploadPhoto')

  const claimsTabs: TabsType = [
    {
      title: t('claims:tabs.fill_the_data'),
      key: 'fillTheData',
      component: () => (
        <ClaimsFillData onNextStep={onNextStep} data={claimsDetails} loadingFetch={loadingClaimsDetailsFetch} />
      ),
    },
    {
      title: t('claims:tabs.upload_photo'),
      key: 'uploadPhoto',
      component: () => (
        <ClaimsUploadPhoto onPrevStep={onPrevStep} data={claimsDetails} loadingFetch={loadingClaimsDetailsFetch} />
      ),
      disabled: isNilOrEmpty(claimsDetails),
    },
  ]

  return (
    <div className={st['claims-tabs']} data-test-id='claims'>
      <div className='mx-2'>
        {orNull(
          showWarning,
          <WarningMessage
            text={
              <Typography variant='secondary' color='gray-7'>
                {t('claims:warning_paid')}
              </Typography>
            }
            onClose={() => setShowWarning(false)}
          />,
        )}
      </div>
      <Tabs tabs={claimsTabs} onChangeTabs={onChangeTabs} activeKey={activeTabKey} />
    </div>
  )
}
