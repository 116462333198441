import { useQueryClient } from '@tanstack/react-query'

import { ArticlesType } from 'src/models'

import { parcelsKeys, useParcelsGenerateMutation } from 'src/bus/parcels'

type UseParcelsGenerateType = {
  loadingParcelsGenerate: boolean
  onGenerateParcels: any | null
}
export const useParcelsGenerate = (type: ArticlesType): UseParcelsGenerateType => {
  const queryClient = useQueryClient()
  const { onGenerateParcelsMutation } = useParcelsGenerateMutation()

  const onGenerateParcels = async () => {
    try {
      await onGenerateParcelsMutation.mutateAsync({ data: { type } })
      await queryClient.invalidateQueries({ queryKey: parcelsKeys.all })
    } catch (error) {
      console.log('useParcelsGenerate error', error)
    }
  }

  return {
    loadingParcelsGenerate: onGenerateParcelsMutation.isPending,
    onGenerateParcels,
  }
}
