import { useRouter } from 'next/router'

import { ParcelType } from 'src/models'

import { parcelsKeys, parcelsService } from 'src/bus/parcels'

import { useQueryAsync } from 'src/hooks'

import { isNotNilOrNotEmpty } from 'src/utils'

type UseParcelsDetailsFetchType = {
  loadingParcelsDetailsFetch: boolean
  parcelsDetails: ParcelType | null
}

export const useParcelsDetailsFetch = (id?: number): UseParcelsDetailsFetchType => {
  const router = useRouter()

  const parcelId = Number(id || router.query.id)
  const params = { id: parcelId }

  const fetchParcelsDetailsProps = useQueryAsync<ParcelType>({
    key: parcelsKeys.detail(parcelId),
    name: 'fetchParcelsDetails',
    fetcher: parcelsService.fetchParcelsDetails,
    fetcherPayload: params,
    options: { enabled: isNotNilOrNotEmpty(params) },
  })

  return {
    loadingParcelsDetailsFetch: fetchParcelsDetailsProps.isLoading,
    parcelsDetails: fetchParcelsDetailsProps.data || null,
  }
}
