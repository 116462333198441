import { useTranslation } from 'next-i18next'

import { PlanVisitForm } from 'src/components/common'
import { DialogContent, DialogHeader, Typography } from 'src/components/ui'

import { useVisitsAvailableDateFetch, useVisitsDetailsFetch, useVisitsPlanVisit } from 'src/bus/visits'

import { GET_ENUMS, getTranslate } from 'src/utils'

type DialogReplanVisitProps = {
  title?: string
  id?: number
}

export const DialogReplanVisit = ({ title, id }: DialogReplanVisitProps) => {
  const { t } = useTranslation()

  const { visitsDetails, loadingVisitsFetch } = useVisitsDetailsFetch(id)
  const { onReplanVisit, loadingVisitPlan } = useVisitsPlanVisit(id)
  const { visitsAvailableDate, loadingVisitsAvailableDateFetch } = useVisitsAvailableDateFetch({
    visitId: id || visitsDetails?.id,
  })

  const getRescheduleVisitT = getTranslate('common:cancel_visit', t)

  return (
    <DialogContent name={GET_ENUMS.dialog.replanVisit}>
      <DialogHeader>
        <Typography variant='title' color='gray-10'>
          {getRescheduleVisitT('plan_new_visit', {
            name: visitsDetails?.companyName || title,
          })}
        </Typography>
        <Typography variant='secondary' color='gray-7'>
          {getRescheduleVisitT('new_visit')}
        </Typography>
      </DialogHeader>
      <PlanVisitForm
        onSubmit={onReplanVisit}
        dates={visitsAvailableDate}
        loadingCreate={loadingVisitPlan}
        loadingFetch={loadingVisitsFetch || loadingVisitsAvailableDateFetch}
      />
    </DialogContent>
  )
}
