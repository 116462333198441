import { Control } from 'react-hook-form'

import { GroupDateRangePicker } from 'src/components/common'
import { FormControl, FormField, FormFields, FormItem, FormMessage } from 'src/components/ui'

import {
  AutocompletePropsType,
  FiltersFormFieldVariantKeys,
  FilterStaticFieldNamesType,
  StaticDataItemType,
} from 'src/models'

type FiltersDynamicItemProps = {
  type: FilterStaticFieldNamesType
  name?: string
  autocompleteProps?: AutocompletePropsType | null
  control: Control<any>
  loadingFetchStaticData?: boolean
  hasDisabled?: boolean
  options?: StaticDataItemType[]
  isMulti?: boolean
  variant?: FiltersFormFieldVariantKeys
  loadingFetch?: boolean
}

export const FiltersStaticItem = ({
  name = '',
  isMulti = false,
  loadingFetchStaticData = false,
  options = [],
  variant,
  control,
}: FiltersDynamicItemProps) => {
  const renderItem = () => {
    switch (variant) {
      case 'collapsed-checklist':
        return (
          <FormField
            control={control}
            name={name}
            render={({ field: { onChange, value, name } }) => (
              <FormItem gutter='none'>
                <FormControl>
                  <FormFields.InputCheckList
                    name={name}
                    onChange={onChange}
                    value={value}
                    multiple={isMulti}
                    options={options}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )
      case 'checklist':
        return (
          <FormField
            control={control}
            name={name}
            render={({ field: { onChange, value, name } }) => (
              <FormItem gutter='none'>
                <FormControl>
                  <FormFields.InputCheckList
                    name={name}
                    onChange={onChange}
                    value={value}
                    multiple={isMulti}
                    hasCollapsed={false}
                    options={options}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )
      case 'multi-select':
        return (
          <FormField
            control={control}
            name={name}
            render={({ field }) => (
              <FormItem gutter='none'>
                <FormControl>
                  <FormFields.InputSelect
                    {...field}
                    loading={loadingFetchStaticData}
                    isFilter
                    isMulti
                    isSearchable
                    options={options}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )
      case 'date':
        return <GroupDateRangePicker loadingFetch={false} control={control} title='search_period' />
    }
  }

  return <>{renderItem()}</>
}
