import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import {
  Dialog,
  VisitsActions,
  CustomersDetails,
  ReturnNavigation,
  CustomersComment,
  DialogViewsSwitcher,
} from 'src/components/common'
import { Button, Icon, ScrollArea, Typography } from 'src/components/ui'

import { useCustomersDetailsFetch } from 'src/bus/customers'

import { useDialog } from 'src/contexts'

import { GET_ENUMS } from 'src/utils'

export const ViewVisitsActions = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { onPageDialogs, setOnPageDialog } = useDialog()
  const { customersDetails } = useCustomersDetailsFetch()

  return (
    <ScrollArea className='py-2' data-test-id='visits-plan'>
      <Dialog
        open={onPageDialogs[GET_ENUMS.dialog.customerIssue]?.visible}
        onOpenChange={(open) => setOnPageDialog(GET_ENUMS.dialog.customerIssue, open)}
      >
        <DialogViewsSwitcher name={GET_ENUMS.dialog.customerIssue} />
      </Dialog>
      {/*<NPS />*/}
      <div className='mb-4'>
        <ReturnNavigation />
      </div>
      <div className='mb-4'>
        <VisitsActions title={<Typography className='font-semibold'>{t('visits:actions')}</Typography>} />
      </div>
      <div className='mb-4'>
        <CustomersComment />
      </div>
      <div className='flex flex-col gap-2'>
        <Typography className='font-semibold'>{t('visits:issue')}</Typography>
        <Button
          disabled={!customersDetails?.awsId}
          onClick={() => setOnPageDialog(GET_ENUMS.dialog.customerIssue, true)}
          variant='card'
        >
          <Typography variant='secondary' color='blue-7'>
            {t('buttons:report_issue')}
          </Typography>
          <Icon name='ChevronRight' color='blue-8' />
        </Button>
      </div>
      <CustomersDetails visitId={Number(router.query.id)} />
    </ScrollArea>
  )
}
