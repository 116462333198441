import { useCallback, useEffect, useState } from 'react'

import { CustomersStaticListType } from 'src/models'

import { customersService, customersKeys } from 'src/bus/customers'

import { useQueryAsync } from 'src/hooks'

type RefetchStaticDataCustomersCbType = (keyword: string) => void

type UseCustomersStaticListFetchType = {
  loadingCustomersStaticListFetch: boolean
  customersStaticList: CustomersStaticListType
  onRefetchStaticDataCustomers: RefetchStaticDataCustomersCbType
}

export const useCustomersStaticListFetch = (customerId?: number): UseCustomersStaticListFetchType => {
  const [params, setParams] = useState<any>({})

  useEffect(() => {
    customerId && setParams({ filter: { search: customerId } })
  }, [setParams, customerId])

  const fetchCustomersStaticListProps = useQueryAsync<CustomersStaticListType>({
    key: customersKeys.staticList(params),
    name: 'fetchCustomersList',
    fetcher: customersService.fetchCustomersStaticList,
    fetcherPayload: params,
    options: { enabled: Number(params?.filter?.search?.length) !== 0 },
  })

  const onRefetchStaticDataCustomers = useCallback<RefetchStaticDataCustomersCbType>(
    async (keyword) => {
      setParams({ ...params, filter: { search: keyword } })
    },
    [params],
  )

  return {
    loadingCustomersStaticListFetch: fetchCustomersStaticListProps.isLoading,
    customersStaticList: fetchCustomersStaticListProps.data || [],
    onRefetchStaticDataCustomers,
  }
}
