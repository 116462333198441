import { BottomNavigationBar, OrdersTotal } from 'src/components/common'

export const OrdersDetailsFooter = () => {
  return (
    <>
      <div className='z-10 bg-gray-1 p-2 shadow-level-2'>
        <OrdersTotal />
      </div>
      <BottomNavigationBar />
    </>
  )
}
