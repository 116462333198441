import * as Portal from '@radix-ui/react-portal'
import { useEffect, useState } from 'react'
import { ToastContainer as ReactToastContainer } from 'react-toastify'

export const ToastContainer = () => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  if (!isLoaded) {
    return null
  }

  return (
    <Portal.Root className='pointer-events-auto'>
      <ReactToastContainer />
    </Portal.Root>
  )
}
