import { useTranslation } from 'next-i18next'

import { VisitReportForm, VisitReportPanel } from 'src/components/common'
import { Typography } from 'src/components/ui'

import { useActionsReportsFetch, useBusinessActionsFetch } from 'src/bus/actions'
import { useVisitsReportCreate } from 'src/bus/visits'

export const ViewReport = () => {
  const { t } = useTranslation()
  const { actionsReports, loadingActionsReportsFetch } = useActionsReportsFetch()
  const { businessActions, loadingBusinessActionsFetch } = useBusinessActionsFetch()
  const { onCreateVisitsReport, loadingVisitsReportCreate } = useVisitsReportCreate()

  return (
    <div className='mt-2 space-y-2 px-2' data-test-id='visits-report'>
      <Typography className='font-semibold'>{t('visits:visit_plan')}</Typography>
      <VisitReportForm
        actionsReports={actionsReports}
        businessActions={businessActions}
        loadingActionsFetch={loadingActionsReportsFetch}
        loadingBusinessActionsFetch={loadingBusinessActionsFetch}
        onSubmit={onCreateVisitsReport}
        loadingCreate={loadingVisitsReportCreate}
        renderFloatingPanel={(onSubmit, loadingSubmit, disabledBtn) => (
          <VisitReportPanel onSubmit={onSubmit} loadingSubmit={loadingSubmit} disabledBtn={disabledBtn} />
        )}
      />
    </div>
  )
}
