import { BaseDataListType, StaticDataItemType } from 'src/models'

import { userOptionsService, userOptionsKeys } from 'src/bus/userOptions'

import { useQueryAsync } from 'src/hooks'

type UserSectorsFetchType = {
  loadingUserSectorsFetch: boolean
  sectors: StaticDataItemType[]
}

export const useUserSectorsFetch = (): UserSectorsFetchType => {
  const fetchUserOptionsSectorsProps = useQueryAsync<BaseDataListType>({
    key: userOptionsKeys.sectors(),
    name: 'fetchUserOptionsSectors',
    fetcher: userOptionsService.sectorsFetch,
  })

  return {
    loadingUserSectorsFetch: fetchUserOptionsSectorsProps.isLoading,
    sectors: fetchUserOptionsSectorsProps.data?.items || [],
  }
}
