import { FiltersDynamicForm } from 'src/components/common'

import {
  ArticlesType,
  ConvertCodeType,
  FiltersDynamicType,
  FilterStaticDataType,
  FilterStaticEnum,
  FormSubmitParams,
} from 'src/models'

import { useParcelsArticlesFetch, useParcelsArticlesFilters } from 'src/bus/parcels'

import { useUrlQuery } from 'src/hooks'

import { usePageUrlContext } from 'src/contexts'

type ParcelsArticlesFiltersProps = {
  type?: ArticlesType
  parcelId?: number
  onCheckParcels?: (data: ConvertCodeType) => void
}

export const ParcelsArticlesFilters = ({ type, parcelId, onCheckParcels }: ParcelsArticlesFiltersProps) => {
  const { initialFilters, setStorageFilters } = usePageUrlContext()
  const { onUpdateQuery } = useUrlQuery()
  const { parcelsArticlesFilters } = useParcelsArticlesFilters()
  const { onRefetchParcelsArticles } = useParcelsArticlesFetch({ type, parcelId })

  const filterStaticItems: FilterStaticDataType = {
    parcelArticleStatus: {
      name: FilterStaticEnum.parcelArticleStatus,
      options: parcelsArticlesFilters.parcelArticleStatus,
    },
  }

  const filterSearchItem: FilterStaticDataType = {
    search: {
      name: FilterStaticEnum.search,
      placeholder: 'parcels_articles_search.placeholder',
    },
  }

  const onSubmit = async (data: FormSubmitParams<FiltersDynamicType>) => {
    const { filterData, filter } = onUpdateQuery({
      filtersValues: data.values,
      filterItems: filterStaticItems,
    })

    setStorageFilters(filter)
    await onRefetchParcelsArticles(filterData)
  }

  return (
    <div className='bg-gray-1 px-2'>
      <FiltersDynamicForm
        filterItemsSet={filterSearchItem}
        onSubmit={onSubmit}
        advancedIcon='Camera'
        advancedFn={onCheckParcels}
        initialValues={initialFilters?.filter}
      />
    </div>
  )
}
