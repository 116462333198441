import { useMutation } from '@tanstack/react-query'
import { UseMutationResult } from '@tanstack/react-query'

import { HttpErrorType, HttpRequestParams } from 'src/models'

import { otrsService } from 'src/bus/otrs'

import { queryFetcher } from 'src/utils'

type IssueCreateMutationType = {
  onCreateIssueMutation: UseMutationResult<void, HttpErrorType, HttpRequestParams>
}

export const useIssueCreateMutation = (): IssueCreateMutationType => {
  const onCreateIssueMutation = useMutation<void, HttpErrorType, HttpRequestParams>({
    mutationFn: (params) => {
      return queryFetcher({
        name: 'createIssue',
        fetcher: otrsService.createIssue,
        fetcherPayload: params,
      })
    },
  })

  return { onCreateIssueMutation }
}
