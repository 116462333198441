import { zodResolver } from '@hookform/resolvers/zod'
import debounce from 'lodash.debounce'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { FilterAutocompleteItem } from 'src/components/common'
import { Form, FormControl, FormField, FormFields, FormItem } from 'src/components/ui'

import {
  FormSubmitFn,
  FiltersDynamicType,
  AutocompletePropsType,
  FilterStaticRequestValuesType,
  ConvertCodeType,
  FilterStaticDataType,
  FilterStaticBaseConfigType,
  FormFilterStaticEnum,
  FilterStaticEnum,
} from 'src/models'

import { formShapes } from 'src/bus/forms'

import { createFilterStaticBaseConfig } from 'src/utils'

type FiltersDynamicFormType = {
  initialValues?: FilterStaticRequestValuesType | null
  onSubmit: FormSubmitFn<FiltersDynamicType>
  placeholder?: string
  autocompleteProps?: AutocompletePropsType | null
  advancedIcon?: string
  advancedFn?: (data: ConvertCodeType) => void
  filterItemsSet?: FilterStaticDataType
}

const DELAY = 1000

export const FiltersDynamicForm = ({
  onSubmit,
  filterItemsSet = {
    search: {
      name: FilterStaticEnum.search,
      placeholder: 'search.placeholder',
    },
  },
  // initialValues = null,
  autocompleteProps,
  advancedIcon,
  advancedFn,
}: FiltersDynamicFormType) => {
  const { t } = useTranslation()

  const filtersInitialValues = { ...formShapes.staticFiltersForm.shape(filterItemsSet) }

  const formProps = useForm<FiltersDynamicType>({
    defaultValues: filtersInitialValues,
    resolver: zodResolver(formShapes.staticFiltersForm.schema(filterItemsSet)),
  })

  const { control, handleSubmit, setError, reset, setValue } = formProps

  const [, setDebouncedSearch] = useState('')

  const onSubmitForm = handleSubmit(async (values) => {
    await onSubmit({ values, acts: { setError, reset } })
  })

  const debounceSearch = debounce(async (value: string) => {
    setDebouncedSearch(value)

    await onSubmitForm()
  }, DELAY)

  const handleInputChange = async (value: string) => {
    setValue('search', value)

    // Debounce the search function
    await debounceSearch(value)
  }

  // useDeepCompareEffect(() => {
  //   if (initialValues) {
  //     reset({ ...initialValues })
  //   }
  // }, [initialValues, reset]);

  const filtersConfig = createFilterStaticBaseConfig(
    Object.keys(filterItemsSet) as Array<keyof FilterStaticBaseConfigType>,
    filterItemsSet,
  )

  const filtersList = Object.keys(filtersConfig) as Array<keyof FilterStaticBaseConfigType>

  return (
    <Form {...formProps}>
      <FormFields.FormGeneral>
        {filtersList.map((filterKey) => {
          const filterItem = filtersConfig[filterKey]

          const getPlaceholder = t(`fields:filter_dynamic.${filterItem.placeholder}`)

          return (
            <FormField
              key={filterKey}
              control={control}
              name={FormFilterStaticEnum.search}
              render={({ field }) => {
                return (
                  <>
                    {filterItem.variant === 'suggestion' ? (
                      <FormItem gutter='none'>
                        <FormControl>
                          <FormFields.InputSuggestion
                            {...field}
                            placeholder={getPlaceholder}
                            renderItem={<FilterAutocompleteItem {...autocompleteProps} />}
                            loadingFetch={
                              autocompleteProps?.loadingClaimsFetch || autocompleteProps?.loadingClaimsSearchFetch
                            }
                            advancedIcon={advancedIcon}
                            advancedFn={advancedFn}
                            onChange={handleInputChange}
                          />
                        </FormControl>
                      </FormItem>
                    ) : (
                      <FormItem gutter='none'>
                        <FormControl>
                          <FormFields.SearchBar
                            {...field}
                            onChange={handleInputChange}
                            placeholder={getPlaceholder}
                            advancedIcon={advancedIcon}
                            advancedFn={advancedFn}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  </>
                )
              }}
            />
          )
        })}
      </FormFields.FormGeneral>
    </Form>
  )
}
