import { StaticDataItemType, StaticDataKeys } from 'src/models'

import { useStaticDataFetch } from 'src/bus/staticData'

type CustomersStaticDataFetchType = {
  countries: StaticDataItemType[]
  phoneCodes: StaticDataItemType[]
  quantityEmployees: StaticDataItemType[]
  businessType: StaticDataItemType[]
  planVisits: StaticDataItemType[]
  customerStatus: StaticDataItemType[]
  potential: StaticDataItemType[]
  loadingCustomerStaticDataFetch: boolean
}

export const useCustomersStaticDataFetch = (): CustomersStaticDataFetchType => {
  const staticDataKeys = [
    StaticDataKeys.countries,
    StaticDataKeys.phoneCodes,
    StaticDataKeys.quantityEmployees,
    StaticDataKeys.businessType,
    StaticDataKeys.planVisits,
    StaticDataKeys.customerStatus,
    StaticDataKeys.potential,
  ]

  const { staticData, loadingStaticDataFetch } = useStaticDataFetch(staticDataKeys)

  const data = {
    countries: staticData?.countries || [],
    phoneCodes: staticData?.['phone-codes'] || [],
    quantityEmployees: staticData?.['quantity-employees'] || [],
    businessType: staticData?.['business-type'] || [],
    planVisits: staticData?.['plan-visits'] || [],
    customerStatus: staticData?.['customer-status'] || [],
    potential: staticData?.['potential'] || [],
  }

  return {
    loadingCustomerStaticDataFetch: loadingStaticDataFetch,
    ...data,
  }
}
