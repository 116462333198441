import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'

import { HttpErrorType } from 'src/models'

import { tourKeys, useTourPointsDeleteMutation } from 'src/bus/tour'

import { useToast } from 'src/hooks'

type DeleteTourPointCbType = (id: number) => void

type UseTourPointsDeleteType = {
  loadingTourPointsDelete: boolean
  onDeleteTourPoint: DeleteTourPointCbType
}

export const useTourPointsDelete = (): UseTourPointsDeleteType => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const showToast = useToast()
  const { onDeleteTourPointMutation } = useTourPointsDeleteMutation()

  const onDeleteTourPoint = useCallback<DeleteTourPointCbType>(
    async (id) => {
      try {
        await onDeleteTourPointMutation.mutateAsync({ params: { id: String(id) } })
        showToast.success({ title: t('notifications:point_delete') })
        await queryClient.refetchQueries({ queryKey: tourKeys.tourPoints() })
        await queryClient.refetchQueries({ queryKey: tourKeys.tourPointsStatic() })
      } catch (error) {
        const err = error as HttpErrorType
        showToast.error({ title: err.type, body: err.message })
      }
    },
    [onDeleteTourPointMutation, showToast, t, queryClient],
  )

  return {
    onDeleteTourPoint,
    loadingTourPointsDelete: onDeleteTourPointMutation.isPending,
  }
}
