import type { TFunction } from 'i18next'

import { CellSwipe } from 'src/components/common'

import type { SwipeActionType } from 'src/models'

import { getActionsWithoutHidden } from 'src/utils'

type GetVisitItemLeftActionsProps = {
  onSelectCell: (type: string) => () => void
  t: TFunction
}

export const getVisitItemLeftActions = ({ onSelectCell, t }: GetVisitItemLeftActionsProps): SwipeActionType[] => {
  const actions = [
    {
      key: 'visitPlan',
      text: CellSwipe({
        text: t('visits:visits_list.visit_plan_info'),
        icon: 'Visits',
        type: 'blue-gray3',
      }),
      onClick: onSelectCell('visit-plan'),
    },
    {
      key: 'customersDetails',
      text: CellSwipe({
        text: t('visits:visits_list.customer_info'),
        icon: 'User',
        type: 'info',
      }),
      onClick: onSelectCell('customer-details'),
    },
  ]

  return getActionsWithoutHidden(actions)
}
