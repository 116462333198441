import { Col, Row } from 'antd'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { cond, equals } from 'ramda'

import { book } from 'src/init/book'

import { WrappedNodeDefault } from 'src/components/common'
import { ListItem, SwipeAction, Typography } from 'src/components/ui'

import { ParcelType } from 'src/models'

import { useParcelsPack, useParcelsRestore, useParcelsShipmentRequest } from 'src/bus/parcels'

import { useCurrencyFormat } from 'src/hooks'

import { useDialog } from 'src/contexts'

import { GET_ENUMS, getParcelsActions, getReadyStatus, rowGutters } from 'src/utils'

type ParcelsItemProps = {
  item: ParcelType
  onSelectId: (id: number) => void
}

export const ParcelsItem = ({ item, onSelectId }: ParcelsItemProps) => {
  const { t } = useTranslation()
  const { setOnPageDialog } = useDialog()
  const { onPackParcels } = useParcelsPack()
  const { onRestoreParcel } = useParcelsRestore()
  const { onShipmentParcels } = useParcelsShipmentRequest()
  const { currencyFormatNumber } = useCurrencyFormat({ precision: 1 })

  const pathToDetails = `${book.parcelsList}/${item.id}`

  const renderContent = (span: number, value?: string | number | null) => {
    return (
      <Col span={span}>
        <Typography variant='secondary'>{WrappedNodeDefault(value)}</Typography>
      </Col>
    )
  }

  const onSelectCell = (type: string) => () =>
    cond([
      [
        equals('pack'),
        async () => {
          await onPackParcels(item.id, 'pack')
          onSelectId(item.id)
          setOnPageDialog(GET_ENUMS.alertDialog.shipmentParcel, true)
        },
      ],
      [
        equals('request'),
        async () => {
          item.isShipmentNumberRequired
            ? setOnPageDialog(GET_ENUMS.dialog.trackNumberNL, true)
            : await onShipmentParcels(item.id)
          onSelectId(item.id)
        },
      ],
      [
        equals('show-qr'),
        () => {
          onSelectId(item.id)
          setOnPageDialog(GET_ENUMS.dialog.showQRCode, true)
        },
      ],
      [
        equals('reject'),
        () => {
          onSelectId(item.id)
          setOnPageDialog(GET_ENUMS.alertDialog.rejectParcel, true)
        },
      ],
      [
        equals('restore'),
        async () => {
          await onRestoreParcel(item.id)
        },
      ],
      [
        equals('unpack'),
        async () => {
          if (getReadyStatus(item)) {
            onSelectId(item.id)
            setOnPageDialog(GET_ENUMS.alertDialog.unpackParcel, true)
          } else {
            await onPackParcels(item.id, 'unpack')
          }
        },
      ],
    ])(type)

  const articlesActions = getParcelsActions({ onSelectCell, t, parcel: item })

  return (
    <SwipeAction rightActions={articlesActions}>
      <ListItem size='sm'>
        <Link href={pathToDetails}>
          <Row align='middle' gutter={rowGutters}>
            {renderContent(4, item.id)}
            <Col span={8}>
              <Typography variant='secondary'>{WrappedNodeDefault(item?.status?.label)}</Typography>
              <Typography variant='secondary' color='gray-6'>
                {WrappedNodeDefault(item?.type?.label)}
              </Typography>
            </Col>
            {renderContent(3, currencyFormatNumber(item.weight))}
            {renderContent(3, item.quantity)}
            {renderContent(6, currencyFormatNumber(item.amount))}
          </Row>
        </Link>
      </ListItem>
    </SwipeAction>
  )
}
