import { CellText, CellDate, CellStatus } from 'src/components/common'

import { ReturnsInfiniteType } from 'src/models'

type VisitReturnsModelProps = {
  data: ReturnsInfiniteType
  loadingFetch: boolean
}

export const visitReturnsModel = ({ data, loadingFetch }: VisitReturnsModelProps) => {
  return data.pages.map((page) => {
    return page.items.map((item) => {
      const Return = () => CellText({ value: item.id, loadingFetch })
      const Article = () => CellText({ value: item.quantity, loadingFetch })
      const Status = () => CellStatus({ value: item?.status, loadingFetch, centered: true })
      const Date = () => CellDate({ value: item.createdAt, loadingFetch })

      return {
        id: item.id,
        return: Return,
        article: Article,
        status: Status,
        date: Date,
      }
    })
  })
}
