import { z } from 'zod'

import { hasID, metaSchema } from './Base'
import { HttpRequestParams } from './index'
import { selectSchema, selectListSchema } from './StaticData'

export const imageUploadSchema = z.object({
  key: z.union([z.string(), z.number()]).optional(),
  url: z.string(),
  thumbnailUrl: z.string().optional(),
  extra: z.custom(),
})

export const claimBaseSchema = z.object({
  article: z.string(),
  images: imageUploadSchema.array(),
  status: z.enum(['in-progress', 'confirmed', 'refused']),
  cause: selectSchema,
  orderId: z.number().nullable(),
  orderProductId: z.number(),
  additionalCause: selectSchema,
  quantity: selectSchema,
  price: z.number(),
  amount: z.number(),
  comment: z.string(),
  isEditable: z.boolean(),
  isImagesEditable: z.boolean(),
  isDeletable: z.boolean(),
  isOrderPaid: z.boolean(),
})

export const autocompleteOrderSchema = z.object({
  orderProductId: z.number(),
  article: z.string(),
  orderId: z.number(),
  articleType: z.string(),
  brand: z.string(),
  price: z.number(),
  isAvailableToReturn: z.boolean(),
  isOrderPaid: z.boolean(),
  causes: selectListSchema,
  causesNonReturn: selectListSchema,
})

export const claimSchema = claimBaseSchema.merge(hasID)

export const claimListSchema = z.object({
  items: claimSchema.array(),
  meta: metaSchema,
})

export const claimsSearchSchema = z.object({
  items: autocompleteOrderSchema.array(),
  meta: metaSchema,
})

export const claimOptionsSchema = z.object({
  causes: selectListSchema,
  quantities: selectListSchema,
})

export const claimTotalPriceSchema = z.object({
  total: z.number(),
})

export const autocompletePropsSchema = z.object({
  data: z
    .object({
      claims: claimSchema.array().optional(),
      products: autocompleteOrderSchema.array().optional(),
    })
    .nullable(),
  loadingClaimsSearchFetch: z.boolean(),
  loadingClaimsFetch: z.boolean(),
})

export type AutocompleteOrderType = z.infer<typeof autocompleteOrderSchema>
export type AutocompletePropsType = z.infer<typeof autocompletePropsSchema>

export type ClaimType = z.infer<typeof claimSchema>
export type ClaimsType = z.infer<typeof claimListSchema>
export type ClaimsSearchType = z.infer<typeof claimsSearchSchema>
export type ClaimOptionsType = z.infer<typeof claimOptionsSchema>
export type ClaimTotalPriceType = z.infer<typeof claimTotalPriceSchema>

export type ClaimsParams = HttpRequestParams<{
  id: number
  data: ClaimType
}>

export enum ClaimStatusEnum {
  inProgress = 'in-progress',
  confirmed = 'confirmed',
  refused = 'refused',
}

export type ClaimsActionsType = 'delete' | 'print'
