import { useRouter } from 'next/router'
import { equals } from 'ramda'
import { useCallback, useState } from 'react'

import { FillDataClaimsForm } from 'src/components/common'
import { Loader } from 'src/components/ui'

import { ClaimStatusEnum, ClaimType, FormSubmitParams, FormUpdateClaimType } from 'src/models'

import {
  useClaimsAdditionalCausesFetch,
  useClaimsCausesFetch,
  useClaimsCreate,
  useClaimsQuantityFetch,
  useClaimsTotalPriceFetch,
  useClaimsUpdate,
} from 'src/bus/claims'

import { getParamsFromQueryStr, isNotNilOrNotEmpty, orNull } from 'src/utils'

type ClaimsFillDataProps = {
  data: ClaimType | null
  loadingFetch: boolean
  onNextStep: () => void
}

export const ClaimsFillData = ({ data = null, loadingFetch = false, onNextStep }: ClaimsFillDataProps) => {
  const router = useRouter()
  const initialFormData = getParamsFromQueryStr(router.query)

  const { onCreateClaim, loadingClaimsCreate } = useClaimsCreate(onNextStep)
  const { onUpdateClaim, loadingClaimsUpdate } = useClaimsUpdate(onNextStep)
  const { claimsCauses, loadingClaimsCausesFetch } = useClaimsCausesFetch()
  const { additionalCauses, loadingAdditionalCausesFetch } = useClaimsAdditionalCausesFetch()

  const [selectedCauseId, setSelectedCauseId] = useState<number>()
  const [selectedQuantityId, setSelectedQuantityId] = useState<number>()

  const { claimsQuantity, loadingClaimsQuantityFetch } = useClaimsQuantityFetch(selectedCauseId)
  const { claimsTotalPrice, loadingClaimsTotalPriceFetch } = useClaimsTotalPriceFetch(
    selectedQuantityId,
    selectedCauseId,
  )

  const createdClaim = isNotNilOrNotEmpty(data)

  const onSelectCauseId = useCallback((id: number) => setSelectedCauseId(id), [])
  const onSelectQuantityId = useCallback((id: number) => setSelectedQuantityId(id), [])

  // TODO get this values from store
  const initialValues: FormUpdateClaimType = createdClaim
    ? {
        orderId: Number(data?.orderId || 0),
        amount: Number(data?.amount || 0),
        orderProductId: Number(data?.orderProductId || 0),
        cause: data?.cause || null,
        additionalCause: data?.additionalCause || null,
        quantity: data?.quantity || null,
        comment: data?.comment || '',
        status: data?.status || '',
      }
    : {
        orderId: Number(initialFormData.orderId || 0),
        amount: Number(initialFormData.amount || 0),
        orderProductId: Number(initialFormData.orderProductId || 0),
        cause: null,
        additionalCause: null,
        quantity: null,
        comment: '',
        status: '',
      }

  const onSubmit = async (data: FormSubmitParams<FormUpdateClaimType>) => {
    if (createdClaim) {
      if (!equals(initialValues, data.values)) {
        await onUpdateClaim(data)
      } else {
        data.values.status === ClaimStatusEnum.refused ? router.back() : onNextStep()
      }
    } else {
      const result = await onCreateClaim(data)

      if (result) {
        setSelectedCauseId(undefined)
        setSelectedQuantityId(undefined)
      }
    }
  }

  return (
    <>
      {orNull(loadingFetch, <Loader type='fixed' />)}
      <FillDataClaimsForm
        isNotEditable={createdClaim ? !data?.isEditable : false}
        createdClaim={createdClaim}
        quantity={claimsQuantity}
        price={claimsTotalPrice}
        causes={claimsCauses}
        additionalCauses={additionalCauses}
        loadingPriceFetch={loadingClaimsTotalPriceFetch}
        loadingQuantityFetch={loadingClaimsQuantityFetch}
        loadingAdditionalCausesFetch={loadingAdditionalCausesFetch}
        onSelectCauseId={onSelectCauseId}
        onSelectQuantityId={onSelectQuantityId}
        initialValues={initialValues}
        loadingCausesFetch={loadingClaimsCausesFetch}
        loadingFetch={loadingFetch}
        loadingUpdate={loadingClaimsCreate || loadingClaimsUpdate}
        onSubmit={onSubmit}
      />
    </>
  )
}
