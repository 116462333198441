import { Locale } from 'antd/lib/locale'
import de from 'antd/lib/locale/de_DE'
import en from 'antd/lib/locale/en_US'
import fr from 'antd/lib/locale/fr_FR'
import nl from 'antd/lib/locale/nl_NL'
import dayjs from 'dayjs'
import 'dayjs/locale/de'
import 'dayjs/locale/en'
import 'dayjs/locale/fr'
import 'dayjs/locale/nl'
import { GetServerSidePropsContext, GetStaticPropsContext } from 'next'
// @ts-ignore
import NextI18nConfig from 'nextI18nConfig'

import { initialLocale } from 'src/utils/constants'

export const antdLocales = (locale: string) => {
  dayjs.locale(locale)

  const ANTD_LOCALES_MAP: { [key: string]: Locale } = {
    en,
    fr,
    de,
    nl,
  }

  return ANTD_LOCALES_MAP[locale]
}

export const getLocaleFromCtx = ({
  locale = NextI18nConfig.i18n.defaultLocale || initialLocale,
}: GetServerSidePropsContext | GetStaticPropsContext): string => locale
