import { TCountryCodes, TCurrencyConfig } from 'src/models'

export const currencyConfig: Record<string, TCurrencyConfig> = {
  [TCountryCodes.EN]: {
    symbol: '£',
    currencyIso: 'GBP',
    decimal: ',',
    separator: '',
    precision: 2,
    pattern: '!#',
    negativePattern: '-!#',
  },
  [TCountryCodes.FR]: {
    symbol: '€',
    currencyIso: 'EUR',
    decimal: ',',
    separator: ' ',
    precision: 2,
    pattern: '# !',
    negativePattern: '-# !',
  },
  [TCountryCodes.DE]: {
    symbol: '€',
    currencyIso: 'EUR',
    decimal: ',',
    separator: '.',
    precision: 2,
    pattern: '# !',
    negativePattern: '-# !',
  },
  [TCountryCodes.NL]: {
    symbol: '€',
    currencyIso: 'EUR',
    decimal: ',',
    separator: '.',
    precision: 2,
    pattern: '# !',
    negativePattern: '-# !',
  },
}
