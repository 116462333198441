import useDeepCompareEffect from 'use-deep-compare-effect'

import { FiltersDrawer } from 'src/components/common'

import { FilterStaticDataType, FilterStaticEnum, FilterStaticType, FormSubmitParams } from 'src/models'

import { useParcelsFilters, useParcelsListFetch, useParcelsTotalFetch } from 'src/bus/parcels'

import { useUrlQuery } from 'src/hooks'

import { useDrawer, usePageUrlContext } from 'src/contexts'

import { GET_ENUMS, isNotNilOrNotEmpty } from 'src/utils'

export const ParcelsFilters = () => {
  const { initialFilters, setStorageFilters } = usePageUrlContext()
  const { setOnPageDrawer } = useDrawer()

  const { onUpdateQuery } = useUrlQuery()
  const { parcelsFilters, loadingParcelsFiltersFetch } = useParcelsFilters()
  const { onRefetchParcelsList, parcelsFiltersStaticInitialValues, loadingParcelsListFetch } =
    useParcelsListFetch(initialFilters)
  const { onRefetchParcelsTotal, loadingParcelsTotalFetch } = useParcelsTotalFetch(initialFilters)

  const filterStaticItems: FilterStaticDataType = {
    parcelsStatus: {
      name: FilterStaticEnum.parcelsStatus,
      options: parcelsFilters.parcelsStatus,
      isMulti: true,
    },
    parcelsType: {
      name: FilterStaticEnum.parcelsType,
      options: parcelsFilters.parcelsType,
    },
    date: {
      name: FilterStaticEnum.date,
    },
  }

  useDeepCompareEffect(() => {
    if (isNotNilOrNotEmpty(initialFilters.filter)) {
      return
    }

    setStorageFilters({ parcelsStatus: ['1', '2', '3'] })
  }, [initialFilters])

  const onSubmit = async (data: FormSubmitParams<FilterStaticType>) => {
    const { filterData, filter } = onUpdateQuery({
      filtersValues: data.values,
      filterItems: filterStaticItems,
    })

    setStorageFilters(filter)
    await onRefetchParcelsList(filterData)
    await onRefetchParcelsTotal(filterData)
    setOnPageDrawer(GET_ENUMS.drawer.filters, false)
  }

  return (
    <FiltersDrawer
      onSubmit={onSubmit}
      filterItemsSet={filterStaticItems}
      buttons={['apply', 'reset']}
      loadingSubmit={loadingParcelsListFetch || loadingParcelsTotalFetch}
      initialValues={parcelsFiltersStaticInitialValues.filter}
      loadingStaticDataFetch={loadingParcelsFiltersFetch}
    />
  )
}
