import { useTranslation } from 'next-i18next'

import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  Typography,
} from 'src/components/ui'

import { useParcelsPack } from 'src/bus/parcels'

export const AlertDialogUnpackParcel = ({ id }: { id?: number }) => {
  const { t } = useTranslation()
  const { onPackParcels, loadingParcelsPack } = useParcelsPack()

  const onConfirm = async () => {
    await onPackParcels(Number(id), 'unpack')
  }

  return (
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>
          <Typography variant='title' color='gray-10'>
            {t('parcels:modal_unpack.title')}
          </Typography>
        </AlertDialogTitle>
        <AlertDialogDescription>
          <Typography variant='secondary' color='gray-7'>
            {t('parcels:modal_unpack.description')}
          </Typography>
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel variant='outline' className='w-full'>
          {t('buttons:no')}
        </AlertDialogCancel>
        <Button onClick={onConfirm} block loading={loadingParcelsPack} asChild>
          <AlertDialogAction>{t('buttons:yes')}</AlertDialogAction>
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  )
}
