import { useMutation, useQueryClient } from '@tanstack/react-query'
import toFormData from 'json-form-data'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { book } from 'src/init/book'

import { FormReturnSignature, FormSubmitParams, HttpErrorType } from 'src/models'

import { claimsKeys } from 'src/bus/claims'
import { useCustomersDetailsFetch } from 'src/bus/customers'
import { returnsKeys, returnsService } from 'src/bus/returns'
import { useVisitsFetch } from 'src/bus/visits'

import { useToast } from 'src/hooks'

import { dataURLtoFile, fillServerErrorsToForm, queryFetcher } from 'src/utils'

type ReturnsConfirmCb = (data: FormSubmitParams<FormReturnSignature>) => void

type ReturnsConfirmType = {
  loadingReturnsConfirm: boolean
  onConfirmReturn: ReturnsConfirmCb
}

export const useReturnsConfirm = (): ReturnsConfirmType => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const { t } = useTranslation()
  const showToast = useToast()
  const { visitFirst } = useVisitsFetch()
  const { customerId } = useCustomersDetailsFetch()

  const visitId = visitFirst?.id
  const { id } = router.query
  const params = { id, customerId }

  const confirmReturnQuery = useMutation<void, HttpErrorType, FormData>({
    mutationKey: returnsKeys.lists(),
    mutationFn: (data) =>
      queryFetcher({
        name: 'confirmReturn',
        fetcher: returnsService.confirmReturn,
        fetcherPayload: { params, data },
      }),
  })

  const onConfirmReturn = useCallback<ReturnsConfirmCb>(
    (data) => {
      const file = dataURLtoFile(data.values.signature, 'signature.png')
      const jsonData = toFormData({ signature: file, customerId })

      confirmReturnQuery.mutate(jsonData, {
        onSuccess: async () => {
          showToast.success({ title: t('notifications:return_approve') })
          await router.push({
            pathname: `${book.visits}/${visitId}${book.visitPlan}`,
            query: { customerId },
          })
        },
        onError: (error) => {
          const errs = (error as HttpErrorType).errors
          errs && fillServerErrorsToForm(errs, data.acts.setError)
        },
        onSettled: async () => {
          await queryClient.invalidateQueries({ queryKey: returnsKeys.lists() })
          await queryClient.invalidateQueries({ queryKey: returnsKeys.totals() })
          await queryClient.invalidateQueries({ queryKey: claimsKeys.lists() })
          await queryClient.invalidateQueries({ queryKey: claimsKeys.searchLists() })
        },
      })
    },
    [confirmReturnQuery, queryClient, customerId, router, showToast, t, visitId],
  )

  return {
    loadingReturnsConfirm: confirmReturnQuery.isPending,
    onConfirmReturn,
  }
}
