import { StaticDataItemType, StaticDataKeys } from 'src/models'

import { useStaticDataFetch } from 'src/bus/staticData'

type UseNavigationFetchType = {
  loadingNavigationFetch: boolean
  navigator: StaticDataItemType[]
}

export const useNavigationFetch = (): UseNavigationFetchType => {
  const staticDataKeys = [StaticDataKeys.navigator]

  const { staticData, loadingStaticDataFetch } = useStaticDataFetch(staticDataKeys)

  return {
    loadingNavigationFetch: loadingStaticDataFetch,
    navigator: staticData?.navigator || [],
  }
}
