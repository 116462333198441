import { useRouter } from 'next/router'

import { Returns, FiltersDynamicForm } from 'src/components/common'

import { FormSubmitParams, FiltersDynamicType } from 'src/models'

import { useReturnsListFetch } from 'src/bus/returns'

import { useUrlQuery } from 'src/hooks'

import { getParamsFromQueryStr } from 'src/utils'

export const ViewVisitReturns = () => {
  const router = useRouter()
  const { onUpdateQuery } = useUrlQuery()
  const initialFilterData = getParamsFromQueryStr(router.query)

  const { returnsList, returnsListHasMore, onFetchMoreReturnsList, loadingReturnsListFetch, onRefetchReturnsList } =
    useReturnsListFetch()

  const onSubmit = async (data: FormSubmitParams<FiltersDynamicType>) => {
    const { filterData } = onUpdateQuery({
      filtersValues: data.values,
      filterItems: {},
    })
    await onRefetchReturnsList(filterData)
  }

  return (
    <div className='flex h-full flex-col py-2' data-test-id='returns'>
      <div className='bg-gray-1 px-2 pt-2'>
        <FiltersDynamicForm onSubmit={onSubmit} initialValues={initialFilterData?.filter} />
      </div>
      <Returns
        data={returnsList}
        loadingFetch={loadingReturnsListFetch}
        onFetchMore={onFetchMoreReturnsList}
        hasMore={returnsListHasMore}
      />
    </div>
  )
}
