import { Collapse } from 'antd-mobile'
import { CollapseProps as antMobileCollapseProps } from 'antd-mobile/es/components/collapse'
import { ReactElement } from 'react'
import { cn } from 'utils/helpers'

import st from './Styles.module.css'

type CollapseProps = antMobileCollapseProps & {
  children?: string | ReactElement | ReactElement[]
  className?: string
  activeKey?: string
}

export const Accordion = (props: CollapseProps) => {
  const { children, className, activeKey } = props

  const accordionStyle = cn(st.accordion, className)

  return (
    <Collapse className={accordionStyle} accordion defaultActiveKey={activeKey}>
      {children}
    </Collapse>
  )
}
