import type { TFunction } from 'i18next'
import { z } from 'zod'

import { messages } from 'src/utils'

export const trackNumber = {
  shape: {
    shipmentNumber: '',
  },
  schema: (t: TFunction) => {
    return z.object({
      shipmentNumber: z.string().trim().min(1, t(messages.trackNumber.shipmentNumber.required)),
    })
  },
}
