import { ToastOptions } from 'react-toastify'

import { toastEnhanced } from 'src/components/common'
import { ToastEnhancedContent } from 'src/components/common/Toaster'

type ToastCbType = (content: ToastEnhancedContent, options?: ToastOptions) => void

type ToastEnhancedHookType = () => {
  success: ToastCbType
  error: ToastCbType
  info: ToastCbType
  warn: ToastCbType
}

export const useToast: ToastEnhancedHookType = () => {
  return {
    success: (content, options) => {
      toastEnhanced(content, { ...options, type: 'success' })
    },
    error: (content, options) => {
      toastEnhanced(content, { ...options, type: 'error' })
    },
    info: (content, options) => {
      toastEnhanced(content, { ...options, type: 'info' })
    },
    warn: (content, options) => {
      toastEnhanced(content, { ...options, type: 'warning' })
    },
  }
}
