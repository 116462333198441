import type { TFunction } from 'i18next'
import { z } from 'zod'

import { messages } from 'src/utils'

export const moveParcelsItems = {
  shape: {
    quantity: 1,
  },
  schema: (t: TFunction) => {
    return z.object({
      quantity: z.number().gte(1, t(messages.quantity.required)),
    })
  },
}
