import { SearchBar as AntMobileSearchBar } from 'antd-mobile'
import { SearchBarProps as AntMobileSearchBarProps, SearchBarRef } from 'antd-mobile/es/components/search-bar'
import { useTranslation } from 'next-i18next'
import { forwardRef } from 'react'
import { cn } from 'utils/helpers'

import { Dialog, DialogViewsSwitcher } from 'src/components/common'
import { Button, Icon, Loader } from 'src/components/ui'

import { ConvertCodeType } from 'src/models'

import { useBarcodeScanner } from 'src/hooks'

import { useDialog } from 'src/contexts'

import { GET_ENUMS, orNull } from 'src/utils'

import st from './Styles.module.css'

type SearchBarProps = AntMobileSearchBarProps & {
  hasDisabled?: boolean
  advancedIcon?: string
  advancedFn?: (data: ConvertCodeType) => void
}

export const SearchBar = forwardRef<SearchBarRef, SearchBarProps>(
  (
    { hasDisabled, advancedIcon = '', icon = <Icon name='Search' color='gray-7' />, advancedFn, onChange, ...rest },
    ref,
  ) => {
    const { t } = useTranslation()
    const { onPageDialogs, setOnPageDialog } = useDialog()
    const { onOpenScanner, loadingConvertCode } = useBarcodeScanner({ onChange, advancedFn })

    const classNames = cn(st['search-bar'], {
      [st['disabled']]: hasDisabled,
      [st['icon']]: advancedIcon ? false : icon,
    })

    return (
      <div className={classNames}>
        <Dialog
          open={onPageDialogs[GET_ENUMS.dialog.info]?.visible}
          onOpenChange={(open) => setOnPageDialog(GET_ENUMS.dialog.info, open)}
        >
          <DialogViewsSwitcher
            name={GET_ENUMS.dialog.info}
            params={{
              title: t('modal_detail_not_found.title'),
              description: t('modal_detail_not_found.description'),
            }}
          />
        </Dialog>
        {orNull(loadingConvertCode, <Loader />)}
        <AntMobileSearchBar {...rest} onChange={onChange} icon={advancedIcon ? null : icon} ref={ref} />
        {orNull(
          advancedIcon,
          <div className={st['search-bar-button']}>
            <div className={st['search-bar-button-icon']} onClick={onOpenScanner}>
              <Button variant='ghost' size='icon-sm' asChild>
                <Icon name={advancedIcon} color='gray-7' />
              </Button>
            </div>
          </div>,
        )}
      </div>
    )
  },
)

SearchBar.displayName = 'SearchBar'
