import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'next-i18next'
import { replace } from 'ramda'
import { useForm } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { Button, Form, FormControl, FormField, FormFields, FormItem, FormMessage, Typography } from 'src/components/ui'

import {
  FormDeleteParcelsArticleEnum,
  FormDeleteParcelsArticleType,
  FormSubmitFn,
  ParcelsArticleType,
} from 'src/models'

import { formShapes } from 'src/bus/forms'

import { messages } from 'src/utils'

type MoveParcelsItemsFormProps = {
  onSubmit?: FormSubmitFn<FormDeleteParcelsArticleType>
  loadingCreate?: boolean
  loadingFetch?: boolean
  parcelsArticlesDetails: ParcelsArticleType | null
  initialValues: Partial<ParcelsArticleType> | null
}

export const DeleteParcelsArticleForm = ({
  onSubmit,
  loadingCreate,
  loadingFetch,
  initialValues = null,
  parcelsArticlesDetails,
}: MoveParcelsItemsFormProps) => {
  const { t } = useTranslation()

  const formProps = useForm<FormDeleteParcelsArticleType>({
    defaultValues: { ...formShapes.deleteParcelsArticle.shape, ...initialValues },
    resolver: zodResolver(formShapes.deleteParcelsArticle.schema(t)),
    mode: 'onChange',
  })

  const { control, handleSubmit, setError, reset } = formProps

  useDeepCompareEffect(() => {
    if (initialValues) {
      reset(initialValues)
    }
  }, [initialValues, reset])

  const onSubmitForm = handleSubmit(async (values) => {
    await onSubmit?.({ values, acts: { setError, reset } })
  })

  const quantity = parcelsArticlesDetails?.quantity

  const title = replace(/^, /, '-, ', `${parcelsArticlesDetails?.title}, ${parcelsArticlesDetails?.article}`)

  return (
    <Form {...formProps}>
      <FormFields.FormGeneral
        onSubmit={onSubmitForm}
        footer={
          <Button block type='submit' loading={loadingCreate} disabled={loadingFetch}>
            {t('buttons:delete')}
          </Button>
        }
      >
        <div className='mb-5 flex items-center justify-between gap-2'>
          <div className='basis-8/12'>
            <Typography variant='secondary'>{title}</Typography>
            <Typography variant='caption' color='gray-7'>
              {t('parcels:quantity_items', { qty: quantity }) as string}
            </Typography>
          </div>
          <div className='basis-4/12'>
            <FormField
              control={control}
              name={FormDeleteParcelsArticleEnum.quantity}
              render={({ field }) => (
                <FormItem gutter='none'>
                  <FormControl>
                    <FormFields.InputStepper {...field} max={quantity || undefined} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <FormField
          control={control}
          name={FormDeleteParcelsArticleEnum.comment}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormFields.InputTextArea
                  {...field}
                  placeholder={t(messages.deleteArticle.comment.placeholder)}
                  rows={5}
                />
              </FormControl>
              <FormMessage className='absolute' />
            </FormItem>
          )}
        />
      </FormFields.FormGeneral>
    </Form>
  )
}
