import { Control } from 'react-hook-form'

import {
  FormFields,
  Skeleton,
  Typography,
  buttonVariants,
  FormItem,
  FormControl,
  FormMessage,
  FormField,
} from 'src/components/ui'

import { FormCancelVisitEnum, StaticDataItemType } from 'src/models'

import { cn, ternary } from 'src/utils'

type GroupVisitCancelReasonFieldsProps = {
  control: Control<any>
  loading?: boolean
  options?: StaticDataItemType[]
  hasDisabled?: boolean
}

export const GroupVisitCancelReasonFields = ({
  control,
  options = [],
  loading = false,
  hasDisabled = false,
}: GroupVisitCancelReasonFieldsProps) => {
  return (
    <FormField
      control={control}
      name={FormCancelVisitEnum.reason}
      render={({ field }) => (
        <FormItem>
          <FormControl>
            <FormFields.InputRadioGroup {...field}>
              {ternary(
                loading,
                <>
                  <Skeleton className='mb-2 h-10' />
                  <Skeleton className='mb-2 h-10' />
                  <Skeleton className='mb-2 h-10' />
                  <Skeleton className='h-10' />
                </>,
                <>
                  {options.map((item) => {
                    return (
                      <FormFields.InputRadio
                        key={item?.id}
                        value={item?.value}
                        hasDisabled={hasDisabled}
                        className={cn(
                          buttonVariants({
                            variant: 'card',
                            class: cn('h-auto justify-start whitespace-normal [&:not(:last-child)]:mb-2', {
                              'pointer-events-none border-disabled-bg bg-disabled-bg text-disabled-text': hasDisabled,
                            }),
                          }),
                        )}
                      >
                        <Typography variant='secondary'>{item?.label}</Typography>
                      </FormFields.InputRadio>
                    )
                  })}
                </>,
              )}
            </FormFields.InputRadioGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
