import { ImageUploader } from 'antd-mobile'
import { ImageUploadItem } from 'antd-mobile/es/components/image-uploader'
import Compressor from 'compressorjs'
import { cn } from 'utils/helpers'

import { Icon } from 'src/components/ui/Icon'
import { Typography } from 'src/components/ui/Typography'

import { useToast } from 'src/hooks'

import st from './Styles.module.css'

const MAX_IMAGE_UPLOAD_SIZE = 10000000 // 10mb

type InputImageUploaderProps = {
  maxCount?: number
  multiple?: boolean
  onChange: () => void
  value: ImageUploadItem[]
  hasDisabled?: boolean
  onDelete?: (item: ImageUploadItem) => Promise<boolean> | void
  accept?: string
  placeholder?: string
}

export const InputImageUploader = ({
  maxCount = 10,
  multiple = true,
  accept = 'image/jpeg, image/jpg, image/png',
  hasDisabled = false,
  onDelete,
  placeholder = 'Upload',
  ...rest
}: InputImageUploaderProps) => {
  const showToast = useToast()

  const beforeUpload = (file: File) => {
    return new Promise<File | null>(
      (resolve) =>
        new Compressor(file, {
          quality: 0.6, // 0.6 can be used, but it's not recommended to go below.
          success: (compressedFile: File) => {
            if (compressedFile.size > MAX_IMAGE_UPLOAD_SIZE) {
              showToast.error({ title: 'Image size should be less than 10mb' })
              return null
            }

            resolve(compressedFile)
          },
          error: () => resolve(null),
        }),
    )
  }

  const upload = (file: File) => Promise.resolve({ url: URL.createObjectURL(file), extra: file })

  const uploaderClasses = cn(st['image-uploader'], {
    [st['image-uploader-disabled']]: hasDisabled,
  })

  return (
    <div className={uploaderClasses}>
      <ImageUploader
        {...rest}
        accept={accept}
        maxCount={maxCount}
        onDelete={onDelete}
        multiple={multiple}
        upload={upload}
        beforeUpload={beforeUpload}
        // capture
      >
        <div className={st['image-uploader-inner']}>
          <Icon name='PlusCircle' className={st['image-uploader-img']} color='gray-3' />
          <Typography>{placeholder}</Typography>
        </div>
      </ImageUploader>
    </div>
  )
}
