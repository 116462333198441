import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import { useCallback, useState } from 'react'

import { FormMoveParcelsItemsType, FormSubmitParams, HttpErrorType } from 'src/models'

import { parcelsKeys, useParcelsRecoverMutation } from 'src/bus/parcels'

import { useToast } from 'src/hooks'

import { useDialog } from 'src/contexts'

import { GET_ENUMS } from 'src/utils'

type RecoverParcelsCb = (data: FormSubmitParams<FormMoveParcelsItemsType>, id?: number) => void

type UseParcelsRecoverType = {
  loadingParcelsRecover: boolean
  onRecoverParcels: RecoverParcelsCb
}

export const useParcelsRecover = (): UseParcelsRecoverType => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { onRecoverParcelsMutation } = useParcelsRecoverMutation()
  const showToast = useToast()
  const { setOnPageDialog } = useDialog()

  const [isLoading, setIsLoading] = useState(false)

  const onRecoverParcels = useCallback<RecoverParcelsCb>(
    async (data, id) => {
      try {
        setIsLoading(true)

        const quantity = data.values.quantity

        await onRecoverParcelsMutation.mutateAsync({
          data: { quantity },
          params: { id: String(id) },
        })

        setOnPageDialog(GET_ENUMS.dialog.recoverArticle, false)
        showToast.success({ title: t('notifications:parcel_article_recover') })
        await queryClient.refetchQueries({ queryKey: parcelsKeys.articles() })
        await queryClient.refetchQueries({ queryKey: parcelsKeys.lists() })
      } catch (error) {
        const err = error as HttpErrorType
        const errs = err.errors

        if (errs) {
          const [[title, body]] = Object.entries(errs)
          showToast.error({ title, body: body.desc })
        }
      } finally {
        setIsLoading(false)
      }
    },
    [setOnPageDialog, showToast, onRecoverParcelsMutation, t, queryClient],
  )

  return {
    loadingParcelsRecover: isLoading,
    onRecoverParcels,
  }
}
