import { z } from 'zod'

import { HttpServerRequestParams, BaseDataInfiniteType } from 'src/models'

import { hasID, metaSchema } from './Base'
import { selectSchema } from './StaticData'

export type ArticlesType = 'dirty' | 'clear'
export type ParcelsPackType = 'pack' | 'unpack'

export const parcelBaseSchema = z.object({
  createdAt: z.number(),
  weight: z.number().nullable(),
  quantity: z.number().nullable(),
  countArticles: z.number(),
  countCheckedArticles: z.number(),
  amount: z.number(),
  status: selectSchema,
  type: selectSchema,
  isChecked: z.boolean(),
  isAvailableCheck: z.boolean(),
  isAvailablePack: z.boolean(),
  isAvailableUnpack: z.boolean(),
  isAvailableQrCode: z.boolean(),
  isAvailableShipmentRequest: z.boolean(),
  shipmentNumber: z.string(),
  trackingLink: z.string(),
  isShipmentNumberRequired: z.boolean(),
})

export const parcelSchema = parcelBaseSchema.merge(hasID)

export const parcelArticleBaseSchema = z.object({
  article: z.string(),
  title: z.string(),
  parcelId: z.number().nullable(),
  weight: z.number().nullable(),
  quantity: z.number(),
  amount: z.number(),
  type: selectSchema,
  status: selectSchema,
  isChecked: z.boolean(),
  isAvailableMoveOut: z.boolean(),
  isAvailableMoveToParcel: z.boolean(),
  isAvailableReplace: z.boolean(),
})

export const parcelQRCodeSchema = z.object({
  content: z.string(),
})

export const parcelCheckSchema = z.object({
  available: z.boolean(),
})

export const parcelArticleSchema = parcelArticleBaseSchema.merge(hasID)

export const parcelTotalSchema = z.object({
  weight: z.number().nullable(),
  quantity: z.number().nullable().optional(),
  amount: z.number().nullable(),
})

export const parcelReplaceSchema = z.object({
  id: z.number(),
  type: selectSchema,
  status: selectSchema,
  weight: z.number(),
  quantity: z.number(),
})

export const parcelListSchema = z.object({
  items: parcelSchema.array(),
  meta: metaSchema,
})

export const parcelArticleListSchema = z.object({
  items: parcelArticleSchema.array(),
  meta: metaSchema,
})

export const parcelReplaceListSchema = z.object({
  items: parcelReplaceSchema.array(),
})

export type ParcelsCheckType = z.infer<typeof parcelCheckSchema>
export type ParcelsQRCodeType = z.infer<typeof parcelQRCodeSchema>
export type ParcelType = z.infer<typeof parcelSchema>
export type ParcelsType = z.infer<typeof parcelListSchema>
export type ParcelsInfiniteType = BaseDataInfiniteType<ParcelType>
export type ParcelsTotalType = z.infer<typeof parcelTotalSchema>
export type ParcelsArticleType = z.infer<typeof parcelArticleSchema>
export type ParcelsArticlesType = z.infer<typeof parcelArticleListSchema>
export type ParcelsReplaceType = z.infer<typeof parcelReplaceSchema>
export type ParcelsReplacesType = z.infer<typeof parcelReplaceListSchema>
export type ParcelsArticlesInfiniteType = BaseDataInfiniteType<ParcelsArticleType>

export type ParcelsCheckComplianceState = {
  onCheckCompliance: (value: boolean) => void
  checkCompliance: boolean
}

export type ParcelsParams = HttpServerRequestParams
