export const ordersState = {
  ordersArticles: {
    pages: [
      {
        items: [],
        meta: null,
      },
    ],
  },
  ordersDetails: null,
}

export const ordersKeys = {
  all: ['orders'],
  ordersDetails: () => [...ordersKeys.all, 'details'],
  ordersDetail: (params: any) => [...ordersKeys.ordersDetails(), 'detail', params],
  orderOptions: (customerId?: number) => [...ordersKeys.all, 'order-options', customerId],
  orderProductOptions: (ids: Record<string, number | undefined>) => [...ordersKeys.all, 'order-product-options', ids],
  articles: () => [...ordersKeys.all, 'articles'],
  articlesList: (params: any) => [...ordersKeys.articles(), 'article-list', params],
  deliveries: () => [...ordersKeys.all, 'deliveries'],
  deliveriesList: (params: any) => [...ordersKeys.deliveries(), 'deliveries-list', params],
}
