import { useInfiniteQuery, keepPreviousData } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import QS from 'qs'
import { useCallback } from 'react'

// Models
import {
  FilterStaticFilterQueryParamsType,
  TRefetchFilterStaticCb,
  VisitsHistoryInfiniteType,
  VisitsHistoryType,
} from 'src/models'

import { visitsHistoryService, visitsHistoryState, visitsHistoryKeys } from 'src/bus/visitsHistory'

import { convertSortParametersToSortingRules, getNextPageParam, getParamsFromQueryStr, queryFetcher } from 'src/utils'

type UseVisitsHistoryFetchType = {
  visitsHistory: VisitsHistoryInfiniteType
  visitsHistoryHasMore: boolean
  onFetchMoreVisitsHistory: () => void
  loadingVisitsHistoryFetch: boolean
  onRefetchVisitsHistory: TRefetchFilterStaticCb
  visitsFiltersStaticInitialValues: FilterStaticFilterQueryParamsType
  visitsHistorySortByInitialValues: { sort?: { [key: string]: 'desc' | 'asc' } }
}

export const useVisitsHistoryFetch = (): UseVisitsHistoryFetchType => {
  const router = useRouter()

  const initialQuery = getParamsFromQueryStr(router.query)

  const visitsHistorySortBy = convertSortParametersToSortingRules(initialQuery.sort)

  const fetchVisitsHistoryProps = useInfiniteQuery<VisitsHistoryType>({
    queryKey: visitsHistoryKeys.history(initialQuery),
    queryFn: ({ pageParam }) =>
      queryFetcher({
        name: 'fetchVisitsHistory',
        fetcher: visitsHistoryService.fetchVisitsHistory,
        fetcherPayload: { params: { ...initialQuery, page: pageParam } },
      }),
    initialPageParam: 1,
    getNextPageParam,
    placeholderData: keepPreviousData,
    refetchOnMount: true,
  })

  const onRefetchVisitsHistory = useCallback<TRefetchFilterStaticCb>(
    async (values) => {
      const newParams = { ...initialQuery, ...values?.filterData }
      await router.replace({ pathname: router.pathname, query: QS.stringify(newParams) })
    },
    [router, initialQuery],
  )

  return {
    visitsHistory: fetchVisitsHistoryProps.data || visitsHistoryState.visitsHistory,
    visitsHistoryHasMore: fetchVisitsHistoryProps.hasNextPage,
    onRefetchVisitsHistory,
    visitsFiltersStaticInitialValues: initialQuery,
    onFetchMoreVisitsHistory: fetchVisitsHistoryProps.fetchNextPage,
    loadingVisitsHistoryFetch: fetchVisitsHistoryProps.isLoading,
    visitsHistorySortByInitialValues: { sort: visitsHistorySortBy },
  }
}
