import { z } from 'zod'

import { metaSchema } from './Base'
import { HttpServerRequestParams, BaseDataInfiniteType } from './index'
import { selectSchema } from './StaticData'

export const returnBaseSchema = z.object({
  id: z.number(),
  quantity: z.number(),
  status: selectSchema,
  isEditable: z.boolean(),
  claimsCount: z.number(),
  totalSum: z.number(),
  dateCreated: z.number(),
})

export const returnApiSchema = returnBaseSchema.merge(z.object({ dateCreated: z.number() }))
export const returnSchema = returnBaseSchema.merge(z.object({ createdAt: z.number() }))

export const returnApiListSchema = z.object({
  items: returnApiSchema.array(),
  meta: metaSchema,
})

export const returnTotalSchema = z.object({
  id: z.number(),
  dateCreated: z.number(),
  total: z
    .object({
      articles: z.number().nullable(),
      quantity: z.number().nullable(),
      sum: z.number().nullable(),
    })
    .nullable(),
  isEditable: z.boolean(),
  isClaimsReady: z.boolean(),
})

export const returnInfoMarkerSchema = z.array(
  z.object({
    id: z.number(),
    icon: z.string(),
    title: z.string(),
    description: z.string(),
  }),
)

export type ReturnType = z.infer<typeof returnSchema>
export type ReturnsType = z.infer<typeof returnApiListSchema>
export type ReturnsInfiniteType = BaseDataInfiniteType<ReturnType>
export type ReturnTotalType = z.infer<typeof returnTotalSchema>
export type ReturnsInfoMarkersType = z.infer<typeof returnInfoMarkerSchema>
export type ReturnsCreateParams = { customerId: number }
export type ReturnsParams = HttpServerRequestParams<FormData | ReturnsCreateParams>
