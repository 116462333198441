import { complement, pathEq, pathOr } from 'ramda'

import { AuthRole } from 'src/models'

export const getIsRoleProspector = pathEq('prospector', ['role', 'name'])
export const getIsRoleServiceAgent = pathEq('service-agent', ['role', 'name'])
export const getCurrentRole = pathOr<AuthRole>('service-agent', ['role', 'name'])
export const getCurrentCountry = pathOr('fr', ['country', 'value'])
export const getIsCountryDE = pathEq('de', ['country', 'value'])
export const getIsCountryFR = pathEq('fr', ['country', 'value'])
export const getIsCountryNL = pathEq('nl', ['country', 'value'])
export const getIsCountryAT = pathEq('at', ['country', 'value'])
export const getIsCountryNotNL = complement(getIsCountryNL)
