import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'next-i18next'
import { ReactNode } from 'react'
import { useForm } from 'react-hook-form'
import { HookReturn } from 'use-places-autocomplete'

import { Button, Form, FormControl, FormField, FormFields, FormItem, FormMessage } from 'src/components/ui'

import { FormCreateTourPointsEnum, FormCreateTourPointsType, FormSubmitFn, LocationType } from 'src/models'

import { formShapes } from 'src/bus/forms'

import { messages } from 'src/utils'

type CreateTourPointsFormProps = {
  onSubmit: FormSubmitFn<FormCreateTourPointsType>
  renderMap: ReactNode
  loading: boolean
  loadingCreate: boolean
  panTo: (latLng: LocationType | null) => void
  placesAutocomplete: HookReturn
}

export const CreateTourPointsForm = ({
  onSubmit,
  loading,
  loadingCreate,
  renderMap,
  panTo,
  placesAutocomplete,
}: CreateTourPointsFormProps) => {
  const { t } = useTranslation()

  const formProps = useForm<FormCreateTourPointsType>({
    defaultValues: { ...formShapes.createTourPoints.shape },
    resolver: zodResolver(formShapes.createTourPoints.schema(t)),
  })

  const { control, handleSubmit, setError, reset } = formProps

  const onSubmitForm = handleSubmit(async (values) => {
    await onSubmit({ values, acts: { setError, reset } })
  })

  return (
    <Form {...formProps}>
      <FormFields.FormGeneral
        onSubmit={onSubmitForm}
        footer={
          <div className='p-2'>
            <Button
              block
              type='submit'
              loading={loadingCreate}
              disabled={loading}
              data-test-id='create-new-destination-btn'
            >
              {t('buttons:create_new_point')}
            </Button>
          </div>
        }
      >
        <div className='absolute left-0 z-10 w-full p-2' data-test-id='tour-points-create'>
          <FormField
            control={control}
            name={FormCreateTourPointsEnum.label}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <FormFields.InputBase {...field} placeholder={t(messages.tourPointName.placeholder)} />
                </FormControl>
                <FormMessage className='absolute' />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name={FormCreateTourPointsEnum.coordinates}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <FormFields.InputGoogleSearch
                    {...field}
                    {...placesAutocomplete}
                    panTo={panTo}
                    placeholder={t(messages.addressWaypoint.placeholder)}
                  />
                </FormControl>
                <FormMessage className='absolute' />
              </FormItem>
            )}
          />
        </div>
        <div className='flex h-full flex-col'>{renderMap}</div>
      </FormFields.FormGeneral>
    </Form>
  )
}
