import { SettingsType } from 'src/models'

import { settingsService, settingsKeys, settingsState } from 'src/bus/settings'

import { useQueryAsync } from 'src/hooks'

type UseSettingsFetchType = {
  loadingSettingsFetch: boolean
  settings: SettingsType
}

export const useSettingsFetch = (): UseSettingsFetchType => {
  const fetchSettingsProps = useQueryAsync<SettingsType>({
    key: settingsKeys.settings(),
    name: 'fetchSettings',
    fetcher: settingsService.fetchSettings,
  })

  return {
    loadingSettingsFetch: fetchSettingsProps.isLoading,
    settings: fetchSettingsProps.data || settingsState.settings,
  }
}
