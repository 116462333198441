import { Ellipsis as AntMobileEllipsis } from 'antd-mobile'
import type { EllipsisProps } from 'antd-mobile/es/components/ellipsis'
import { useTranslation } from 'next-i18next'

import st from './Styles.module.css'

export const HistoryEllipsis = ({
  content = '',
  direction = 'end',
  rows = 1,
  expandText = 'more',
  collapseText = 'less',
}: EllipsisProps) => {
  const { t } = useTranslation()

  return (
    <div className={st['ellipsis']}>
      <AntMobileEllipsis
        direction={direction}
        rows={rows}
        content={content}
        expandText={t(expandText as string)}
        collapseText={t(collapseText as string)}
      />
    </div>
  )
}
