import { useMutation, UseMutationResult } from '@tanstack/react-query'

import { SettingsParams, HttpErrorType } from 'src/models'

import { settingsService } from 'src/bus/settings'

import { queryFetcher } from 'src/utils'

type UseSettingsUpdateMutationType = {
  onUpdateSettingsMutation: UseMutationResult<void, HttpErrorType, SettingsParams>
}

export const useSettingsUpdateMutation = (): UseSettingsUpdateMutationType => {
  const onUpdateSettingsMutation = useMutation<void, HttpErrorType, SettingsParams>({
    mutationFn: (params) => {
      return queryFetcher({
        name: 'updateSettings',
        fetcher: settingsService.updateSettings,
        fetcherPayload: params,
      })
    },
  })

  return { onUpdateSettingsMutation }
}
