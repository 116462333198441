import { HttpResponse, SettingsParams, settingsSchema, SettingsType } from 'src/models'

import { zodValidateResponse } from 'src/utils'

import { httpAxiosProvider } from 'src/api'

export const settingsService = Object.freeze({
  fetchSettings: async () => {
    const response = await httpAxiosProvider.get<HttpResponse<SettingsType>>('/employee-settings')
    zodValidateResponse(settingsSchema, response.data.data)
    return response
  },
  updateSettings: ({ data }: SettingsParams) => {
    return httpAxiosProvider.put<HttpResponse<void>>('/employee-settings', data)
  },
})
