import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { book } from 'src/init/book'

import { Dialog, DialogViewsSwitcher } from 'src/components/common'
import { Button, FloatingPanel, Icon, ListItem, Typography } from 'src/components/ui'

import { MenuProfileType } from 'src/models'

import { useCustomersDetailsFetch } from 'src/bus/customers'
import { useVisitsHistoryDetailsFetch } from 'src/bus/visitsHistory'

import { useDialog } from 'src/contexts'

import { getVisitHistoryPanel, getPlanedStatus, getCanceledStatus, GET_ENUMS, FLOATING_PANEL_ANCHORS } from 'src/utils'

export const VisitHistoryPlanPanel = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const { onPageDialogs, setOnPageDialog } = useDialog()
  const { visitsHistoryDetails, loadingVisitsHistoryDetailsFetch } = useVisitsHistoryDetailsFetch()
  const { customersDetails, loadingCustomersDetailsFetch } = useCustomersDetailsFetch()

  const visitId = router.query.id

  const isPlanedStatus = getPlanedStatus(visitsHistoryDetails)
  const isCanceledStatus = getCanceledStatus(visitsHistoryDetails)

  const btnTitle = isCanceledStatus ? 'view_cancel_reason' : isPlanedStatus ? 'create_report' : 'view_report'

  const items = getVisitHistoryPanel({
    t,
    customerId: Number(customersDetails?.id),
    isPlanedStatus,
    loadingFetch: loadingCustomersDetailsFetch || loadingVisitsHistoryDetailsFetch,
  })

  const onNavigationHandler = async () => {
    if (isCanceledStatus) {
      setOnPageDialog(GET_ENUMS.dialog.cancelVisitReason, true)
      return
    }

    if (!isPlanedStatus) {
      await router.push(`${book.visitsHistory}/${visitId}${book.visitReport}`)
      return
    }

    visitId && (await router.push(`${book.visits}/${visitId}${book.visitReport}`))
  }

  const onClickHandler = async (item: MenuProfileType) => {
    if (item.dialog) {
      setOnPageDialog(item.key, true)
      return
    }

    await router.push(item.key)
  }

  return (
    <>
      {[GET_ENUMS.dialog.cancelVisit, GET_ENUMS.dialog.replanVisit, GET_ENUMS.dialog.cancelVisitReason].map((key) => {
        return (
          <Dialog key={key} open={onPageDialogs[key]?.visible} onOpenChange={(open) => setOnPageDialog(key, open)}>
            <DialogViewsSwitcher name={key} params={{ title: visitsHistoryDetails?.companyName }} />
          </Dialog>
        )
      })}
      <FloatingPanel anchors={FLOATING_PANEL_ANCHORS.THREE_STEPS}>
        <div className='mb-4 px-4'>
          <Button block disabled={isPlanedStatus} onClick={onNavigationHandler}>
            {t(`buttons:${btnTitle}`)}
          </Button>
        </div>
        {items.map((item) => {
          const isCancel = item.type === 'cancel'

          return (
            <ListItem key={item.key} disabled={item.disabled} onClick={() => onClickHandler(item)} size='panel'>
              <div className='flex items-center gap-4'>
                <Icon name={item.icon} color={isCancel ? 'error' : 'blue-gray-7'} />
                <Typography color={isCancel ? 'error' : 'default'}>{item.title}</Typography>
              </div>
            </ListItem>
          )
        })}
      </FloatingPanel>
    </>
  )
}
